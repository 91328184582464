// Private
export const _RESET_STATE = 'Zagrywki/user/_RESET_STATE';
export const _SAVE_REQUEST = 'Zagrywki/user/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/user/_SAVE_SUCCESS';
export const _SAVE_FAILURE = 'Zagrywki/user/_SAVE_FAILURE';

export const _GET_REQUEST = 'Zagrywki/user/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/user/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/user/_GET_FAILURE';

export const _GET_ADMIN_REQUEST = 'Zagrywki/user/_GET_ADMIN_REQUEST';
export const _GET_ADMIN_SUCCESS = 'Zagrywki/user/_GET_ADMIN_SUCCESS';
export const _GET_ADMIN_FAILURE = 'Zagrywki/user/_GET_ADMIN_FAILURE';

// Public
export const EDIT = 'Zagrywki/user/EDIT';
export const ADD = 'Zagrywki/user/ADD';
export const SAVE = 'Zagrywki/user/SAVE';
export const MOUNTED = 'Zagrywki/user/MOUNTED';
export const REMOVE = 'Zagrywki/user/REMOVE';
