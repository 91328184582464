import { combineReducers } from 'redux';

import adminUsersReducer from './adminUser';
import availableUsersReducer from './availableUsers';
import consentsListReducer from './consentsList';
import entryFormReducer from './entryForm';
import tokenReducer from './tokenFormIo';
import usersReducer from './users';

const reducer = combineReducers({
  adminUser: adminUsersReducer,
  availableUsers: availableUsersReducer,
  consentsList: consentsListReducer,
  entryForm: entryFormReducer,
  token: tokenReducer,
  users: usersReducer,
});

export default reducer;
