import { get } from '@Model/authorization/selectors';
import { edit, mounted, unmount } from '@Model/happening/actions';
import { getHappening } from '@Model/happening/selectors';
import { allPermissions, HAPPENING } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Edit from './Edit.component';
import { IEditFromDispatch, IEditFromState } from './Edit.types';

const mapStateToProps = (state: _Store.IState): IEditFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    happening: getHappening(state),
    isLoading: getLoading(HAPPENING)(state),
    permissionHappeningsWrite: permissions.includes(
      allPermissions.access_happenings_write,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IEditFromDispatch => {
  return {
    mounted: () => {
      dispatch(mounted());
    },
    save: (happening) => {
      dispatch(edit(happening));
    },
    unmount: () => {
      dispatch(unmount());
    },
  };
};

export default connect<IEditFromState, IEditFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
