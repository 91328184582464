import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import {
  catchSelectedKeys,
  getReport,
  setFilters,
} from '@Model/reports/actions';
import { getReports } from '@Model/reports/selectors';
import { getReservationDetails } from '@Model/reservation/selectors';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import Reports from './Reports.component';
import {
  IFilter,
  ISummaryFromDispatch,
  ISummaryFromState,
} from './Reports.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const {
    userInfo: { session, permissions },
  } = get(state);

  const { keys, selectedKeys, items, filters, selectedReport } = getReports(
    state,
  );
  return {
    isSession: !!(session && session.session_id),
    keys,
    permissionReportsRead: permissions.includes(
      allPermissions.access_reports_read,
    ),
    reports: items,
    reservationDetails: getReservationDetails(state),
    selectedFilters: filters,
    selectedKeys,
    selectedReport,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryFromDispatch => ({
  catchSelectedKeys: (key: string) => dispatch(catchSelectedKeys(key)),
  getReport: () => dispatch(getReport()),
  setFilters: (filters: IFilter[]) => dispatch(setFilters(filters)),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Reports);
