import React, { useEffect, useState } from 'react';
import { Form, Loader } from 'tabler-react';

import ConsentsListTable from './components/ConsentsListTable';
import Pagination from './components/Pagination';
import { IConsentsListProps } from './ConsentsList.types';

import styles from './ConsentsList.module.scss';
import moment from 'moment';

const monthLabels = [
  'styczeń',
  'luty',
  'marzec',
  'kwiecień',
  'maj',
  'czerwiec',
  'lipiec',
  'sierpień',
  'wrzesień',
  'październik',
  'listopad',
  'grudzień',
];

const ConsentsList = ({
  mounted,
  isLoading,
  limit,
  getFormIOUsers,
  setEmail,
  setFirstName,
  setLastName,
  email,
  firstName,
  lastName,
  date,
  setDate,
}: IConsentsListProps) => {
  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);
  const SEARCH_EMAIL_TEXT = 'Email:';
  const SEARCH_FIRST_NAME_TEXT = 'Imie:';
  const SEARCH_LAST_NAME_TEXT = 'Nazwisko:';
  const SEARCH_BRIGHT_DAY_TEXT = 'Data urodzenia:';

  useEffect(() => {
    mounted(limit);
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  const search = () => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(setTimeout(() => {
      getFormIOUsers();
    }, 1000) as any);
  };

  const catchHandleSearchByEmail = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setEmail(value);
    search();
  };

  const catchHandleSearchByFirstName = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setFirstName(value);
    search();
  };

  const catchHandleSearchByLastName = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setLastName(value);
    search();
  };

  const catchHandleSearchByDate = (value: string) => {
    setDate(value);
    search();
  };

  return (
    <>
      <Form.Input
        className={styles.searchInput}
        name="search"
        placeholder={SEARCH_EMAIL_TEXT}
        value={email}
        onChange={catchHandleSearchByEmail}
        autoComplete="off"
      />
      <Form.Input
        className={styles.searchInput}
        name="search"
        placeholder={SEARCH_FIRST_NAME_TEXT}
        value={firstName}
        onChange={catchHandleSearchByFirstName}
        autoComplete="off"
      />
      <Form.Input
        className={styles.searchInput}
        name="search"
        placeholder={SEARCH_LAST_NAME_TEXT}
        value={lastName}
        onChange={catchHandleSearchByLastName}
        autoComplete="off"
      />

      {/* TODO: keep it */}
      {/* <Form.DatePicker
        className={styles.searchInput}
        name="search"
        placeholder={SEARCH_BRIGHT_DAY_TEXT}
        defaultDate={moment(date || new Date()).toDate()}
        onChange={catchHandleSearchByDate}
        autoComplete="off"
        monthLabels={monthLabels}
      /> */}

      <ConsentsListTable />
      <Pagination />
    </>
  );
};

export default ConsentsList;
