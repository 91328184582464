import { get, getUserInfo } from '@Model/authorization/selectors';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
} from 'connected-react-router';
import format from 'date-fns/format';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  take as take$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import routes from '@/routes/routes';
import config from '@Config';
import { getHappening, resetState } from '@Model/happening/actions';
import {
  getAvailabilities,
  getHappening as getHappeningSelector,
} from '@Model/happening/selectors';
import { getHappenings } from '@Model/happenings/actions';
import { getHappenings as selectHappenings } from '@Model/happenings/selectors';
import {
  getPrinters,
  getPrintersPinging,
  printReceipt,
  startPingingPrinter,
} from '@Model/printer/actions';
import { getPinging } from '@Model/printer/selectors';
import { getProducts } from '@Model/products/selectors';
import { getAdd, getSelectedSlot } from '@Model/reservation/selectors';
import { resetSelection } from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import {
  ICompanyInfoResponse,
  IReservation,
  ISelectedProduct,
} from '@Services/$reservations-api/types';
import _Store from '@Store';
import moment from 'moment';
import {
  IEmptyReservationSaveRequestPayload,
  IMatch,
  IReservationPrintData,
  ITransactionDetailsResponse,
} from '../types';
import {
  addMounted,
  cancelAutoTransaction,
  cancelTransaction,
  captureTransactionsDetailsRequest,
  captureTransactionsEmpikDetailsRequest,
  catchCompanyData,
  getCompanyData,
  getReservationPrintData,
  postEmptyReservation,
  postReservation,
  reset,
  saveBill,
  saveReservation,
  selectHappening,
  selectTimeSlot,
  setCompanyData,
  setDate,
  setDurationTimeAfterMidnight,
  setTimeSlot,
  summaryMounted,
  transactionSave,
} from './../actions';

const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const SAVE_BILL_ERROR_TEXT = 'Paragon został juz wydrukowany';
const RECEIPT_HAS_BEEN_SAVED = 'Paragon został zapisany w systemie';
const RESERVATION_WITHOUT_TRANSACTION_DONE_TEXT =
  'Rezerwacja bez transakcji dodana';

const CHECK_PRICE = false;

export interface IPriceCheckBodyUpSell {
  configurationId: number;
}

// TODO get from shaved librart
export interface IPriceCheckBodyDiscount {
  code: string | null;
}

// TODO get from shaved librart
export interface IPriceCheckBody {
  dateTime: string;
  discount?: IPriceCheckBodyDiscount;
  numberOfPeople?: number;
  price: number;
  spaceId: number | null;
  upsell?: IPriceCheckBodyUpSell;
  products: ISelectedProduct[];
  rulePriceId?: number;
  priceType?: string;
}

export const getPriceReduction = (
  // TODO get from shaved librart
  discountCode: string,
  upSellSelected: boolean,
  price: number,
  dateTime: string,
  numberOfPeople: number,
  products: ISelectedProduct[],
  spaceId: number,
  configurationId: number | null,
  priceType: string,
): IPriceCheckBody | undefined => {
  if ((discountCode && discountCode.length) || upSellSelected) {
    const priceReduction: IPriceCheckBody = {
      dateTime,
      numberOfPeople,
      price,
      products,
      spaceId,
    };

    if (discountCode) {
      priceReduction.discount = {
        code: discountCode,
      };
    }

    if (upSellSelected) {
      priceReduction.upsell = {
        configurationId: configurationId || 0,
      };
    }

    if (config.cms.showUnfinishedFeatures) {
      priceReduction.rulePriceId = configurationId || 0;
      priceReduction.priceType = priceType;

      if (upSellSelected) {
        priceReduction.upsell = true as any; // TODO: nomalize
      }
    }

    return priceReduction;
  }

  return undefined;
};

export const requestForHappeningsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(addMounted)),
    mergeMap$(() => {
      return of$(getHappenings.request(), reset());
    }),
  );
};

export const requestForHappeningWhenSelected: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(selectHappening)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const happenings = selectHappenings(state);
      const happeningId = action.payload;

      if (happenings && happeningId) {
        const happening = happenings.find((item) => item.id === happeningId);

        if (happening) {
          return of$(getHappening.request(happening.metadata.slug));
        }
      } else if (happeningId === null) {
        return of$(resetState());
      }

      return EMPTY$;
    }),
  );
};

export const handleSettingSlotWhenRequested: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(selectTimeSlot)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSlot = action.payload;
      const currentSlot = getSelectedSlot(state);

      if (selectedSlot === null) {
        return EMPTY$;
      }

      if (
        selectedSlot &&
        currentSlot &&
        selectedSlot.startTime === currentSlot.startTime
      ) {
        return of$(setTimeSlot(null));
      }

      return of$(setTimeSlot(selectedSlot));
    }),
  );
};

export const requestForPostReservationWhenSave: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(saveReservation)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const addingModel = getAdd(state);
      const { session } = getUserInfo(state);
      const availabilities = getAvailabilities(state);
      const values = action.payload;
      const products = getProducts(state);
      const happening = getHappeningSelector(state);
      let price = 0;
      let configurationId = -1;
      let priceType = '';

      if (!session) {
        return EMPTY$;
      }

      const { session_external_id: sessionUuid } = session;

      const getNumberOfPeople = (): number | undefined => {
        if (
          values.numberOfPeople &&
          values.numberOfPeople > 0 &&
          happening &&
          happening.calculatePricePerPerson
        ) {
          return values.numberOfPeople;
        } else if (happening) {
          const selectedSpace = happening.spaces.find(
            (space) => space.id === values.spaceId,
          );
          if (selectedSpace && selectedSpace.maxNumberOfPeople) {
            return selectedSpace.maxNumberOfPeople;
          }
        } else if (!values.happeningId && !values.spaceId) {
          return undefined;
        }
        return 0;
      };

      const selectedProducts = (): ISelectedProduct[] => {
        const DEFAULT_SELECTED_PRODUCT_COUNT = 1;

        if (products && products.items && products.items.length) {
          const { items } = products;

          return items
            .filter((item) => item && item.count && item.count > 0)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || DEFAULT_SELECTED_PRODUCT_COUNT,
            }));
        }
        return [];
      };

      let mergedDays = availabilities.currentDay;

      if (availabilities.otherDays) {
        mergedDays = {
          ...availabilities.currentDay,
          ...availabilities.otherDays,
        };
      }

      if (addingModel.selectedSlot && addingModel.selectedSlot.startTime) {
        const space = mergedDays[addingModel.selectedSlot.startTime].find(
          (_space) => _space.spaceId === values.spaceId,
        );

        if (space) {
          configurationId = space.configurationId || -1;
          price = space.price;

          if (
            config.cms.showUnfinishedFeatures &&
            space &&
            space.prices &&
            space.prices.length
          ) {
            configurationId = space.rulePriceId;

            const defaultPriceKey = space.prices.findIndex(
              (_price) => _price.type === 'default',
            );

            if (defaultPriceKey !== -1) {
              priceType = space.prices[defaultPriceKey].type;
            }

            priceType = space.prices[0].type;
          }
        }
      }

      if (
        !values.spaceId ||
        !values.reservationCheckbox ||
        (values.happeningId && values.happeningId === -1)
      ) {
        values.spaceId = undefined;
      }

      if (
        !values.happeningId ||
        !values.reservationCheckbox ||
        (values.happeningId && values.happeningId === -1)
      ) {
        values.happeningId = undefined;
      }

      if (
        !values.discount ||
        !values.showDiscountForm ||
        (values.happeningId && values.happeningId === -1)
      ) {
        values.discount = undefined;
      }

      if (
        (!values.happeningId && !values.spaceId) ||
        !values.reservationCheckbox
      ) {
        values.dateTime = undefined;
      }

      const getCheckTransactionLinks = () => {
        const { baseUrl } = config.cms;
        return {
          linkCancel: `${baseUrl}?transactionId={transactionId}&error=500`,
          linkFail: `${baseUrl}?transactionId={transactionId}&error=401`,
          linkOk: `${baseUrl}?transactionId={transactionId}`,
        };
      };

      const getSelectedDayIncludeTimeSlot = () => {
        if (
          values.startTime &&
          values.date &&
          availabilities.otherDays &&
          availabilities.otherDays[values.startTime]
        ) {
          const day = moment(values.date)
            .add('days', 1)
            .toDate();

          const dateTime = `${format(day || values.dateTime, 'yyyy-MM-dd')}T${
            values.startTime
          }+00:00`;

          return {
            dateTime,
          };
        }

        return {
          dateTime: `${format(moment(values.date).toDate(), 'yyyy-MM-dd')}T${
            values.startTime
          }+00:00`,
        };
      };

      const getReservations = (): IReservation[] => {
        const getCurrentReservation = (): IReservation[] => {
          if (happening && happening.id && values.spaceId) {
            const getDuration = (): number | undefined => {
              if (happening) {
                const selectedSpace = happening.spaces.find(
                  (space) => space.id === values.spaceId,
                );
                if (selectedSpace && selectedSpace.timeSlot) {
                  return selectedSpace.timeSlot;
                }
              }
              return undefined;
            };

            const currentReservation: IReservation = {
              ...getSelectedDayIncludeTimeSlot(),
              duration: getDuration(),
              happeningId: happening.id,
              numberOfPeople: getNumberOfPeople(),
              priceReduction: getPriceReduction(
                values.discount || '',
                !!values.upsell,
                price ? price : 0,
                getSelectedDayIncludeTimeSlot().dateTime,
                getNumberOfPeople() || 0,
                [],
                values.spaceId ? values.spaceId : 0,
                configurationId || null,
                priceType,
              ),
              priceType,
              products: [],
              spaceId: values.spaceId,
            };
            return [currentReservation];
          }
          return [];
        };

        const getReservationsFromBasket = (): IReservation[] => {
          const { basketItems } = action.payload;

          if (basketItems && basketItems.length) {
            return basketItems.map((item) => {
              return {
                dateTime: item.dateTime,
                duration: item.duration,
                happeningId: item.happeningId,
                numberOfPeople: item.numberOfPeople,
                priceReduction: getPriceReduction(
                  values.discount || '',
                  !!item.isUpSellSelected,
                  item.price,
                  item.dateTime || '',
                  item.numberOfPeople,
                  [],
                  item.spaceId,
                  item.configurationId,
                  item.priceType,
                ),
                priceType: item.priceType,
                products: [],
                spaceId: item.spaceId,
                title: item.title,
              };
            });
          }
          return [];
        };

        return [...getReservationsFromBasket(), ...getCurrentReservation()];
      };

      const getDelayProps = () => {
        if (values.delayedTransaction) {
          return {
            delay: 86400,
          };
        }
        return {};
      };

      const payload = {
        products: selectedProducts(),
        ...values,
        ...getCheckTransactionLinks(),
        ...getDelayProps(),
        agent: values.delayedTransaction ? 'zagrywki-web' : 'zagrywki-onsite',
        idempotencyKey: values.idempotencyKey || '',
        numberOfPeople: getNumberOfPeople(),
        onDone: action.payload.onDone,
        paymentOperator: values.delayedTransaction ? 3 : 2,
        prepaidCard: values.prepaidCard || undefined,
        reservations: getReservations(),
        salesChannelId: 12,
        sessionIdentifier: sessionUuid,
      };

      delete payload.date;
      delete payload.basketItems;
      delete payload.happeningId;
      delete payload.spaceId;

      if (values.delayedTransaction && payload.linkCancel) {
        delete payload.linkCancel;
      }

      // Save only empty reservation to free space
      if (
        values.emptyReservation &&
        payload.reservations &&
        payload.reservations.length
      ) {
        const currentHappening = payload.reservations[0];

        const emptyPayload: IEmptyReservationSaveRequestPayload = {
          ...getSelectedDayIncludeTimeSlot(),
          description: '',
          happeningId: currentHappening.happeningId,
          numberOfPeople: currentHappening.numberOfPeople,
          onDone: action.payload.onDone,
          paymentOperator: 3,
          salesChannelId: 12,
          spaceId: currentHappening.spaceId,
          user: payload.user,
        };

        return [postEmptyReservation.request(emptyPayload)];
      }

      return [postReservation.request(payload)];
    }),
  );
};

export const postEmptyReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi, iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(postEmptyReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const {
        payload,
        payload: { onDone },
      } = action;
      return from$(reservationsApi.saveEmptyReservation(payload)).pipe(
        mergeMap$((data) => {
          onDone();
          return [
            addToast(RESERVATION_WITHOUT_TRANSACTION_DONE_TEXT, TYPE_SUCCESS),
            push(routes.calendar),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelReservationSave()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(postReservation.failure(error));
        }),
      );
    }),
  );
};
export const postReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi, iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(postReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const {
        payload,
        payload: { onDone },
      } = action;
      return from$(reservationsApi.saveNewReservation(payload)).pipe(
        mergeMap$((data) => {
          onDone();

          if (data && data.payment && data.payment.redirect) {
            return [
              transactionSave(data.payment),
              addToast('Rezerwacja dodana', TYPE_SUCCESS),
              push(routes.checking),
            ];
          }
          if (data && data.payment && data.payment.formUrl) {
            iframeProvider.runRedirectParentMethod(data.payment.formUrl);
            return EMPTY$;
          }

          return EMPTY$;
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelReservationSave()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(postReservation.failure(error));
        }),
      );

      return EMPTY$;
    }),
  );
};

export const capturePrintersList: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([summaryMounted])),
    take$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(getPrinters.request());
    }),
  );
};

export const caputeTransactionDetailsWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getPrinters.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summarySuccess);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return of$(captureTransactionsEmpikDetailsRequest.request(id));
      }

      return EMPTY$;
    }),
  );
};

export const startPingingWhenPrintersFetch: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getPrintersPinging.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userData = getUserInfo(state);
      const pinging = getPinging(state);
      if (userData && userData.session && !pinging) {
        return of$(
          startPingingPrinter({
            printerId: userData.session.printer_id,
            startCash: userData.session.start_cash,
            userId: userData.id,
          }),
        );
      }

      return EMPTY$;
    }),
  );
};

export const captureTransactionEmpikDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi, linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(
        reservationsApi.getTransactionEmpikDetails(action.payload),
      ).pipe(
        mergeMap$((response: ITransactionDetailsResponse) => {
          return of$(getReservationPrintData.success(response as any));
        }),
        catchError$(() => {
          const redirectUrl = linksProvider.buildSummaryFailLink(
            action.payload,
          );

          return of$(
            push(redirectUrl),
            captureTransactionsDetailsRequest.failure(
              new Error(HAPPENING_DOESNT_EXIST_TEXT),
            ),
          );
        }),
      );
    }),
  );
};

export const cancelTransactionWhenPayload: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(cancelTransaction)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        return from$(reservationsApi.cancelTransaction(action.payload)).pipe(
          mergeMap$(() => {
            return of$(resetSelection(), getHappenings.request());
          }),
          catchError$(() => {
            return EMPTY$;
          }),
        );
      }

      const matchSelectorFail = createMatchSelector(routes.summaryFail);
      const matchSelectorCanceled = createMatchSelector(routes.summaryCanceled);

      const matchFail: IMatch | null = matchSelectorFail(state);
      const matchCanceled: IMatch | null = matchSelectorCanceled(state);

      const match = matchFail || matchCanceled;

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return from$(reservationsApi.cancelTransaction(id)).pipe(
          mergeMap$(() => {
            return EMPTY$;
          }),
          catchError$(() => {
            return EMPTY$;
          }),
        );
      }
      return EMPTY$;
    }),
    catchError$(() => {
      return EMPTY$;
    }),
  );
};

export const cancelAutoTransactionWhenPayload: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(cancelAutoTransaction)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        return from$(
          reservationsApi.cancelAutoTransaction(action.payload),
        ).pipe(
          mergeMap$(() => {
            return of$(resetSelection(), getHappenings.request());
          }),
        );
      }
      return EMPTY$;
    }),
  );
};

export const saveBillWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(saveBill)),
    mergeMap$((action) => {
      return from$(reservationsApi.saveBill(action.payload)).pipe(
        mergeMap$(() => {
          return [addToast(RECEIPT_HAS_BEEN_SAVED, TYPE_SUCCESS)];
        }),
        catchError$((error: Error) => [
          addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR),
        ]),
      );
    }),
    catchError$((error: Error) => [addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR)]),
  );
};

export const getReceiptPrintDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationPrintData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summarySuccess);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return from$(reservationsApi.getTransactionPrintDetails(id)).pipe(
          mergeMap$((data: IReservationPrintData) => {
            return of$(getReservationPrintData.success(data));
          }),
          catchError$(() => {
            return EMPTY$;
          }),
        );
      }
      return EMPTY$;
    }),
  );
};

export const getReceiptWhenRequestWhentPrindDataSuccess: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationPrintData.success)),
    mergeMap$((action) => {
      const { billId } = action.payload;

      if (billId) {
        return of$(addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR));
      }
      return of$(printReceipt.request());
    }),
  );
};

export const catchCompanyDataWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchCompanyData)),
    mergeMap$((action) => {
      return of$(getCompanyData.request(action.payload));
    }),
  );
};

export const getCompanyDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getCompanyData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(reservationsApi.getCompanyData(action.payload)).pipe(
        mergeMap$((response: ICompanyInfoResponse) => {
          const {
            apartmentNumber,
            city,
            propertyNumber,
            name: companyName,
            postCode: zipCode,
            street,
            taxNumber: nip,
          } = response;
          const getPropertyNumber = (): string => {
            if (apartmentNumber) {
              return `${propertyNumber}/${apartmentNumber}`;
            }
            return propertyNumber;
          };

          const data = {
            city,
            companyName,
            facture: true,
            houseNumber: '',
            nip,
            propertyNumber: getPropertyNumber(),
            street,
            zipCode,
          };

          return of$(setCompanyData(data), getCompanyData.success());
        }),
        catchError$(() => {
          return of$(getCompanyData.failure());
        }),
      );
    }),
    catchError$(() => {
      return EMPTY$;
    }),
  );
};

export const whenGetHappeningCheckTimeIsBetweenMidnightAndTime: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const happening = getHappeningSelector(state);

      if (!happening || !happening.startShowingSlotsAt) {
        return EMPTY$;
      }

      const { startShowingSlotsAt } = happening;

      const slotDateTime = moment();
      const time = moment(startShowingSlotsAt, 'HH:mm:ss');

      slotDateTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second'),
      });

      const midnightTime = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const now = moment();

      const isBetweenMidnightAndTime: boolean = now.isBetween(
        midnightTime,
        slotDateTime,
      );

      if (isBetweenMidnightAndTime) {
        return [
          setDate(
            moment()
              .subtract(1, 'days')
              .toDate(),
          ),
          setDurationTimeAfterMidnight(),
        ];
      }

      return [setDate(moment().toDate())];
    }),
  );
};
