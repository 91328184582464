import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getFormIoConsentsList } from '@Model/formio/selectors';
import {
  catchCancelDelayedTransaction,
  catchDelayedTransactions,
} from '@Model/reservations/actions';
import { getDelayedTransactions } from '@Model/reservations/selectors';
import { DELAYED_TRANSACTIONS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import TransactionDelayed from './TransactionsDelayed.component';
import {
  IConsentsListFromDispatch,
  IConsentsListFromState,
} from './TransactionsDelayed.types';

const mapStateToProps = (state: _Store.IState): IConsentsListFromState => {
  const {
    perPage,
    totalCount,
    items: delayedTransactions,
  } = getDelayedTransactions(state);

  const showPagination = totalCount > perPage;

  return {
    delayedTransactions,
    isLoading: getLoading(DELAYED_TRANSACTIONS)(state),
    limit: perPage,
    showPagination,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IConsentsListFromDispatch => {
  return {
    cancelTransaction: (UUId: string) =>
      dispatch(catchCancelDelayedTransaction(UUId)),
    mounted: () => dispatch(catchDelayedTransactions()),
  };
};

export default connect<
  IConsentsListFromState,
  IConsentsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionDelayed);
