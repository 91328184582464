import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import SessionForm from '../SessionForm';

const SessionModal = () => {
  return (
    <Portal>
      <Modal>
        <SessionForm />
      </Modal>
    </Portal>
  );
};
export default SessionModal;
