import { LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { getUserInfo } from '@Model/authorization/selectors';
import { mounted } from '@Model/discounts/actions';
import { get as getHappenings } from '@Model/happenings/selectors';
import { selectSpace } from '@Model/reservation/actions';
import { getSelectedDate, getSelectedSlot } from '@Model/reservation/selectors';
import _Store from '@Store';
import {
  addProduct,
  getAllProducts,
  getProducts,
  selectProduct,
  setProductCount,
  unSelectProduct,
  updateList,
} from '../actions';
import { getProducts as getProductsSelector } from '../selectors';
import { IGetProductsPayloadSuccess } from '../types';
// TODO:
// export const whenSelectedSpaceGetProducts: _Store.IEpic = (action$, state$) => {
//   return action$.pipe(
//     filter$(isActionOf([selectSpace])),
//     withLatestFrom$(state$),
//     mergeMap$(([action, state]) => {
//       const slot = getSelectedSlot(state);

//       if (!(action.payload && slot && slot.startTime)) {
//         return EMPTY$;
//       }
//       const spaceId = String(action.payload);
//       const date = getSelectedDate(state) || new Date();
//       const dateTime = `${format(date, 'yyyy-MM-dd')}T${slot.startTime}+00:00`;

//       return of$(getProducts.request({ dateTime, spaceId }));
//     }),
//   );
// };

export const whenReservationMountedGetAllProducts: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$((action) => {
      return of$(getAllProducts.request());
    }),
  );
};

export const getProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getProducts.request)),
    mergeMap$((action) => {
      return from$(productsApi.getProducts(action.payload)).pipe(
        map$((data: IGetProductsPayloadSuccess) => {
          return getProducts.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts()),
          ),
        ),
        catchError$((error: Error) => of$(getProducts.failure(error))),
      );
    }),
  );
};
export const getAllProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getAllProducts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { partnerId } = getHappenings(state);
      const { partners } = getUserInfo(state);

      return from$(
        productsApi.getAllProducts(partnerId || (partners && partners[0].id)),
      ).pipe(
        map$((data: IGetProductsPayloadSuccess) => {
          return getAllProducts.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts()),
          ),
        ),
        catchError$((error: Error) => of$(getAllProducts.failure(error))),
      );
    }),
  );
};

export const selectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                selected: true,
              };
            } else {
              return item;
            }
          }),
        ),
      );
    }),
  );
};
export const addProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(addProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const finedProduct = items.find(
        (product) => product.id === action.payload.id,
      );

      if (finedProduct) {
        return of$(
          updateList(
            items.map((item) => {
              if (item.id === action.payload.id) {
                return {
                  ...item,
                  count: (item.count || 1) + 1,
                  selected: true,
                };
              } else {
                return item;
              }
            }),
          ),
        );
      } else {
        return of$(
          updateList([
            ...items,
            { ...action.payload, count: 1, selected: true },
          ]),
        );
      }
    }),
  );
};

export const unSelectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(unSelectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                selected: false,
              };
            } else {
              return item;
            }
          }),
        ),
      );
    }),
  );
};

export const setProductCountWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setProductCount)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const {
        product: { id },
        count,
      } = action.payload;

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === id) {
              if (count) {
                return {
                  ...item,
                  count,
                  selected: true,
                };
              }
              return {
                ...item,
                count: 0,
                selected: false,
              };
            }
            return item;
          }),
        ),
      );
    }),
  );
};
