import React, { useState } from 'react';

import cn from 'classnames';
import { Button, Card, Form } from 'tabler-react';

import { IFilterItemProps } from './FilterItem.types';

import styles from './FilterItem.module.scss';

const FilterItem = ({ happening, switchFilter }: IFilterItemProps) => {
  const [showItems, handleShowItems] = useState(false);

  const switchAllHappening = () => {
    switchFilter(happening.id);
  };

  return (
    <Form.Group className={cn(styles.formGroup, 'mb-0')}>
      <Card>
        <Card.Header className={styles.header}>
          <div
            className={styles.color}
            style={{ background: happening.color || '' }}
          />
          <Form.Checkbox
            checked={
              happening.spaces &&
              happening.spaces.every((space) => space.selected)
            }
            isInline={true}
            label=" "
            name="isPublic"
            onChange={switchAllHappening}
          />
          <h5 className={styles.filterTitle}>{happening.title}</h5>
          <Button
            color="secondary"
            onClick={() => handleShowItems(!showItems)}
            icon={showItems ? 'arrow-up' : 'arrow-down'}
          />
        </Card.Header>
        {showItems && (
          <Card.Body>
            {happening.spaces.map((space) => (
              <Form.Checkbox
                checked={space.selected}
                key={space.id}
                label={space.title}
                name={space.slug}
                /* tslint:disable-next-line:jsx-no-lambda */
                onChange={() => switchFilter(happening.id, space.id)}
                value={space.id}
              />
            ))}
          </Card.Body>
        )}
      </Card>
    </Form.Group>
  );
};

export default FilterItem;
