import { getType } from 'typesafe-actions';
import {
  addUser,
  editUser,
  getAdminUsers,
  getUsers,
  resetState,
} from '../actions';
import { IAction, IUserReducer } from '../types';

const initialState: IUserReducer = {
  activeUser: null,
  adminUsers: [],
  showModal: false,
  users: [],
};

const userReducer = (
  state: IUserReducer = initialState,
  action: IAction,
): IUserReducer => {
  switch (action.type) {
    case getType(editUser):
      return {
        ...state,
        activeUser: action.payload,
        showModal: true,
      };

    case getType(getUsers.success):
      return {
        ...state,
        users: action.payload.items,
      };

    case getType(getAdminUsers.success):
      return {
        ...state,
        adminUsers: action.payload.items,
      };

    case getType(addUser):
      return {
        ...state,
        activeUser: null,
        showModal: true,
      };

    case getType(resetState):
      return {
        ...state,
        activeUser: null,
        showModal: false,
      };
    default:
      return state;
  }
};

export default userReducer;
