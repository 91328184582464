const getColorByIndex = (index: number): string => {
  switch (index) {
    case 0:
      return '#2AB99B';
    case 1:
      return '#4EBA6E';
    case 2:
      return '#975CA5';
    case 3:
      return '#D35626';
    case 4:
      return '#E57D26';
    case 5:
      return '#E84A3B';
    case 6:
      return '#4A55A5';
    case 7:
      return '#7F8D8E';
    case 8:
      return '#37495D';
    default:
      return '#2AB99B';
  }
};

export default getColorByIndex;
