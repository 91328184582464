import { getType } from 'typesafe-actions';
import {
  reset,
  selectDiscount,
  selectHappening,
  selectPeopleCount,
  selectSpace,
  selectUpSell,
  setDate,
  setTimeSlot,
} from './../actions';
import { IAction, IReservationAddReducer } from './../types';

const initialState: IReservationAddReducer = {
  happeningId: null,
  selectedDate: null,
  selectedDiscount: null,
  selectedPeopleCount: null,
  selectedSlot: null,
  selectedUpSell: false,
  spaceId: null,
};

const addReducer = (
  state: IReservationAddReducer = initialState,
  action: IAction,
): IReservationAddReducer => {
  switch (action.type) {
    case getType(selectHappening):
      return {
        ...state,
        happeningId: action.payload,
        selectedPeopleCount: 1,
        selectedSlot: null,
        selectedUpSell: false,
        spaceId: null,
      };

    case getType(selectSpace):
      return { ...state, spaceId: action.payload };

    case getType(setDate):
      return { ...state, selectedDate: action.payload };

    case getType(selectPeopleCount):
      return { ...state, selectedPeopleCount: action.payload, spaceId: null };

    case getType(setTimeSlot):
      return { ...state, selectedSlot: action.payload, spaceId: null };

    case getType(selectDiscount):
      return { ...state, selectedDiscount: action.payload };

    case getType(selectUpSell):
      return { ...state, selectedUpSell: action.payload };

    case getType(reset):
      return initialState;

    default:
      return state;
  }
};

export default addReducer;
