import { createMatchSelector } from 'connected-react-router';
import { EMPTY as EMPTY$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';

import routes from '@/routes/routes';
import { history } from '@/store';
import {
  redirectBuyingContinue,
  refreshPage,
  setIframeSession,
  setInnerSizes,
} from '@Model/iframe/actions';
import { IMatch } from '@Model/reservation/types';
import _Store from '@Store';
import { isActionOf } from 'typesafe-actions';

export const runResizeMethodWhenSetDevice: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setInnerSizes)),
    mergeMap$((action) => {
      const { height } = action.payload;

      iframeProvider.runResizeMethod(Math.floor(height) + 'px');

      return EMPTY$;
    }),
  );
};

export const refreshPageWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(refreshPage)),
    mergeMap$((action) => {
      iframeProvider.runRefreshPage();

      return EMPTY$;
    }),
  );
};

export const setIframeSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setIframeSession)),
    mergeMap$((action) => {
      iframeProvider.setIframeSession();

      return EMPTY$;
    }),
  );
};

export const runRedirectBuyingContinue: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(redirectBuyingContinue)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summarySuccess);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        iframeProvider.runRedirectBuyingMethod(id);
        history.push(routes.calendar);
      }

      return EMPTY$;
    }),
  );
};
