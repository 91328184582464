import { boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const PRICE_TO_LOW = 'Wartość za niska';

const configuration = {
  addNewConfiguration: boolean(),
  closed: boolean(),

  price: number().when('closed', {
    is: false,
    then: number()
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  upsellValue: number().when('isUpsell', {
    is: true,
    then: number()
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  isPeopleCount: boolean(),
  perPersonType: string(),
  priceGoesUp: string(),

  fromNumberOfPeople: number().when('isPeopleCount', {
    is: true,
    then: number()
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  perPersonValue: number().when('isPeopleCount', {
    is: true,
    then: number()
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  durationAdvanced: string().when('advanced', {
    is: true,
    then: string().required(REQUIRED_VALIDATOR_TEXT),
  }),
  ruleAdvanced: string().when('advanced', {
    is: true,
    then: string().required(REQUIRED_VALIDATOR_TEXT),
  }),
};

export const AddingConfigurationSchema = object().shape(configuration);
