import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_ADMIN_FAILURE,
  _GET_ADMIN_REQUEST,
  _GET_ADMIN_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  _SAVE_FAILURE,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  ADD,
  EDIT,
  MOUNTED,
  REMOVE,
  SAVE,
} from '../constants/actions';
import {
  IGetAdminUsersSuccessPayload,
  IGetUsersSuccessPayload,
  ISaveUserPayload,
} from '../types';

export const resetState = createStandardAction(_RESET_STATE)();
export const saveUser = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE,
)<ISaveUserPayload, void, Error>();

export const getUsers = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IGetUsersSuccessPayload, Error>();

export const getAdminUsers = createAsyncAction(
  _GET_ADMIN_REQUEST,
  _GET_ADMIN_SUCCESS,
  _GET_ADMIN_FAILURE,
)<undefined, IGetAdminUsersSuccessPayload, Error>();

export const addUser = createStandardAction(ADD)();
export const editUser = createStandardAction(EDIT)<ISaveUserPayload>();
export const remove = createStandardAction(REMOVE)<number>();
export const save = createStandardAction(SAVE)<ISaveUserPayload>();
export const mounted = createStandardAction(MOUNTED)();
