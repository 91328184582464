import React, { FC } from 'react';

import Discounts from '@Compo/Discounts';
import Happenings from '@Compo/happenings/List';
import Grid from '@Compo/layout/Grid';
import PrinterAdmin from '@Compo/PrinterAdmin';
import Calendar from '@Compo/reservations/Calendar';
import { IMainState } from './Main.types';

const NO_PERMISSION_TEXT =
  'Brak dostępu do zakładek menu, poproś administratora o odpowiednie uprawnienia';

const MainAdminPage: FC<IMainState> = ({
  permissionDiscountsView,
  permissionHappeningsView,
  permissionReservationView,
  permissionCashiersRead,
  permissionPrintersRead,
}) => {
  const startPageOptions = [
    {
      component: <Happenings />,
      permission: permissionHappeningsView,
    },
    {
      component: <Calendar />,
      permission: permissionReservationView,
    },
    {
      component: <Discounts />,
      permission: permissionDiscountsView,
    },
    {
      component: <PrinterAdmin />,
      permission: permissionCashiersRead || permissionPrintersRead,
    },
  ];

  const isBookmarkAvailable = (bookmark: { permission: boolean }) => {
    return bookmark.permission;
  };

  const startBookmark = startPageOptions.find(isBookmarkAvailable);

  const getStartPage = () =>
    startBookmark ? startBookmark.component : NO_PERMISSION_TEXT;

  return <Grid>{getStartPage()}</Grid>;
};

export default MainAdminPage;
