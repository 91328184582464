import React from 'react';

import { Form } from 'tabler-react';
import { Field } from 'formik';

import MyInput from '../../../../Input';
import { IFormLayoutProps } from '../FormLayout.types';

const CAPACITY_LABEL_TEXT = 'Maksymalna liczba osób';

export const CapacityField = (props: IFormLayoutProps) => (
  <Form.Group label={CAPACITY_LABEL_TEXT}>
    <Field
      name="capacity"
      type="number"
      value={props.values.capacity}
      component={MyInput}
    />
  </Form.Group>
);
