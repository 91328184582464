import React, { FC } from 'react';

import { IPrinter } from '@Model/printer/types';
import { Button, Table } from 'tabler-react';
import { IDPrinterTableProps } from './PrinterTable.types';

export const ARE_YOU_SURE_TEXT =
  'Czy na pewno wydrukować raport i wyzerować cene na drukarce: ';
export const EMAIL_TEXT = 'Email';
export const ID_TEXT = 'Id';
export const ID_PRINTER_TEXT = 'Nazwa';
export const SALE_URL_TEXT = 'Url';

const PrinterTable: FC<IDPrinterTableProps> = ({ Printers, printReport }) => {
  const confirmModal = (
    event: React.FormEvent<HTMLButtonElement>,
    printer: IPrinter,
  ) => {
    event.preventDefault();

    if (window.confirm(`${ARE_YOU_SURE_TEXT} ${printer.id}?`) && printReport) {
      printReport(printer);
    }
  };

  return (
    <Table
      responsive={true}
      highlightRowOnHover={true}
      hasOutline={true}
      verticalAlign="center"
      cards={true}
      className="text-nowrap"
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>
            <div>{ID_TEXT}</div>
          </Table.ColHeader>
          <Table.ColHeader>{ID_PRINTER_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">
            {SALE_URL_TEXT}
          </Table.ColHeader>
          <Table.ColHeader alignContent="center">
            <i className="icon-settings" />
          </Table.ColHeader>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Printers.map((printer) => {
          const { printerId, saleUrl, id } = printer;

          return (
            <Table.Row key={id}>
              <Table.Col>{id}</Table.Col>
              <Table.Col>{printerId}</Table.Col>
              <Table.Col alignContent="center">{saleUrl}</Table.Col>
              <Table.Col alignContent="center">
                <Button.List align="right">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={(e: React.FormEvent<HTMLButtonElement>) =>
                      confirmModal(e, printer)
                    }
                    icon="printer"
                  />
                </Button.List>
              </Table.Col>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default PrinterTable;
