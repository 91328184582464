import { add } from '@Model/happening/actions';
import { getAvailabilities, getHappening } from '@Model/happening/selectors';
import { getAdd } from '@Model/reservation/selectors/index';
import { createSelector } from 'reselect';

const prepareDataForFormLayout = createSelector(
  [getHappening, getAdd, getAvailabilities],
  (happening, addingModel, availabilities) => {
    let isUpsell = false;
    let showUserForm = false;

    let mergedDays = availabilities.currentDay;

    if (availabilities.otherDays) {
      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    if (addingModel.selectedSlot) {
      const slot = mergedDays[addingModel.selectedSlot.startTime] || [];
      const availableSpace = slot.find(
        (_space) => _space.spaceId === addingModel.spaceId,
      );

      if (availableSpace && availableSpace.upsell) {
        isUpsell = true;
      }
    }

    if (happening && addingModel.spaceId && addingModel.selectedSlot) {
      showUserForm = true;
    }

    return {
      isHappeningSelected: Boolean(
        addingModel.happeningId && addingModel.happeningId > 0,
      ),
      isPaymentForPerson: happening ? happening.calculatePricePerPerson : false,
      isUpsell,
      selectedSpaceId: addingModel.spaceId,
      showUserForm,
      sliderLength: (happening && happening.maxNumberOfPeople) || 1,
    };
  },
);

export default prepareDataForFormLayout;
