import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { get as getSession } from '@Model/authorization/selectors';
import { mounted } from '@Model/discounts/actions';
import { resetState } from '@Model/happening/actions';
import { get, getHappenings } from '@Model/happenings/selectors';
import { getProducts } from '@Model/products/selectors';
import {
  catchCompanyData,
  selectDiscount,
  selectHappening,
  selectPeopleCount,
  selectSpace,
  selectTimeSlot,
  selectUpSell,
  setDate,
  setFormData,
} from '@Model/reservation/actions';
import {
  getAdd,
  getDurationAfterMidnight,
  getSpacesOfSelectedHappening,
} from '@Model/reservation/selectors';
import getBasketData from '@Model/reservation/selectors/getBasketData';
import prepareDataForFormLayout from '@Model/reservation/selectors/prepareDataForFormLayout';
import { allPermissions, COMPANY_DATA } from '@Model/state/constants';
import { getLoading, isAnyLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { IFormLayoutFromDispatch, IFormLayoutFromState } from './FormLayout';
import FormLayout from './FormLayout.component';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const { partnerId } = get(state);
  const durationAfterMidnight = getDurationAfterMidnight(state);

  const {
    userInfo: { permissions },
  } = getSession(state);

  return {
    basketData: getBasketData(state),
    controls: prepareDataForFormLayout(state),
    happenings: getHappenings(state),
    isCompanyDataLoading: getLoading(COMPANY_DATA)(state),
    isLoading: isAnyLoading(state),
    minDate: durationAfterMidnight
      ? moment()
          .subtract(1, 'days')
          .toDate()
      : moment().toDate(),
    partnerId,
    permissionFormIoAccessEmptyTransaction: permissions.includes(
      allPermissions.access_transactionless_reservation,
    ),
    products: getProducts(state).items,
    selectedDate: getAdd(state).selectedDate,
    spaces: getSpacesOfSelectedHappening(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  getCompanyData: (nip) => dispatch(catchCompanyData(nip)),
  mounted: () => dispatch(mounted()),
  resetState: () => dispatch(resetState()),
  selectDate: (date) => dispatch(setDate(date)),
  selectDiscount: (space) => dispatch(selectDiscount(space)),
  selectHappening: (happeningId) => dispatch(selectHappening(happeningId)),
  selectPeopleCount: (count) => dispatch(selectPeopleCount(count)),
  selectSlot: (slot) => dispatch(selectTimeSlot(slot)),
  selectSpace: (space) => dispatch(selectSpace(space)),
  selectUpSell: (data) => dispatch(selectUpSell(data)),
  setFormData: (data) => dispatch(setFormData(data)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FormLayout);
