import React from 'react';

import { Loader } from 'tabler-react';

import { IPrinterLoaderProps } from './PrinterLoader.types';

import styles from './PrinterLoader.module.scss';

const COMMUNICATION_IN_PROGRESS = 'Trwa komunikacja z drukarką';

const PrinterLoader = ({ isLoading }: IPrinterLoaderProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Loader />
      <div className={styles.title}>{COMMUNICATION_IN_PROGRESS}</div>
    </div>
  );
};

export default PrinterLoader;
