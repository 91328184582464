import { getType } from 'typesafe-actions';

import { setFormData } from '../actions';
import { IAction, IFormDataReduce } from '../types';

const initialState: IFormDataReduce = {
  date: new Date(),
  delayedTransaction: false,
  email: '',
  emptyReservation: false,
  firstName: '',
  happeningId: null,
  invoiceCheckbox: false,
  lastName: '',
  numberOfPeople: 1,
  phone: '',
  reservationCheckbox: false,
  showDiscountForm: false,
  slot: null,
  spaceId: null,
  upsell: false,
  withFromData: false,
  zipCode: '',
};

const companyDataFromReduce = (
  state: IFormDataReduce = initialState,
  action: IAction,
): IFormDataReduce => {
  switch (action.type) {
    case getType(setFormData):
      return action.payload;
    default:
      return state;
  }
};

export default companyDataFromReduce;
