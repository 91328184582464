import React, { useEffect } from 'react';

import { Avatar, Grid } from 'tabler-react';
import styles from './SummaryCanceled.module.scss';
import { ISummaryCanceledProps } from './SummaryCanceled.types';

const TEXT_1 = 'Transakcja anulowana.';
const TEXT_2 = ' Dziękujemy.';

const SummaryCanceled = ({ cancelTransaction }: ISummaryCanceledProps) => {
  useEffect(() => {
    cancelTransaction();
  }, []);

  return (
    <section>
      <Grid.Col>
        <Grid.Row className={styles.center}>
          <Avatar icon="x" color="blue" size="xxl" className={styles.bigIcon} />
        </Grid.Row>
        <Grid.Row className={styles.center}>
          <h1 className={styles.title}>{TEXT_1}</h1>
        </Grid.Row>
        <Grid.Row className={styles.center}>
          <h1>{TEXT_2}</h1>
        </Grid.Row>
      </Grid.Col>
    </section>
  );
};

export default SummaryCanceled;
