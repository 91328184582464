import React, { FC } from 'react';

import Toast from './components/Toast';
import { IToastsProps } from './Toast.types';
import styles from './Toasts.module.scss';

const Toasts: FC<IToastsProps> = ({ toasts, remove, dispatch }) => (
  <div className={styles.toast}>
    {toasts.map((toast) => {
      return (
        <Toast
          key={toast.id}
          data={toast}
          handleRemove={remove}
          handleDispatch={dispatch}
        />
      );
    })}
  </div>
);

export default Toasts;
