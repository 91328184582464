import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _RESET_STATE,
  _SAVE_FAILURE,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  ADD,
  EDIT,
  REMOVE,
  SAVE,
} from '../constants/actions';
import { IDiscount } from '../types';

export const resetState = createStandardAction(_RESET_STATE)();
export const saveDiscount = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE,
)<IDiscount, void, Error>();

export const addDiscount = createStandardAction(ADD)();
export const editDiscount = createStandardAction(EDIT)<IDiscount>();
export const remove = createStandardAction(REMOVE)<string | null>();
export const save = createStandardAction(SAVE)<IDiscount>();
