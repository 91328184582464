import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import getDiscountsSelector from '@Model/discounts/selectors/get';
import { getHappenings, setPartnerID } from '@Model/happenings/actions';
import { get as getHappeningsSelector } from '@Model/happenings/selectors';
import { setFilters } from '@Model/reservations/actions/index';
import { IDiscountsResponse } from '@Services/$discounts-api/types';
import _Store from '@Store';
import {
  getDiscounts,
  handleActivePage,
  mounted,
  resetState,
  setActiveFilter,
  updateSearchText,
} from '../actions';

export const getDiscountsWhenSetFilter: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([setPartnerID, setActiveFilter, updateSearchText])),
    mergeMap$(() => {
      return of$(handleActivePage(0), setFilters([]), getDiscounts.request());
    }),
  );
};

export const requestDiscountsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(resetState(), getDiscounts.request(), getHappenings.request());
    }),
  );
};

export const fetchDiscountsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi },
) => {
  return action$.pipe(
    filter$(isActionOf([getDiscounts.request, handleActivePage])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { activePage, perPage, searchText, active } = getDiscountsSelector(
        state,
      );
      const { partnerId } = getHappeningsSelector(state);

      return from$(
        discountsApi.getDiscounts(
          activePage + 1,
          perPage,
          partnerId,
          active,
          searchText,
        ),
      ).pipe(
        map$((data: IDiscountsResponse) => {
          const normalizedData = discountsApi.normalizeDiscounts(data);

          return getDiscounts.success(normalizedData);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error));
        }),
      );
    }),
  );
};
