import React, { FC } from 'react';

import ReactPaginate from 'react-paginate';

import { IPaginationProps } from './Pagination.types';

import styles from './Pagination.module.scss';

const Pagination: FC<IPaginationProps> = ({
  activePage,
  handleActivePage,
  perPage,
  totalCount,
}) => {
  const cathActivePage = (page: { selected: number }) => {
    handleActivePage(page.selected);
  };

  const DISPLAY_PAGINATION_PAGES = 3;

  if (!totalCount) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <ReactPaginate
        pageCount={totalCount / perPage}
        onPageChange={cathActivePage}
        marginPagesDisplayed={DISPLAY_PAGINATION_PAGES}
        pageRangeDisplayed={DISPLAY_PAGINATION_PAGES}
        forcePage={activePage}
        activeClassName={styles.selected}
      />
    </div>
  );
};

export default Pagination;
