import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { setActiveFilter } from '@Model/discounts/actions';
import { get as getDiscounts } from '@Model/discounts/selectors';
import _Store from '@Store';
import FiltersByActive from './FiltersByActive.component';
import {
  IFiltersFromDispatch,
  IFiltersFromState,
} from './FiltersByActive.types';

const mapStateToProps = (state: _Store.IState): IFiltersFromState => {
  const { active } = getDiscounts(state);

  return {
    active,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFiltersFromDispatch => {
  return {
    setActiveFilter: (active: string) => {
      dispatch(setActiveFilter(active));
    },
  };
};

export default connect<
  IFiltersFromState,
  IFiltersFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FiltersByActive);
