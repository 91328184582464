import React, { useEffect } from 'react';

import { IframeProvider } from '@Services/$iframe-provider';
import { Loader } from 'tabler-react';
import styles from './Start.module.scss';
import { IStartProps } from './Start.types';

const Start = ({ mounted, isLoading, captureIframeEvent }: IStartProps) => {
  useEffect(() => {
    mounted();

    window.addEventListener(
      'message',
      (messageEvent: MessageEvent) =>
        IframeProvider.addEventListenerMethod(messageEvent, captureIframeEvent),
      false,
    );
  }, []);

  return (
    <>
      {isLoading && (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )}
      {!isLoading && <div className={styles.unauthorized}>Unauthorized</div>}
    </>
  );
};

export default Start;
