import {
  catchDeleteConfiguration,
  deleteConfiguration,
  getConfigurations,
  handleConfigurationsActivePage,
} from '@Model/configurations/actions';
import { CONFIGURATIONS } from '@Model/state/constants/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';

export const setUpStateWhenConfigurationsFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getConfigurations.request,
        handleConfigurationsActivePage,
        catchDeleteConfiguration,
      ]),
    ),
    mergeMap$(() => [resetError(CONFIGURATIONS), setLoading(CONFIGURATIONS)]),
  );
};

export const setLoaderWhenConfigurationsFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getConfigurations.success,
          getConfigurations.failure,
          deleteConfiguration.failure,
        ],
        action,
      ),
    ),
    map$(() => resetLoading(CONFIGURATIONS)),
  );
};

export const setErrorOnConfigurationsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getConfigurations.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
