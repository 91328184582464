// State properties
export const _LOADING = 'loading';
export const _ERROR_MESSAGE = 'errorMessage';

// Modules
export const AUTHORIZATION = 'AUTHORIZATION';
export const CONFIGURATIONS = 'configurations';
export const DISCOUNT = 'discount';
export const DISCOUNTS = 'discounts';
export const FORM_IO = 'form_io';
export const HAPPENING = 'happening';
export const HAPPENING_AVAILABILITY = 'happening_availability';
export const HAPPENINGS = 'happenings';
export const RESERVATION = 'reservation';
export const RESERVATION_CHECK = 'reservation_check';
export const RESERVATIONS = 'reservations';
export const USER = 'user';
export const PRINTER = 'printer';
export const REPORTS = 'reports';
export const COMPANY_DATA = 'company_data';
export const DELAYED_TRANSACTIONS = 'delayed_transactions';
