import React, { FC } from 'react';
import { Form, Grid } from 'tabler-react';

import { IPaginationProps } from './ReportType.types';

const REPORT_TYPE_TEXT = 'Typ raportu';

const Pagination: FC<IPaginationProps> = ({
  availableReports,
  selectedReport,
  setReportType,
}) => {
  const handleReportType = (
    reportValue: React.FormEvent<HTMLSelectElement>,
  ) => {
    const reportId = Number(reportValue.currentTarget.value);
    if (!availableReports.length) {
      return null;
    }

    const report = availableReports.find(
      (availableReport) => availableReport.id === reportId,
    );

    if (report) {
      setReportType(report);
    }
  };

  return (
    <Grid.Col>
      <Form.Select
        label={REPORT_TYPE_TEXT}
        onChange={handleReportType}
        value={(selectedReport && selectedReport.id) || ''}
      >
        <option value={-1}>-</option>
        {availableReports.map((filter) => (
          <option key={filter.id} value={filter.id}>
            {filter.name || ''}
          </option>
        ))}
      </Form.Select>
    </Grid.Col>
  );
};

export default Pagination;
