import moment from 'moment';
import React, { useEffect } from 'react';
import { Avatar, Button, Grid } from 'tabler-react';

import config from '@Config';
import TransactionView from './components/TransactionView';
import { ISummaryProps } from './Summary.types';

import styles from './Summary.module.scss';

const BOOKING_SUCCESSFUL_TEXT = 'Dziękujemy za złożenie zamówienia.';
const TICKET_TEXT = 'Kod biletu:';
const CONTINUE_BUYING_TEXT = 'Kontynuuj zakupy';
const PRINT_AGAIN_TEXT = 'Spróbuj ponownie wydrukować paragon';
const TITLE_TEXT = 'Podsumowanie transakcji';

const Summary = ({
  mounted,
  reservationDetails,
  reservationPrintData,
  continueBuying,
  printingReceiptHasError,
  printReceipt,
}: ISummaryProps) => {
  useEffect(() => {
    mounted();
  }, []);

  const {
    details: {
      space: { metadata: spaceMetadata },
      happening: { metadata: happeningMetadata },
    },
    start,
    end,
  } = reservationDetails;

  const returnSpaceSlug = (): string => {
    if (
      spaceMetadata &&
      spaceMetadata.length &&
      spaceMetadata[0] &&
      spaceMetadata[0].slug
    ) {
      return spaceMetadata[0].slug;
    }
    return '';
  };

  const returnHappeningSlug = (): string => {
    if (
      happeningMetadata &&
      happeningMetadata.length &&
      happeningMetadata[0] &&
      happeningMetadata[0].slug
    ) {
      return happeningMetadata[0].slug;
    }
    return '';
  };

  const returnDate = () => {
    if (start.length && end.length) {
      const timezoneStartDate = moment.utc(start).format('YYYY-MM-DD HH:mm:ss');
      const timezoneEndDate = moment.utc(end).format('YYYY-MM-DD HH:mm:ss');

      const startDate = moment(timezoneStartDate).format('DD.MM.YYYY HH:mm:ss');
      const endDate = moment(timezoneEndDate).format('DD.MM.YYYY HH:mm:ss');

      const date = `${startDate} - ${endDate}`;

      return date;
    }
    return '';
  };

  const showTicketCode =
    !!reservationPrintData.transactionItems.length &&
    reservationPrintData.transactionItems[0].entryToken &&
    !!reservationPrintData.transactionItems[0].entryToken.length;

  const ticketText = `${TICKET_TEXT} ${!!reservationPrintData.transactionItems
    .length && reservationPrintData.transactionItems[0].entryToken}`;

  const spaceSlug = returnSpaceSlug();
  const happeningSlug = returnHappeningSlug();
  const formattedDate = returnDate();

  const ZAGRYWKI_URL = config.api.clientAppUrl + '?extendedPayment=';

  const renderAdditionalLinkToPayment = () => {
    if (
      reservationPrintData.status &&
      reservationPrintData.status === 'init' &&
      reservationPrintData.transactionUuid
    ) {
      return (
        <Grid.Row width={12} className={styles.buttonContainer}>
          <Grid.Col width={2} className={styles.center}>
            <Avatar
              icon="link"
              size="md"
              className={styles.smallIcon}
              color="lime"
            />
          </Grid.Col>
          <Grid.Col width={10}>
            <h2 className={styles.description}>
              {ZAGRYWKI_URL + reservationPrintData.transactionUuid}
            </h2>
          </Grid.Col>
        </Grid.Row>
      );
    }
    return null;
  };

  return (
    <section>
      <Grid.Row className="mb-5">
        <Grid.Col>
          <Grid.Row>
            <h3>{TITLE_TEXT}</h3>
          </Grid.Row>
        </Grid.Col>
        <Grid.Row>
          <Grid.Col>
            <section className={styles.section}>
              <Avatar
                icon="home"
                color="blue"
                size="xxl"
                className={styles.bigIcon}
              />
              <h1 className={styles.title}>{BOOKING_SUCCESSFUL_TEXT}</h1>
            </section>
          </Grid.Col>
          <TransactionView
            transactionItems={reservationPrintData.transactionItems}
            details={reservationPrintData.details}
          />
          {/* TODO: Uncomment when basket ready */}
          {/* <ProductsView
            transactionProducts={reservationPrintData.transactionProducts}
          /> */}
        </Grid.Row>
        {renderAdditionalLinkToPayment()}
        <Grid.Row width={12} className={styles.buttonContainer}>
          <Button
            color="success"
            size="md"
            className={styles.close}
            icon="check"
            onClick={continueBuying}
          >
            {CONTINUE_BUYING_TEXT}
          </Button>

          {printingReceiptHasError && (
            <Button
              color="secondary"
              size="md"
              className={styles.close}
              icon="printer"
              onClick={printReceipt}
            >
              {PRINT_AGAIN_TEXT}
            </Button>
          )}
        </Grid.Row>
      </Grid.Row>
    </section>
  );
};

export default Summary;
