import { isJsonString } from '@Misc/helpers/strings/isJsonString';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { IPortalState } from './Portal.types';

const GOING_TYPE = 'GOING';
const POSITION_TOP_TYPE = 'POSITION_TOP';
const GET_POSITION_TOP_TYPE = 'GET_POSITION_TOP';
const SET_POSITION_TOP_TYPE = 'SET_POSITION_TOP';
const ORIGIN = '*';

class Portal extends Component<{}, IPortalState> {
  public state: IPortalState = {
    positionTop: 0,
  };

  private readonly wrapper =
    typeof document !== 'undefined' && document.createElement('div');
  private readonly root = typeof document !== 'undefined' && document.body;

  constructor(props: {}) {
    super(props);
    window.addEventListener('message', this.receiveMessage, false);
  }

  public receiveMessage = (messageEvent: any) => {
    if (isJsonString(messageEvent.data)) {
      const event = JSON.parse(messageEvent.data);

      if (event.type === GOING_TYPE) {
        const { action } = event;
        this.receivePostMessage(action);
      }
    }
  };

  public receivePostMessage = (action: any) => {
    if (action.type === POSITION_TOP_TYPE) {
      this.setState({
        positionTop: action.payload,
      });
    }
  };

  public sendMessage = (action: any) => {
    window.parent.postMessage(
      JSON.stringify({
        action,
        type: GOING_TYPE,
      }),
      ORIGIN,
    );
  };

  public componentDidMount() {
    if (this.root && this.wrapper) {
      this.root.appendChild(this.wrapper);

      this.sendMessage({
        type: GET_POSITION_TOP_TYPE,
      });
    }
  }

  public componentWillUnmount() {
    if (this.root && this.wrapper) {
      this.root.removeChild(this.wrapper);

      this.sendMessage({
        payload: this.state.positionTop || 0,
        type: SET_POSITION_TOP_TYPE,
      });
    }
  }

  public render() {
    if (this.wrapper) {
      return ReactDOM.createPortal(this.props.children, this.wrapper);
    }

    return null;
  }
}

export default Portal;
