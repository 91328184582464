import { getType } from 'typesafe-actions';

import { removeDuplicatesArray } from '@Misc/helpers/removeDuplicatesArray';
import { getAvailableUsers } from './../actions';
import { FORM_IO_EMAIL, FORM_IO_NAME } from './../constants/constants';
import { IAction, IFormIoUser, IFormIoUsersReducer } from './../types';

const initialState: IFormIoUsersReducer = [];

const usersReducer = (
  state: IFormIoUsersReducer = initialState,
  action: IAction,
): IFormIoUsersReducer => {
  switch (action.type) {
    case getType(getAvailableUsers.success):
      return removeDuplicatesArray(
        action.payload.users,
        (item: IFormIoUser) =>
          item.data[FORM_IO_EMAIL] && item.data[FORM_IO_NAME],
      );
    default:
      return state;
  }
};

export default usersReducer;
