import React, { useEffect, useState } from 'react';

import { Field } from 'formik';
import { Button, Form, Grid } from 'tabler-react';

import DatePicker from '@Compo/reusable/DatePicker';
import Days from '../../../Days';
import MyInput from '../../../Input';
import MultiDuration from '../../../MultiDuration';
import Preview from '../../../Preview';
import MySelect from '../../../Select';
import MaxNumberOfPeople from '../MaxNumberOfPeople/MaxNumberOfPeople';
import RulePerPerson from '../RulePerPerson/RulePerPerson';
import { makeName, makeRRule } from './FormLayout.helpes';
import { IFormLayoutProps } from './FormLayout.types';

import styles from './FormLayout.module.scss';

const RULES_NAME_TEXT = 'Nazwa reguły';
const PROPOSED_NAME_TEXT = ' Proponowana nazwa: ';
const PRICE_TEXT = 'Cena (PLN)';
const ADD_UPSALE_TEXT = 'Dodaj upsell';
const VALUE_PRICE_TEXT = 'wartość (PLN)';
const PERCENT_TEXT = 'procent';
const DISCOUNT_VALUE = 'Wartość obniżki';
const TIME_TEXT = 'Czas obowiązywania reguły';
const RULE_TYPE_TEXT = 'Typ reguły';
const DISCOUNT_TYPE = 'Typ obniżki';
const CYCLIC_TEXT = 'Cykliczna';
const ONE_TIME_TEXT = 'Jednorazowa';
const CLICK_TO_CHOSE = 'Kliknij by wybrać';
const MODE_TEXT = 'To jest tryb zaawansowany';
const ADVANCE_TEXT = `Jezeli znasz RRULE wpisz wyrażenie w formacie
zgodnym z RFC-5545. Czas trwania podaj zgodnie z ISO-8601.
Jezżli nie znasz tych formatów, skorzystaj z trybu podstawowego.
Tryb podstawowy nie przenosi ustawionych pól do trybu
rozszerzonego (i odwrotnie).`;
const DURATION_TIME_TEXT = 'Czas trwania';
const RULE_TEXT = 'Reguła RRULE';
const RULE_ERROR_TEXT = ' (Błąd składni!)';
const HIDE_TEXT = 'Ukryj';
const SHOW_TEXT = 'Pokaż';
const ADVANCED_MODE_TEXT = 'tryb zaawansowany';
const PLACE_CLOSED = 'Czy lokal jest zamknięty?';
const YES_TEXT = ' Tak';
const SAVE_RULE_TEXT = 'Zapisz regułę';
const REMOVE_RULE_TEXT = 'Usuń regułę';
const CANCEL_TEXT = 'Anuluj';
const NEARLIEST_TIME_TEXT = ' najbliższe terminy';
const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć obniżkę?';
const RULE_PER_PERSON_TEXT = 'Reguła ważna dla liczby osób';
const START_DATE_TEXT = 'Data rozpoczęcia';
const END_DATE_TEXT = 'Data zakończenia';
const CLICK_TEXT = 'Kliknij by wybrać';
const PARTNER_TEXT = 'Partner';

export const FormLayout = (props: IFormLayoutProps) => {
  const [preview, handlePreview] = useState<boolean>(false);
  const {
    handleSubmit,
    values,
    dirty,
    handleActiveConfiguration,
    handleChange,
    setFieldValue,
    handleBlur,
    deleteConfiguration,
    partners,
    touched,
    errors,
  } = props;

  const {
    advanced,
    fromTime,
    duration,
    id,
    title,
    closed,
    cyclic,
    isUpsell,
    days,
    until,
    newConfiguration,
    isPeopleCount,
    validTo,
    validFrom,
  } = values;

  const rrule = makeRRule(values);
  const rruleName = makeName(values, rrule);

  const catchHandleClose = () => {
    handleActiveConfiguration(null);
  };

  const confirmModal = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (window.confirm(ARE_YOU_SURE_TEXT) && id) {
      deleteConfiguration(id);
    }
  };

  const catchRuleSetPartner = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setFieldValue('partnerId', value);
  };

  const RRULE_TEXT = `${RULE_TEXT} ${!rruleName ? RULE_ERROR_TEXT : ''}`;

  const RULE_NAME_TEXT = `${RULES_NAME_TEXT}  ${id ? '- tryb edycji' : ''}`;

  return (
    <>
      <Button
        color="warning"
        size="sm"
        className={styles.close}
        icon="x"
        onClick={catchHandleClose}
      />

      <form onSubmit={handleSubmit}>
        <Form.Group label={RULE_NAME_TEXT}>
          <Field
            placeholder={rruleName || ''}
            name="title"
            type="text"
            value={title || name}
            onChange={handleChange}
            component={MyInput}
          />
          {newConfiguration && newConfiguration !== rruleName && dirty && (
            <div className={styles.useName}>
              {PROPOSED_NAME_TEXT}
              <span
                onClick={() => {
                  setFieldValue('title', rruleName);
                }}
              >
                {rruleName}
              </span>
            </div>
          )}
        </Form.Group>

        <Form.Group label={PRICE_TEXT}>
          <Field
            name="price"
            type="number"
            component={MyInput}
            disabled={closed}
          />

          <div className={styles.upsell}>
            <div>
              {!closed && (
                <div>
                  <input
                    name="isUpsell"
                    type="checkbox"
                    onChange={handleChange}
                    checked={isUpsell}
                  />
                  {ADD_UPSALE_TEXT}
                </div>
              )}
              <div>
                <input
                  name="isPeopleCount"
                  type="checkbox"
                  onChange={handleChange}
                  checked={isPeopleCount}
                />
                {RULE_PER_PERSON_TEXT}
              </div>
            </div>
          </div>
        </Form.Group>

        <Form.Group label={PARTNER_TEXT}>
          <Form.Select
            value={values.partnerId || ''}
            onChange={catchRuleSetPartner}
            name={'partnerId'}
            invalid={touched.partnerId && errors.partnerId}
          >
            <option value={-1}>-</option>

            {partners &&
              partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name || ''}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <DatePicker
          label={START_DATE_TEXT}
          name="validFrom"
          onBlur={props.handleBlur}
          onChange={props.setFieldValue}
          placeholder={CLICK_TEXT}
          value={validFrom}
          invalid={props.touched.validFrom && props.errors.validFrom}
          feedback={props.errors.validFrom}
          clearable={true}
        />

        <DatePicker
          label={END_DATE_TEXT}
          name="validTo"
          onBlur={props.handleBlur}
          onChange={props.setFieldValue}
          placeholder={CLICK_TEXT}
          value={validTo}
          invalid={props.touched.validTo && props.errors.validTo}
          feedback={props.errors.validTo}
          clearable={true}
        />
        <RulePerPerson {...props} />

        {isUpsell && !closed && (
          <Grid.Row>
            <Grid.Col>
              <Form.Group label={DISCOUNT_TYPE}>
                <Field component={MySelect} name="upsellType">
                  <option value={0}>{VALUE_PRICE_TEXT}</option>
                  <option value={1}>{PERCENT_TEXT}</option>
                </Field>
              </Form.Group>
            </Grid.Col>
            <Grid.Col>
              <Form.Group label={DISCOUNT_VALUE}>
                <Field name="upsellValue" type="number" component={MyInput} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        )}

        {!advanced && (
          <>
            <Form.Group label={TIME_TEXT}>
              <MultiDuration
                onChange={handleChange}
                names={['fromTime', 'durationToString']}
                values={[fromTime, duration.toString()]}
              />
            </Form.Group>
            <Form.Group label={RULE_TYPE_TEXT}>
              <div className={styles.cyclic}>
                <Form.Radio
                  name="cyclic"
                  label={CYCLIC_TEXT}
                  checked={cyclic}
                  onChange={() => {
                    setFieldValue('cyclic', true);
                  }}
                />
                <Form.Radio
                  name="cyclic"
                  label={ONE_TIME_TEXT}
                  checked={!cyclic}
                  onChange={() => {
                    setFieldValue('cyclic', false);
                  }}
                />
              </div>
            </Form.Group>
            {cyclic && (
              <>
                <Days name="days" onChange={handleChange} value={days} />
                <input type="hidden" defaultValue={days} />
              </>
            )}

            {!cyclic && (
              <DatePicker
                name="until"
                onBlur={handleBlur}
                onChange={setFieldValue}
                placeholder={CLICK_TO_CHOSE}
                value={until}
              />
            )}
          </>
        )}

        {advanced && (
          <>
            <div className={styles.advanced}>
              <h4>{MODE_TEXT}</h4>
              <p>{ADVANCE_TEXT}</p>
            </div>

            <Form.Group label={DURATION_TIME_TEXT}>
              <Field
                name="durationAdvanced"
                placeholder="np.: 8h0m"
                type="text"
                component={MyInput}
              />
            </Form.Group>

            <Form.Group label={RRULE_TEXT}>
              <Field
                placeholder="RRULE:"
                name="ruleAdvanced"
                type="text"
                component={MyInput}
              />
            </Form.Group>
          </>
        )}

        <div className={styles.preview}>
          <div
            className={styles.switch}
            onClick={() => {
              setFieldValue('advanced', !advanced);
            }}
          >
            {advanced ? HIDE_TEXT : SHOW_TEXT} {ADVANCED_MODE_TEXT}
          </div>
        </div>

        <MaxNumberOfPeople />

        <Form.Group label={PLACE_CLOSED}>
          <input
            name="closed"
            type="checkbox"
            onChange={handleChange}
            checked={closed}
          />
          {YES_TEXT}
        </Form.Group>

        <Button.List>
          <Button icon="check" color="primary" type="submit">
            {SAVE_RULE_TEXT}
          </Button>

          {id && (
            <Button icon="x" color="danger" onClick={confirmModal}>
              {REMOVE_RULE_TEXT}
            </Button>
          )}

          <Button icon="x" color="warning" onClick={catchHandleClose}>
            {CANCEL_TEXT}
          </Button>
        </Button.List>
      </form>

      <div className={styles.preview}>
        <div className={styles.switch} onClick={() => handlePreview(!preview)}>
          {preview ? HIDE_TEXT : SHOW_TEXT} {NEARLIEST_TIME_TEXT}
        </div>

        {preview && <Preview rrule={rrule} />}
      </div>
    </>
  );
};

export default FormLayout;
