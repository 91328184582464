import { isMobile, isSmallMobile, isTablet } from '@Model/app/selectors';
import { get } from '@Model/authorization/selectors';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import { connect } from 'react-redux';
import Grid from './Grid.component';
import { IGridState } from './Grid.types';

const mapStateToProps = (state: _Store.IState): IGridState => {
  const mobile = isMobile(state);
  const smallMobile = isSmallMobile(state);
  const tablet = isTablet(state);

  const mobileView = mobile || smallMobile || tablet;

  const {
    userInfo: { permissions },
  } = get(state);

  return {
    permissionDelayedTransactionsView: permissions.includes(
      allPermissions.access_permission_delayed_transactions_view,
    ),
    permissionDiscountsView: permissions.includes(
      allPermissions.access_discounts_view,
    ),
    permissionHappeningsView: permissions.includes(
      allPermissions.access_happenings_view,
    ),
    permissionPrintersRead: permissions.includes(
      allPermissions.access_printers_read,
    ),
    permissionPrintersView: permissions.includes(
      allPermissions.access_printers_view,
    ),
    permissionPrintersWrite: permissions.includes(
      allPermissions.access_printers_write,
    ),
    permissionReportsRead: permissions.includes(
      allPermissions.access_reports_read,
    ),
    permissionReservationView: permissions.includes(
      allPermissions.access_reservations_view,
    ),
    permissionRulesView: permissions.includes(allPermissions.access_rules_view),
    permissionStaticsView: permissions.includes(
      allPermissions.access_analytics_view,
    ),

    mobileView,
  };
};

export default connect<IGridState, void, {}, _Store.IState>(mapStateToProps)(
  Grid,
);
