import React, { useState } from 'react';

import { ColorResult, SketchPicker } from 'react-color';
import { Button } from 'tabler-react';
import { IColorPickerProps } from './ColorPicker';
import styles from './ColorPicker.module.scss';

const DEFAULT_COLOR = '#3174ad';

const ColorPicker = ({ color, onColorChange }: IColorPickerProps) => {
  const [showPicker, setShowPicker] = useState(false);

  const handlePickerClick = () => setShowPicker(!showPicker);

  const handleCoverClick = () => setShowPicker(false);

  const handleColorChange = (selectedColor: ColorResult) =>
    onColorChange(selectedColor.hex);

  return (
    <div className={styles.colorPicker}>
      <Button color="primary" type="button" onClick={handlePickerClick}>
        Wybierz kolor
      </Button>
      <div
        className={styles.selectedColor}
        style={{ background: color || '' }}
      />
      {showPicker && (
        <div className={styles.popover}>
          <div
            className={styles.cover}
            role="button"
            onClick={handleCoverClick}
          />
          <SketchPicker
            color={color || DEFAULT_COLOR}
            onChange={handleColorChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
