import React from 'react';

import Routes from '@/routes';
import Resizer from '@Compo/functional/Resizer';
import Toasts from '@Compo/Toasts';
import { BagStore } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';

import '@Misc/styles/global.module.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// TODO: rebuild remove this

const App = () => (
  <>
    <BagStore>
      {/* Components which have side-effects e.g.: connected with redux */}
      <Resizer />

      <Toasts />

      {/* react-router v4 routing */}
      <Routes />
    </BagStore>
  </>
);

export default App;
