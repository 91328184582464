import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import React, { useEffect } from 'react';

import { IStaticProps } from './Statics.types';

export interface IOwnFilters {
  id: number;
}

const Statics = ({
  quickSightUrl: url,
  permissionStaticsView,
  mounted,
}: IStaticProps) => {
  useEffect(() => {
    mounted();
  }, []);

  if (!permissionStaticsView) {
    return null;
  }

  useEffect(() => {
    if (url && url.length) {
      const containerDiv = document.getElementById('embeddingContainer');
      const options = {
        container: containerDiv,
        footerPaddingEnabled: true,
        height: 'AutoFit',
        loadingHeight: '700px',
        locale: 'pl_PL',
        parameters: {
          country: 'Poland',
        },
        scrolling: 'yes',
        url,
        width: '100%',
      };

      embedDashboard(options);
    }
  }, [url]);

  return (
    <section>
      <div id="embeddingContainer" style={{ minHeight: '600px' }} />
    </section>
  );
};

export default Statics;
