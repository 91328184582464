import React from 'react';

import { Form, Grid } from 'tabler-react';

import { IFiltersProps } from './FiltersByActive.types';

const SHOW_TEXT = 'Pokaż';
const ACTIVE_TEXT = 'Aktywne';
const NO_ACTIVE_TEXT = 'Nie aktywne';
const ALL_TEXT = 'Wszystkie';

const FiltersByActive = ({ setActiveFilter, active }: IFiltersProps) => {
  const catchPartnerId = (partner: React.FormEvent<HTMLSelectElement>) => {
    const { value } = partner.currentTarget;
    setActiveFilter(value);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Form.Select
            label={SHOW_TEXT}
            onChange={catchPartnerId}
            value={active || ''}
            name="partnerId"
          >
            <option value={-1}>{ALL_TEXT}</option>
            <option value={0}>{NO_ACTIVE_TEXT}</option>
            <option value={1}>{ACTIVE_TEXT}</option>
          </Form.Select>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default FiltersByActive;
