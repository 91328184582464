// Private
export const _GET_REQUEST = 'Zagrywki/discounts/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/discounts/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/discounts/_GET_FAILURE';
export const _RESET_STATE = 'Zagrywki/discounts/_RESET_STATE';

// Public
export const MOUNTED = 'Zagrywki/discounts/MOUNTED';
export const UPDATE_SEARCH_TEXT = 'Zagrywki/discounts/UPDATE_SEARCH_TEXT';
export const SET_ACTIVE_FILTER = 'Zagrywki/discounts/SET_ACTIVE_FILTER';
export const HANDLE_DISCOUNT_ACTIVE_PAGE =
  'Zagrywki/discounts/HANDLE_DISCOUNT_ACTIVE_PAGE';
