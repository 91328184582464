import React from 'react';

import moment from 'moment';
import { Button, Table } from 'tabler-react';

import {
  FORM_IO_BIRTHDAY,
  FORM_IO_EMAIL,
  FORM_IO_LAST_NAME,
  FORM_IO_NAME,
} from '@Model/formio/constants/constants';
import { IUsersListProps } from './UsersList.types';

const NAME_TEXT = 'Imię';
const LAST_NAME_TEXT = 'Nazwisko';
const EMAIL_TEXT = 'Email';
const AGE_TEXT = 'Wiek';
const ID_TEXT = 'ID';

const UsersList = ({ removeUser, users }: IUsersListProps) => {
  const calculateAge = (birthDate: string) => {
    return moment().diff(birthDate, 'years', false);
  };

  return (
    <Table
      responsive={true}
      highlightRowOnHover={true}
      hasOutline={true}
      verticalAlign="center"
      cards={true}
      className="text-nowrap"
      style={{ flex: 1 }}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>{ID_TEXT}</Table.ColHeader>
          <Table.ColHeader>{EMAIL_TEXT}</Table.ColHeader>
          <Table.ColHeader>{NAME_TEXT}</Table.ColHeader>
          <Table.ColHeader>{LAST_NAME_TEXT}</Table.ColHeader>
          <Table.ColHeader>{AGE_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">
            <i className="icon-settings" />
          </Table.ColHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {users.map((user, index) => {
          return (
            <Table.Row key={user._id}>
              <Table.Col>{index + 1}</Table.Col>
              <Table.Col>{user.data[FORM_IO_EMAIL]}</Table.Col>
              <Table.Col>{user.data[FORM_IO_NAME]}</Table.Col>
              <Table.Col>{user.data[FORM_IO_LAST_NAME]}</Table.Col>
              <Table.Col>{calculateAge(user.data[FORM_IO_BIRTHDAY])}</Table.Col>
              <Table.Col alignContent="center">
                <Button.List align="center">
                  <Button
                    color="danger"
                    size="sm"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => removeUser(user._id)}
                    icon="trash"
                  />
                </Button.List>
              </Table.Col>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default UsersList;
