import React, { FC } from 'react';
import { Card, Dimmer, Form } from 'tabler-react';

import { IProduct } from '@Model/products/types';
import { IProductProps } from './Products.types';

import styles from './Products.module.scss';

const PRODUCTS_TEXT = 'Produkty';

const Products: FC<IProductProps> = ({ products, onChange, productIds }) => {
  const changeSelectedProducts = (event: React.FormEvent<HTMLInputElement>) => {
    const productId = event.currentTarget.value;

    if (productIds && productIds.length) {
      if (productIds.includes(productId)) {
        return onChange(
          'productIds',
          productIds.filter((product) => product !== productId),
        );
      }
      return onChange('productIds', [...productIds, productId]);
    }

    return onChange('productIds', [productId]);
  };

  const checkIsSelectedProduct = (productId: string): boolean => {
    return !!(
      productIds &&
      productIds.length &&
      productIds.includes(productId)
    );
  };

  return (
    <div className={styles.wrapper}>
      <Card title={PRODUCTS_TEXT} isCollapsible={true} isCollapsed={true}>
        <Card.Body>
          <Dimmer>
            {products.map((product: IProduct) => (
              <Form.Checkbox
                key={product.id}
                label={product.name}
                name={product.name}
                value={product.id}
                checked={checkIsSelectedProduct(product.id.toString())}
                onChange={changeSelectedProducts}
              />
            ))}
          </Dimmer>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Products;
