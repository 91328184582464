import { get } from '@Model/authorization/selectors';
import { editDiscount, remove } from '@Model/discount/actions';
import getDiscounts from '@Model/discounts/selectors/getDiscounts';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import DiscountTable from './DiscountTable.component';
import {
  IDiscountTableFromDispatch,
  IDiscountTableFromState,
} from './DiscountTable.types';

const mapStateToProps = (state: _Store.IState): IDiscountTableFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    discounts: getDiscounts(state),
    permissionDiscountWrite: permissions.includes(
      allPermissions.access_discounts_write,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IDiscountTableFromDispatch => {
  return {
    edit: (discount) => {
      dispatch(editDiscount(discount));
    },
    remove: (discount) => {
      dispatch(remove(discount));
    },
  };
};

export default connect<
  IDiscountTableFromState,
  IDiscountTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountTable);
