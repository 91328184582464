import React, { useEffect, useState } from 'react';

import { Form, setUser } from 'react-formio';
import { Form as TablerForm } from 'tabler-react';

import {
  FORM_IO_EMAIL,
  FORM_IO_LAST_NAME,
  FORM_IO_NAME,
} from '@Model/formio/constants/constants';
import { IFormIoUser } from '@Model/formio/types';
import UsersList from '../UsersList';
import { formTranslations } from './Formio.translations';
import { IFormLayoutProps } from './FormIo.types';

import styles from './FormIo.module.scss';
import './styles/Bootstrap.module.scss';

const ENTRY_TEXT = 'Lista wejsciowa:';
const SEARCH_EMAIL_TEXT = 'Email:';
const ENTRY_TOKEN_TEXT = 'Wpisz kod biletu';
const ENTRY_TOKEN_LABEL_TEXT = 'Identyfikator wejściówki';
const SEARCH_FIRST_NAME_TEXT = 'Imie:';
const SEARCH_LAST_NAME_TEXT = 'Nazwisko:';

const FormIo = ({
  addUser,
  availableUsers,
  submission,
  formUrl,
  token,
  formIoUser,
  getFormIOUsers,
  email,
  setEmail,
  setFirstName,
  setLastName,
  firstName,
  lastName,
}: IFormLayoutProps) => {
  const [tokenText, setTokenText] = useState('');
  const [scrollerVisible, setScrollerVisible] = useState(false);
  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);

  useEffect(() => {
    setTokenText(submission.data.text);
  }, [submission]);

  if (formIoUser && formIoUser._id) {
    setUser(formIoUser.data);
  }

  if (!token || !tokenText.length) {
    return null;
  }

  const search = () => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        getFormIOUsers();
      }, 1000) as any,
    );
  };

  const catchHandleSearchByEmail = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setEmail(value);
    search();
  };

  const catchHandleSearchByFirstName = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setFirstName(value);
    search();
  };

  const catchHandleSearchByLastName = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = event.currentTarget;

    setLastName(value);
    search();
  };

  const catchAddUser = (user: IFormIoUser) => {
    setTimeout(() => setScrollerVisible(false), 200);
    addUser(user);
  };

  return (
    <div id="formio" className={styles.wrapper}>
      <div>{ENTRY_TEXT}</div>
      <div className={styles.formWrapper}>
        <TablerForm.Group>
          <TablerForm.Label>{ENTRY_TOKEN_LABEL_TEXT}</TablerForm.Label>
          <TablerForm.Input
            className={styles.searchInput}
            name="entryToken"
            placeholder={ENTRY_TOKEN_TEXT}
            value={tokenText}
            disabled={true}
          />
        </TablerForm.Group>
        <TablerForm.Input
          onFocus={() => setScrollerVisible(true)}
          className={styles.searchInput}
          name="search"
          placeholder={SEARCH_EMAIL_TEXT}
          value={email}
          onChange={catchHandleSearchByEmail}
          autoComplete="off"
        />
        <TablerForm.Input
          onFocus={() => setScrollerVisible(true)}
          className={styles.searchInput}
          name="search"
          placeholder={SEARCH_FIRST_NAME_TEXT}
          value={firstName}
          onChange={catchHandleSearchByFirstName}
          autoComplete="off"
        />
        <TablerForm.Input
          onFocus={() => setScrollerVisible(true)}
          className={styles.searchInput}
          name="search"
          placeholder={SEARCH_LAST_NAME_TEXT}
          value={lastName}
          onChange={catchHandleSearchByLastName}
          autoComplete="off"
        />
        {scrollerVisible && (
          <div className={styles.scroller}>
            <ul>
              {availableUsers.map((user, index) => (
                <li key={index}>
                  <button
                    type="button"
                    onClick={() => catchAddUser(user)}
                    data-value={`${user.data[FORM_IO_EMAIL]} ${user.data[FORM_IO_NAME]} ${user.data[FORM_IO_LAST_NAME]}`}
                  >
                    {`${user.data[FORM_IO_EMAIL]} ${user.data[FORM_IO_NAME]} ${user.data[FORM_IO_LAST_NAME]}`}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <UsersList />
        <Form
          id="formio"
          src={formUrl}
          submission={submission}
          options={formTranslations}
        />
      </div>
    </div>
  );
};

export default FormIo;
