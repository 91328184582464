import moment from 'moment';
import uuid from 'uuid';

import {
  IManagerState,
  IManagerValues,
} from '@Compo/BetterManager/Manager/Manager.types';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  IConfiguration,
  IHappeningBody,
  IHappeningOwnSpace,
} from '@Model/happenings/types/index';
import { makeDurationFromTime } from './../AddingConfiguration/AddingConfiguration.helpers';

const getConfigurations = (space: IHappeningOwnSpace) => {
  const getConfigurationProps = (configuration: IConfiguration) => {
    const { prices, upsell, id } = configuration;

    const configurationHash = configuration.hash || uuid();

    const getPrice = (): number => {
      if (prices && prices.length) {
        const defaultPriceKey = prices.findIndex(
          (price) => price.type === 'default',
        );

        if (defaultPriceKey !== -1) {
          return prices[defaultPriceKey].value;
        }

        return prices[0].value;
      }

      return 0;
    };

    const getUpsalle = () => {
      if (upsell) {
        const { value, percentageValue } = upsell;
        return {
          upsell: {
            percentageValue:
              percentageValue &&
              percentageValue !== null &&
              percentageValue !== undefined
                ? percentageValue
                : undefined,
            value:
              value && value !== null && value !== undefined
                ? value
                : undefined,
          },
        };
      }

      return {
        upsell: {},
      };
    };

    return {
      ...configuration,
      closed: configuration.closed || false,
      duration: configuration.duration,
      durationToSting: makeDurationFromTime(configuration.duration),
      hash: configurationHash,
      id: id || configurationHash,
      maxNumberOfPeople: configuration.maxNumberOfPeople || null,
      name: configuration.title,
      partnerId: 0,
      price: getPrice(),
      prices: prices || [],
      rrule: configuration.rrule,
      shouldSave: false,
      title: '',
      validFrom: configuration.validFrom || null,
      validTo: configuration.validTo || null,
      ...getUpsalle(),
    };
  };

  const normalConfigurations = (space.configurations || []).map(
    (configuration) => getConfigurationProps(configuration),
  );
  const capacityConfigurations = (space.ruleCapacity || []).map(
    (configuration) => getConfigurationProps(configuration),
  );

  const priceConfigurations = (space && space.rulePrice
    ? space.rulePrice
    : []
  ).map((configuration) => getConfigurationProps(configuration));
  const timeConfigurations = (space.ruleTime || []).map((configuration) =>
    getConfigurationProps(configuration),
  );

  return [
    ...normalConfigurations,
    ...capacityConfigurations,
    ...priceConfigurations,
    ...timeConfigurations,
  ];
};

const mapHappeningToStateAndInitialValues = (
  happening: IHappeningBody | undefined,
) => {
  const state: IManagerState = {
    color: null,
    name: 'Gra bez nazwy',
    numberOfPeople: 10,
    partnerId: 0,
    sliderLength: 10,
    slotTime: '15m',
    spaces: [],
    startShowingSlotsAt: '',
  };

  const initialValues: IManagerValues = {
    activityTimeInSlots: -1,
    allowNextSlotWhenOverbooked: false,
    canBeBookedMax: null,
    canBeBookedMin: null,
    description: null,
    endDate: null,
    imageUrl: '',
    isPublic: false,
    partnerId: null,
    paymentType: 'person',
    startDate: null,
    startShowingSlotsAt: '',
    startShowingSlotsAtActive: false,
    vat: 23,
  };

  if (!happening) {
    return {
      initialValues,
      state,
    };
  }

  const happeningMetadata = getMetadataForLanguage(happening.metadata);

  state.name = happeningMetadata.title;

  initialValues.canBeBookedMax = happening.canBeBookedMax || null;
  initialValues.canBeBookedMin = happening.canBeBookedMin || null;

  if (happening.color) {
    state.color = happening.color;
  }

  if (happening.maxNumberOfPeople) {
    state.sliderLength = happening.maxNumberOfPeople;
  }

  if (happening.spaces && happening.spaces.length > 0) {
    state.numberOfPeople = happening.spaces[0].maxNumberOfPeople;
  }

  if (happening.timeSlot) {
    state.slotTime = makeDurationFromTime(happening.timeSlot);
  }

  if (happening.startShowingSlotsAt) {
    initialValues.startShowingSlotsAt = happening.startShowingSlotsAt;
    initialValues.startShowingSlotsAtActive = true;
  }

  if (happening.activityTimeInSlots) {
    initialValues.activityTimeInSlots = happening.activityTimeInSlots;
  }

  if (happening.imageUrl) {
    initialValues.imageUrl = happening.imageUrl;
  }

  initialValues.allowNextSlotWhenOverbooked =
    happening.allowNextSlotWhenOverbooked;

  if (happeningMetadata.description) {
    initialValues.description = happeningMetadata.description;
  }

  if (happening.endDate) {
    initialValues.endDate = moment(happening.endDate).toDate();
  }

  if (happening.vat) {
    initialValues.vat = happening.vat;
  }

  initialValues.paymentType = happening.calculatePricePerPerson
    ? 'person'
    : 'room';

  initialValues.isPublic = happening.isPublic;

  if (happening.partner && happening.partner.id) {
    initialValues.partnerId = happening.partner.id;
  }

  if (happening.startDate) {
    initialValues.startDate = moment(happening.startDate).toDate();
  }

  if (initialValues.allowNextSlotWhenOverbooked) {
    const firstSpace = happening.spaces[0];

    state.slotTime = makeDurationFromTime(firstSpace.timeSlot);
    state.numberOfPeople = firstSpace.maxNumberOfPeople;
  }

  state.spaces = happening.spaces.map((space) => {
    const spaceMetadata = getMetadataForLanguage(space.metadata);

    const hash = space.id.toString();

    return {
      apartmentId: space.apartmentId || 0,

      configurations: getConfigurations(space),
      description: spaceMetadata.description || '',
      hash,
      id: hash,
      name: spaceMetadata.title,
      numberOfPeople: space.maxNumberOfPeople,
      productIds:
        space.products && space.products.length
          ? space.products.map((product) => product.id.toString())
          : [],
      slotTime: makeDurationFromTime(space.timeSlot),
      timeBreak: space.timeBreak
        ? makeDurationFromTime(Number(space.timeBreak))
        : null,
      url: space.url || null,
    };
  });

  return {
    initialValues,
    state,
  };
};

export default mapHappeningToStateAndInitialValues;
