import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { addEntryCode, catchSaveUser, closeModal } from '@Model/formio/actions';
import {
  getFormIoConsentsListEntryTokens,
  getFormIoSelectedUser,
} from '@Model/formio/selectors';
import { IData } from '@Model/formio/types/index';
import _Store from '@Store';
import FormLayout from './FormLayout.component';
import { IFormLayoutFromDispatch, IFromState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFromState => {
  return {
    entryTokens: getFormIoConsentsListEntryTokens(state),
    user: getFormIoSelectedUser(state),
  };
};
const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => {
  return {
    addEntryCode: (code) => {
      dispatch(addEntryCode(code));
    },
    catchSaveUser: (userData: IData) => {
      dispatch(catchSaveUser(userData));
    },
    closeModal: () => {
      dispatch(closeModal());
    },
  };
};

export default connect<IFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(FormLayout);
