import React from 'react';

import { ConnectedRouter as Router } from 'connected-react-router';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import configureStore, { history } from '@/store';
import preLoadedState from '@/store/preLoadedState';
import App from '@Compo/App';
import AppClassName from '@Compo/functional/AppClassName';
import PrinterLoader from '@Compo/reusable/PrinterLoader';

// Configure store
const store = configureStore(preLoadedState);

render(
  <Provider store={store}>
    <AppClassName>
      <PrinterLoader />
      <Router history={history}>
        <App />
      </Router>
    </AppClassName>
  </Provider>,
  document.getElementById('app'),
);

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (module.hot && localStorage) {
    const callback = () => {
      const state = store.getState();

      localStorage.setItem('@@hmr', JSON.stringify(state));

      document.location.reload();
    };

    module.hot.accept('./../components/App', callback);
    module.hot.accept('./../store/index.ts', callback);
  }
}
////////////////////////////////////////////////////////////////////////////////
