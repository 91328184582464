import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { handleActiveConfiguration } from '@Model/configurations/actions';
import { IConfiguration } from '@Model/configurations/types';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import Space from './Space.component';
import { ISpaceFromDispatch, ISpaceFromState } from './Space.types';

const mapStateToProps = (state: _Store.IState): ISpaceFromState => {
  const {
    userInfo: { permissions },
  } = get(state);
  return {
    permissionApartmentsView: permissions.includes(
      allPermissions.access_apartments_view,
    ),
    permissionConfigurationView: permissions.includes(
      allPermissions.access_cashiersessions_view,
    ),
    permissionConfigurationWrite: permissions.includes(
      allPermissions.access_configurations_write,
    ),
    permissionSpaceWrite: permissions.includes(
      allPermissions.access_spaces_write,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISpaceFromDispatch => {
  return {
    handleActiveConfiguration: (configurations: IConfiguration) => {
      dispatch(handleActiveConfiguration(configurations));
    },
  };
};

export default connect<ISpaceFromState, ISpaceFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Space);
