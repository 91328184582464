import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';
import { catchGetRuleType, getRuleTypes } from '../actions';
import { IGetPriceTypesSuccessPayload } from '../types';

export const catchGetProductsWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchGetRuleType)),
    mergeMap$(() => {
      return of$(getRuleTypes.request());
    }),
  );
};

export const getProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { ruleTypesApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getRuleTypes.request)),
    mergeMap$((action) => {
      return from$(ruleTypesApi.getPriceTypes()).pipe(
        map$((data: IGetPriceTypesSuccessPayload) => {
          return getRuleTypes.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ruleTypesApi.cancelProductsTypes()),
          ),
        ),
        catchError$((error: Error) => of$(getRuleTypes.failure(error))),
      );
    }),
  );
};
