import React from 'react';

import { Button } from 'tabler-react';

import DefinedConfigurations from '@Compo/BetterManager/DefinedConfigurations';
import NewDefinedConfigurations from '@Compo/BetterManager/NewDefinedConfigurations';
import config from '@Config';
import { IConfigurationListOwnProps } from './ConfigurationList.types';

import styles from './ConfigurationList.module.scss';

export const ConfigurationList = ({
  handleAdd,
  handleClose,
  handleActiveConfiguration,
}: IConfigurationListOwnProps) => {
  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <div className={styles.right}>
          <Button
            color="warning"
            size="sm"
            className={styles.close}
            icon="x"
            onClick={handleClose}
          />
          {config.cms.showUnfinishedFeatures ? (
            <NewDefinedConfigurations
              handleAdd={handleAdd}
              modal={true}
              handleEditConfiguration={handleActiveConfiguration}
            />
          ) : (
            <DefinedConfigurations
              handleAdd={handleAdd}
              modal={true}
              handleEditConfiguration={handleActiveConfiguration}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationList;
