import React, { useEffect } from 'react';

import moment from 'moment';
import { Button, Table } from 'tabler-react';

import config from '@Config';
import Pagination from './components/Pagination/Pagination.container';
import { IConsentsListProps } from './TransactionsDelayed.types';

import styles from './TransactionsDelayed.module.scss';

const ID_TEXT = 'Id';
const LINK_TEXT = 'Link';

const BOOKING_ID_TEXT = 'Booking Identifier';
const START_TEXT = 'Od';
const END_TEXT = 'Do';
const HAPPENING_ID_TEXT = 'Gra';
const FIRST_NAME_TEXT = 'Imie';
const LAST_NAME_TEXT = 'Nazwisko';
const LOCK_CODE_TEXT = 'Lock Code';
const NUMBER_OF_PEOPLE_TEXT = 'Liczba osób';
const SPACE_ID_TEXT = 'Miejsce';
const TRANSACTIONS_TEXT = 'Transakcje';
const DELAYED_AT_TEXT = 'Wygaśnie za:';

const TransactionDelayed = ({
  mounted,
  delayedTransactions,
  cancelTransaction,
}: IConsentsListProps) => {
  useEffect(() => {
    mounted();
  }, []);

  const getLink = (uuid: string): string => {
    return config.api.clientAppUrl + '?extendedPayment=' + uuid;
  };

  const getTimezoneDate = (date?: string): string => {
    if (date) {
      const timezoneDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
      return moment(timezoneDate).format('DD.MM.YYYY HH:mm');
    }
    return '';
  };

  const getDelayedTime = (seconds: number): string => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  };

  return (
    <>
      <Table
        responsive={true}
        highlightRowOnHover={true}
        hasOutline={true}
        verticalAlign="center"
        cards={true}
        className="text-nowrap"
      >
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>{ID_TEXT}</Table.ColHeader>
            <Table.ColHeader>{TRANSACTIONS_TEXT}</Table.ColHeader>
            <Table.ColHeader>{LINK_TEXT}</Table.ColHeader>
            <Table.ColHeader>{DELAYED_AT_TEXT}</Table.ColHeader>
            <Table.ColHeader alignContent="center">
              <i className="icon-settings" />
            </Table.ColHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {delayedTransactions &&
            delayedTransactions.map((transaction) => (
              <Table.Row key={transaction.transactionUUID}>
                <Table.Col>{transaction.transactionUUID}</Table.Col>
                <Table.Col>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>{BOOKING_ID_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{HAPPENING_ID_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{SPACE_ID_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{FIRST_NAME_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{LAST_NAME_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{START_TEXT}</Table.ColHeader>
                        <Table.ColHeader>{END_TEXT}</Table.ColHeader>
                        <Table.ColHeader>
                          {NUMBER_OF_PEOPLE_TEXT}
                        </Table.ColHeader>
                        <Table.ColHeader>{LOCK_CODE_TEXT}</Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transaction.items.map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Col>{item.bookingIdentifier}</Table.Col>
                          <Table.Col>
                            {item.details &&
                              item.details.happening.metadata[0].title}
                          </Table.Col>
                          <Table.Col>
                            {item.details &&
                              item.details.space.metadata[0].title}
                          </Table.Col>
                          <Table.Col>{item.firstName}</Table.Col>
                          <Table.Col>{item.lastName}</Table.Col>
                          <Table.Col>{getTimezoneDate(item.start)}</Table.Col>
                          <Table.Col>{getTimezoneDate(item.end)}</Table.Col>
                          <Table.Col>{item.numberOfPeople}</Table.Col>
                          <Table.Col>{item.lockCode}</Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Table.Col>

                <Table.Col>{getLink(transaction.transactionUUID)}</Table.Col>
                <Table.Col>
                  {getDelayedTime(transaction.secondsToExpire)}
                </Table.Col>

                <Table.Col alignContent="center">
                  <Button.List align="right">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() =>
                        cancelTransaction(transaction.transactionUUID)
                      }
                      icon="trash"
                    />
                  </Button.List>
                </Table.Col>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <div className={styles.pagination}>
        <Pagination />
      </div>
    </>
  );
};

export default TransactionDelayed;
