const cms = {
  baseUrl: process.env.REACT_APP_CMS_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  transactionProductUrl: process.env.REACT_APP_TRANSACTION_PRODUCT_URL,

  showUnfinishedFeatures: process.env.REACT_APP_UNFINISHED_FEATURE
    ? process.env.REACT_APP_UNFINISHED_FEATURE === 'true'
    : false,
};

export default cms;
