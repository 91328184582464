import React, { useEffect, useRef, useState } from 'react';

import Color from 'color';
import globalize from 'globalize';
import 'globalize/lib/cultures/globalize.culture.pl';
import moment from 'moment';
import {
  Calendar as BigCalendar,
  globalizeLocalizer,
  View,
} from 'react-big-calendar';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Loader } from 'tabler-react';

import routes from '@/routes/routes';
import FiltersPartnerId from '@Compo/happenings/List/components/Filters';
import weekEndDate from '@Misc/helpers/weekEndDate';
import weekStartDate from '@Misc/helpers/weekStartDate';
import { IReservationFormatted } from '@Model/reservations/types';
import { ICalendarProps } from './Calendar';
import { MESSAGES, VIEWS, WEEK } from './Calendar.constants';
import DetailsModal from './components/DetailsModal';
import Filters from './components/Filters';
import ListModal from './components/ListModal';

import styles from './Calendar.module.scss';

const ADD_TEXT = 'Dodaj';
const CLEAR_FILTERS_TEXT = 'Odznacz wszystko';
const SET_ALL_FILTERS_TEXT = 'Zaznacz wszystko';
const TITLE_TEXT = 'Kalendarz';
const HIDE_FILTERS_TEXT = 'Ukryj filtry';
const SHOW_FILTERS_TEXT = 'Pokaz filtry';
const PULL_OUT_DRAWER_TEXT = 'Wysuń szuflade';

const defaultCulture = 'pl';
const localizer = globalizeLocalizer(globalize);

const Calendar = ({
  clearFilters,
  selectAllFilters,
  dateChanged,
  isLoading,
  isReservationListSelected,
  isReservationSelected,
  isSession,
  mounted,
  permissionReservationWrite,
  range,
  reservations,
  selectReservation,
  selectReservationList,
  pullDrawer,
  canPullDraw,
  setFilters,
}: ICalendarProps) => {
  const [currentView, setCurrentView] = useState(WEEK as View);
  const [showFilter, handleShowFilter] = useState(false);
  const calendarRef = useRef<any>(null);

  useEffect(() => {
    mounted();

    if (localStorage) {
      const filters = localStorage.getItem('filters');
      if (filters && filters.length) {
        setFilters(JSON.parse(filters));
      }
    }
  }, []);

  useEffect(() => {
    if (
      calendarRef &&
      calendarRef.current &&
      ReactDOM &&
      ReactDOM.findDOMNode &&
      !isLoading
    ) {
      const myScroll = document.getElementsByClassName('rbc-time-content');
      const list = document.getElementsByClassName('rbc-label');
      const currentTime = new Date().setMinutes(0);

      const currentTimeAsString = moment(currentTime).format('HH:mm');

      if (list && list.length) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < list.length; i++) {
          if (list[i].textContent === currentTimeAsString) {
            if (list[i] instanceof HTMLElement && list[i] !== null) {
              if (myScroll && myScroll.length) {
                myScroll[0].scrollTop = (list[i] as HTMLElement).offsetTop;
              }
            }
          }
        }
      }
    }
  }, [isLoading, currentView]);

  const handleNavigate = (date: Date, view: View) => {
    dateChanged({
      endDate: view === WEEK ? weekEndDate(date) : date,
      startDate: view === WEEK ? weekStartDate(date) : date,
    });
  };

  const handleEventProp = (event: IReservationFormatted) => {
    if (event.color) {
      const colorFormatter = Color(event.color);

      return {
        style: {
          background: event.color,
          borderColor: colorFormatter.darken(0.2).hex(),
          color: colorFormatter.isLight() ? '#000' : '#fff',
        },
      };
    }

    return {};
  };

  const handleDoubleClick = (reservation: IReservationFormatted) => {
    if (reservation.events.length > 1) {
      selectReservationList(reservation.events);
    } else {
      selectReservation(reservation.id);
    }
  };

  // TODO: maybe get this range from configurations somehow?
  const today = new Date();
  const minHour = new Date(today.setHours(6, 0, 0));
  const maxHour = new Date(today.setHours(22, 0, 0));

  return (
    <section>
      <Grid.Row className="mb-5">
        <Grid.Col className={styles.smallColumn}>
          <h3>{TITLE_TEXT}</h3>
        </Grid.Col>

        <Grid.Col>
          <Button.List align="right">
            {!isLoading && (
              <>
                {isSession && permissionReservationWrite && (
                  <Button
                    color="primary"
                    RootComponent={Link}
                    to={`/dodaj-rezerwacje`}
                    icon="plus"
                  >
                    {ADD_TEXT}
                  </Button>
                )}

                {canPullDraw && (
                  <Button
                    color="secondary"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => pullDrawer()}
                    icon={'printer'}
                  >
                    {PULL_OUT_DRAWER_TEXT}
                  </Button>
                )}
                {/* TODO: uncomment when ready */}
                {/* <PrinterPaymentPanel /> */}

                <Button
                  color="lime"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleShowFilter(!showFilter)}
                  icon={showFilter ? 'arrow-up' : 'arrow-down'}
                >
                  {showFilter ? HIDE_FILTERS_TEXT : SHOW_FILTERS_TEXT}
                </Button>
              </>
            )}
          </Button.List>
        </Grid.Col>
      </Grid.Row>

      {isReservationListSelected && <ListModal />}

      {isReservationSelected && <DetailsModal />}

      {isLoading && (
        <Card
          body={
            <div className={styles.loader}>
              <Loader />
            </div>
          }
        />
      )}

      {!isLoading && (
        <>
          {showFilter && (
            <>
              <FiltersPartnerId />
              <div>
                <Button
                  className={styles.clearFilterButton}
                  color="warning"
                  onClick={clearFilters}
                  to={routes.addReservation}
                  icon="file-minus"
                >
                  {CLEAR_FILTERS_TEXT}
                </Button>
                <Button
                  className={styles.clearFilterButton}
                  color="primary"
                  onClick={selectAllFilters}
                  to={routes.addReservation}
                  icon="file-plus"
                >
                  {SET_ALL_FILTERS_TEXT}
                </Button>
              </div>
              <Filters />
            </>
          )}
          <div className={styles.wrapper}>
            <BigCalendar
              culture={defaultCulture}
              date={moment(range ? range.startDate : new Date()).toDate()}
              events={reservations}
              eventPropGetter={handleEventProp}
              localizer={localizer}
              messages={MESSAGES}
              step={15}
              timeslots={1}
              view={currentView}
              views={VIEWS}
              onDoubleClickEvent={handleDoubleClick}
              onNavigate={handleNavigate}
              onView={setCurrentView}
              ref={calendarRef}
            />
          </div>
        </>
      )}
    </section>
  );
};

export default Calendar;
