import React from 'react';

import { Grid } from 'tabler-react';

import Product from './Product';
import { IProductsProps } from './Products.types';

import styles from './Products.module.scss';

const PRODUCTS_TEXT = 'Produkty';

const FormLayout = ({ products }: IProductsProps) => {
  if (products && products.length) {
    return (
      <Grid.Row>
        <Grid.Col>
          <div className={styles.mainBasketTitle}>{PRODUCTS_TEXT}</div>

          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </Grid.Col>
      </Grid.Row>
    );
  }
  return null;
};

export default FormLayout;
