import React from 'react';

import DefinedConfigurations from '@Compo/BetterManager/NewDefinedConfigurations';
import { Button } from 'tabler-react';

import styles from './ConfigurationList.module.scss';
import { IConfigurationListOwnProps } from './ConfigurationList.types';

export const ConfigurationList = ({
  handleAdd,
  handleClose,
  handleActiveConfiguration,
}: IConfigurationListOwnProps) => {
  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <div className={styles.right}>
          <Button
            color="warning"
            size="sm"
            className={styles.close}
            icon="x"
            onClick={handleClose}
          />
          <DefinedConfigurations
            handleAdd={handleAdd}
            modal={true}
            handleEditConfiguration={handleActiveConfiguration}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationList;
