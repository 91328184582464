import api from './api';
import cms from './cms';
import resizer from './resizer';

const config = {
  api,
  cms,
  resizer,
};

export default config;
