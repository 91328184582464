const api = {
  baseEmpikTicketUrl: process.env.REACT_APP_EMPIK_API_URL,
  baseInvoiceUrl: process.env.REACT_APP_INVOICE_API_URL,
  baseUrl: process.env.REACT_APP_API_URL,
  baseUrlV2: process.env.REACT_APP_API_URL_V2,
  clientAppUrl: process.env.REACT_APP_CLIENT_APP_URL,
  saleSessionApi: process.env.REACT_APP_SALE_SESSION_URL,
  saleSessionAuthToken: process.env.REACT_APP_SALE_SESSION_AUTH_TOKEN,
};

export default api;
