import React, { FC, useEffect, useRef } from 'react';

import { Card, Loader } from 'tabler-react';
import { ICheckingFromProps } from './Form.types';

import styles from './Form.module.scss';

const Form: FC<ICheckingFromProps> = ({ payment }) => {
  const inputEl = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.submit();
    }
  }, []);

  return (
    <>
      <Card body={<Loader />} />

      <form
        action={payment.redirect}
        ref={inputEl}
        method="POST"
        target="_parent"
        className={styles.formWrapper}
      >
        {Object.keys(payment).map((key) => {
          if (!Array.isArray(payment[key])) {
            const value = payment[key] !== null && payment[key].toString();

            return (
              <input
                type="text"
                key={key}
                name={key}
                defaultValue={value || ''}
              />
            );
          } else if (Array.isArray(payment[key])) {
            const array = payment[key] as string[];
            return array.map((value, index) => {
              return (
                <input
                  type="text"
                  key={`${key}[${index}]`}
                  name={`${key}[${index}]`}
                  defaultValue={value}
                />
              );
            });
          }
        })}

        <button type="submit" name="Wyslij...." />
      </form>
    </>
  );
};

export default Form;
