import React from 'react';

import { FieldProps } from 'formik';
import { Form } from 'tabler-react';
import styles from '../../NewAddingConfiguration.module.scss';

const Input = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps) => (
  <div>
    <Form.Input {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <div className={styles.error}>{errors[field.name]}</div>
    )}
  </div>
);

export default Input;
