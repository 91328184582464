import React, { useState } from 'react';

import { Formik, FormikErrors, FormikTouched } from 'formik';
import moment from 'moment-timezone';
import { Button, Card, Form, Grid, Table } from 'tabler-react';

import DatePicker from '@Compo/reusable/DatePicker';
import {
  FORM_IO_BIRTHDAY,
  FORM_IO_EMAIL,
  FORM_IO_LAST_NAME,
  FORM_IO_NAME,
} from '@Model/formio/constants/constants';
import { IData } from '@Model/formio/types';
import { IFormLayoutProps } from './FormLayout.types';
import { FomIoUserSchema } from './FormLayout.validation';

import styles from './FormLayout.module.scss';

export const CLOSE_TEXT = 'Zamknij';

const ADD_ENTRY_CODE_TEXT = 'Dodaj kod biletu';
const EDIT_DATA = 'Edytuj dane';
const CANCEL_TEXT = 'Anuluj';
const ENTRY_CODES_TEXT = 'Kody biletów';
const ADD_CODE_PLACE_HOLDER_TEXT = 'Wpisz kod biletu';
const ID_TEXT = 'ID: ';
const NAME_TEXT = 'Imię: ';
const LAST_NAME_TEXT = 'Nazwisko: ';
const EMAIL_TEXT = 'Email: ';
const AGE_TEXT = 'Wiek: ';
const BIRTHDAY_TEXT = 'Data urodzenia: ';
const SAVE_TEXT = 'Zapisz';
const PLEASE_REMEMBER_TEXT =
  'Proszę pamiętaj zmieniająć email tracisz przypisane do niego kody.';

const FormLayout = ({
  closeModal,
  user,
  entryTokens,
  addEntryCode,
  catchSaveUser,
}: IFormLayoutProps) => {
  const [entryCode, setEntryCode] = useState('');
  const [isEditing, handleEdit] = useState(false);

  const handleCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    setEntryCode(event.currentTarget.value);
  };

  const handleAddCode = () => {
    addEntryCode(entryCode);
    setEntryCode('');
  };

  const calculateAge = (birthDate: string) => {
    return moment().diff(birthDate, 'years', false);
  };

  const handleCloseModal = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    closeModal();
  };

  const handleSendForm = (data: IData) => {
    catchSaveUser(data);
    closeModal();
  };

  const renderField = (
    fieldName: keyof IData,
    handleChange: (event: React.FormEvent<HTMLButtonElement>) => void,
    values: IData,
    handleBlur: (event: React.FormEvent<HTMLButtonElement>) => void,
    touched: FormikTouched<IData>,
    errors: FormikErrors<IData>,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    if (isEditing) {
      return (
        <div className={styles.inputWrapper}>
          {fieldName === FORM_IO_BIRTHDAY &&
          typeof values[fieldName] === 'string' ? (
            <DatePicker
              name={fieldName}
              onBlur={handleBlur}
              onChange={(a, b) => {
                const formattedValue = moment(b)
                  .tz('UTC')
                  .format();

                setFieldValue(a, formattedValue);
              }}
              placeholder={''}
              value={moment(values[fieldName]).toDate()}
              className={styles.formInput}
            />
          ) : (
            <Form.Input
              name={fieldName}
              value={values[fieldName]}
              onChange={handleChange}
              type="text"
              className={styles.formInput}
              onBlur={handleBlur}
            />
          )}

          {touched && touched[fieldName] && errors && errors[fieldName] && (
            <Form.InputGroupText className={styles.errorMessage}>
              {errors[fieldName]}
            </Form.InputGroupText>
          )}

          {fieldName === FORM_IO_EMAIL && touched[FORM_IO_EMAIL] && (
            <Form.InputGroupText className={styles.errorMessage}>
              {PLEASE_REMEMBER_TEXT}
            </Form.InputGroupText>
          )}
        </div>
      );
    }
    const value =
      fieldName === FORM_IO_BIRTHDAY
        ? moment(user.data[fieldName]).format('DD-MM-YYYY')
        : user.data[fieldName];

    return <div className={styles.description}>{value}</div>;
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleSendForm}
      initialValues={user.data}
      validationSchema={FomIoUserSchema}
    >
      {({
        values,
        handleChange,
        touched,
        handleBlur,
        errors,
        setFieldValue,
      }) => (
        <>
          <Card className={styles.card}>
            <div className={styles.wrapper}>
              <Grid.Row>
                <Grid.Col>
                  <div className={styles.item}>
                    <div className={styles.title}>{ID_TEXT}</div>
                    <div className={styles.description}>{user._id}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.title}>{NAME_TEXT} </div>
                    {renderField(
                      FORM_IO_NAME,
                      handleChange,
                      values,
                      handleBlur,
                      touched,
                      errors,
                      setFieldValue,
                    )}
                  </div>
                  <div className={styles.item}>
                    <div className={styles.title}>{LAST_NAME_TEXT}</div>
                    {renderField(
                      FORM_IO_LAST_NAME,
                      handleChange,
                      values,
                      handleBlur,
                      touched,
                      errors,
                      setFieldValue,
                    )}
                  </div>
                  <div className={styles.item}>
                    <div className={styles.title}>{EMAIL_TEXT}</div>

                    {renderField(
                      FORM_IO_EMAIL,
                      handleChange,
                      values,
                      handleBlur,
                      touched,
                      errors,
                      setFieldValue,
                    )}
                  </div>

                  <div className={styles.item}>
                    <div className={styles.title}>{BIRTHDAY_TEXT}</div>

                    {renderField(
                      FORM_IO_BIRTHDAY,
                      handleChange,
                      values,
                      handleBlur,
                      touched,
                      errors,
                      setFieldValue,
                    )}
                  </div>
                  <div className={styles.item}>
                    <div className={styles.title}>{AGE_TEXT}</div>
                    <div className={styles.description}>
                      {calculateAge(user.data[FORM_IO_BIRTHDAY])}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.title}>{ENTRY_CODES_TEXT}</div>
                  </div>
                  <div className={styles.tableWrapper}>
                    <Table>
                      <Table.Body>
                        {entryTokens.map((token, index) => (
                          <Table.Row key={index}>
                            <Table.Col>{token}</Table.Col>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                  <Form.Input
                    className={styles.addCodeInput}
                    name="addcode"
                    placeholder={ADD_CODE_PLACE_HOLDER_TEXT}
                    value={entryCode}
                    onChange={handleCodeChange}
                  />
                </Grid.Col>
              </Grid.Row>
              <Button.List>
                <Button
                  onClick={handleAddCode}
                  type="button"
                  color="primary"
                  icon="plus"
                >
                  {ADD_ENTRY_CODE_TEXT}
                </Button>

                <Button
                  onClick={() => handleEdit(!isEditing)}
                  type="button"
                  color="primary"
                  icon="edit"
                >
                  {isEditing ? CANCEL_TEXT : EDIT_DATA}
                </Button>

                {isEditing && (
                  <Button
                    onClick={() => handleSendForm(values)}
                    type="button"
                    color="primary"
                    icon="edit"
                  >
                    {SAVE_TEXT}
                  </Button>
                )}
              </Button.List>
            </div>
          </Card>

          <Button.List>
            <Button
              onClick={handleCloseModal}
              type="button"
              color="secondary"
              icon="x"
            >
              {CLOSE_TEXT}
            </Button>
          </Button.List>
        </>
      )}
    </Formik>
  );
};

export default FormLayout;
