import React, { useEffect, useState } from 'react';

import { Form, Grid, Loader, Table } from 'tabler-react';

import Configuration from './components/Configuration';
import Empty from './components/Empty';
import Pagination from './components/Pagination';
import { IDefinedConfigurationsProps } from './DefinedConfigurations.types';

import styles from './DefinedConfigurations.module.scss';

const SEARCH_TEXT = 'Wpisz by wyszukać...';
const ADD_SMALL_TEXT = '+';

const DefinedConfigurations = ({
  mounted,
  isLoading,
  configurations,
  handleActiveConfiguration,
  addNewConfiguration,
  handleAdd,
  handleSearchParam,
  searchParam,
  getConfiguration,
  modal,
  handleEditConfiguration,
}: IDefinedConfigurationsProps) => {
  useEffect(() => {
    mounted();
  }, []);

  const [timeOut, setTimeOut] = useState<number>(0);

  const catchHandleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    handleSearchParam(value);

    if (timeOut) {
      clearTimeout(timeOut as any);
    }
    setTimeOut(setTimeout(() => {
      getConfiguration();
    }, 1000) as any);
  };

  const showNoResultInfo = !isLoading && configurations.length < 1;

  const clickAction = handleAdd || handleActiveConfiguration;

  const NAME_TEXT = 'Nazwa';
  const TYPE_TEXT = 'Typ';
  const CLOSED_TEXT = 'Zamknięte';
  const DAYS_TEXT = 'Dni';
  const TIME_TEXT = 'Czas';
  const PRICE_TEXT = 'Cena';
  const UPSELL_TEXT = 'Upsell';
  const GROUP_TEXT = 'B. grupowy';
  const MAX_PEOPLE_TEXT = 'Max l. osób';

  return (
    <div>
      <Grid.Row>
        <Grid.Col>
          <Form.Input
            name="search"
            placeholder={SEARCH_TEXT}
            value={searchParam}
            onChange={catchHandleSearch}
          />
        </Grid.Col>
        {!modal && (
          <Grid.Col width={1} className={styles.addButton}>
            <button
              className={styles.button}
              type="button"
              onClick={() => addNewConfiguration()}
            >
              {ADD_SMALL_TEXT}
            </button>
          </Grid.Col>
        )}
      </Grid.Row>

      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <div className={styles.list}>
        {showNoResultInfo && <Empty />}

        <Table
          responsive={true}
          highlightRowOnHover={true}
          hasOutline={true}
          verticalAlign="center"
          cards={true}
          className="text-nowrap"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>{NAME_TEXT}</Table.ColHeader>
              <Table.ColHeader>{TYPE_TEXT}</Table.ColHeader>
              <Table.ColHeader>{CLOSED_TEXT}</Table.ColHeader>
              <Table.ColHeader>{DAYS_TEXT}</Table.ColHeader>
              <Table.ColHeader>{TIME_TEXT}</Table.ColHeader>
              <Table.ColHeader>{PRICE_TEXT}</Table.ColHeader>
              <Table.ColHeader>{UPSELL_TEXT}</Table.ColHeader>
              <Table.ColHeader>{GROUP_TEXT}</Table.ColHeader>
              <Table.ColHeader>{MAX_PEOPLE_TEXT}</Table.ColHeader>
              {handleEditConfiguration && (
                <Table.ColHeader alignContent="center">
                  <i className="icon-settings" />
                </Table.ColHeader>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {configurations
              ? configurations.map((configuration) => (
                  <Configuration
                    handleActiveConfiguration={clickAction}
                    handleEditConfiguration={handleEditConfiguration}
                    configuration={configuration}
                    key={configuration.id}
                  />
                ))
              : null}
          </Table.Body>
        </Table>
        <div className={styles.pagination}>
          {!showNoResultInfo ? <Pagination /> : null}
        </div>
      </div>
    </div>
  );
};

export default DefinedConfigurations;
