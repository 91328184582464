import { IReport } from '@/components/Reports/Reports.types';

export const reportsData: IReport[] = [
  {
    filters: [
      {
        name: 'Data transakcji',
        slug: 'transactionDates',
        type: 'date',
      },
      {
        name: 'Id użytkownika',
        slug: 'cmsUserIds',
        type: 'group',
        values: [],
      },
      {
        name: 'Id wydażenia',
        slug: 'happeningIds',
        type: 'group',
        values: [],
      },
      {
        name: 'Grupuj po',
        slug: 'groupBy',
        type: 'group',
        values: [],
      },

      {
        name: 'Status',
        slug: 'statuses',
        type: 'group',
        values: [
          {
            name: 'Sfinalizowane',
            value: 'finalized',
          },
          {
            name: 'Anulowane',
            value: 'canceled',
          },
        ],
      },
    ],
    id: 1,
    name: 'Raport Rezerwacji',
    slug: 'reservations',
  },
  {
    filters: [
      {
        name: 'Data od',
        slug: 'startDates',
        type: 'date',
      },
      {
        name: 'Data do',
        slug: 'endDates',
        type: 'date',
      },
      {
        name: 'Id użytkownika',
        slug: 'cmsUserIds',
        type: 'group',
        values: [],
      },
    ],
    id: 2,
    name: 'Raport Sesji',
    slug: 'sessions',
  },
];
