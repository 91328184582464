import { connect } from 'react-redux';

import { getReports } from '@Model/reports/selectors';
import { REPORTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import ReportsTable from './ReportsTable.component';
import { IReportsTableFromState } from './ReportsTable.types';

const mapStateToProps = (state: _Store.IState): IReportsTableFromState => {
  const { counts, keys, items: reports, selectedKeys } = getReports(state);
  return {
    counts,
    isLoading: getLoading(REPORTS)(state),
    keys,
    reports,
    selectedKeys,
  };
};

export default connect<IReportsTableFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(ReportsTable);
