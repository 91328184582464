import cn from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import { Button, Table, Text } from 'tabler-react';

import { correctPercentHelper } from '@Misc/helpers/correctPercent';
import { dateFormated } from '@Misc/helpers/dateFormated';

import { IDiscountsProps } from './DiscountTable.types';

export const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć obniżkę?';
export const CODE_TEXT = 'Kod';
export const DURATION_TEXT = 'Czas trwania';
export const EDIT_TEXT = 'Edytuj';
export const FROM_PRESENT_TEXT = 'o procent';
export const FROM_PRICE_TEXT = 'o kwotę';
export const HAPPENING_TEXT = 'Wydarzenie';
export const INFORMATION_TEXT = 'Ingormacje';
export const LIMIT_TEXT = 'Limit';
export const USAGE_TEXT = 'Zużyto';
export const NO_BOUNDARIES_TEXT = '(bez ograniczeń)';
export const NO_TEXT = 'Nie';
export const REMOVE_TEXT = 'Usuń';
export const SUBTRACT_A_PERCENTAGE_TEXT = 'Obniż cenę';
export const SUBTRACT_AMOUNT_TEXT = 'Odejmnij kwotę';
export const TYPE_TEXT = 'Typ';
export const VALUE_TEXT = 'Wartość';
export const YES_TEXT = 'Tak';

const DiscountTable: FC<IDiscountsProps> = ({
  discounts,
  remove,
  edit,
  permissionDiscountWrite,
}) => {
  const confirmModal = (discountCode: string | null) => {
    if (window.confirm(ARE_YOU_SURE_TEXT) && discountCode) {
      remove(discountCode);
    }
  };

  return (
    <Table
      responsive={true}
      highlightRowOnHover={true}
      hasOutline={true}
      verticalAlign="center"
      cards={true}
      className="text-nowrap"
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>{INFORMATION_TEXT}</Table.ColHeader>
          <Table.ColHeader>{DURATION_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">{TYPE_TEXT}</Table.ColHeader>
          <Table.ColHeader>{VALUE_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">{LIMIT_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">{USAGE_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">
            <i className="icon-settings" />
          </Table.ColHeader>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {discounts.map((discount) => {
          const timezoneStartDate = moment
            .utc(discount.startDate || new Date())
            .format('YYYY-MM-DD HH:mm:ss');

          const timezoneEndDate = moment
            .utc(discount.endDate || new Date())
            .format('YYYY-MM-DD HH:mm:ss');

          const startDate = moment(timezoneStartDate)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate();

          const endDate = moment(timezoneEndDate)
            .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
            .toDate();

          const isActive =
            discount.endDate && moment(endDate).isSameOrAfter(Date.now());

          const getIcon = (): string => {
            if (
              discount.usageCount &&
              discount.transactionsLimit &&
              discount.usageCount >= discount.transactionsLimit
            ) {
              return 'bg-danger';
            } else if (isActive) {
              return 'bg-success';
            }

            return 'bg-danger';
          };

          const classString = cn('status-icon', getIcon());

          const percentageValue = discount.percentageValue || 0;
          const transactionsLimit = discount.transactionsLimit || null;
          const startDateFormated = startDate ? dateFormated(startDate) : null;
          const endDateFormated = timezoneEndDate
            ? dateFormated(moment(timezoneEndDate).toDate())
            : null;

          return (
            <Table.Row key={discount.id}>
              <Table.Col>
                <div>
                  {CODE_TEXT}: {discount.code}
                </div>
                {discount.happening && discount.happening.metadata[0].title && (
                  <Text size="sm" muted={true}>
                    {HAPPENING_TEXT}:{' '}
                    {discount.happening && discount.happening.metadata[0].title}
                  </Text>
                )}
              </Table.Col>

              <Table.Col>
                <span className={classString} />
                {startDateFormated}{' '}
                {discount.endDate ? (
                  `- ${endDateFormated}`
                ) : (
                  <i>- {NO_BOUNDARIES_TEXT}</i>
                )}
              </Table.Col>
              <Table.Col>
                <Text size="sm" muted={true}>
                  {SUBTRACT_A_PERCENTAGE_TEXT}
                </Text>
                <div>
                  {percentageValue > 0 ? FROM_PRESENT_TEXT : FROM_PRICE_TEXT}
                </div>
              </Table.Col>
              <Table.Col alignContent="center">
                {discount.value || discount.percentageValue}
                {discount.value ? 'zł' : '%'}
              </Table.Col>
              <Table.Col alignContent="center">
                {transactionsLimit && transactionsLimit > 0
                  ? discount.transactionsLimit
                  : '-'}
              </Table.Col>
              <Table.Col alignContent="center">
                {discount.usageCount ? discount.usageCount : '-'}
              </Table.Col>

              {!!permissionDiscountWrite && (
                <Table.Col alignContent="center">
                  <Button.List align="right">
                    <Button
                      color="primary"
                      size="sm"
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => edit(discount)}
                      icon="edit-3"
                    />
                    <Button
                      color="secondary"
                      size="sm"
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => confirmModal(discount.code)}
                      icon="trash"
                    />
                  </Button.List>
                </Table.Col>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default DiscountTable;
