// Public
export const GET_RESERVATION_USERS = 'Zagrywki/formIo/GET_RESERVATION_USERS';
export const RESET_STATE = 'Zagrywki/formIo/RESET_STATE';
export const SET_USER = 'Zagrywki/formIo/SET_USER';
export const GET_AVAILABLE_USERS_BY_EMAIL =
  'Zagrywki/formio/GET_AVAILABLE_USERS_BY_EMAIL';
export const ADD_USER = 'Zagrywki/formIo/ADD_USER';
export const REMOVE_USER = 'Zagrywki/formIo/REMOVE_USER';
export const MOUNTED = 'Zagrywki/formIo/MOUNTED';
export const HANDLE_ACTIVE_PAGE_CONSENTS_LIST =
  'Zagrywki/formIo/HANDLE_ACTIVE_PAGE_CONSENTS_LIST';
export const SEARCH_CONSENTS_LIST = 'Zagrywki/formIo/SEARCH_CONSENTS_LIST';
export const SET_ENTRY_ID_EDIT = 'Zagrywki/formIo/SET_ENTRY_ID_EDIT';
export const CLOSE_MODAL = 'Zagrywki/formIo/CLOSE_MODAL';
export const ADD_ENTRY_CODE = 'Zagrywki/formIo/ADD_ENTRY_CODE';
export const CATCH_SAVE_USER = 'Zagrywki/formIo/CATCH_SAVE_USER';

export const SET_EMAIL = 'Zagrywki/formIo/SET_EMAIL';
export const SET_FIRST_NAME = 'Zagrywki/formIo/SET_FIRST_NAME';
export const SET_LAST_NAME = 'Zagrywki/formIo/SET_LAST_NAME';
export const SET_DATE = 'Zagrywki/formIo/SET_DATE';

// Private
export const _GET_REQUEST = 'Zagrywki/formIo/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/formIo/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/formIo/_GET_FAILURE';

export const _GET_AVAILABLE_USERS_REQUEST =
  'Zagrywki/formIo/_GET_AVAILABLE_USERS_REQUEST';
export const _GET_AVAILABLE_USERS_SUCCESS =
  'Zagrywki/formIo/_GET_AVAILABLE_USERS_SUCCESS';
export const _GET_AVAILABLE_USERS_FAILURE =
  'Zagrywki/formIo/_GET_AVAILABLE_USERS_FAILURE';

export const _GET_ENTRY_TOKENS_REQUEST =
  'Zagrywki/formIo/_GET_ENTRY_TOKENS_REQUEST';
export const _GET_ENTRY_TOKENS_SUCCESS =
  'Zagrywki/formIo/_GET_ENTRY_TOKENS_SUCCESS';
export const _GET_ENTRY_TOKENS_FAILURE =
  'Zagrywki/formIo/_GET_ENTRY_TOKENS_FAILURE';

export const _ADD_ENTRY_CODE_REQUEST =
  'Zagrywki/formIo/_ADD_ENTRY_CODE_REQUEST';
export const _ADD_ENTRY_CODE_SUCCESS =
  'Zagrywki/formIo/_ADD_ENTRY_CODE_SUCCESS';
export const _ADD_ENTRY_CODE_FAILURE =
  'Zagrywki/formIo/_ADD_ENTRY_CODE_FAILURE';

export const _GET_TOKEN_REQUEST = 'Zagrywki/formIo/_GET_TOKEN_REQUEST';
export const _GET_TOKEN_SUCCESS = 'Zagrywki/formIo/_GET_TOKEN_SUCCESS';
export const _GET_TOKEN_FAILURE = 'Zagrywki/formIo/_GET_TOKEN_FAILURE';

export const _GET_ENTRY_FORM_REQUEST =
  'Zagrywki/formIo/_GET_ENTRY_FORM_REQUEST';
export const _GET_ENTRY_FORM_SUCCESS =
  'Zagrywki/formIo/_GET_ENTRY_FORM_SUCCESS';
export const _GET_ENTRY_FORM_FAILURE =
  'Zagrywki/formIo/_GET_ENTRY_FORM_FAILURE';

export const _SAVE_USER_RESERVATION_REQUEST =
  'Zagrywki/formIo/_SAVE_USER_RESERVATION_REQUEST';
export const _SAVE_USER_RESERVATION_SUCCESS =
  'Zagrywki/formIo/_SAVE_USER_RESERVATION_SUCCESS';
export const _SAVE_USER_RESERVATION_FAILURE =
  'Zagrywki/formIo/_SAVE_USER_RESERVATION_FAILURE';

export const _EDIT_USER_REQUEST = 'Zagrywki/formIo/_EDIT_USER_REQUEST';
export const _EDIT_USER_SUCCESS = 'Zagrywki/formIo/_EDIT_USER_SUCCESS';
export const _EDIT_USER_FAILURE = 'Zagrywki/formIo/_EDIT_USER_FAILURE';
