import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { handleConfigurationsActivePage } from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import _Store from '@Store';
import Pagination from './Pagination.component';
import {
  IPaginationFromDispatch,
  IPaginationFromState,
} from './Pagination.types';

const mapStateToProps = (state: _Store.IState): IPaginationFromState => {
  const { activePage, totalCount, perPage } = getConfigurations(state);
  return {
    activePage,
    perPage,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPaginationFromDispatch => ({
  handleActivePage: (page: number) =>
    dispatch(handleConfigurationsActivePage(page)),
});

export default connect<
  IPaginationFromState,
  IPaginationFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Pagination);
