import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IGetAdminUsersSuccessPayload,
  IGetUsersSuccessPayload,
  ISaveUserPayload,
} from '@Model/user/types/index';

class UserApi {
  private static getSaveUserUrl(): string {
    return `${config.api.baseUrl}admin/cashiers`;
  }
  private static getUpdateSingleUserUrl(): string {
    return `${config.api.baseUrl}admin/cashiers`;
  }
  private static getUsersUrl(): string {
    return `${config.api.baseUrl}admin/cashiers`;
  }
  private static getAdminUsersUrl(): string {
    return `${config.api.baseUrl}admin/users`;
  }
  private static geRemoveUserUrl(id: number): string {
    return `${config.api.baseUrl}admin/cashiers/${id}/delete`;
  }

  private cancelTokenGetUsers?: CancelTokenSource;

  public addSingleUser(user: ISaveUserPayload): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = UserApi.getSaveUserUrl();
      axios
        .post(url, JSON.stringify(user))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public editSingleUser(user: ISaveUserPayload): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = UserApi.getUpdateSingleUserUrl();
      axios
        .put(url, JSON.stringify(user))
        .then(getData)
        .then(() => {
          resolve();
        });
    });
  }

  public removeUser(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(UserApi.geRemoveUserUrl(id))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getUsers(): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();
      axios
        .get(UserApi.getUsersUrl(), {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then((response: IGetUsersSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getAdminUsers(): Promise<IGetAdminUsersSuccessPayload> {
    return new Promise<IGetAdminUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();
      axios
        .get(UserApi.getAdminUsersUrl(), {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then((response: IGetAdminUsersSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelGetUsers() {
    if (this.cancelTokenGetUsers) {
      this.cancelTokenGetUsers.cancel();
      this.cancelTokenGetUsers = undefined;
    }
  }
}

export default new UserApi();
