import React from 'react';

import { FieldProps } from 'formik';
import { Form } from 'tabler-react';
import styles from '../../NewAddingConfiguration.module.scss';

const Select = ({ field, form: { touched, errors }, ...props }: FieldProps) => (
  <div>
    <Form.Select {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <div className={styles.error}>{errors[field.name]}</div>
    )}
  </div>
);

export default Select;
