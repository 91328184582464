import { FormikProps } from 'formik';
import React from 'react';
import { Form, Grid } from 'tabler-react';
import { string } from 'yup';

import EditableField from '@Compo/BetterManager/EditableField';
import { IManagerValues } from '../../Manager.types';

import styles from './Intervals.module.scss';

const LABEL_TEXT = 'Złożenie rezerwacji możliwe:';
const MIN_TEXT = 'Minimalna liczba dni przed';
const MAX_TEXT = 'Maksymalna liczba dni przed:';

const Intervals = ({ values, setFieldValue }: FormikProps<IManagerValues>) => {
  return (
    <Grid.Row className={styles.wrapper}>
      <Grid.Col>
        <div className={styles.title}> {LABEL_TEXT}</div>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label={MIN_TEXT}>
              <EditableField
                defaultValue={values.canBeBookedMin || ''}
                validator={string().nullable()}
                name={'canBeBookedMin'}
                onChange={setFieldValue}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={MAX_TEXT}>
              <EditableField
                validator={string()}
                defaultValue={values.canBeBookedMax || ''}
                name={'canBeBookedMax'}
                onChange={setFieldValue}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default Intervals;
