import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  addNewConfiguration,
  getConfiguration,
  handleActiveConfiguration,
  handleSearchParam,
  handleRuleTypeParam,
  mounted,
} from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import { IConfiguration, TRuleType } from '@Model/configurations/types';
import { CONFIGURATIONS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import DefinedConfigurations from './NewDefinedConfigurations.component';
import {
  IDefinedConfigurationsFromDispatch,
  IDefinedConfigurationsFromState,
} from './NewDefinedConfigurations.types';

const mapStateToProps = (
  state: _Store.IState,
): IDefinedConfigurationsFromState => {
  const {
    items: configurations,
    searchParam,
    selectedRuleType,
  } = getConfigurations(state);

  return {
    configurations,
    isLoading: getLoading(CONFIGURATIONS)(state),
    searchParam,
    selectedRuleType,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IDefinedConfigurationsFromDispatch => {
  return {
    addNewConfiguration: () => {
      dispatch(addNewConfiguration());
    },
    getConfiguration: () => {
      dispatch(getConfiguration());
    },
    handleActiveConfiguration: (configurations: IConfiguration) => {
      dispatch(handleActiveConfiguration(configurations));
    },
    handleSearchParam: (searchParam: string) => {
      dispatch(handleSearchParam(searchParam));
    },
    handleRuleTypeParam: (searchParam: TRuleType) => {
      dispatch(handleRuleTypeParam(searchParam));
    },
    mounted: () => {
      dispatch(mounted());
    },
  };
};

export default connect<
  IDefinedConfigurationsFromState,
  IDefinedConfigurationsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(DefinedConfigurations);
