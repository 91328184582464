import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IDiscounts } from '@Model/discounts/types';
import { IDiscountsResponse } from './types';

class DiscountsApi {
  private static getActivePrefix(active?: string): string {
    if (!active || active === '-1') {
      return '';
    } else if (active === '1') {
      return '&active=1';
    }
    return '&active=0';
  }
  private static getSearchPrefix(searchText?: string): string {
    if (searchText && searchText.length) {
      return `&filter[code]=${searchText}`;
    }
    return '';
  }

  private static getDiscountsUrl(
    page: number,
    count: number,
    partnerId?: string,
    active?: string,
    searchText?: string,
  ): string {
    const activePrefix = this.getActivePrefix(active);
    const searchPrefix = this.getSearchPrefix(searchText);

    if (partnerId) {
      return `${
        config.api.baseUrl
      }admin/discounts?page=${page}&count=${count}&partnerIds[]=${partnerId}${activePrefix}${searchPrefix}`;
    }
    return `${
      config.api.baseUrl
    }admin/discounts?page=${page}&count=${count}${activePrefix}${searchPrefix}`;
  }

  private cancelTokenDiscounts?: CancelTokenSource;

  public getDiscounts(
    page: number,
    count: number = 20,
    partnerId?: string,
    active?: string,
    searchText?: string,
  ): Promise<IDiscountsResponse> {
    return new Promise<IDiscountsResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(
          DiscountsApi.getDiscountsUrl(
            page,
            count,
            partnerId,
            active,
            searchText,
          ),
          {
            cancelToken: this.cancelTokenDiscounts.token,
          },
        )
        .then(getData)
        .then((response: IDiscountsResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeDiscounts(response: IDiscountsResponse): IDiscounts {
    return {
      items: response.items,
      totalCount: response.totalCount,
    };
  }

  public cancelDiscounts() {
    if (this.cancelTokenDiscounts) {
      this.cancelTokenDiscounts.cancel();
      this.cancelTokenDiscounts = undefined;
    }
  }
}

export default new DiscountsApi();
