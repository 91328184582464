import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment-timezone';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { getAvailabilities, getHappening } from '@Model/happening/actions';
import { setDate } from '@Model/reservation/actions';
import { IReservationPrintData } from '@Model/reservation/types';
import {
  changeReservationDate,
  getEmpikReservationDetails,
  getReservationDetails,
  getTicket,
  resentTicket,
  resetEmpikReservationDetails,
  selectReservation,
} from '@Model/reservations/actions';
import { IReservationFullResponse } from '@Services/$reservations-api/types';
import _Store from '@Store';
import { getDetails } from '../selectors';
import { addToast } from './../../toasts/actions/index';
import { TYPE_SUCCESS } from './../../toasts/constants/constants';

const RESENT_TICKET_SUCCESS_TEXT = 'Bilet wysłany ponownie';

export const requestReservationDetailsWhenSelected: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(selectReservation)),
    mergeMap$((action) =>
      of$(
        getReservationDetails.request(action.payload),
        resetEmpikReservationDetails(),
      ),
    ),
  );
};

export const fetchReservationDetailsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationDetails.request)),
    mergeMap$((action) => {
      return from$(reservationsApi.getReservationDetails(action.payload)).pipe(
        map$((response: IReservationFullResponse) => {
          return getReservationDetails.success(response);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelReservationDetails()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getReservationDetails.failure(error));
        }),
      );
    }),
  );
};

export const setActiveHappeningWhenDetailsSuccess: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getReservationDetails.success)),
    mergeMap$((action) => {
      const {
        start,
        details: { happening },
      } = action.payload;

      const timezoneStartDate = moment
        .utc(start || new Date())
        .format('YYYY-MM-DD HH:mm:ss');

      const startDate = moment(timezoneStartDate).toDate();

      if (happening && happening.metadata && happening.metadata.length) {
        return of$(
          getHappening.request(happening.metadata[0].slug),
          setDate(startDate),
          getAvailabilities.request({
            date: startDate,
            slug: happening.metadata[0].slug,
          }),
        );
      }

      return EMPTY$;
    }),
  );
};

export const getAvailableWhenChangeDate: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(changeReservationDate)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const reservationDetails = getDetails(state);

      if (reservationDetails && typeof reservationDetails !== 'number') {
        return of$(
          setDate(action.payload || new Date()),
          getAvailabilities.request({
            date: action.payload || new Date(),
            slug: reservationDetails.details.happening.metadata[0].slug,
          }),
        );
      }

      return EMPTY$;
    }),
  );
};

export const requestFormEmpikReservationDetailsReuestWhenSelectedReservation: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationDetails.success)),
    mergeMap$((action) => {
      if (action.payload.transactionUUID) {
        return of$(
          getEmpikReservationDetails.request(String(action.payload.uuid)),
        );
      }
      return EMPTY$;
    }),
  );
};

export const getEmpikReservationDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getEmpikReservationDetails.request)),
    mergeMap$((action) => {
      return from$(
        reservationsApi.getTransactionPrintDetails(action.payload),
      ).pipe(
        mergeMap$((data: IReservationPrintData) => {
          return of$(getEmpikReservationDetails.success(data));
        }),
        catchError$(() => {
          return EMPTY$;
        }),
      );
    }),
  );
};

export const resendTicketWhenRequestWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(resentTicket)),
    mergeMap$((action) => {
      return from$(reservationsApi.resentTicket(action.payload)).pipe(
        mergeMap$(() => {
          return of$(addToast(RESENT_TICKET_SUCCESS_TEXT, TYPE_SUCCESS));
        }),
        catchError$(() => {
          return EMPTY$;
        }),
      );
    }),
  );
};

export const getTicketWhenRequestWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getTicket)),
    mergeMap$((action) => {
      return from$(reservationsApi.getTicket(action.payload)).pipe(
        mergeMap$(() => {
          return of$(addToast(RESENT_TICKET_SUCCESS_TEXT, TYPE_SUCCESS));
        }),
        catchError$(() => {
          return EMPTY$;
        }),
      );
    }),
  );
};
