import React from 'react';

import { Form } from 'tabler-react';
import { IFormLayoutProps } from '../FormLayout.types';

const PLACE_CLOSED = 'Czy lokal jest zamknięty?';
const YES_TEXT = ' Tak';

export const TimeField = (props: IFormLayoutProps) => (
  <Form.Group label={PLACE_CLOSED}>
    <input
      name="closed"
      type="checkbox"
      onChange={props.handleChange}
      checked={props.values.closed}
    />
    {YES_TEXT}
  </Form.Group>
);
