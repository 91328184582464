import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { setReportType } from '@Model/reports/actions';
import { getReports } from '@Model/reports/selectors';
import _Store from '@Store';
import { IReport } from '../../Reports.types';
import Pagination from './ReportType.component';
import {
  IPaginationFromDispatch,
  IPaginationFromState,
} from './ReportType.types';

const mapStateToProps = (state: _Store.IState): IPaginationFromState => {
  const { selectedReport, availableReports } = getReports(state);
  return {
    availableReports,
    selectedReport,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPaginationFromDispatch => ({
  setReportType: (report: IReport) => dispatch(setReportType(report)),
});

export default connect<
  IPaginationFromState,
  IPaginationFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Pagination);
