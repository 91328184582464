import moment from 'moment';
import React from 'react';
import { Avatar, Grid } from 'tabler-react';

import { ISummaryProps } from './TransactionView.types';

import styles from './TransactionView.module.scss';

const TransactionView = ({ transactionItems, details }: ISummaryProps) => {
  const isNames = details.firstName || details.lastName;

  return (
    <section>
      {transactionItems.map(
        ({
          productName,
          lockCode,
          bookingIdentifier,
          startDate,
          endDate,
          entryToken,
        }) => {
          const returnDate = () => {
            if (startDate.length && endDate.length) {
              const timezoneStartDate = moment
                .utc(startDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const timezoneEndDate = moment
                .utc(endDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const formattedStartDate = moment(timezoneStartDate).format(
                'DD.MM.YYYY HH:mm:ss',
              );
              const formattedEndDate = moment(timezoneEndDate).format(
                'DD.MM.YYYY HH:mm:ss',
              );

              const date = `${formattedStartDate} - ${formattedEndDate}`;

              return date;
            }
            return '';
          };

          return (
            <Grid.Row key={bookingIdentifier} className="mb-5">
              <Grid.Col>
                <Grid.Row>
                  <Grid.Col width={3} className={styles.center}>
                    <Avatar
                      icon="hash"
                      size="md"
                      className={styles.smallIcon}
                      color="lime"
                    />
                  </Grid.Col>
                  <Grid.Col width={9}>
                    <h2 className={styles.description}>{productName}</h2>
                  </Grid.Col>
                </Grid.Row>

                {lockCode && (
                  <Grid.Row>
                    <Grid.Col width={3} className={styles.center}>
                      <Avatar
                        icon="cpu"
                        size="md"
                        className={styles.smallIcon}
                        color="lime"
                      />
                    </Grid.Col>
                    <Grid.Col width={9}>
                      <h2 className={styles.description}>
                        Lock code: {lockCode}
                      </h2>
                    </Grid.Col>
                  </Grid.Row>
                )}

                {isNames && (
                  <Grid.Row>
                    <Grid.Col width={3} className={styles.center}>
                      <Avatar
                        icon="user"
                        size="md"
                        className={styles.smallIcon}
                        color="lime"
                      />
                    </Grid.Col>
                    <Grid.Col width={9}>
                      <h2 className={styles.description}>
                        {details.firstName || ''} {details.firstName || ''}
                      </h2>
                    </Grid.Col>
                  </Grid.Row>
                )}

                {bookingIdentifier && (
                  <Grid.Row>
                    <Grid.Col width={3} className={styles.center}>
                      <Avatar
                        icon="hash"
                        size="md"
                        className={styles.smallIcon}
                        color="lime"
                      />
                    </Grid.Col>
                    <Grid.Col width={9}>
                      <h2 className={styles.description}>
                        {bookingIdentifier}
                      </h2>
                    </Grid.Col>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Col width={3} className={styles.center}>
                    <Avatar
                      icon="calendar"
                      size="md"
                      className={styles.smallIcon}
                      color="lime"
                    />
                  </Grid.Col>
                  <Grid.Col width={9}>
                    <h2 className={styles.description}>{returnDate()}</h2>
                  </Grid.Col>
                </Grid.Row>

                {entryToken && (
                  <Grid.Row>
                    <Grid.Col width={3} className={styles.center}>
                      <Avatar
                        icon="hash"
                        size="md"
                        className={styles.smallIcon}
                        color="lime"
                      />
                    </Grid.Col>
                    <Grid.Col width={9}>
                      <h2 className={styles.description}>{entryToken}</h2>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Grid.Col>
            </Grid.Row>
          );
        },
      )}
    </section>
  );
};

export default TransactionView;
