import { combineReducers } from 'redux';

import addReducer from './add';
import companyDataReducer from './companyData';
import durationAfterMidnightReducer from './durationAfterMidnight';
import formDataReducer from './formData';
import reservationDetailsReducer from './reservationDetails';
import reservationPrintDataReducer from './reservationPrintData';
import transactionReducer from './transaction';

const reducer = combineReducers({
  add: addReducer,
  companyData: companyDataReducer,
  durationAfterMidnight: durationAfterMidnightReducer,
  formData: formDataReducer,
  reservationDetails: reservationDetailsReducer,
  reservationPrintData: reservationPrintDataReducer,
  transaction: transactionReducer,
});

export default reducer;
