import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Grid } from 'tabler-react';

import { IReportRequest } from '@Model/reports/types';
import Filters from './components/Filters';
import ReportTable from './components/ReportsTable';
import { ISummaryProps } from './Reports.types';

import styles from './Reports.module.scss';

export interface IOwnFilters {
  id: number;
}

const Reports = ({
  getReport,
  reports,
  selectedKeys,
  selectedReport,
  permissionReportsRead,
}: ISummaryProps) => {
  useEffect(() => {
    getReport();
  }, []);

  const jsonToCsv = () => {
    return reports.map((report) => {
      const newObject: IReportRequest = {};

      selectedKeys.forEach((key) => {
        newObject[key] = report[key];
      });

      return newObject;
    });
  };

  if (!permissionReportsRead) {
    return null;
  }

  return (
    <section>
      <Filters />
      <Grid.Row>
        <ReportTable />
      </Grid.Row>
      <div className={styles.downloadButton}>
        <CSVLink
          data={jsonToCsv()}
          filename={`${selectedReport.slug}${moment().format(
            'YYYY-MM-DD/HH:mm:ss',
          )}.csv`}
        >
          <Button icon="download" color="warning">
            Pobierz jako CSV
          </Button>
        </CSVLink>
      </div>
    </section>
  );
};

export default Reports;
