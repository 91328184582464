import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IDiscount } from '@Model/discount/types';
import axios from 'axios';

class DiscountApi {
  private static getDiscountsUrl(): string {
    return `${config.api.baseUrl}admin/discounts`;
  }

  private static getUpdateSingleDiscountUrl(slug: string): string {
    return `${config.api.baseUrl}admin/discounts/${slug}`;
  }

  private static geRemoveDiscountsUrl(slug: string): string {
    return `${
      config.api.baseUrl
    }admin/discounts/${slug.toLocaleLowerCase()}/delete`;
  }

  public addSingleDiscount(body: IDiscount): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (body.id) {
        const url = DiscountApi.getUpdateSingleDiscountUrl(body.id.toString());
        axios
          .put(url, JSON.stringify(body))
          .then(getData)
          .then(() => {
            resolve();
          });
      } else {
        const url = DiscountApi.getDiscountsUrl();
        axios
          .post(url, JSON.stringify(body))
          .then(() => {
            resolve();
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public removeDiscount(slug: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(DiscountApi.geRemoveDiscountsUrl(slug))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
}

export default new DiscountApi();
