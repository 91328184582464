import { get } from '@Model/authorization/selectors';
import { addPrinter, mounted } from '@Model/printer/actions';
import getShowModal from '@Model/printer/selectors/getShowModal';
import { allPermissions, PRINTER } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import PrinterAdmin from './PrinterAdmin.component';
import {
  IPrinterAdminFromDispatch,
  IPrinterAdminFromState,
} from './PrinterAdmin.types';

const mapStateToProps = (state: _Store.IState): IPrinterAdminFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    isLoading: getLoading(PRINTER)(state),
    permissionPrintersRead: permissions.includes(
      allPermissions.access_printers_read,
    ),
    permissionPrintersView: permissions.includes(
      allPermissions.access_printers_view,
    ),
    permissionPrintersWrite: permissions.includes(
      allPermissions.access_printers_write,
    ),
    showModal: getShowModal(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPrinterAdminFromDispatch => {
  return {
    addPrinter: () => {
      dispatch(addPrinter());
    },
    printersMounted: () => {
      dispatch(mounted());
    },
  };
};

export default connect<
  IPrinterAdminFromState,
  IPrinterAdminFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(PrinterAdmin);
