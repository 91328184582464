import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { mounted } from '@Model/smartporter/actions';
import { getApartments } from '@Model/smartporter/selectors';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import Manager from './Manager.component';
import { IManagerFromDispatch, IManagerFromState } from './Manager.types';

const mapStateToProps = (state: _Store.IState): IManagerFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    apartments: getApartments(state),
    permissionSpaceRead: permissions.includes(
      allPermissions.access_spaces_read,
    ),
    permissionSpaceView: permissions.includes(
      allPermissions.access_spaces_view,
    ),
    permissionSpaceWrite: permissions.includes(
      allPermissions.access_spaces_write,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IManagerFromDispatch => {
  return {
    mounted: () => {
      dispatch(mounted());
    },
  };
};

export default connect<
  IManagerFromState,
  IManagerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Manager);
