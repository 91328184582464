import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IConfiguration, TRuleType } from '@Model/configurations/types';
import axios, { CancelTokenSource } from 'axios';
import { IConfigurationsResponse } from './types';

class ConfigurationsApi {
  private static getConfigurationsUrl(
    page: number,
    count: number,
    searchParam: string,
    selectedRuleType: TRuleType,
  ): string {
    if (selectedRuleType && config.cms.showUnfinishedFeatures) {
      return `${
        config.api.baseUrlV2
      }admin/rules/${selectedRuleType}?page=${page}&count=${count}&q=${searchParam}`;
    }
    return `${
      config.api.baseUrl
    }admin/configurations?page=${page}&count=${count}&q=${searchParam}`;
  }

  private static deleteConfigurationsUrl(configurationId: string): string {
    return `${
      config.api.baseUrl
    }admin/configurations/${configurationId}/delete`;
  }

  private static saveConfigurationsUrl(v2?: string): string {
    if (v2) {
      return `${config.api.baseUrlV2}admin/rules/${v2}`;
    }
    return `${config.api.baseUrl}admin/configurations`;
  }

  private cancelTokenConfigurations?: CancelTokenSource;

  public getConfigurations<T extends IConfigurationsResponse>(
    page: number,
    count: number = 20,
    searchParam: string = '',
    selectedRuleType: TRuleType,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      axios
        .get(
          ConfigurationsApi.getConfigurationsUrl(
            page,
            count,
            searchParam,
            selectedRuleType,
          ),
          {
            cancelToken: this.cancelTokenConfigurations.token,
          },
        )
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteConfiguration<T extends IConfigurationsResponse>(
    configurationId: string,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      axios
        .post(ConfigurationsApi.deleteConfigurationsUrl(configurationId), {
          cancelToken: this.cancelTokenConfigurations.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveConfiguration(configuration: IConfiguration, v2?: string) {
    return new Promise((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      const method = configuration.id ? 'put' : 'post';

      axios[method](
        ConfigurationsApi.saveConfigurationsUrl(v2),
        JSON.stringify(configuration),
      )
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelConfigurations() {
    if (this.cancelTokenConfigurations) {
      this.cancelTokenConfigurations.cancel();
      this.cancelTokenConfigurations = undefined;
    }
  }
}

export default new ConfigurationsApi();
