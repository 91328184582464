import { connect } from 'react-redux';

import { get } from '@Model/authorization/selectors';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import Main from './Main.component';
import { IMainState } from './Main.types';

const mapStateToProps = (state: _Store.IState): IMainState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    permissionCashiersRead: permissions.includes(
      allPermissions.access_cashiers_read,
    ),
    permissionDiscountsView: permissions.includes(
      allPermissions.access_discounts_view,
    ),
    permissionHappeningsView: permissions.includes(
      allPermissions.access_happenings_view,
    ),
    permissionPrintersRead: permissions.includes(
      allPermissions.access_printers_read,
    ),
    permissionReservationView: permissions.includes(
      allPermissions.access_reservations_view,
    ),
  };
};

export default connect<IMainState, void, {}, _Store.IState>(mapStateToProps)(
  Main,
);
