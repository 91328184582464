import React, { useEffect } from 'react';

import { Button } from 'tabler-react';
import SessionModal from './components/SessionModal';
import { ISessionPanelProps } from './SessionPanel.types';

import styles from './SessionPanel.module.scss';

const START_SESSION_TEXT = 'ROZPOCZNIJ SESJĘ SPRZEDAŻY';
const END_SESSION_TEXT = 'ZAKOŃCZ SESJĘ SPRZEDAŻY';

const SessionPanel = ({
  mounted,
  showModal,
  isModalOpen,
  isSession,
  endSession,
  permissionWrite,
}: ISessionPanelProps) => {
  useEffect(() => {
    mounted();
  }, []);

  if (isModalOpen) {
    return <SessionModal />;
  }

  if (permissionWrite) {
    return (
      <>
        {isSession ? (
          <Button
            className={styles.sessionButton}
            onClick={endSession}
            color="danger"
          >
            {END_SESSION_TEXT}
          </Button>
        ) : (
          <Button
            className={styles.sessionButton}
            onClick={showModal}
            color="primary"
          >
            {START_SESSION_TEXT}
          </Button>
        )}
      </>
    );
  }

  return null;
};

export default SessionPanel;
