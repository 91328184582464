import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import {
  addNewActiveInputToGroup,
  catchSelectedKeys,
  getReport,
  setFilters,
  setReportType,
} from '@Model/reports/actions';
import { getReports } from '@Model/reports/selectors';
import { ISetAvailableReports } from '@Model/reports/types';
import _Store from '@Store';
import { IFilter, IReport } from '../../Reports.types';
import Filters from './Filters.component';
import { IPaginationFromDispatch, IPaginationFromState } from './Filters.types';

const mapStateToProps = (state: _Store.IState): IPaginationFromState => {
  const {
    selectedReport,
    availableReports,
    selectedKeys,
    keys,
    filters: selectedFilters,
  } = getReports(state);
  return {
    availableReports,
    keys,
    selectedFilters,
    selectedKeys,
    selectedReport,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPaginationFromDispatch => ({
  addNewActiveInputToGroup: (report: ISetAvailableReports) =>
    dispatch(addNewActiveInputToGroup(report)),
  catchSelectedKeys: (key: string) => dispatch(catchSelectedKeys(key)),
  getReport: () => dispatch(getReport()),
  setFilters: (filters: IFilter[]) => dispatch(setFilters(filters)),
  setReportType: (report: IReport) => dispatch(setReportType(report)),
});

export default connect<
  IPaginationFromState,
  IPaginationFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
