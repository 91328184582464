import React from 'react';

import getTimeAsString from '@Misc/helpers/getTimeAsString';

const days = [
  'niedziela',
  'poniedziałek',
  'wtorek',
  'środa',
  'czwartek',
  'piątek',
  'sobota',
];

const months = [
  'stycznia',
  'lutego',
  'marca',
  'kwietnia',
  'maja',
  'czerwca',
  'lipca',
  'sierpnia',
  'września',
  'października',
  'listopada',
  'grudnia',
];

const Date = ({ date }: { date: Date }) => {
  return (
    <tr>
      <td>{days[date.getUTCDay()]}</td>
      <td>
        {date.getUTCDate()} {months[date.getUTCMonth()]} {date.getUTCFullYear()}
      </td>
      <td>{getTimeAsString(date.getUTCHours(), date.getUTCMinutes())}</td>
    </tr>
  );
};

export default Date;
