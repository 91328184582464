import React, { FC } from 'react';

import cn from 'classnames';
import moment from 'moment';
import RRule, { Weekday } from 'rrule';
import { Button, Table } from 'tabler-react';

import { daysTranslator } from '@Compo/BetterManager/AddingConfiguration/components/ConfigurationModal/ConfigurationModal.helpers';
import { IConfigurationProps } from './Configuration.types';

import styles from './Configuration.module.scss';

const UP_TEXT = 'podwyż';
const DOWN_TEXT = 'obniż';
const YES_TEXT = 'tak';
const NO_TEXT = 'nie';

const Configuration: FC<IConfigurationProps> = ({
  configuration,
  handleActiveConfiguration,
  handleEditConfiguration,
  handleRemoveConfiguration,
}) => {
  const {
    title,
    prices,
    closed,
    maxNumberOfPeople,
    upsell,
    duration,
    fromNumberOfPeopleModifier,
    name,
    validFrom,
    validTo,
    isClosed,
    capacity,
    price,
  } = configuration;

  const catchEdit = () => {
    handleActiveConfiguration(configuration);
  };

  const getUpSell = () => {
    return `${
      upsell && (upsell.value || upsell.percentageValue)
        ? `${upsell.value} ${upsell.percentageValue ? '%' : ''}`
        : '-'
    }`;
  };

  const getFromNumberOfPeopleModifier = () => {
    if (fromNumberOfPeopleModifier) {
      const {
        value,
        priceGoesUp,
        fromNumberOfPeople,
        percentageValue,
      } = fromNumberOfPeopleModifier;

      return ` ${`od: ${fromNumberOfPeople} ${
        priceGoesUp ? UP_TEXT : DOWN_TEXT
      } o ${value} ${percentageValue ? '%' : 'zł'}`}
          `;
    }
    return '-';
  };

  const getDays = () => {
    const options = RRule.fromString(configuration.rrule).origOptions;

    if (options.byweekday) {
      const arrayDays = options.byweekday as Weekday[];
      const daysString = arrayDays
        .map((day) => daysTranslator(day.toString()))
        .join(',');

      return `${daysString}`;
    } else if (options.bymonth && options.bymonthday) {
      let month;
      let day;
      if (Array.isArray(options.bymonth)) {
        month = options.bymonth[0];
      } else {
        month = options.bymonth;
      }
      if (Array.isArray(options.bymonthday)) {
        day = options.bymonthday[0];
      } else {
        day = options.bymonthday;
      }
      const year = new Date().getFullYear() - 1;

      return `${moment(new Date(year, month - 1, day, 0, 0, 0)).format(
        'DD-MM-YYYY',
      )}`;
    }
  };

  const getMaxPeople = () => {
    return `${maxNumberOfPeople ? maxNumberOfPeople : '-'}`;
  };
  const getClosed = () => {
    return isClosed || closed ? YES_TEXT : NO_TEXT;
  };

  const getType = () => {
    if (prices && prices.length && prices[0].type) {
      return 'Cenowa';
    } else if (isClosed !== undefined) {
      return 'Czasowa';
    } else if (capacity !== undefined) {
      return 'Pojemnościowa';
    }

    return '-';
  };

  const getPrice = () => {
    return price || prices[0].value || '-';
  };

  const getTime = () => {
    const options = RRule.fromString(configuration.rrule).origOptions;

    if (
      typeof options.byhour === 'number' &&
      typeof options.byminute === 'number'
    ) {
      const nextDate = moment().toDate();

      nextDate.setHours(options.byhour);
      nextDate.setMinutes(options.byminute);
      const helpDate = moment(nextDate).add(duration, 'seconds');
      const isSameDay = moment(nextDate).isSame(helpDate, 'day');

      return `${moment(nextDate).format('H:m')}-${helpDate.format('H:m')} ${
        !isSameDay ? 'n. d.' : ''
      }`;
    }
  };

  const isActive = (): boolean => {
    if (validFrom && validTo) {
      return moment().isBetween(moment(validFrom), moment(validTo));
    } else if (validFrom && !validTo) {
      return moment().isAfter(moment(validFrom));
    }
    return true;
  };

  return (
    <Table.Row
      onClick={catchEdit}
      className={cn(!isActive() && styles.isNotActive)}
    >
      <Table.Col>{title || name}</Table.Col>
      <Table.Col alignContent="center">{getType()}</Table.Col>
      <Table.Col alignContent="center">{getClosed()}</Table.Col>
      <Table.Col alignContent="center">{getDays()}</Table.Col>
      <Table.Col alignContent="center">{getTime()}</Table.Col>
      <Table.Col alignContent="center">{getPrice()}</Table.Col>
      <Table.Col alignContent="center">{getUpSell()}</Table.Col>
      <Table.Col alignContent="center">
        {getFromNumberOfPeopleModifier()}
      </Table.Col>
      <Table.Col alignContent="center">{getMaxPeople()}</Table.Col>

      {(handleEditConfiguration || handleRemoveConfiguration) && (
        <Table.Col alignContent="center">
          <Button.List align="right">
            {handleEditConfiguration && (
              <Button
                color="primary"
                size="sm"
                onClick={() => handleEditConfiguration(configuration)}
                icon="edit-3"
              />
            )}
            {handleRemoveConfiguration && (
              <Button
                color="secondary"
                size="sm"
                onClick={() =>
                  handleRemoveConfiguration(configuration.id || '')
                }
                icon="trash"
              />
            )}
          </Button.List>
        </Table.Col>
      )}
    </Table.Row>
  );
};

export default Configuration;
