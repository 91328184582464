import React, { useEffect } from 'react';

import { Formik } from 'formik';

import { IConfiguration, IFormValuesProps } from '@Model/configurations/types';
import * as helpers from '../../NewAddingConfiguration.helpers';
import FormLayout from './Components/FormLayout';
import { makeName, makeRRule } from './Components/FormLayout/FormLayout.helpes';
import { IConfigurationModalProps } from './ConfigurationModal.types';
import { AddingConfigurationSchema } from './ConfigurationModal.validation';

import styles from './ConfigurationModal.module.scss';

export const ConfigurationModal = ({
  activeConfiguration,
  catchSaveConfiguration,
  initialValues,
  catchGetRuleType,
}: IConfigurationModalProps) => {
  if (!activeConfiguration) {
    return null;
  }

  useEffect(() => {
    catchGetRuleType();
  }, []);

  const catchHandleSubmit = (values: IFormValuesProps) => {
    const {
      id,
      price,
      durationToString,
      durationAdvanced,
      duration,
      advanced,
      title,
      isUpsell,
      closed,
      upsellValue,
      isPeopleCount,
      partnerId,
      validTo,
      validFrom,
      ruleType,
      capacity,
      prices,
    } = values;

    const getDuration = () => {
      if (!advanced) {
        return {
          duration: durationNewValue || duration,
        };
      }
      return {
        duration: durationAdvanced,
      };
    };

    const getTitle = (): { title: string } => {
      if (title && title.length > 0) {
        return {
          title,
        };
      }

      return {
        title: makeName(values, makeRRule(values).toUpperCase()),
      };
    };

    const getPeopleCount = () => {
      const {
        fromNumberOfPeople,
        perPersonValue,
        perPersonType,
        priceGoesUp,
      } = values;

      const getValue = () => {
        if (perPersonType === 'value') {
          return {
            percentageValue: null,
            value: Number(perPersonValue),
          };
        } else {
          return {
            percentageValue: Number(perPersonValue),
            value: null,
          };
        }
      };

      if (isPeopleCount) {
        return {
          fromNumberOfPeopleModifier: {
            fromNumberOfPeople: Number(fromNumberOfPeople),
            priceGoesUp: priceGoesUp === 'up',
            ...getValue(),
          },
        };
      }
      return {};
    };

    const durationNewValue = helpers.makeTimeFromDuration(durationToString);

    const getPropsByPriceRule = () => {
      if (ruleType === 'price') {
        const getUpsalle = () => {
          const value = upsellValue || 0;

          if (isUpsell) {
            if (values.upsellType === 0) {
              return {
                upsell: {
                  percentageValue: null,
                  value,
                },
              };
            }
            return {
              upsell: {
                percentageValue: value,
                value: null,
              },
            };
          }

          return {};
        };

        return {
          prices,
          ...getUpsalle(),
          ...getPeopleCount(),
        };
      }
      return {};
    };

    const getPropsByTimeRule = () => {
      if (ruleType === 'time') {
        return {
          closed,
        };
      }
      return {};
    };

    const getPropsByCapacityRule = () => {
      if (ruleType === 'capacity') {
        return {
          capacity,
        };
      }
      return {};
    };

    const answer = {
      id: id || undefined,
      partnerId:
        partnerId && Number(partnerId) !== -1 && Number(partnerId) !== 0
          ? Number(partnerId)
          : null,
      rrule: makeRRule(values).toUpperCase(),
      validFrom,
      validTo,
      ...getPropsByPriceRule(),
      ...getPropsByTimeRule(),
      ...getDuration(),
      ...getPropsByCapacityRule(),
      ...getTitle(),
    };

    if (values.closed) {
      answer.closed = true;
    }

    catchSaveConfiguration({
      configuration: answer as IConfiguration,
      type: ruleType,
    });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <div className={styles.right}>
          <Formik
            initialValues={initialValues}
            onSubmit={catchHandleSubmit}
            validationSchema={AddingConfigurationSchema}
            component={FormLayout}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationModal;
