import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { setEntryIdEdit } from '@Model/formio/actions';
import {
  getFormIoAvailableUsers,
  getFormIoConsentsListShowModal,
} from '@Model/formio/selectors';
import _Store from '@Store';
import ConsentsListTable from './ConsentsListTable.component';
import {
  IConsentsListTableFromDispatch,
  IConsentsListTableFromState,
} from './ConsentsListTable.types';

const mapStateToProps = (state: _Store.IState): IConsentsListTableFromState => {
  return {
    showModal: getFormIoConsentsListShowModal(state),
    users: getFormIoAvailableUsers(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IConsentsListTableFromDispatch => ({
  setEntryIdEdit: (user) => dispatch(setEntryIdEdit(user)),
});

export default connect<
  IConsentsListTableFromState,
  IConsentsListTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentsListTable);
