import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { redirectBuyingContinue } from '@Model/iframe/actions';
import { catchPrintReceipt } from '@Model/printer/actions';
import { getPrinterData } from '@Model/printer/selectors';
import { summaryMounted } from '@Model/reservation/actions';
import {
  getReservationDetails,
  getReservationPrintData,
} from '@Model/reservation/selectors';
import _Store from '@Store';
import Summary from './Summary.component';
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const {
    userInfo: { session },
  } = get(state);
  const { printingReceiptHasError } = getPrinterData(state);
  return {
    isSession: !!(session && session.session_id),
    printingReceiptHasError,
    reservationDetails: getReservationDetails(state),
    reservationPrintData: getReservationPrintData(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryFromDispatch => ({
  continueBuying: () => dispatch(redirectBuyingContinue()),
  mounted: () => dispatch(summaryMounted()),
  printReceipt: () => dispatch(catchPrintReceipt()),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);
