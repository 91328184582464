import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IGetApartmentsSuccessPayload } from '@Model/smartporter/types';
import axios, { CancelTokenSource } from 'axios';

class SmartporterApi {
  private static getRefreshList() {
    return `${config.api.baseUrl}admin/apartments-update`;
  }
  private static getApartmentsUrl() {
    return `${config.api.baseUrl}admin/apartments`;
  }
  private cancelTokenApartments?: CancelTokenSource;

  public refreshList(): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      axios
        .get(SmartporterApi.getRefreshList())
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getApartments(): Promise<IGetApartmentsSuccessPayload> {
    return new Promise<IGetApartmentsSuccessPayload>((resolve, reject) => {
      this.cancelTokenApartments = axios.CancelToken.source();
      axios
        .get(SmartporterApi.getApartmentsUrl(), {
          cancelToken: this.cancelTokenApartments.token,
        })
        .then(getData)
        .then((response: IGetApartmentsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelApartments() {
    if (this.cancelTokenApartments) {
      this.cancelTokenApartments.cancel();
      this.cancelTokenApartments = undefined;
    }
  }
}

export default new SmartporterApi();
//
