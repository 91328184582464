import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IPaymentSessionPayload, IUserInfo } from '@Model/authorization/types';
import axios, { CancelTokenSource } from 'axios';
import jwtDecode from 'jwt-decode';
import {
  IAuthorizationResponse,
  ICasherInfoResponse,
  IPaymentSessionResponse,
  IRunSessionRequest,
} from './types';

class AuthorizationApi {
  private static getAuthorizationUrl(token: string): string {
    return `${config.api.baseUrl}admin/auth/${token}`;
  }

  private static getCasherInfoUrl(id: number): string {
    return `${config.api.baseUrl}admin/cashiers/${id}`;
  }

  private static getRunSessionUrl(): string {
    return `${config.api.baseUrl}admin/cashiersession`;
  }

  private static getRunClearSessionUrl(printerId?: number): string {
    return printerId
      ? `${config.api.baseUrl}admin/cashiersession/close?printerId=${printerId}`
      : `${config.api.baseUrl}admin/cashiersession/close`;
  }

  private static getSaleSessionApiUrl(sessionExternalId: string): string {
    return `${config.api.saleSessionApi}session/${sessionExternalId}`;
  }

  private static getSaleSessionApiUrlAction(): string {
    return `${config.api.saleSessionApi}action`;
  }

  private cancelTokenAuthorization?: CancelTokenSource;

  public getSessionToken(token: string): Promise<IAuthorizationResponse> {
    return new Promise<IAuthorizationResponse>((resolve, reject) => {
      this.cancelTokenAuthorization = axios.CancelToken.source();
      axios
        .get(AuthorizationApi.getAuthorizationUrl(token), {
          cancelToken: this.cancelTokenAuthorization.token,
        })
        .then(getData)
        .then((response: IAuthorizationResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public encodeSessionToken(sessionToken: string): IUserInfo {
    const decoded = jwtDecode(sessionToken) as IUserInfo;
    return {
      email: '',
      exp: 0,
      first_name: '',
      iat: 0,
      is_activated: true,
      is_superuser: false,
      last_name: '',
      username: '',
      ...decoded,
      permissions: decoded.permissions,
    };
  }

  public fetchAuthorizationCasherInfo(
    id: number,
  ): Promise<ICasherInfoResponse> {
    return new Promise<ICasherInfoResponse>((resolve, reject) => {
      axios
        .get(AuthorizationApi.getCasherInfoUrl(id))
        .then(getData)
        .then((response: ICasherInfoResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public runSession(sessionData: IRunSessionRequest): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const url = AuthorizationApi.getRunSessionUrl();
      axios
        .post(url, JSON.stringify(sessionData))
        .then(getData)
        .then((response: {}) => {
          resolve(response);
        });
    });
  }

  public clearSession(): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const url = AuthorizationApi.getRunClearSessionUrl();
      axios
        .get(url)
        .then(getData)
        .then((response: {}) => {
          resolve(response);
        });
    });
  }

  public clearSessionByPrinterId(printerId: number): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const url = `${AuthorizationApi.getRunClearSessionUrl(printerId)}`;
      axios
        .get(url)
        .then(getData)
        .then((response: {}) => {
          resolve(response);
        });
    });
  }

  public cancelAuthorization() {
    if (this.cancelTokenAuthorization) {
      this.cancelTokenAuthorization.cancel();
      this.cancelTokenAuthorization = undefined;
    }
  }

  public getPaymentSession(
    sessionExternalId: string,
  ): Promise<IPaymentSessionResponse[]> {
    return new Promise((resolve, reject) => {
      const instance = axios.create({});
      delete instance.defaults.headers.common.Authorization;

      const headers = { 'auth-token': config.api.saleSessionAuthToken };

      return instance
        .get(AuthorizationApi.getSaleSessionApiUrl(sessionExternalId), {
          headers,
        })
        .then(getData)
        .then(getData)
        .then((response: IPaymentSessionResponse[]) => {
          resolve(response);
        })
        .catch((error) => {
          return reject(catchHttpError(error));
        });
    });
  }

  public updatePaymentSession(payload: IPaymentSessionPayload): Promise<{}> {
    return new Promise((resolve, reject) => {
      const instance = axios.create({});
      delete instance.defaults.headers.common.Authorization;

      const headers = { 'auth-token': config.api.saleSessionAuthToken };

      return instance
        .post(AuthorizationApi.getSaleSessionApiUrlAction(), payload, {
          headers,
        })

        .then(() => {
          resolve();
        })
        .catch((error) => {
          return reject(catchHttpError(error));
        });
    });
  }
}

export default new AuthorizationApi();
