import React from 'react';

import moment from 'moment';
import { Button, Grid, Table } from 'tabler-react';

import {
  FORM_IO_BIRTHDAY,
  FORM_IO_EMAIL,
  FORM_IO_LAST_NAME,
  FORM_IO_NAME,
} from '@Model/formio/constants/constants';
import ConsentsListModal from './../ConsentsListModal';
import { IConsentsListTableProps } from './ConsentsListTable.types';

const EMAIL_TEXT = 'Email';
const NAME_TEXT = 'Imię';
const LAST_NAME_TEXT = 'Nazwisko';
const AGE_TEXT = 'Data urodzenia';

const ConsentsListTable = ({
  users,
  setEntryIdEdit,
  showModal,
}: IConsentsListTableProps) => {
  return (
    <>
      {showModal && <ConsentsListModal />}
      <Grid.Row className="mb-5">
        <Grid.Col>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>{EMAIL_TEXT}</Table.ColHeader>
                <Table.ColHeader>{NAME_TEXT}</Table.ColHeader>
                <Table.ColHeader>{LAST_NAME_TEXT}</Table.ColHeader>
                <Table.ColHeader>{AGE_TEXT}</Table.ColHeader>
                <Table.ColHeader alignContent="center">
                  <i className="icon-settings" />
                </Table.ColHeader>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {users.map((user, index) => (
                <Table.Row key={index}>
                  <Table.Col>{user.data[FORM_IO_EMAIL]}</Table.Col>
                  <Table.Col>{user.data[FORM_IO_NAME]}</Table.Col>
                  <Table.Col>{user.data[FORM_IO_LAST_NAME]}</Table.Col>
                  {user.data[FORM_IO_BIRTHDAY] && (
                    <Table.Col>
                      {moment(user.data[FORM_IO_BIRTHDAY]).format('DD.MM.YYYY')}
                    </Table.Col>
                  )}
                  <Table.Col>
                    <Button
                      color="primary"
                      size="sm"
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => setEntryIdEdit(user)}
                      icon="edit-3"
                    />
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default ConsentsListTable;
