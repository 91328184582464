import React from 'react';

import { IFormFields } from '@Model/printer/types';
import { Field } from 'formik';
import { Button, Card, Form, Grid } from 'tabler-react';
import styles from './FormLayout.module.scss';
import { IFormLayoutProps } from './FormLayout.types';

export const SAVE_TEXT = 'Zapisz';
export const CANCEL_TEXT = 'Anuluj';
export const Printer_EMAIL_TEXT = 'Email użytkownika';
export const SALE_URL_TEXT = 'Url drukarki';
export const PRINTER_ID_TEXT = 'Nazwa drukarki';
export const FIRMWARE_TEXT = 'Wersja opogramawonia';
export const PARTNER_TEXT = 'Partner';
export const CONTAIN_DRAWER_TEXT = 'Szuflada';

const FormLayout = ({
  handleSubmit,
  values,
  touched,
  errors,
  handleChange,
  closeModal,
  permissionPrintersWrite,
  setFieldValue,
  partners,
}: IFormLayoutProps) => {
  const isHaveEnyError = (paramName: IFormFields): boolean => {
    return !!(touched && touched[paramName] && errors && errors[paramName]);
  };

  const catchPrinterFirmware = (event: React.FormEvent<HTMLSelectElement>) => {
    setFieldValue('firmware', event.currentTarget.value);
  };

  const catchPrinterSetPartner = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    setFieldValue('partnerId', event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={PRINTER_ID_TEXT}>
                  <Field
                    type="text"
                    className={styles.formInput}
                    value={values.printerId || ''}
                    name="printerId"
                    onChange={handleChange}
                  />{' '}
                  {isHaveEnyError('printerId') && (
                    <Form.InputGroupText className={styles.errorMessage}>
                      {errors.printerId}
                    </Form.InputGroupText>
                  )}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={SALE_URL_TEXT}>
                  <Field
                    className={styles.formInput}
                    value={values.saleUrl || ''}
                    name="saleUrl"
                    onChange={handleChange}
                  />{' '}
                  {isHaveEnyError('saleUrl') && (
                    <Form.InputGroupText className={styles.errorMessage}>
                      {errors.saleUrl}
                    </Form.InputGroupText>
                  )}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={FIRMWARE_TEXT}>
                  <Form.Select
                    value={values.firmware}
                    onChange={catchPrinterFirmware}
                    name={'firmware'}
                    invalid={touched.firmware && errors.firmware}
                  >
                    <option value={-1}>-</option>
                    <option value={'1.0.0'}>1.0.0</option>
                    <option value={'1.0.1'}>1.0.1</option>
                  </Form.Select>
                  {touched.printerId && errors.printerId && (
                    <div className={styles.errorMessage}>
                      {errors.printerId}
                    </div>
                  )}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={PARTNER_TEXT}>
                  <Form.Select
                    value={values.partnerId || ''}
                    onChange={catchPrinterSetPartner}
                    name={'partnerId'}
                    invalid={touched.partnerId && errors.partnerId}
                  >
                    <option value={-1}>-</option>

                    {partners &&
                      partners.map((partner) => (
                        <option key={partner.id} value={partner.id}>
                          {partner.name || ''}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group>
                  <Form.Checkbox
                    checked={values.drawer}
                    isInline={true}
                    label={CONTAIN_DRAWER_TEXT}
                    name="drawer"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </div>
        </div>
      </Card>

      <Button.List>
        {permissionPrintersWrite ? (
          <Button type="submit" color="primary" icon="save">
            {SAVE_TEXT}
          </Button>
        ) : null}
        <Button onClick={closeModal} type="button" color="secondary" icon="x">
          {CANCEL_TEXT}
        </Button>
      </Button.List>
    </form>
  );
};

export default FormLayout;
