/* tslint:disable:jsx-no-lambda */
import { format } from 'date-fns';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { Alert, Button, Card, Form, Grid } from 'tabler-react';
import uuid from 'uuid/v4';
import { string } from 'yup';

import mapHappeningToStateAndInitialValues from '@Compo/BetterManager/Manager/Manager.helpers';
import {
  durationValidator,
  ManagerConfigurationSchema,
  numberValidator,
  REQUIRED_VALIDATOR_TEXT,
} from '@Compo/BetterManager/Manager/Manager.validation';
import ColorPicker from '@Compo/reusable/ColorPicker';
import DatePicker from '@Compo/reusable/DatePicker';
import TimePicker from '@Compo/reusable/TimePicker';
import { getRuleType } from '@Misc/helpers/getRuleType';
import goTrough from '@Misc/helpers/goTrough';
import { IConfiguration, TRuleType } from '@Model/configurations/types';
import { makeTimeFromDuration } from './../AddingConfiguration/AddingConfiguration.helpers';
import EditableField from './../EditableField';
import Space from './../Space';
import Image from './../Space/components/Image';
import Info from './components/Info';
import Intervals from './components/Intevals';
import Partner from './components/Partner';
import { IManagerProps, IManagerState, IManagerValues } from './Manager.types';

import styles from './../Space/Space.module.scss';

const MOVE_HOURS_TEXT = 'Godzina przesunięcia';

class Manager extends Component<IManagerProps, IManagerState> {
  protected initialValues: IManagerValues;
  protected iteratorName = 0;

  constructor(props: IManagerProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.addSpace = this.addSpace.bind(this);
    this.remove = this.remove.bind(this);
    this.send = this.send.bind(this);
    this.modifySpace = this.modifySpace.bind(this);

    const values = mapHappeningToStateAndInitialValues(props.happening);
    this.state = values.state;
    this.initialValues = values.initialValues;
  }

  public componentDidUpdate = (prevProps: IManagerProps) => {
    if (this.props.happening !== prevProps.happening) {
      const values = mapHappeningToStateAndInitialValues(this.props.happening);
      this.setState(values.state);
      this.initialValues = values.initialValues;
    }
  };

  public componentDidMount = () => {
    this.props.mounted();
  };

  public render() {
    const {
      apartments,
      permissionSpaceRead,
      permissionSpaceView,
      permissionSpaceWrite,
      hideSaveButton,
    } = this.props;

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={ManagerConfigurationSchema}
        onSubmit={(values) => {
          this.send(values);
        }}
        render={(props: FormikProps<IManagerValues>) => {
          return (
            <>
              <Card>
                <div className={styles.managerWrapper}>
                  <div className={styles.formBody}>
                    <div className={styles.titleContent}>
                      <Form.Group label="Obraz">
                        <div className={styles.photo}>
                          <Image
                            url={props.values.imageUrl}
                            setUrl={(url) => {
                              props.setFieldValue('imageUrl', url);
                            }}
                          />
                        </div>
                      </Form.Group>
                      <div className={styles.title}>
                        <Form.Group label="Nazwa gry">
                          <EditableField
                            defaultValue={this.state.name}
                            validator={string().required(
                              REQUIRED_VALIDATOR_TEXT,
                            )}
                            name={'name'}
                            onChange={this.onChange}
                            size="big"
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <Form.Group label="Opis">
                      <Field
                        name="description"
                        render={({ field }: { field: FieldProps }) => (
                          <Form.Textarea {...field} />
                        )}
                      />
                    </Form.Group>

                    <Grid.Row>
                      <Grid.Col>
                        <DatePicker
                          label={'Data rozpoczęcia'}
                          name="startDate"
                          onBlur={props.handleBlur}
                          onChange={props.setFieldValue}
                          placeholder="Kliknij by wybrać"
                          value={props.values.startDate}
                          invalid={
                            props.touched.startDate && props.errors.startDate
                          }
                          feedback={props.errors.startDate}
                        />
                      </Grid.Col>
                      <Grid.Col>
                        <DatePicker
                          clearable={true}
                          label={'Data zakończenia'}
                          name="endDate"
                          onBlur={props.handleBlur}
                          onChange={props.setFieldValue}
                          placeholder="Kliknij by wybrać"
                          value={props.values.endDate}
                          invalid={
                            props.touched.endDate && props.errors.endDate
                          }
                          feedback={props.errors.endDate}
                        />
                      </Grid.Col>

                      <Grid.Col>
                        <Form.Group label="Czas trwania gry (w slotach)">
                          <Form.Select
                            name="activityTimeInSlots"
                            onChange={props.handleChange}
                            value={props.values.activityTimeInSlots}
                          >
                            <option value={-1}>domyślnie</option>
                            {goTrough(20, 1).map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group label="Stawka VAT">
                          <EditableField
                            defaultValue={props.values.vat}
                            validator={numberValidator}
                            name={'vat'}
                            onChange={props.setFieldValue}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col>
                        <Grid.Row>
                          <Grid.Col>
                            <Form.Group
                              label={'Czy wydarzenie jest publiczne?'}
                            >
                              <Form.Checkbox
                                checked={props.values.isPublic}
                                isInline={true}
                                label="Tak"
                                name="isPublic"
                                onBlur={props.handleBlur}
                                onChange={props.handleChange}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col>
                            <Form.Group label="Typ płatności">
                              <div className={styles.radioWrapper}>
                                <Form.Radio
                                  name="paymentType"
                                  label="za osobę"
                                  checked={
                                    props.values.paymentType === 'person'
                                  }
                                  onChange={() => {
                                    props.setFieldValue(
                                      'paymentType',
                                      'person',
                                    );
                                    setTimeout(() => {
                                      props.setFieldValue(
                                        'allowNextSlotWhenOverbooked',
                                        false,
                                      );
                                    }, 0);
                                  }}
                                />
                                <Form.Radio
                                  name="paymentType"
                                  label="za pokój"
                                  checked={props.values.paymentType === 'room'}
                                  onChange={() => {
                                    props.setFieldValue('paymentType', 'room');
                                    setTimeout(() => {
                                      props.setFieldValue(
                                        'allowNextSlotWhenOverbooked',
                                        false,
                                      );
                                    }, 0);
                                  }}
                                />
                              </div>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid.Col>

                      <Grid.Col>
                        {props.values.paymentType === 'person' && (
                          <Grid.Row>
                            <Grid.Col>
                              <Form.Group label="Domyślna długość suwaka">
                                <EditableField
                                  defaultValue={this.state.sliderLength}
                                  validator={numberValidator}
                                  name={'sliderLength'}
                                  onChange={this.onChange}
                                />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col>
                              <Form.Group label="Czy zezwolić na overbooking?">
                                <Form.Checkbox
                                  checked={
                                    props.values.allowNextSlotWhenOverbooked
                                  }
                                  isInline={true}
                                  label="Tak"
                                  name="allowNextSlotWhenOverbooked"
                                  onBlur={props.handleBlur}
                                  onChange={props.handleChange}
                                />

                                <Info>
                                  Zaznaczenie tej opcji spowoduje, że po
                                  wybraniu większej liczby osób niż jest
                                  dostępna w danej grze w pojedynczym
                                  slocie/miejscu, rezerwowane będą kolejne
                                  sloty.
                                </Info>

                                {props.values.allowNextSlotWhenOverbooked && (
                                  <>
                                    <Form.Group label="Max. l. uczestników">
                                      <EditableField
                                        defaultValue={this.state.numberOfPeople}
                                        validator={numberValidator}
                                        name={'numberOfPeople'}
                                        onChange={this.onChange}
                                      />
                                    </Form.Group>
                                    <Form.Group label="Slot czasowy">
                                      <EditableField
                                        defaultValue={this.state.slotTime}
                                        validator={durationValidator}
                                        name={'slotTime'}
                                        onChange={this.onChange}
                                      />
                                    </Form.Group>
                                  </>
                                )}
                              </Form.Group>
                            </Grid.Col>
                          </Grid.Row>
                        )}
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label="Kolor">
                          <ColorPicker
                            color={this.state.color}
                            onColorChange={(colorHex) => {
                              this.onChange('color', colorHex);
                            }}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group label={MOVE_HOURS_TEXT}>
                          <span className="timePickerWrapper">
                            <Form.Checkbox
                              checked={props.values.startShowingSlotsAtActive}
                              isInline={false}
                              label=" "
                              className="customCheckBox"
                              name="startShowingSlotsAtActive"
                              onBlur={props.handleBlur}
                              onChange={() =>
                                props.setFieldValue(
                                  'startShowingSlotsAtActive',
                                  !props.values.startShowingSlotsAtActive,
                                )
                              }
                            />

                            <TimePicker
                              className={styles.formGroup}
                              feedback={'errr'}
                              invalid={false}
                              label={''}
                              name="startShowingSlotsAt"
                              onBlur={() => null}
                              onChange={(fieldName: string, value: string) => {
                                props.setFieldValue(fieldName, value);
                                props.setFieldValue(
                                  'startShowingSlotsAtActive',
                                  true,
                                );
                              }}
                              timeInterval={1}
                              placeholder={MOVE_HOURS_TEXT}
                              value={props.values.startShowingSlotsAt}
                            />
                          </span>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Intervals {...props} />
                    <Partner {...props} />
                  </div>
                </div>
              </Card>

              {!!permissionSpaceView &&
                this.state.spaces.map((space) => (
                  <Space
                    apartments={apartments}
                    data={space}
                    handleModify={this.modifySpace}
                    handleRemove={this.remove}
                    key={space.hash}
                    overbooking={props.values.allowNextSlotWhenOverbooked}
                    perRoom={props.values.paymentType === 'room'}
                  />
                ))}

              {permissionSpaceRead && this.state.spaces.length < 1 && (
                <Alert type="secondary" icon="map-pin">
                  Dodaj miejsce, by móc zapisać grę.
                </Alert>
              )}
              {permissionSpaceWrite && (
                <Button.List>
                  <Button
                    onClick={this.addSpace}
                    type="button"
                    color="primary"
                    outline={true}
                    icon="map-pin"
                  >
                    Dodaj nowe miejsce
                  </Button>

                  {!hideSaveButton && this.renderSaveButton(props.handleSubmit)}
                </Button.List>
              )}
            </>
          );
        }}
      />
    );
  }

  protected returnTime = (time: string): string => {
    const parts = time.split(':');
    if (parts.length) {
      if (isNaN(Number(parts[1]))) {
        return `${parts[0]}:00:00`;
      }
      return `${parts[0]}:${parts[1]}:00`;
    }
    return '';
  };

  protected renderSaveButton(handleSubmit: () => void) {
    let disabled = false;

    if (this.state.spaces.length < 1) {
      disabled = true;
    } else {
      const canSave = this.state.spaces.reduce((_canSave, space) => {
        return space.configurations.length > 0 && _canSave;
      }, true);

      if (!canSave) {
        disabled = true;
      }
    }

    return (
      <Button
        onClick={handleSubmit}
        type="button"
        color="primary"
        icon="save"
        disabled={disabled}
      >
        Zapisz
      </Button>
    );
  }

  protected onChange(key: string, value: any) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  protected addSpace() {
    this.iteratorName++;

    this.setState((state) => ({
      ...state,
      spaces: [
        ...state.spaces,
        {
          apartmentId: 0,
          configurations: [],
          description: '',
          hash: uuid(),
          id: undefined,
          name: `Miejsce bez nazwy ${this.iteratorName}`,
          numberOfPeople: 10,
          productIds: [],
          slotTime: '15m',
          timeBreak: null,
          url: null,
        },
      ],
    }));
  }

  protected modifySpace(hash: string, key: string, value: any) {
    this.setState((state) => {
      const foundedSpace = state.spaces.findIndex(
        (space) => space.hash === hash,
      );

      if (foundedSpace >= 0) {
        const newSpace = {
          ...state.spaces[foundedSpace],
          [key]: value,
        };

        const newSpaces = [...state.spaces];
        newSpaces.splice(foundedSpace, 1, newSpace);

        return {
          ...state,
          spaces: newSpaces,
        };
      }

      return state;
    });
  }

  protected remove(hash: string) {
    this.setState((state) => ({
      spaces: state.spaces.filter((space) => space.hash !== hash),
    }));
  }

  getSortedConfiguration = (configurations: IConfiguration[]) => {
    const configurationIds: Array<number> = [];
    const ruleTimeIds: Array<number> = [];
    const ruleCapacityIds: Array<number> = [];
    const rulePriceIds: Array<number> = [];

    configurations.forEach((configuration) => {
      if (configuration.id) {
        const type: TRuleType | undefined = getRuleType(configuration);

        if (!type) {
          configurationIds.push(Number(configuration.id));
        } else if (type === 'capacity') {
          ruleCapacityIds.push(Number(configuration.id));
        } else if (type === 'price') {
          rulePriceIds.push(Number(configuration.id));
        } else if (type === 'time') {
          ruleTimeIds.push(Number(configuration.id));
        }
      }
    });

    return {
      configurationIds,
      ruleTimeIds,
      ruleCapacityIds,
      rulePriceIds,
    };
  };

  protected send(values: IManagerValues) {
    const extra: {
      maxNumberOfPeople?: number;
      activityTimeInSlots?: number;
      imageUrl?: string;
    } = {};

    const overbook = !!values.allowNextSlotWhenOverbooked;
    const paymentPerPerson = values.paymentType === 'person';

    if (paymentPerPerson) {
      extra.maxNumberOfPeople = parseInt(
        this.state.sliderLength.toString(),
        10,
      );
    }

    if (values.activityTimeInSlots > 0) {
      extra.activityTimeInSlots = parseInt(
        values.activityTimeInSlots.toString(),
        10,
      );
    }

    if (values.imageUrl) {
      extra.imageUrl = values.imageUrl;
    }

    const startShowingSlotsAt = this.returnTime(values.startShowingSlotsAt);

    const happening = {
      ...extra,
      allowNextSlotWhenOverbooked: values.allowNextSlotWhenOverbooked,
      calculatePricePerPerson: values.paymentType === 'person',
      canBeBookedMax: values.canBeBookedMax
        ? Number(values.canBeBookedMax)
        : null,
      canBeBookedMin: values.canBeBookedMin
        ? Number(values.canBeBookedMin)
        : null,
      color: this.state.color,
      endDate: values.endDate ? format(values.endDate, 'dd-MM-yyyy') : null,
      isPublic: values.isPublic,
      metadata: [
        {
          description: values.description ? values.description : null,
          language: 'PL',
          title: this.state.name,
        },
      ],
      partnerId:
        values.partnerId &&
        Number(values.partnerId) !== -1 &&
        Number(values.partnerId) !== 0
          ? Number(values.partnerId)
          : null,
      startDate: values.startDate
        ? format(values.startDate, 'dd-MM-yyyy')
        : null,
      startShowingSlotsAt,

      spaces: this.state.spaces.map((space) => {
        return {
          apartmentId: parseInt(space.apartmentId.toString(), 10) || null,

          hash: space.hash,
          id: space.id ? parseInt(space.id, 10) : null,
          maxNumberOfPeople: overbook
            ? parseInt(this.state.numberOfPeople.toString(), 10)
            : parseInt(space.numberOfPeople.toString(), 10),
          metadata: [
            {
              description: space.description ? space.description : null,
              language: 'PL',
              title: space.name,
            },
          ],

          ...this.getSortedConfiguration(space.configurations),

          timeSlot: makeTimeFromDuration(
            overbook ? this.state.slotTime : space.slotTime,
          ),

          timeBreak: space.timeBreak
            ? makeTimeFromDuration(space.timeBreak)
            : null,

          url: space.url || null,

          productIds:
            space.productIds && space.productIds.length
              ? space.productIds.map((product) => Number(product))
              : [],
        };
      }),
      vat: parseInt(values.vat.toString(), 10),
    };

    this.props.handleSave(happening);
  }
}

export default Manager;
