import { IHappeningBody } from '@Model/happenings/types';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_AVAILABILITIES_FAILURE,
  _GET_AVAILABILITIES_REQUEST,
  _GET_AVAILABILITIES_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  _SAVE_FAILURE,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  ADD,
  EDIT,
  MOUNTED,
  UNMOUNT,
} from './../constants/actions';
import {
  IAvailabilitiesReducer,
  IAvailabilitiesRequestPayload,
  IGetHappeningSuccessPayload,
  ISaveHappeningRequestPayload,
} from './../types';

export const getHappening = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<string, IGetHappeningSuccessPayload, Error>();

export const resetState = createStandardAction(_RESET_STATE)();

export const saveHappening = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE,
)<ISaveHappeningRequestPayload, void, Error>();

export const add = createStandardAction(ADD)<IHappeningBody>();

export const edit = createStandardAction(EDIT)<IHappeningBody>();

export const mounted = createStandardAction(MOUNTED)();

export const unmount = createStandardAction(UNMOUNT)();

export const getAvailabilities = createAsyncAction(
  _GET_AVAILABILITIES_REQUEST,
  _GET_AVAILABILITIES_SUCCESS,
  _GET_AVAILABILITIES_FAILURE,
)<IAvailabilitiesRequestPayload, IAvailabilitiesReducer, Error>();
