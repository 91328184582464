import { IAction, IAppTokenReducer } from '@Model/app/types';
import { getType } from 'typesafe-actions';
import { setToken } from '../../actions';

const initialState: IAppTokenReducer = null;

const appSettingsReducer = (
  state: IAppTokenReducer = initialState,
  action: IAction,
): IAppTokenReducer => {
  switch (action.type) {
    case getType(setToken):
      return action.payload;

    default:
      return state;
  }
};

export default appSettingsReducer;
