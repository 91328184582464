import React from 'react';

import DatePicker from '@Compo/reusable/DatePicker';
import { Field } from 'formik';
import moment from 'moment';
import { Button, Card, Form, Grid } from 'tabler-react';
import styles from './FormLayout.module.scss';
import { IFormLayoutProps } from './FormLayout.types';

const CANCEL_TEXT = 'Anuluj';
const CLICK_TO_CHOSE_TEXT = 'Kliknij by wybrać';
const CURRENCY_PERCENT_TEXT = 'procentowo';
const CURRENCY_TEXT = 'PLN';
const DISCOUNT_CODE_TEXT = 'Kod obniżki';
const EDIT_TEXT = 'Zapisz edycję';
const END_DATE_TEXT = 'Do';
const ENTER_AMOUNT_TEXT = 'Wartość zniżki w ';
const EXPIRATION_DATE_TEXT = 'Data ważności:';
const HAPPENING_TEXT = 'Wydarzenie';
const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const SAVE_TEXT = 'Zapisz obniżkę';
const SELECT_HAPPENING_TEXT = 'Wybierz wydarzenie';
const SELECT_PARTNER_TEXT = 'Wybierz partnera';
const START_DATE_TEXT = 'Od';
const TRANSACTION_LIMIT_TEXT = 'Limit transakcji';
const PARTNER_TEXT = 'Partner';

const FormLayout = ({
  handleSubmit,
  values,
  touched,
  errors,
  activeDiscount,
  handleChange,
  happenings,
  setFieldValue,
  handleBlur,
  closeModal,
  partners,
}: IFormLayoutProps) => {
  const isEditing = activeDiscount && activeDiscount.code;
  const DISCOUNT_SAVE_TEXT = isEditing ? EDIT_TEXT : SAVE_TEXT;

  const getTransactionLimit = () => {
    if (isEditing) {
      return values.transactionsLimit || '';
    } else if (typeof values.transactionsLimit !== 'undefined') {
      return values.transactionsLimit;
    }
    return 1;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={DISCOUNT_CODE_TEXT}>
                  <Field
                    className={styles.formInput}
                    invalid={touched.code && errors.code}
                    value={values.code || ''}
                    name="code"
                    onChange={handleChange}
                  />
                  {errors.code && touched.code && (
                    <Form.InputGroupText className={styles.errorMessage}>
                      {errors.code}
                    </Form.InputGroupText>
                  )}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <div className={styles.customSelect}>
                  <Field
                    component="select"
                    label={HAPPENING_TEXT}
                    className={styles.formSelect}
                    name="happeningId"
                    value={
                      values.happeningId ||
                      (values.happening && values.happening.id)
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    invalid={values.happeningId}
                    feedback={errors.startDate}
                  >
                    <option defaultValue={'true'} value={-1}>
                      {SELECT_HAPPENING_TEXT}
                    </option>
                    {happenings.map((happening) => (
                      <option value={happening.id} key={happening.id}>
                        {happening.metadata.title}
                      </option>
                    ))}
                  </Field>
                  {errors.happeningId && touched.happeningId && (
                    <Form.InputGroupText className={styles.errorMessage}>
                      {errors.happeningId}
                    </Form.InputGroupText>
                  )}
                </div>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <div className={styles.customSelect}>
                  <Field
                    component="select"
                    label={PARTNER_TEXT}
                    className={styles.formSelect}
                    name="partnerId"
                    value={
                      values.partnerId || (values.partner && values.partner.id)
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    invalid={values.happeningId}
                    feedback={errors.startDate}
                  >
                    <option defaultValue={'true'} value={-1}>
                      {SELECT_PARTNER_TEXT}
                    </option>
                    {partners &&
                      partners.map((partner) => (
                        <option value={partner.id} key={partner.id}>
                          {partner.name}
                        </option>
                      ))}
                  </Field>
                  {errors.partnerId && touched.partnerId && (
                    <Form.InputGroupText className={styles.errorMessage}>
                      {errors.partnerId}
                    </Form.InputGroupText>
                  )}
                </div>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group
                  label={`${ENTER_AMOUNT_TEXT}${
                    values.isPercentage ? '%' : CURRENCY_TEXT
                  }`}
                >
                  <Grid.Row>
                    <Grid.Col width={8}>
                      <Field
                        className={styles.formInput}
                        value={values.value || values.percentageValue || ''}
                        name="value"
                        onChange={handleChange}
                        type="number"
                      />
                      {touched.value && !values.value && (
                        <Form.InputGroupText className={styles.errorMessage}>
                          {REQUIRED_VALIDATOR_TEXT}
                        </Form.InputGroupText>
                      )}
                    </Grid.Col>
                    <Grid.Col width={4}>
                      <Form.Checkbox
                        className={styles.customCheckBox}
                        checked={values.isPercentage}
                        isInline={true}
                        label={CURRENCY_PERCENT_TEXT}
                        name="isPercentage"
                        onChange={handleChange}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={EXPIRATION_DATE_TEXT}>
                  <Grid.Col>
                    <DatePicker
                      label={START_DATE_TEXT}
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                      placeholder={CLICK_TO_CHOSE_TEXT}
                      value={
                        values.startDate
                          ? moment(values.startDate).toDate()
                          : null
                      }
                      invalid={touched.startDate && errors.startDate}
                      feedback={errors.startDate}
                    />
                  </Grid.Col>
                  <Grid.Col>
                    <DatePicker
                      label={END_DATE_TEXT}
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                      placeholder={CLICK_TO_CHOSE_TEXT}
                      value={
                        values.endDate ? moment(values.endDate).toDate() : null
                      }
                      invalid={touched.endDate && errors.endDate}
                      feedback={errors.endDate}
                    />
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={TRANSACTION_LIMIT_TEXT}>
                  <Form.Input
                    value={getTransactionLimit()}
                    name="transactionsLimit"
                    type="number"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </div>
        </div>
      </Card>

      <Button.List>
        <Button type="submit" color="primary" icon="save">
          {DISCOUNT_SAVE_TEXT}
        </Button>
        <Button onClick={closeModal} type="button" color="secondary" icon="x">
          {CANCEL_TEXT}
        </Button>
      </Button.List>
    </form>
  );
};

export default FormLayout;
