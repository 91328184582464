import { getType } from 'typesafe-actions';
import { addDiscount, editDiscount, resetState } from '../actions';
import { IAction, IDiscountReducer } from '../types';

const initialState: IDiscountReducer = {
  activeDiscount: null,
  showModal: false,
};

const discountReducer = (
  state: IDiscountReducer = initialState,
  action: IAction,
): IDiscountReducer => {
  switch (action.type) {
    case getType(editDiscount):
      return {
        ...state,
        activeDiscount: action.payload,
        showModal: true,
      };

    case getType(addDiscount):
      return {
        ...state,
        activeDiscount: null,
        showModal: true,
      };

    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default discountReducer;
