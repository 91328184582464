import React, { FC } from 'react';

import cancelClick from '@Misc/helpers/cancelClick';
import { IModalProps } from './Modal';
import styles from './Modal.module.scss';

const Modal: FC<IModalProps> = ({ isMobile, children }) => {
  return (
    <>
      <div className={styles.background} onClick={cancelClick} />
      <div onClick={cancelClick} className={styles.modal}>
        {children}
      </div>
    </>
  );
};

export default Modal;
