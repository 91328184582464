import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  HANDLE_DISCOUNT_ACTIVE_PAGE,
  MOUNTED,
  SET_ACTIVE_FILTER,
  UPDATE_SEARCH_TEXT,
} from '../constants/actions';
import { IGetDiscountsSuccessPayload } from '../types';

export const getDiscounts = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IGetDiscountsSuccessPayload, Error>();

export const handleActivePage = createStandardAction(
  HANDLE_DISCOUNT_ACTIVE_PAGE,
)<number>();

export const resetState = createStandardAction(_RESET_STATE)();

export const mounted = createStandardAction(MOUNTED)();

export const updateSearchText = createStandardAction(UPDATE_SEARCH_TEXT)<
  string
>();

export const setActiveFilter = createStandardAction(SET_ACTIVE_FILTER)<
  string
>();
