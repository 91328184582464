/* tslint:disable:jsx-no-lambda */
import arrayMove from 'array-move';
import cn from 'classnames';
import React, { Component } from 'react';
import { Alert, Button, Card, Form, Grid, Table } from 'tabler-react';
import { string } from 'yup';

import config from '@/config';
import {
  durationValidator,
  numberValidator,
} from '@Compo/BetterManager/Manager/Manager.validation';
import { getRuleType } from '@Misc/helpers/getRuleType';
import { IConfiguration, TRuleType } from '@Model/configurations/types';
import { makeTimeFromDuration } from '../AddingConfiguration/AddingConfiguration.helpers';
import ConfigurationModal from '../AddingConfiguration/components/ConfigurationModal';
import ConfigurationList from '../ConfigurationList/ConfigurationList.component';
import NewConfigurationModal from '../NewAddingConfiguration/components/ConfigurationModal';
import NewConfigurationList from '../NewConfigurationList/ConfigurationList.component';
import EditableField from './../EditableField';
import Image from './components/Image';
import Products from './components/Products';
import { SortableBody, SortableItem } from './components/Sortable';
import { ISpaceProps, ISpaceState } from './Space.types';

import styles from './Space.module.scss';

const NAME_TEXT = 'Nazwa';
const CLOSED_TEXT = 'Zamknięte';
const DAYS_TEXT = 'Dni';
const TYPE_TEXT = 'Type';
const TIME_TEXT = 'Czas';
const PRICE_TEXT = 'Cena';
const UPSELL_TEXT = 'Upsell';
const GROUP_TEXT = 'B. grupowy';
const MAX_PEOPLE_TEXT = 'Max l. osób';
const TIME_BREAK_ERROR_MESSAGE_TEXT =
  'Czas przerwy nie może być wiekszy od czasu gry';
const TIME_BREAK_TEXT = 'Czas przerwy';

class Space extends Component<ISpaceProps, ISpaceState> {
  constructor(props: ISpaceProps) {
    super(props);

    this.state = {
      addingRule: false,
      configuredHash: null,
      timeBreakError: '',
    };

    this.openAddingConfiguration = this.openAddingConfiguration.bind(this);
    this.closeAddingConfiguration = this.closeAddingConfiguration.bind(this);
    this.onChange = this.onChange.bind(this);
    this.add = this.add.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.removeConfiguration = this.removeConfiguration.bind(this);
  }

  public render() {
    const {
      data: { apartmentId, configurations, timeBreak },
      permissionApartmentsView,
      permissionConfigurationView,
      permissionConfigurationWrite,
      permissionSpaceWrite,
    } = this.props;
    const showBody = configurations.length > 0;

    const {
      props: {
        data: { productIds },
        handleActiveConfiguration,
      },
    } = this;

    const { timeBreakError } = this.state;
    return (
      <>
        {config.cms.showUnfinishedFeatures ? (
          <NewConfigurationModal />
        ) : (
          <ConfigurationModal />
        )}

        <Card>
          <div className={styles.wrapper}>
            <div className={cn(styles.formBody, styles.withPhoto)}>
              <div className={styles.photo}>
                <Image
                  url={this.props.data.url}
                  setUrl={(url) => {
                    this.onChange('url', url);
                  }}
                />
              </div>
              <div className={styles.data}>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Nazwa miejsca">
                      <EditableField
                        defaultValue={this.props.data.name}
                        validator={string().required()}
                        name={'name'}
                        onChange={this.onChange}
                        size="medium"
                      />
                    </Form.Group>

                    {permissionApartmentsView && (
                      <Form.Group label="Blokada wejścia do miejsca">
                        <Form.Select
                          value={apartmentId.toString()}
                          onChange={(event: {
                            currentTarget: { value: number };
                          }) => {
                            this.onChange(
                              'apartmentId',
                              event.currentTarget.value,
                            );
                          }}
                        >
                          {this.props.apartments.map((apartment) => (
                            <option
                              key={apartment.id}
                              value={apartment.id.toString()}
                            >
                              {apartment.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}

                    {!this.props.overbooking && (
                      <Grid.Row>
                        <Grid.Col>
                          <Form.Group
                            label={
                              this.props.perRoom
                                ? 'Informacja o max. l. uczestników'
                                : 'Max. l. uczestników'
                            }
                          >
                            <EditableField
                              defaultValue={this.props.data.numberOfPeople}
                              validator={numberValidator}
                              name={'numberOfPeople'}
                              onChange={this.onChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col>
                          <Form.Group label="Slot czasowy">
                            <EditableField
                              defaultValue={this.props.data.slotTime}
                              validator={durationValidator}
                              name={'slotTime'}
                              onChange={this.onChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    )}
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label={TIME_BREAK_TEXT}>
                          <EditableField
                            defaultValue={timeBreak || ''}
                            validator={durationValidator}
                            name={'timeBreak'}
                            onChange={this.handleBreakChange}
                            canBeEmpty={true}
                          />
                          {timeBreakError && (
                            <div className={styles.errorMessage}>
                              {timeBreakError}
                            </div>
                          )}
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Opis">
                      <EditableField
                        defaultValue={this.props.data.description}
                        validator={string()}
                        name={'description'}
                        onChange={this.onChange}
                        textarea={true}
                        textareaProps={{
                          rows: 5,
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </div>
            </div>

            <Card.Options className={styles.options}>
              <Button.List>
                <Button
                  color="primary"
                  size="sm"
                  icon="plus"
                  onClick={this.openAddingConfiguration}
                />
                {permissionSpaceWrite && (
                  <Button
                    color="secondary"
                    size="sm"
                    icon="trash"
                    onClick={() => {
                      const isConfirmed = confirm('Czy usunąć?');

                      if (isConfirmed) {
                        this.props.handleRemove(this.props.data.hash);
                      }
                    }}
                  />
                )}
              </Button.List>
            </Card.Options>
          </div>

          <Table
            responsive={true}
            highlightRowOnHover={true}
            hasOutline={true}
            verticalAlign="center"
            cards={true}
            className="text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>{NAME_TEXT}</Table.ColHeader>
                <Table.ColHeader>{TYPE_TEXT}</Table.ColHeader>
                <Table.ColHeader>{CLOSED_TEXT}</Table.ColHeader>
                <Table.ColHeader>{DAYS_TEXT}</Table.ColHeader>
                <Table.ColHeader>{TIME_TEXT}</Table.ColHeader>
                <Table.ColHeader>{PRICE_TEXT}</Table.ColHeader>
                <Table.ColHeader>{UPSELL_TEXT}</Table.ColHeader>
                <Table.ColHeader>{GROUP_TEXT}</Table.ColHeader>
                <Table.ColHeader>{MAX_PEOPLE_TEXT}</Table.ColHeader>
                <Table.ColHeader alignContent="center">
                  <i className="icon-settings" />
                </Table.ColHeader>
              </Table.Row>
            </Table.Header>

            {showBody && permissionConfigurationView && (
              <SortableBody
                onSortEnd={this.onSortEnd}
                lockAxis="y"
                helperClass={'moving'}
                distance={10}
              >
                {configurations.map((value, index) => (
                  <SortableItem
                    index={index}
                    value={value}
                    key={value.id}
                    handleRemove={this.removeConfiguration}
                    handleEdit={handleActiveConfiguration}
                    permissionConfigurationWrite={permissionConfigurationWrite}
                  />
                ))}
              </SortableBody>
            )}
          </Table>

          <Products onChange={this.onChange} productIds={productIds} />

          {!showBody && (
            <Alert
              type="secondary"
              icon="calendar"
              className={styles.alertWrapper}
            >
              Dodaj regułę, by móc zapisać grę.
            </Alert>
          )}
        </Card>

        {this.state.addingRule && config.cms.showUnfinishedFeatures && (
          <ConfigurationList
            handleAdd={this.add}
            handleActiveConfiguration={handleActiveConfiguration}
            handleClose={this.closeAddingConfiguration}
          />
        )}

        {this.state.addingRule && !config.cms.showUnfinishedFeatures && (
          <NewConfigurationList
            handleAdd={this.add}
            handleActiveConfiguration={handleActiveConfiguration}
            handleClose={this.closeAddingConfiguration}
          />
        )}
      </>
    );
  }

  protected onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    this.onChange(
      'configurations',
      arrayMove(this.props.data.configurations, oldIndex, newIndex),
    );
  };

  protected openAddingConfiguration() {
    this.setState({
      addingRule: true,
    });
  }

  protected closeAddingConfiguration() {
    this.setState({
      addingRule: false,
      configuredHash: null,
    });
  }

  protected handleBreakChange = (key: string, value: any) => {
    if (value && this.props.data.slotTime) {
      const slotTimeNumber = makeTimeFromDuration(this.props.data.slotTime);
      const breakTimeNumber = makeTimeFromDuration(value);

      if (
        slotTimeNumber &&
        breakTimeNumber &&
        breakTimeNumber > slotTimeNumber
      ) {
        this.setState({
          timeBreakError: TIME_BREAK_ERROR_MESSAGE_TEXT,
        });
        return;
      }
    }

    this.setState(
      {
        timeBreakError: '',
      },
      () => {
        this.onChange(key, value);
      },
    );
  };

  protected onChange(key: string, value: any) {
    this.props.handleModify(this.props.data.hash, key, value);
  }

  protected add(rule: IConfiguration) {
    const { configurations } = this.props.data;

    const foundedIndex = configurations.findIndex(
      (configuration) => configuration.id === rule.id,
    );

    let newConfigurations: IConfiguration[] = [];

    if (foundedIndex > -1) {
      const inComingRuleType: TRuleType | undefined = getRuleType(rule);
      const currentRuleType: TRuleType | undefined = getRuleType(
        configurations[foundedIndex],
      );

      // If are the same replace it
      if (inComingRuleType === currentRuleType) {
        newConfigurations = [...configurations];
        newConfigurations.splice(foundedIndex, 1, rule);
      } else {
        newConfigurations = [...configurations, rule];
      }
    } else {
      newConfigurations = [...configurations, rule];
    }

    this.setState(
      {
        addingRule: false,
        configuredHash: null,
      },
      () => {
        this.onChange('configurations', newConfigurations);
      },
    );
  }

  protected removeConfiguration(id: string) {
    this.onChange(
      'configurations',
      this.props.data.configurations.filter(
        (configuration) => configuration.id !== id,
      ),
    );
  }
}

export default Space;
