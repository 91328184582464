import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import { Formik } from 'formik';
import FormLayout from '../FormLayout';
import { IDetailsModalProps } from './DetailsModal';
import { DetailsModalSchema } from './DetailsModal.validators';

const DetailsModal = ({
  initialValues,
  saveReservation,
}: IDetailsModalProps) => {
  return (
    <Portal>
      <Modal>
        <Formik
          component={FormLayout}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={saveReservation}
          validationSchema={DetailsModalSchema}
        />
      </Modal>
    </Portal>
  );
};

export default DetailsModal;
