import { get } from '@Model/authorization/selectors';
import RRule, { Weekday } from 'rrule';

import { IConfiguration, IFormValuesProps } from '@Model/configurations/types';
import * as helpers from '../../AddingConfiguration.helpers';
import { IDay } from '../Days/Days.types';

export const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

const UPSELL_VALUE = 0;
const UPSELL_PERCENTAGE = 1;

export const daysTranslator = (day: string): string => {
  switch (day) {
    case 'MO':
      return 'PON';
    case 'TU':
      return 'WT';
    case 'WE':
      return 'ŚR';
    case 'TH':
      return 'CZW';
    case 'FR':
      return 'PT';
    case 'SA':
      return 'SOB';
    case 'SU':
      return 'NIEDZ';

    default:
      return '';
  }
};

export const getInitialDays = () => {
  return days[(new Date().getUTCDay() + 6) % 7]; // starts from Monday
};

export const prepareInitialValues = (
  configuration: IConfiguration | null,
): IFormValuesProps => {
  let daysString = getInitialDays();

  const defaultValues: IFormValuesProps = {
    addNewConfiguration: true,
    advanced: false,
    closed: false,
    cyclic: true,
    days: daysString,
    duration: 0,
    durationAdvanced: 0,
    fromTime: '08:00',
    isUpsell: false,
    newConfiguration: '',
    price: 0,
    ruleAdvanced: '',
    ruleType: 'price',
    title: '',
    until: new Date(),
    upsellType: 0,
    upsellValue: 0,

    prices: [],

    isPeopleCount: false,
    maxNumberOfPeople: null,

    fromNumberOfPeople: '',
    perPersonType: 'value',
    perPersonValue: '1',
    priceGoesUp: 'up',
    validFrom: null,
    validTo: null,

    partnerId: null,
  };

  if (configuration) {
    const {
      prices,
      duration,
      id,
      upsell,
      maxNumberOfPeople,
      fromNumberOfPeopleModifier,
      closed,
      validFrom,
      validTo,
      partnerId,
    } = configuration;

    const options = RRule.fromString(configuration.rrule).origOptions;
    let advanced: boolean =
      !!configuration.rrule && helpers.checkIfRuleIsAdvanced(options);

    let forOneTime = {};

    if (options.byweekday) {
      const arrayDays = options.byweekday as Weekday[];

      daysString = arrayDays.map((day) => day.toString()).join(',');
    } else if (options.bymonth && options.bymonthday) {
      let month;
      let day;

      if (Array.isArray(options.bymonth)) {
        month = options.bymonth[0];
      } else {
        month = options.bymonth;
      }

      if (Array.isArray(options.bymonthday)) {
        day = options.bymonthday[0];
      } else {
        day = options.bymonthday;
      }

      const year = new Date().getFullYear() - 1;

      forOneTime = {
        cyclic: false,
        until: new Date(year, month - 1, day, 0, 0, 0),
      };

      advanced = false;
    }

    let plainUpsell = {};

    if (upsell) {
      if (upsell.value !== undefined && upsell.value !== null) {
        plainUpsell = {
          isUpsell: true,
          upsellType: UPSELL_VALUE,
          upsellValue: upsell.value,
        };
      } else if (
        upsell.percentageValue !== undefined &&
        upsell.percentageValue !== null
      ) {
        plainUpsell = {
          isUpsell: true,
          upsellType: UPSELL_PERCENTAGE,
          upsellValue: upsell.percentageValue,
        };
      }
    }

    const getPrice = (): {
      price: number;
    } => {
      if (prices && prices.length) {
        const defaultPriceKey = prices.findIndex(
          (price) => price.type === 'default',
        );

        if (defaultPriceKey !== -1) {
          return {
            price: prices[defaultPriceKey].value,
          };
        }

        return {
          price: prices[0].value,
        };
      }
      return {
        price: 0,
      };
    };

    const getNumberOfPeople = () => {
      return {
        maxNumberOfPeople: maxNumberOfPeople
          ? maxNumberOfPeople.toString()
          : null,
      };
    };

    const getFromNumberOfPeople = () => {
      if (fromNumberOfPeopleModifier) {
        const {
          fromNumberOfPeople,
          value,
          percentageValue,
          priceGoesUp,
        } = fromNumberOfPeopleModifier;

        const perPersonValue = (value || percentageValue || '1').toString();

        return {
          fromNumberOfPeople: (fromNumberOfPeople || '').toString(),
          isPeopleCount: true,
          perPersonType: percentageValue ? 'percentageValue' : 'value',
          perPersonValue,
          priceGoesUp: priceGoesUp ? 'up' : 'down',
        };
      }

      return {
        fromNumberOfPeople: defaultValues.fromNumberOfPeople,
        isPeopleCount: defaultValues.isPeopleCount,
        perPersonType: defaultValues.perPersonType,
        perPersonValue: defaultValues.perPersonValue,
        priceGoesUp: defaultValues.priceGoesUp,
      };
    };

    const title = configuration.title || configuration.name || '';
    const fromTime = `${options.byhour}:${options.byminute}`;

    const getValidDates = () => {
      return {
        validFrom: validFrom ? new Date(validFrom) : null,
        validTo: validTo ? new Date(validTo) : null,
      };
    };

    if (advanced) {
      return {
        ...defaultValues,
        addNewConfiguration: true,
        advanced,
        closed: closed || false,
        days: daysString,
        duration,
        durationAdvanced: duration,
        fromTime,
        id,
        partnerId,
        ruleAdvanced: configuration.rrule,
        title,
        ...getNumberOfPeople(),
        ...getFromNumberOfPeople(),
        ...forOneTime,
        ...plainUpsell,
        ...getPrice(),
        ...getValidDates(),
      };
    }

    return {
      ...defaultValues,
      addNewConfiguration: true,
      advanced: false,
      closed: closed || false,
      days: daysString,
      duration,
      fromTime,
      id,
      partnerId,
      title,
      ...getNumberOfPeople(),
      ...getFromNumberOfPeople(),
      ...forOneTime,
      ...plainUpsell,
      ...getPrice(),
      ...getValidDates(),
    };
  }

  return defaultValues;
};
