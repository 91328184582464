import * as Yup from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
export const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';
export const DURATION_VALIDATOR_TEXT = 'Niepoprawny format';

const reDuration = /^(\d{1,2}h|\d{1,2}m|\d{1,2}h\d{1,2}m)$/i;

export const durationValidator = Yup.string().matches(
  reDuration,
  DURATION_VALIDATOR_TEXT,
);

export const numberValidator = Yup.number()
  .typeError(NOT_NUMBER_VALIDATOR_TEXT)
  .required(REQUIRED_VALIDATOR_TEXT);

const configuration = {
  partnerId: Yup.string()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  startDate: Yup.string()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
};

export const ManagerConfigurationSchema = Yup.object().shape(configuration);
