import React, { FC, useState } from 'react';

import routes from '@/routes/routes';
import SessionPanel from '@Compo/SessionPanel/';
import { NavLink } from 'react-router-dom';
import { Button, Nav, Page, Site } from 'tabler-react';
import config from '@Config';

import 'tabler-react/dist/Tabler.css';
import styles from './Grid.module.scss';
import { IGridState } from './Grid.types';

const ADMINISTRATION_TEXT = 'Administracja';
const CALENDAR_TEXT = 'Kalendarz';
const CODES_TEXT = 'Kody rabatowe';
const CONSENTS_LIST_TEXT = 'Lista zgód';
const DELAYED_TRANSACTIONS = 'Transakcje opóźnione';
const HAPPENINGS_TEXT = 'Zasoby';
const REPORT_TEXT = 'Raporty';
const RULE_TEXT = 'Reguły';
const STATICS_TEXT = 'Statystyki';

const Grid: FC<IGridState> = ({
  children,
  permissionDiscountsView,
  permissionPrintersRead,
  permissionHappeningsView,
  permissionReservationView,
  permissionPrintersView,
  permissionPrintersWrite,
  permissionRulesView,
  permissionStaticsView,
  permissionReportsRead,
  permissionDelayedTransactionsView,
  mobileView,
}) => {
  const [showMenu, handleShowMenu] = useState<boolean>(mobileView);

  return (
    <Page>
      <Page.Main>
        {mobileView && (
          <Site.Nav collapse={false}>
            <Nav className={styles.mobileNavigationWrapper}>
              <SessionPanel />
              <Button
                social="list"
                className={styles.hamburgerButton}
                onClick={() => handleShowMenu(!showMenu)}
              />
            </Nav>
          </Site.Nav>
        )}
        <Site.Nav collapse={showMenu}>
          <span className={styles.navigation}>
            <Nav className={styles.navigationWrapper}>
              {permissionHappeningsView && (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.happenings}
                  useExact={true}
                  icon="list"
                >
                  {HAPPENINGS_TEXT}
                </Nav.Item>
              )}
              {permissionReservationView && (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.calendar}
                  useExact={true}
                  icon="calendar"
                >
                  {CALENDAR_TEXT}
                </Nav.Item>
              )}
              {permissionDiscountsView && (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.discounts}
                  useExact={true}
                  icon="list"
                >
                  {CODES_TEXT}
                </Nav.Item>
              )}
              {permissionPrintersRead ||
              permissionPrintersWrite ||
              permissionPrintersView ? (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.admin}
                  useExact={true}
                  icon="list"
                >
                  {ADMINISTRATION_TEXT}
                </Nav.Item>
              ) : null}
              {permissionReportsRead ? (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.reports}
                  useExact={true}
                  icon="list"
                >
                  {REPORT_TEXT}
                </Nav.Item>
              ) : null}
              {permissionRulesView ? (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={
                    config.cms.showUnfinishedFeatures
                      ? routes.newRules
                      : routes.rules
                  }
                  useExact={true}
                  icon="list"
                >
                  {RULE_TEXT}
                </Nav.Item>
              ) : null}

              {!!permissionStaticsView && (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.statics}
                  useExact={true}
                  icon="bar-chart-2"
                >
                  {STATICS_TEXT}
                </Nav.Item>
              )}

              {!!permissionDelayedTransactionsView && (
                <Nav.Item
                  LinkComponent={NavLink}
                  to={routes.transactionsDelayed}
                  useExact={true}
                  icon="bar-chart-2"
                >
                  {DELAYED_TRANSACTIONS}
                </Nav.Item>
              )}

              <Nav.Item
                LinkComponent={NavLink}
                to={routes.consentsList}
                useExact={true}
                icon="list"
              >
                {CONSENTS_LIST_TEXT}
              </Nav.Item>
            </Nav>
            {!mobileView && <SessionPanel />}
          </span>
        </Site.Nav>
        <Page.Content>{children}</Page.Content>
      </Page.Main>
    </Page>
  );
};

export default Grid;
