import { getType } from 'typesafe-actions';

import { IAction, IRuleTypesReducer } from '../types';

import { getRuleTypes } from '../actions/index';

const initialState: IRuleTypesReducer = {
  items: [],
};

const productsReducer = (
  state: IRuleTypesReducer = initialState,
  action: IAction,
): IRuleTypesReducer => {
  switch (action.type) {
    case getType(getRuleTypes.success):
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
