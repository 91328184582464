import React, { useContext } from 'react';

import moment from 'moment';
import { Button } from 'tabler-react';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';

import styles from './Basket.module.scss';

const RESERVATION_TEXT = 'Rezerwacje w koszyku';

const Basket = () => {
  const state = useContext(clearContext);

  if (!state || (state && !state.basketItems.length)) {
    return null;
  }

  const getTime = (dateTime: string | null): string => {
    if (dateTime) {
      const timezoneDate = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
      return moment(timezoneDate).format('DD.MM.YYYY HH:mm:ss');
    }
    return '';
  };

  const renderItems = () => {
    const handleRemove = (id: number) => {
      if (state) {
        state.removeFromBag(id);
      }
    };

    return (
      <>
        <div className={styles.basketWrapper}>
          {state.basketItems.map(({ id, title, dateTime }) => (
            <div className={styles.product} key={id}>
              <div className={styles.delete}>
                <Button
                  color="secondary"
                  size="sm"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleRemove(id)}
                  icon="x"
                  type="button"
                />
              </div>
              <div className={styles.info}>
                <span className={styles.title}>
                  {title} {getTime(dateTime)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={styles.summaryBox}>
      <div className={styles.mainBasketTitle}>{RESERVATION_TEXT}</div>

      {renderItems()}
    </div>
  );
};

export default Basket;
