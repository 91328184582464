import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getUserInfo } from '@Model/authorization/selectors';
import {
  getEmpikReservationDetails,
  resetEmpikReservationDetails,
} from '@Model/reservations/actions';
import {
  getEmpikReservationDetails as getEmpikReservationDetailsSelector,
  getEmpikReservationEntryCode,
} from '@Model/reservations/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IFormIoLoginResponse } from '@Services/$formio-api/types';
import _Store from '@Store';
import {
  addEntryCode,
  addEntryCodeRequest,
  catchSaveUser,
  editUserData,
  getAvailableUsers,
  getEntryFormsFormIo,
  getEntryTokens,
  getFormIOUsers,
  getReservationUsers,
  getToken,
  getUsers,
  handleActivePageConsentsList,
  mounted,
  resetState,
  saveUserReservation,
  setEmail,
  setEntryIdEdit,
  setFirstName,
  setLastName,
  setUser,
} from '../actions';
import {
  getFormIoAdminUser,
  getFormIoAvailableUsers,
  getFormIoConsentsList,
  getFormIoEntryForm,
  getFormIoSelectedUser,
  getFormIoToken,
  getFormIoUsers,
} from '../selectors';
import { IFormIoSaveUsersResponse, IGetUsersSuccessPayload } from '../types';

const ADD_CODE_SUCCESS_TEXT = 'Dodano kod biletu.';

export const loginFormIoWhenSelectReservation: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf([getEmpikReservationDetails.success, mounted])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);

      if (parameters && parameters.length) {
        const loginObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_login'),
        );
        const passObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_pass'),
        );
        const loginFormUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_login_url'),
        );

        if (loginObject && passObject && loginFormUrlObject) {
          const login = loginObject.form_io_transaction_form_login;
          const pass = passObject.form_io_transaction_form_pass;
          const formUrl = loginFormUrlObject.form_io_transaction_form_login_url;

          return from$(formIoApi.loginFormIo(formUrl, login, pass)).pipe(
            mergeMap$((data: IFormIoLoginResponse) => {
              if (localStorage) {
                localStorage.setItem('formioToken', data.token);
              }

              return [
                getToken.success(data.token),
                setUser(data.formIoUser),
                getAvailableUsers.request(),
              ];
            }),

            catchError$((error: Error) => {
              return of$(getToken.failure(error));
            }),
          );
        }
      }

      return EMPTY$;
    }),
  );
};

export const resetStateWhenSelectReservation: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getEmpikReservationDetails.success)),
    mergeMap$(() => {
      return of$(resetState());
    }),
  );
};

export const resetStateWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(resetEmpikReservationDetails(), resetState());
    }),
  );
};

export const getFormAvailableUsersByEmail: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getFormIOUsers)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return of$(getAvailableUsers.request());
    }),
  );
};

export const getFormAvailableUsersWhenPageChange: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePageConsentsList)),
    mergeMap$(() => {
      return of$(getAvailableUsers.request());
    }),
  );
};

export const addEntryCodeWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(addEntryCode)),
    map$((action) => {
      return addEntryCodeRequest.request(action.payload);
    }),
  );
};

export const saveUserWhenEntryCodeAdded: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(addEntryCodeRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const selectedUser = getFormIoSelectedUser(state);
      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_sms_form_url'),
        );
        if (tokenObject && formUrlObject) {
          const formUrl = formUrlObject.form_io_sms_form_url;

          return from$(
            formIoApi.updateUserEntryId(
              formUrl,
              action.payload,
              selectedUser,
              token,
            ),
          ).pipe(
            mergeMap$(() => {
              return of$(
                addEntryCodeRequest.success(action.payload),
                addToast(ADD_CODE_SUCCESS_TEXT, TYPE_SUCCESS),
              );
            }),
            catchError$((error: Error) => {
              return of$(addEntryCodeRequest.failure(error));
            }),
          );
        }
      }
      return EMPTY$;
    }),
  );
};

export const catchSaveUserWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveUser)),
    map$((action) => {
      return editUserData.request(action.payload);
    }),
  );
};

export const saveUserDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(editUserData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);
      const selectedUser = getFormIoSelectedUser(state);
      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_user_url'),
        );
        if (tokenObject && formUrlObject) {
          const formUrl = formUrlObject.form_io_transaction_form_user_url;

          return from$(
            formIoApi.updateUserData(formUrl, action.payload, selectedUser),
          ).pipe(
            mergeMap$(() => {
              const { perPage } = getFormIoConsentsList(state);

              return of$(
                mounted(perPage),
                addToast(ADD_CODE_SUCCESS_TEXT, TYPE_SUCCESS),
              );
            }),
            catchError$((error: Error) => {
              return of$(addEntryCodeRequest.failure(error));
            }),
          );
        }
      }
      return EMPTY$;
    }),
  );
};

export const getEnterFormUserTokensWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(setEntryIdEdit)),
    map$(() => {
      return getEntryTokens.request();
    }),
  );
};

export const getEnterFormUserTokens: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getEntryTokens.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const { email } = getFormIoSelectedUser(state).data;
      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_sms_form_url'),
        );
        if (tokenObject && formUrlObject) {
          const formUrl = formUrlObject.form_io_sms_form_url;
          return from$(formIoApi.getAllEntryTokens(formUrl, token, email)).pipe(
            map$((data) => {
              return getEntryTokens.success(data);
            }),
            catchError$((error: Error) => {
              return of$(getEntryTokens.failure(error));
            }),
          );
        }
      }
      return EMPTY$;
    }),
  );
};

export const getFormAvailableUsers: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailableUsers.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);

      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_user_url'),
        );

        const formUrlObjectDefault = parameters.find((param) =>
          param.hasOwnProperty('form_io_default_url'),
        );

        if (tokenObject && formUrlObject) {
          const {
            perPage: limit,
            email,
            lastName,
            firstName,
            date,
            activePage: page,
          } = getFormIoConsentsList(state);

          let formUrl = formUrlObject.form_io_transaction_form_user_url;

          if (
            formUrlObjectDefault &&
            tokenObject &&
            (email.length !== 0 ||
              firstName.length !== 0 ||
              lastName.length !== 0 ||
              date.length !== 0)
          ) {
            const availableUsers = getFormIoAvailableUsers(state);

            if (availableUsers[0]) {
              const { form } = availableUsers[0];
              formUrl = formUrlObjectDefault.form_io_default_url;

              return from$(
                formIoApi.getAvailableUsersSearch(
                  formUrl,
                  form,
                  token,
                  email,
                  limit,
                  firstName,
                  lastName,
                  date,
                  page,
                ),
              ).pipe(
                map$((data) => {
                  if (data.totalCount > 0) {
                    return getAvailableUsers.success(data);
                  }
                  return getAvailableUsers.failure({
                    message: 'Brak użytkowników',
                    name: 'Error',
                  });
                }),
                catchError$((error: Error) => {
                  return of$(getAvailableUsers.failure(error));
                }),
              );
            }
          } else {
            return from$(
              formIoApi.getAvailableUsers(formUrl, token, email, limit, page),
            ).pipe(
              map$((data) => {
                return getAvailableUsers.success(data);
              }),
              catchError$((error: Error) => {
                return of$(getAvailableUsers.failure(error));
              }),
            );
          }
        }
      }
      return EMPTY$;
    }),
  );
};

export const getEntryFormWhenGotToken: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const { transactionItems } = getEmpikReservationDetailsSelector(state);

      if (
        transactionItems &&
        transactionItems.length &&
        parameters &&
        parameters.length
      ) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_url'),
        );

        const { entryToken } = transactionItems[0];

        if (tokenObject && formUrlObject && entryToken) {
          const formUrl = formUrlObject.form_io_transaction_form_url;

          return from$(formIoApi.getEntryForm(entryToken, formUrl, token)).pipe(
            mergeMap$((data) => {
              if (
                data &&
                data[0] &&
                data[0].data &&
                data[0].data.text &&
                data[0].data.uczestnicy
              ) {
                return of$(
                  getEntryFormsFormIo.success(data[0]),
                  getReservationUsers(),
                );
              }

              return of$(getReservationUsers());
            }),

            catchError$((error: Error) => {
              return of$(getEntryFormsFormIo.failure(error));
            }),
          );
        }
      }

      return EMPTY$;
    }),
  );
};

export const catchUsersFromFormIoWhenGetUserSuccess: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationUsers)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const { modified } = getFormIoEntryForm(state);
      const { transactionItems } = getEmpikReservationDetailsSelector(state);

      if (
        transactionItems &&
        transactionItems.length &&
        parameters &&
        parameters.length
      ) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token'),
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_sms_form_url'),
        );

        const { entryToken } = transactionItems[0];

        if (entryToken && tokenObject && formUrlObject) {
          const formUrl = formUrlObject.form_io_sms_form_url;

          return from$(formIoApi.getUsers(entryToken, formUrl, token)).pipe(
            map$((data: IGetUsersSuccessPayload) => {
              const filteredData = modified.length
                ? data.users.filter(
                    (user) =>
                      new Date(user.modified).getTime() >=
                      new Date(modified).getTime(),
                  )
                : data.users;
              return getUsers.success({
                totalCount: data.totalCount,
                users: filteredData,
              });
            }),

            catchError$((error: Error) => {
              return of$(getUsers.failure(error));
            }),
          );
        }
      }

      return EMPTY$;
    }),
  );
};

export const saveUsersReservationWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(saveUserReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const text = getEmpikReservationEntryCode(state) || '';
      const { parameters } = getUserInfo(state);

      const getFormUrl = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_transaction_form_url'),
          );
          if (formUrlObject) {
            return formUrlObject.form_io_transaction_form_url;
          }
        }
        return '';
      };

      const users = getFormIoUsers(state);
      const entryForm = getFormIoEntryForm(state);
      const formIoUser = getFormIoAdminUser(state);

      const getSubmissionData = () => {
        if (users && users.length) {
          return {
            availableUsers: getFormIoAvailableUsers(state),
            formIoUser,
            formUrl: getFormUrl(),
            submission: {
              data: {
                text: entryForm.data.text || text,
                uczestnicy: users,
              },
              modified: entryForm.modified,
            },
            token: getFormIoToken(state),
          };
        }

        return {
          availableUsers: getFormIoAvailableUsers(state),
          formIoUser,
          formUrl: getFormUrl(),
          submission: {
            data: {
              text,
              uczestnicy: [],
            },
            modified: '',
          },
          token: getFormIoToken(state),
        };
      };
      return from$(
        formIoApi.saveUsersReservation(
          getSubmissionData().formUrl,
          getSubmissionData().submission,
          getSubmissionData().token,
        ),
      ).pipe(
        map$((data: IFormIoSaveUsersResponse) => {
          return saveUserReservation.success(data);
        }),

        catchError$((error: Error) => {
          return of$(saveUserReservation.failure(error));
        }),
      );
    }),
  );
};
