import cn from 'classnames';
import format from 'date-fns/format';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Card, Form, Grid, Loader } from 'tabler-react';

import DatePicker from '@Compo/reservations/Add/components/DatePicker/';
import { ITimeSlot } from '@Model/reservation/types';
import FormIo from './components/FormIo';
import { IFormLayoutProps } from './FormLayout';

import styles from './FormLayout.module.scss';

const FIRST_NAME_TEXT = 'Imię';
const LAST_NAME_TEXT = 'Nazwisko';
const EMAIL_TEXT = 'Adres e-mail';
const PHONE_TEXT = 'Numer telefonu';
const DATE_TEXT = 'Data rozpoczęcia';
const DATE_END_TEXT = 'Data zakończenia';
const TIME_TEXT = 'Godzina rozpoczęcia';
const END_TIME_TEXT = 'Godzina zakończenia';
const PRICE_TEXT = 'Cena';
const RESERVATION_SAVE_TEXT = 'Zapisz';
const RESENT_TICKET_TEXT = 'Wyślij ponownie bilet';
const GET_TICKET_TEXT = 'Pobierz bilet';
const CANCEL_TEXT = 'Anuluj';
const CANCEL_RESERVATION_TEXT = 'Odwołaj rezerwacje';
const CANCEL_AUTO_RESERVATION_TEXT =
  'Odwołaj rezerwację wraz z automatycznym zwrotem pieniędzy';
export const ARE_YOU_SURE_TEXT = 'Czy na pewno chcesz odwołać rezerwacje?';
const NUMBER_OF_PEOPLE_TEXT = 'Liczba osób';
const NOTES_TEXT_TEXT = 'Opis';
const CHANGE_TEXT = 'zmień';
const DISCOUNT_TEXT = 'Kod zniżkowy';
const PRODUCTS_TEXT = 'Produkty';
const NAME_TEXT = 'Nazwa';
const QUANTITY_TEXT = 'Ilość';
const VALIDATED_TICKET_TEXT = 'Bilet skasowany';
const YES_TEXT = 'Tak';
const NO_TEXT = 'Nie';

const FormLayout = ({
  availabilityMessage,
  availabilities,
  cancel,
  cancelAutoTransaction,
  cancelTransaction,
  checkDate,
  errors,
  handleSubmit,
  handleChange,
  isChecking,
  isDateAvailable,
  isLoading,
  isValidated,
  permissionRefundsWrite,
  permissionReservationWrite,
  priceMessage,
  setFieldValue,
  touched,
  values,
  timeSlots,
  changeReservationDate,
  permissionFormIoAccessListRead,
  resentTicket,
  getTicket,
}: IFormLayoutProps) => {
  const [editDate, setEditDate] = useState(false);
  const [editedEdited, setDateEdited] = useState(false);

  const start = values.startDate ? moment(values.startDate).toDate() : null;
  const end = values.endDate ? moment(values.endDate) : null;
  const canBeSaved =
    isDateAvailable &&
    Object.entries(errors).filter(([_, value]) => value !== undefined)
      .length === 0;

  const handleDateChange = (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => {
    let { startTime, startDate } = values;

    if (field === 'startDate') {
      startDate = format(value as Date, 'yyyy-MM-dd');
      value = format(value as Date, 'yyyy-MM-dd');
    } else if (field === 'startTime') {
      startTime = value;
    }

    const date = new Date(`${startDate}T${startTime}`);

    if (field === 'startDate') {
      changeReservationDate(date);
      setDateEdited(true);
    }

    checkDate(
      startTime &&
        availabilities &&
        availabilities.otherDays &&
        availabilities.otherDays[startTime]
        ? moment(date)
            .add('days', 1)
            .toDate()
        : date,
    );
    setFieldValue(field as keyof typeof values, value, shouldValidate);
  };

  const handleClickDateChange = () => {
    setDateEdited(true);
    setEditDate(true);
  };

  const confirmModal = (
    event: React.FormEvent,
    autoCashBack: boolean = false,
  ) => {
    event.preventDefault();

    const id = values && values.id ? values.id.toString() : null;

    if (window.confirm(ARE_YOU_SURE_TEXT)) {
      if (autoCashBack) {
        cancelAutoTransaction(id);
      } else {
        cancelTransaction(id);
      }
    }
  };

  const times = (): ITimeSlot[] => {
    if (values && values.startTime) {
      return [...timeSlots, values.startTime];
    } else {
      return timeSlots;
    }
  };

  const getStartTime = (): string => {
    if (values && values.startTime) {
      const parts = values.startTime.split(':');
      if (parts.length) {
        if (isNaN(Number(parts[1]))) {
          return `${parts[0]}:00:00`;
        }
        return `${parts[0]}:${parts[1]}:00`;
      }
    }

    return '';
  };

  const catchResentTicket = () => {
    if (values.id) {
      resentTicket(values.id.toString());
    }
  };

  const catchGetTicket = () => {
    if (values.uuid) {
      getTicket(values.uuid.toString());
    }
  };

  const { paymentMethod, discountCode } = values;
  const showAutoReturnButton = paymentMethod && paymentMethod === 3;
  const classString = cn('status-icon', {
    'bg-danger': !isValidated,
    'bg-success': !isValidated,
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Card className={styles.card}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div className={styles.wrapper}>
            <Grid.Row>
              <Grid.Col>
                <div className={styles.item}>
                  <div className={styles.title}>Id: </div>
                  <div className={styles.description}> {values.id}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>Wydarzenie:</div>
                  <div className={styles.description}>
                    {values.happeningTitle}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>Miejsce:</div>
                  <div className={styles.description}>{values.spaceTitle}</div>
                </div>

                {values.lockCode && (
                  <div className={styles.item}>
                    <div className={styles.title}>Lock code</div>
                    <div className={styles.description}>{values.lockCode}</div>
                  </div>
                )}

                <div className={styles.item}>
                  <div className={styles.title}>{FIRST_NAME_TEXT}:</div>
                  <div className={styles.description}>{values.firstName}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{LAST_NAME_TEXT}:</div>
                  <div className={styles.description}>{values.lastName}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{EMAIL_TEXT}:</div>
                  <div className={styles.description}>{values.email}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{PHONE_TEXT}:</div>
                  <div className={styles.description}>{values.phone}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{NUMBER_OF_PEOPLE_TEXT}:</div>
                  <div className={styles.description}>
                    {values.numberOfPeople}
                  </div>
                </div>

                <div className={styles.listItem}>
                  <div className={styles.title}>{PRODUCTS_TEXT}:</div>
                  <div className={styles.productsList}>
                    {values.transactionProducts.map((product) => (
                      <div key={product.productName} className={styles.product}>
                        <div>
                          {NAME_TEXT}: {product.productName}
                        </div>
                        <div>
                          {QUANTITY_TEXT}: {product.quantity}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{NOTES_TEXT_TEXT}:</div>
                  <div className={styles.description}>
                    <Form.Textarea
                      name="description"
                      error={touched.description && errors.description}
                      invalid={touched.description && errors.description}
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className={editDate ? styles.bigItem : styles.item}>
                  <div className={styles.title}>{DATE_TEXT}:</div>

                  {editDate ? (
                    <div className={styles.calendar}>
                      <DatePicker
                        isDateSelectionAvailable={true}
                        // tslint:disable-next-line: jsx-no-lambda
                        setDate={(event) =>
                          handleDateChange('startDate', event)
                        }
                        selectedDate={start}
                      />
                    </div>
                  ) : (
                    <div className={styles.description}>
                      {moment(start || new Date()).format('YYYY-MM-DD')}{' '}
                      {getStartTime()}{' '}
                      {/* tslint:disable-next-line: jsx-no-lambda */}
                      {permissionReservationWrite && (
                        <span
                          onClick={handleClickDateChange}
                          className={styles.change}
                        >
                          {CHANGE_TEXT}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                {editedEdited && (
                  <div className={styles.item}>
                    <div className={styles.title}>{TIME_TEXT}:</div>
                    <div className={styles.description}>
                      <Form.Select
                        name="startTime"
                        value={getStartTime()}
                        // tslint:disable-next-line: jsx-no-lambda
                        onChange={(event: React.FormEvent<HTMLInputElement>) =>
                          handleDateChange(
                            'startTime',
                            event.currentTarget.value,
                          )
                        }
                      >
                        {times().map((timeSlot) =>
                          !timeSlot.isDisabled ? (
                            <option
                              key={timeSlot.startTime}
                              value={timeSlot.startTime}
                            >
                              {timeSlot.startTime}
                            </option>
                          ) : null,
                        )}
                      </Form.Select>
                    </div>
                  </div>
                )}

                {end && !editDate && (
                  <div className={styles.item}>
                    <div className={styles.title}>{DATE_END_TEXT}:</div>
                    <div className={styles.description}>
                      {moment(end).format('DD-MM-YYYY')}
                    </div>
                  </div>
                )}

                {!editedEdited && (
                  <div className={styles.item}>
                    <div className={styles.title}>{END_TIME_TEXT}:</div>
                    <div className={styles.description}>{values.endTime}</div>
                  </div>
                )}

                <div className={styles.item}>
                  <div className={styles.title}>{PRICE_TEXT}:</div>
                  <div className={styles.description}>{values.price}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.title}>{DISCOUNT_TEXT}:</div>
                  <div className={styles.description}>
                    {discountCode || '-'}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{VALIDATED_TICKET_TEXT}:</div>
                  <div className={styles.description}>
                    <span className={classString} />
                    {isValidated ? YES_TEXT : NO_TEXT}
                  </div>
                </div>

                {permissionFormIoAccessListRead && (
                  <div className={styles.item}>
                    <FormIo />
                  </div>
                )}
              </Grid.Col>
            </Grid.Row>
          </div>
        )}
      </Card>

      {isChecking && (
        <Card className={cn('mb-5', styles.checker)}>
          <Loader />
        </Card>
      )}

      {!isChecking && availabilityMessage.length > 0 && (
        <Card.Alert className="mb-5" color="danger">
          {availabilityMessage}
        </Card.Alert>
      )}
      {!isChecking && priceMessage.length > 0 && (
        <Card.Alert className="mb-5" color="danger">
          {priceMessage}
        </Card.Alert>
      )}

      <Button.List>
        <Button
          type="submit"
          color="primary"
          icon="save"
          disabled={!canBeSaved || isLoading}
        >
          {RESERVATION_SAVE_TEXT}
        </Button>

        {permissionRefundsWrite && (
          <Button
            type="submit"
            color="primary"
            icon="trash-2"
            onClick={confirmModal}
          >
            {CANCEL_RESERVATION_TEXT}
          </Button>
        )}

        {permissionRefundsWrite && showAutoReturnButton && (
          <Button
            type="submit"
            color="primary"
            icon="trash-2"
            // tslint:disable-next-line: jsx-no-lambda
            onClick={(event: React.FormEvent) => confirmModal(event, true)}
          >
            {CANCEL_AUTO_RESERVATION_TEXT}
          </Button>
        )}

        <Button
          onClick={catchResentTicket}
          type="button"
          color="primary"
          icon="send"
          disabled={isLoading}
        >
          {RESENT_TICKET_TEXT}
        </Button>
        <Button
          onClick={catchGetTicket}
          type="button"
          color="primary"
          icon="arrow-down"
          disabled={isLoading}
        >
          {GET_TICKET_TEXT}
        </Button>

        <Button
          onClick={cancel}
          type="button"
          color="primary"
          icon="x"
          disabled={isLoading}
        >
          {CANCEL_TEXT}
        </Button>
      </Button.List>
    </form>
  );
};

export default FormLayout;
