import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import { IReservationSaveBody } from '@Services/$reservations-api/types';
import { IAddFormValues, IAddProps } from './Add.types';
import { AddFormSchema } from './Add.validators';
import FormLayout from './components/FormLayout';

const Add = ({ mounted, save, reset, companyData, formData }: IAddProps) => {
  const state = useContext(clearContext);

  useEffect(() => {
    mounted();

    return () => {
      reset();
    };
  }, []);

  const getDefaultValues = (): IAddFormValues => {
    const {
      date,
      email,
      firstName,
      happeningId,
      invoiceCheckbox,
      reservationCheckbox,
      emptyReservation,
      lastName,
      nip,
      numberOfPeople,
      phone,
      slot,
      spaceId,
      upsell,
      withFromData,
      showDiscountForm,
      delayedTransaction,
    } = formData;

    const {
      companyName,
      street,
      houseNumber,
      zipCode,
      city,
      propertyNumber,
    } = companyData;

    return {
      city,
      companyName,
      date,
      delayedTransaction,
      email,
      emptyReservation,
      firstName,
      happeningId,
      houseNumber: propertyNumber || houseNumber || '',
      invoiceCheckbox,
      lastName,
      nip,
      numberOfPeople,
      phone,
      reservationCheckbox,
      showDiscountForm,
      slot,
      spaceId,
      street,
      upsell,
      withFromData,
      zipCode,
    };
  };
  const [defaultFormData, setDefaultFormData] = useState(getDefaultValues());

  useEffect(() => {
    setDefaultFormData(getDefaultValues());
  }, [formData, companyData]);

  const handleFormReset = () => {
    reset();
  };

  const handleFormSubmit = (formValues: IAddFormValues) => {
    const {
      date,
      emptyReservation,
      slot,
      upsell,
      street,
      houseNumber,
      email,
      companyName: name,
      zipCode: post,
      city,
      nip,
      invoiceCheckbox,
      reservationCheckbox,
      withFromData,
      showDiscountForm,
      delayedTransaction,
    } = formValues;

    const prepareFactureData = () => {
      if (invoiceCheckbox && nip && name && street && post && city) {
        const address = `${street} ${houseNumber}`;

        return {
          invoice: {
            address,
            city,
            email,
            name,
            nip,
            post,
          },
        };
      } else {
        return {};
      }
    };

    const getUser = () => {
      if (withFromData && !emptyReservation) {
        return {
          user: {
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phone: formValues.phone,
            terms: true,
          },
        };
      } else {
        return {
          user: {
            email: 'sprzedaz@goingapp.pl',
            firstName: '-',
            lastName: '-',
            phone: '-',
            terms: true,
          },
        };
      }
    };

    const body: IReservationSaveBody = {
      basketItems: state && state.basketItems ? state.basketItems : [],
      date,
      emptyReservation,
      idempotencyKey: state && state.idempotencyKey ? state.idempotencyKey : '',
      startTime: slot && slot.startTime ? slot.startTime : null,

      delayedTransaction,
      description: formValues.description ? formValues.description : undefined,
      discount: formValues.discount,
      happeningId: Number(formValues.happeningId),
      numberOfPeople: formValues.numberOfPeople,
      onDone: state ? state.clearBasket : () => null,
      paymentOperator: 2,
      prepaidCard: formValues.prepaidCard,
      reservationCheckbox,
      showDiscountForm,
      spaceId: Number(formValues.spaceId),
      upsell,
      ...getUser(),
      ...prepareFactureData(),
    };

    save(body);
  };

  return (
    <Formik
      component={FormLayout}
      enableReinitialize={true}
      initialValues={defaultFormData}
      onSubmit={handleFormSubmit}
      onReset={handleFormReset}
      validationSchema={AddFormSchema}
    />
  );
};

export default Add;
