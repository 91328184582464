import React, { FC, useState } from 'react';

import moment from 'moment-timezone';
import { Button, Form, Grid } from 'tabler-react';

import DatePicker from '@Compo/reusable/DatePicker';
import { IReportRequest } from '@Model/reports/types';
import { IFilter } from '../../Reports.types';
import ReportType from '../ReportType';
import { IPaginationProps } from './Filters.types';

import styles from './Filters.module.scss';

const TITLE_TEXT = 'Raporty';
const ADD_TEXT = 'Dodaj nowy filter';
const SAVE_TEXT = 'Zastosuj';
const FILTER_TYPE_TEXT = 'Typ filtru';
const VALUE_TEXT = 'Wartość';
const HIDE_FILTERS_TEXT = 'Ukryj filtry';
const SHOW_FILTERS_TEXT = 'Pokaz filtry';
const MANAGE_COLUMNS_TEXT = 'Zarządzaj kolumnami';

const Filters: FC<IPaginationProps> = ({
  selectedReport,
  catchSelectedKeys,
  selectedKeys,
  selectedFilters,
  setFilters,
  keys,
  getReport,
  addNewActiveInputToGroup,
}) => {
  const [showFilters, handleShowFilters] = useState<boolean>(false);
  const [manageColumns, handleManageColumns] = useState<boolean>(false);
  const [activeInputs, handleActiveInputs] = useState<IReportRequest>({});

  const catchManageFilters = () => {
    handleShowFilters(!showFilters);
    handleManageColumns(false);
  };

  const catchManageColumns = () => {
    handleManageColumns(!manageColumns);
    handleShowFilters(false);
  };

  const addNewFilter = () => {
    setFilters([
      ...selectedFilters,
      { id: Date.now(), name: '', type: '', slug: '' },
    ]);
  };

  const addNewActiveInput = (
    e: React.FormEvent<HTMLInputElement>,
    slug: string,
  ) => {
    handleActiveInputs({
      ...activeInputs,
      [slug]: e.currentTarget.value,
    });
  };

  const saveActiveInput = (slug: string) => {
    const value = String(activeInputs[slug] || '');
    const newObject = { value, slug };
    addNewActiveInputToGroup(newObject);

    setFilters(
      selectedFilters.map((filter) => {
        if (filter.slug === slug) {
          if (filter.values && filter.values.length) {
            return {
              ...filter,
              values: [...filter.values, { value, name: value }],
            };
          }
          return {
            ...filter,
            values: [{ value, name: value }],
          };
        }
        return filter;
      }),
    );

    handleActiveInputs({
      ...activeInputs,
      [slug]: '',
    });
  };

  const filters = selectedReport
    ? selectedReport.filters.map((filterX) => {
        if (selectedFilters.find((a) => a.slug === filterX.slug)) {
          return {
            ...filterX,
            selected: true,
          };
        }
        return filterX;
      })
    : [];

  const evadableFilters = filters.filter(
    (evadableFilter) => !evadableFilter.selected,
  );

  const saveReport = () => {
    handleShowFilters(false);
    getReport();
  };

  const setFilterValue = (fieldName: string, value: any) => {
    setFilters(
      selectedFilters.map((filter) => {
        if (filter.slug === fieldName) {
          return {
            ...filter,
            value: [value],
          };
        }
        return filter;
      }),
    );
  };

  const setFilterDateValue = (fieldName: string, value: Date) => {
    const newDate = moment(value).format('YYYY-MM-DD');

    setFilters(
      selectedFilters.map((filter) => {
        if (filter.slug === fieldName) {
          return {
            ...filter,
            value: [newDate],
          };
        }
        return filter;
      }),
    );
  };

  const removeFilter = (id?: number) => {
    if (id) {
      setFilters(selectedFilters.filter((filter) => filter.id !== id));
    }
  };

  const changeFilterType = (
    filterValue: React.FormEvent<HTMLSelectElement>,
    id?: number,
  ) => {
    if (id) {
      const slug = filterValue.currentTarget.value;

      if (!selectedReport) {
        return null;
      }

      const selectedFilter = selectedReport.filters.find(
        (filter) => filter.slug === slug,
      );

      if (!selectedFilter) {
        return null;
      }

      setFilters(
        selectedFilters.map((filter) => {
          if (filter.id === id) {
            return {
              ...filter,
              ...selectedFilter,
              slug,
              value: null,
            };
          }
          return filter;
        }),
      );
    }
  };

  const setGroupValue = (fieldName: string, value: string) => {
    setFilters(
      selectedFilters.map((filter) => {
        if (filter.slug === fieldName) {
          if (filter.value && filter.value.length) {
            if (filter.value.includes(value)) {
              return {
                ...filter,
                value: filter.value.filter((v: string) => v !== value),
              };
            }
            return {
              ...filter,
              value: [...filter.value, value],
            };
          }
          return {
            ...filter,
            value: [value],
          };
        }
        return filter;
      }),
    );
  };

  const renderFilter = (filter: IFilter, key: number) => {
    const type = filter.type.toLowerCase();

    if (!selectedReport) {
      return null;
    }

    return (
      <Form.Group key={key}>
        <Grid.Row>
          <Grid.Col width={1} className={styles.center}>
            <Button
              icon="x"
              color="primary"
              outline={true}
              onClick={() => removeFilter(filter.id)}
            />
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={FILTER_TYPE_TEXT}>
              <Form.Select
                onChange={(event: React.FormEvent<HTMLSelectElement>) =>
                  changeFilterType(event, filter.id)
                }
                value={filter.slug || ''}
              >
                <option value={-1}>-</option>
                {filters.map((DefaultFilter) => {
                  if (
                    DefaultFilter.selected &&
                    DefaultFilter.slug !== filter.slug
                  ) {
                    return null;
                  } else {
                    return (
                      <option
                        key={DefaultFilter.slug}
                        value={DefaultFilter.slug}
                      >
                        {DefaultFilter.name || ''}
                      </option>
                    );
                  }
                })}
              </Form.Select>
            </Form.Group>
            {type && (
              <Form.Group label={VALUE_TEXT}>
                {type === 'text' && (
                  <Form.Input
                    name={filter.slug}
                    type="text"
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      setFilterValue(filter.slug, event.currentTarget.value)
                    }
                    value={filter.value}
                  />
                )}
                {type === 'number' && (
                  <Form.Input
                    name={filter.slug}
                    type="number"
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      setFilterValue(filter.slug, event.currentTarget.value)
                    }
                    value={filter.value}
                  />
                )}
                {type === 'date' && (
                  <DatePicker
                    label={''}
                    name={filter.slug || ''}
                    onBlur={() => null}
                    onChange={(e, value) => {
                      setFilterDateValue(filter.slug || '', value);
                    }}
                    placeholder={''}
                    value={
                      filter &&
                      filter.value &&
                      filter.value.length &&
                      moment(filter.value[0]).toDate()
                    }
                  />
                )}

                {type === 'group' && (
                  <>
                    {filter.values &&
                      filter.values.map((value) => (
                        <Form.Checkbox
                          key={value.value}
                          label={value.name}
                          name={filter.slug}
                          value={filter.value}
                          checked={
                            filter.value &&
                            filter.value.length &&
                            filter.value.includes(value.value)
                          }
                          onChange={() => {
                            setGroupValue(filter.slug || '', value.value);
                          }}
                        />
                      ))}

                    <Form.Group>
                      <Form.InputGroup>
                        <Form.Input
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            addNewActiveInput(e, filter.slug)
                          }
                          value={activeInputs[filter.slug] || ''}
                        />
                        <Form.InputGroupAppend>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            onClick={() => {
                              saveActiveInput(filter.slug);
                            }}
                          >
                            Dodaj
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                  </>
                )}
              </Form.Group>
            )}
          </Grid.Col>
        </Grid.Row>
      </Form.Group>
    );
  };

  const showAddButton =
    selectedReport && evadableFilters && evadableFilters.length;

  return (
    <>
      <Grid.Row className="mb-5">
        <Grid.Col>
          <h3>{TITLE_TEXT}</h3>
        </Grid.Col>
        <Grid.Col>
          <Button.List align="right">
            <Button
              color="lime"
              onClick={catchManageFilters}
              icon={showFilters ? 'arrow-up' : 'arrow-down'}
            >
              {showFilters ? HIDE_FILTERS_TEXT : SHOW_FILTERS_TEXT}
            </Button>

            <Button
              color="lime"
              onClick={catchManageColumns}
              icon={manageColumns ? 'arrow-up' : 'arrow-down'}
            >
              {MANAGE_COLUMNS_TEXT}
            </Button>
          </Button.List>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mb-5">
        <Grid.Col>
          {manageColumns && (
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={MANAGE_COLUMNS_TEXT}>
                  <Form.SwitchStack>
                    <div className={styles.filters}>
                      {keys &&
                        keys.map((key) => (
                          <Form.Switch
                            key={key}
                            type="radio"
                            name={key}
                            value={key}
                            label={key}
                            onClick={() => catchSelectedKeys(key)}
                            checked={selectedKeys.includes(key)}
                          />
                        ))}
                    </div>
                  </Form.SwitchStack>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          )}
          {showFilters && (
            <>
              <Grid.Row>
                <ReportType />
              </Grid.Row>
              <Grid.Col>
                {selectedFilters.map((filter, key) =>
                  renderFilter(filter, key),
                )}
              </Grid.Col>
              <Grid.Row>
                {showAddButton ? (
                  <Grid.Col>
                    <Button
                      onClick={addNewFilter}
                      color="secondary"
                      className={styles.button}
                    >
                      {ADD_TEXT}
                    </Button>
                  </Grid.Col>
                ) : null}

                <Grid.Col>
                  <Button
                    onClick={saveReport}
                    color="secondary"
                    className={styles.button}
                  >
                    {SAVE_TEXT}
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </>
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Filters;
