import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import PrinterForm from '../PrinterForm';

const PrintersModal = () => {
  return (
    <Portal>
      <Modal>
        <PrinterForm />
      </Modal>
    </Portal>
  );
};
export default PrintersModal;
