import RRule from 'rrule';

import { IRRuleOptions } from './components/ConfigurationModal/ConfigurationModal.types';

export const getName = (rrule: string) => {
  try {
    return RRule.fromString(rrule).toText();
  } catch (e) {
    return null;
  }
};

export const checkIfRuleIsAdvanced = (options: IRRuleOptions) => {
  const keys = Object.keys(options);

  const required = ['wkst', 'bysecond', 'byminute', 'byhour'];

  const extra = ['bymonthday', 'bymonth', 'until'];

  let sizeOfRequired = required.length;

  /**
   * If something from required props doesnt exist - rule is advanced,
   * because we cannot hydrate the form.
   */
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < required.length; i++) {
    if (keys.indexOf(required[i]) < 0) {
      // not found
      return true;
    }
  }

  let extraExistedCounter = 0;

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < extra.length; i++) {
    if (keys.indexOf(extra[i]) >= 0) {
      extraExistedCounter++;
    }
  }

  const existByWeekDay = keys.indexOf('byweekday') >= 0;
  const existFreq = keys.indexOf('freq') >= 0;

  // maybe is simple
  if (extraExistedCounter === extra.length) {
    if (existByWeekDay || existFreq) {
      return true;
    } else {
      sizeOfRequired += extra.length;
    }
  }

  if (extraExistedCounter === 0) {
    if (!(existByWeekDay && existFreq)) {
      return true;
    } else {
      sizeOfRequired = required.length + 2;
    }
  }

  if (keys.length !== sizeOfRequired) {
    return true;
  }

  const onlySimpleValues = ['byhour', 'byminute', 'bysecond'];

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < onlySimpleValues.length; i++) {
    const value = options[onlySimpleValues[i]];

    if (value !== null) {
      if (Array.isArray(value)) {
        return true;
      }
    }
  }

  return false;
};

const makeHrsAndMinsFromDuration = (duration: string | number) => {
  if (typeof duration === 'number') {
    return [duration];
  } else {
    const durationParts = duration.toLowerCase().split('h');

    if (durationParts.length < 1) {
      return null;
    }

    const firstValue = parseInt(durationParts[0], 10);

    if (durationParts.length === 1) {
      return [firstValue, 0];
    }

    return [parseInt(durationParts[1], 10) || 0, firstValue];
  }
};

export const parseDurationToHuman = (duration: string) => {
  const values = makeHrsAndMinsFromDuration(duration);

  if (!values) {
    return '';
  }

  let arr = [];

  if (values.length > 0 && values[0] > 0) {
    arr.push(`${values[0]} mins`);
  }

  if (values.length > 1 && values[1] > 0) {
    arr.push(`${values[1]} hours`);
  }

  arr = arr.reverse();

  return arr.join(' and ');
};

export const makeTimeFromDuration = (duration?: string | number): number => {
  if (!duration) {
    return 0;
  }

  if (typeof duration === 'number') {
    return duration;
  }

  const values = makeHrsAndMinsFromDuration(duration);

  if (!values) {
    return -1;
  }

  let time = 0;
  let multiply = 60;

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < values.length; i++) {
    time += values[i] * multiply;
    multiply *= 60;
  }

  return time;
};

export const makeDurationFromTime = (seconds: number) => {
  const time = seconds / 60;
  const mins = time % 60;
  const hrs = Math.floor(time / 60);

  const arr = [];

  if (hrs > 0) {
    arr.push(`${hrs}h`);
  }

  if (mins > 0) {
    arr.push(`${mins}m`);
  }

  return arr.join('');
};
