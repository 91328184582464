// Private
export const _RESET_STATE = 'Zagrywki/printer/_RESET_STATE';
export const _SAVE_REQUEST = 'Zagrywki/printer/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/printer/_SAVE_SUCCESS';
export const _SAVE_FAILURE = 'Zagrywki/printer/_SAVE_FAILURE';

export const _GET_REQUEST = 'Zagrywki/printer/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/printer/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/printer/_GET_FAILURE';

export const _GET_PINGING_REQUEST = 'Zagrywki/printer/_GET_PINGING_REQUEST';
export const _GET_PINGING_SUCCESS = 'Zagrywki/printer/_GET_PINGING_SUCCESS';
export const _GET_PINGING_FAILURE = 'Zagrywki/printer/_GET_PINGING_FAILURE';

export const _END_SESSION_REQUEST = 'Zagrywki/printer/_END_SESSION_REQUEST';
export const _END_SESSION_SUCCESS = 'Zagrywki/printer/_END_SESSION_SUCCESS';
export const _END_SESSION_FAILURE = 'Zagrywki/printer/_END_SESSION_FAILURE';

export const _END_SESSION_WITH_OUT_CASH_STATE_REQUEST =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_REQUEST';
export const _END_SESSION_WITH_OUT_CASH_STATE_SUCCESS =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_SUCCESS';
export const _END_SESSION_WITH_OUT_CASH_STATE_FAILURE =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_FAILURE';

export const _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_REQUEST =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_BY_ID_REQUEST';
export const _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_SUCCESS =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_BY_ID_SUCCESS';
export const _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_FAILURE =
  'Zagrywki/printer/_END_SESSION_WITH_OUT_CASH_STATE_BY_ID_FAILURE';

export const _PRINT_RECEIPT_REQUEST = 'Zagrywki/printer/_PRINT_RECEIPT_REQUEST';
export const _PRINT_RECEIPT_SUCCESS = 'Zagrywki/printer/_PRINT_RECEIPT_SUCCESS';
export const _PRINT_RECEIPT_FAILURE = 'Zagrywki/printer/_PRINT_RECEIPT_FAILURE';

export const _START_SESSION_REQUEST = 'Zagrywki/printer/_START_SESSION_REQUEST';
export const _START_SESSION_SUCCESS = 'Zagrywki/printer/_START_SESSION_SUCCESS';
export const _START_SESSION_FAILURE = 'Zagrywki/printer/_START_SESSION_FAILURE';

export const _GET_PAYMENT_SESSION_REQUEST =
  'Zagrywki/printer/  _GET_PAYMENT_SESSION_REQUEST';
export const _GET_PAYMENT_SESSION_SUCCESS =
  'Zagrywki/printer/  _GET_PAYMENT_SESSION_SUCCESS';
export const _GET_PAYMENT_SESSION_FAILURE =
  'Zagrywki/printer/  _GET_PAYMENT_SESSION_FAILURE';

export const _END_SESSION_BY_ID_REQUEST =
  'Zagrywki/printer/_END_SESSION_BY_ID_REQUEST';
export const _END_SESSION_BY_ID_SUCCESS =
  'Zagrywki/printer/_END_SESSION_BY_ID_SUCCESS';
export const _END_SESSION_BY_ID_FAILURE =
  'Zagrywki/printer/_END_SESSION_BY_ID_FAILURE';

export const _PRINTER_PAYMENT_REQUEST =
  'Zagrywki/printer/_PRINTER_PAYMENT_REQUEST';
export const _PRINTER_PAYMENT_SUCCESS =
  'Zagrywki/printer/_PRINTER_PAYMENT_SUCCESS';
export const _PRINTER_PAYMENT_FAILURE =
  'Zagrywki/printer/_PRINTER_PAYMENT_FAILURE';

export const _PRINTER_STATUS_REQUEST =
  'Zagrywki/printer/_PRINTER_STATUS_REQUEST';
export const _PRINTER_STATUS_SUCCESS =
  'Zagrywki/printer/_PRINTER_STATUS_SUCCESS';
export const _PRINTER_STATUS_FAILURE =
  'Zagrywki/printer/_PRINTER_STATUS_FAILURE';

// Public
export const EDIT = 'Zagrywki/printer/EDIT';
export const ADD = 'Zagrywki/printer/ADD';
export const SAVE = 'Zagrywki/printer/SAVE';
export const MOUNTED = 'Zagrywki/printer/MOUNTED';
export const REMOVE = 'Zagrywki/printer/REMOVE';
export const CATCH_PRINT_RECEIPT = 'Zagrywki/printer/CATCH_PRINT_RECEIPT';

export const START_SESSION = 'Zagrywki/printer/START_SESSION';
export const PRINT_REPORT = 'Zagrywki/printer/PRINT_REPORT';
export const END_SESSION = 'Zagrywki/printer/END_SESSION';
export const END_SESSION_BY_ID = 'Zagrywki/printer/END_SESSION_BY_ID';
export const CLEAR_PRINTER_CASH = 'Zagrywki/printer/CLEAR_PRINTER_CASH';
export const PULL_DRAWER = 'Zagrywki/printer/PULL_DRAWER';
export const CATCH_GET_SESSION = 'Zagrywki/printer/CATCH_GET_SESSION';
export const START_PINGING_PRINTER = 'Zagrywki/printer/START_PINGING_PRINTER';
export const STOP_PINGING_PRINTER = 'Zagrywki/printer/STOP_PINGING_PRINTER';
