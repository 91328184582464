import { getType } from 'typesafe-actions';
import { getHappenings, resetState, setPartnerID } from './../actions';
import { IAction, IHappeningsReducer } from './../types';

const initialState: IHappeningsReducer = {};

const happeningsReducer = (
  state: IHappeningsReducer = initialState,
  action: IAction,
): IHappeningsReducer => {
  switch (action.type) {
    case getType(getHappenings.success):
      return {
        ...state,
        ...action.payload,
      };
    case getType(setPartnerID):
      return {
        ...state,
        partnerId: action.payload,
      };

    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default happeningsReducer;
