import React from 'react';

import getShortTime from '@Misc/helpers/getShortTime';
import cn from 'classnames';
import { Button, Card } from 'tabler-react';
import { ITimeSlotsProps } from './TimeSlots';
import styles from './TimeSlots.module.scss';

const NO_SLOTS_TEXT = 'Brak dostępnych slotów czasowych...';

const TimeSlots = ({
  isInvalid,
  isSlotSelectionAvailable,
  setSlot,
  timeSlots,
}: ITimeSlotsProps) => {
  return (
    <Card
      className={cn(
        styles.wrapper,
        isInvalid && 'form-control is-invalid',
        !isSlotSelectionAvailable && styles.disabled,
      )}
    >
      {timeSlots.length === 0 && <div>{NO_SLOTS_TEXT}</div>}
      {timeSlots.length > 0 && (
        <ul className={styles.timeSlots}>
          {timeSlots.map((slot) => {
            const catchEventPreventSubmitForm = (event: React.FormEvent) => {
              event.preventDefault();
              setSlot(slot);
            };

            return (
              <li key={slot.startTime}>
                <Button
                  className={styles.button}
                  color={slot.isSelected ? 'primary' : 'secondary'}
                  disabled={slot.isDisabled}
                  onClick={catchEventPreventSubmitForm}
                >
                  {getShortTime(slot.startTime)}
                </Button>
              </li>
            );
          })}
        </ul>
      )}
    </Card>
  );
};

export default TimeSlots;
