import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_ALL_FAILURE,
  _GET_ALL_REQUEST,
  _GET_ALL_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  SELECT_PRODUCT,
  SET_PRODUCT_COUNT,
  UN_SELECT_PRODUCT,
  UPDATE_LIST,
} from '../constants/actions';
import { IGetProductsPayload, IProduct } from '../types';

export const getProducts = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<IGetProductsPayload, IProduct[], Error>();

export const getAllProducts = createAsyncAction(
  _GET_ALL_REQUEST,
  _GET_ALL_SUCCESS,
  _GET_ALL_FAILURE,
)<undefined, IProduct[], Error>();

export const addProduct = createStandardAction(ADD_PRODUCT)<IProduct>();
export const removeProduct = createStandardAction(REMOVE_PRODUCT)<IProduct>();
export const selectProduct = createStandardAction(SELECT_PRODUCT)<IProduct>();
export const unSelectProduct = createStandardAction(UN_SELECT_PRODUCT)<
  IProduct
>();

export const setProductCount = createStandardAction(SET_PRODUCT_COUNT)<{
  product: IProduct;
  count: number;
}>();
export const updateList = createStandardAction(UPDATE_LIST)<IProduct[]>();
