import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Avatar, Button, Card, Grid, List, Loader } from 'tabler-react';

import Filters from './components/Filters';
import ReportModal from './components/ReportModal';
import { IListProps } from './List.types';

import styles from './List.module.scss';

const ADD_TEXT = 'Dodaj';
const REFRESH_SMARTPORTER_LIST_TEXT = 'Odśwież liste smartporer';
const PRINT_DALI_REPORT = 'Drukuj raport dobowy';
const NO_RESULTS_TEXT = 'Brak zasobów...';
const TITLE_TEXT = 'Zasoby';
const CONFIRM_REMOVE_TEXT = 'Czy chcesz usunąć?';

const HIDE_FILTERS_TEXT = 'Ukryj filtry';
const SHOW_FILTERS_TEXT = 'Pokaz filtry';

const Happenings = ({
  happenings,
  isLoading,
  mounted,
  refreshSmartporterList,
  remove,
  permissionHappeningsView,
  permissionApartmentsView,
  permissionWrite,
}: IListProps) => {
  useEffect(() => {
    mounted();
  }, []);
  const [showFilters, handleShowFilters] = useState<boolean>(false);
  const [showReportModal, handleReportModal] = useState<boolean>(false);

  const isLoaded = !isLoading;
  const hasResults = isLoaded && happenings.length > 0;
  const hasNoResults = isLoaded && happenings.length === 0;

  const catchManageFilters = () => {
    handleShowFilters(!showFilters);
  };

  return (
    <section>
      {showReportModal && (
        <ReportModal cancel={() => handleReportModal(false)} />
      )}
      <div className={styles.buttonList}>
        <div>
          <h3>{TITLE_TEXT}</h3>
        </div>

        <div>
          <Button.List align="right">
            {!isLoading && (
              <>
                {permissionWrite && (
                  <Button
                    color="secondary"
                    RootComponent={Link}
                    to={`/dodaj-zasob`}
                    icon="plus"
                  >
                    {ADD_TEXT}
                  </Button>
                )}

                {permissionApartmentsView && (
                  <Button
                    color="secondary"
                    onClick={refreshSmartporterList}
                    icon="refresh-cw"
                  >
                    {REFRESH_SMARTPORTER_LIST_TEXT}
                  </Button>
                )}

                <Button
                  color="secondary"
                  onClick={() => {
                    handleReportModal(!showReportModal);
                  }}
                  icon="printer"
                >
                  {PRINT_DALI_REPORT}
                </Button>

                <Button
                  color="secondary"
                  onClick={catchManageFilters}
                  icon={showFilters ? 'arrow-up' : 'arrow-down'}
                >
                  {showFilters ? HIDE_FILTERS_TEXT : SHOW_FILTERS_TEXT}
                </Button>
              </>
            )}
          </Button.List>
        </div>
      </div>

      {showFilters && <Filters />}

      {isLoading && (
        <Card
          body={
            <div className={styles.loader}>
              <Loader />
            </div>
          }
        />
      )}

      {hasResults && permissionHappeningsView && (
        <Grid.Row cards={true} deck={true}>
          {happenings.map((happening) => (
            <Grid.Col md={4} key={happening.id}>
              <Card className={styles.item}>
                <Card.Header>
                  <div
                    className={styles.color}
                    style={{ background: happening.color || '' }}
                  />
                  <h5 className={styles.title}>{happening.metadata.title}</h5>
                  {permissionWrite && (
                    <Card.Options>
                      <Button.List align="right">
                        <Button
                          color="primary"
                          size="sm"
                          RootComponent={Link}
                          to={`/edytuj-zasob/${happening.metadata.slug}`}
                          icon="edit-3"
                        />
                        <Button
                          color="secondary"
                          size="sm"
                          icon="trash"
                          onClick={() => {
                            if (confirm(CONFIRM_REMOVE_TEXT)) {
                              remove(happening.metadata.slug);
                            }
                          }}
                        />
                      </Button.List>
                    </Card.Options>
                  )}
                </Card.Header>
                <Card.Body className={styles.body}>
                  <List.Group className={styles.spaces}>
                    {happening.spaces.map((space) => (
                      <List.GroupItem RootComponent="div" key={space.id}>
                        <Avatar imageURL={space.url} />
                        {space.metadata.title}
                      </List.GroupItem>
                    ))}
                  </List.Group>
                </Card.Body>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      )}

      {hasNoResults && <Card body={NO_RESULTS_TEXT} />}
    </section>
  );
};

export default Happenings;
