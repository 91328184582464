import React from 'react';

import { Form, Grid } from 'tabler-react';

import { IPartnerProps } from './Partner.types';

import styles from './Partner.module.scss';

const LABEL_TEXT = 'Partner';

const Partner = ({
  values,
  setFieldValue,
  partners,
  errors,
  touched,
}: IPartnerProps) => {
  const catchSetPartner = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setFieldValue('partnerId', value);
  };

  return (
    <Grid.Row>
      <Grid.Col>
        <div className={styles.wrapper}>
          <div className={styles.title}> {LABEL_TEXT}</div>

          <Form.Group>
            <Form.Select
              value={values.partnerId || ''}
              onChange={catchSetPartner}
              name={'partnerId'}
              invalid={touched.partnerId && errors.partnerId}
            >
              <option value={-1}>-</option>

              {partners &&
                partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.name || ''}
                  </option>
                ))}
            </Form.Select>
            {touched.partnerId && errors.partnerId && (
              <div className={styles.errorMessage}>{errors.partnerId}</div>
            )}
          </Form.Group>
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

export default Partner;
