import Color from 'color';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import getRandomFromRange from '@Misc/helpers/getRandomFromRange';
import { get as getHappenings } from '@Model/happenings/selectors';
import { IHappeningsReducer } from '@Model/happenings/types';
import _Store from '@Store';
import {
  IReservationFormatted,
  IReservationsFiltersReducer,
  IReservationsReducer,
  ISpaceRandom,
} from './../types';
import getFilters from './getFilters';
import getReservations from './getReservations';

const getReservationsFormatted = createSelector<
  _Store.IState,
  IReservationsReducer,
  IReservationsFiltersReducer,
  IHappeningsReducer,
  IReservationFormatted[]
>(
  [getReservations, getFilters, getHappenings],
  (reservations, filters, happenings) => {
    if (reservations === null) {
      return [];
    }

    const getName = (happeningId: number, spaceId: number): string => {
      if (happenings.items && happenings.items.length) {
        const { items } = happenings;
        const selectedHappening = items.find(
          (happening) => happening.id === happeningId,
        );
        if (selectedHappening) {
          const selectedSpace = selectedHappening.spaces.find(
            (space) => space.id === spaceId,
          );
          if (selectedSpace && selectedSpace.metadata.length) {
            return selectedSpace.metadata[0].title;
          }
        }
      }
      return '';
    };

    const spaceRandoms: ISpaceRandom[] = [];
    const spaceIds = Array.from(
      new Set(reservations.map((item) => item.spaceId)),
    );

    spaceIds.forEach((spaceId) =>
      spaceRandoms.push({
        random: getRandomFromRange(25, 75) / 100,
        spaceId,
      }),
    );

    const data: IReservationFormatted[] = [];

    reservations.forEach((reservation, key) => {
      const timezoneStartDate = moment
        .utc(reservation.start)
        .format('YYYY-MM-DD HH:mm:ss');

      const timezoneEndDate = moment
        .utc(reservation.end)
        .format('YYYY-MM-DD HH:mm:ss');

      const start = moment(timezoneStartDate)
        .utc()
        .toDate();
      const end = moment(timezoneEndDate)
        .utc()
        .toDate();

      const matchingFilter = filters.find(
        (filter) => filter.id === reservation.happeningId,
      );

      let reservationColor;
      if (matchingFilter && matchingFilter.color) {
        const colorFormatter = Color(matchingFilter.color);

        let random = 0;
        const spaceRandom = spaceRandoms.find(
          (item) => item.spaceId === reservation.spaceId,
        );
        if (spaceRandom) {
          random = spaceRandom.random;
        }

        if (colorFormatter.isDark()) {
          reservationColor = colorFormatter.lighten(random).hex();
        } else {
          reservationColor = colorFormatter.darken(random).hex();
        }
      }

      if (moment(start).isSame(end, 'day')) {
        data.push({
          color: reservationColor,
          end,
          events: [
            {
              end,
              id: reservation.id,
              numberOfPeople: reservation.numberOfPeople,
              start,
              title: `${reservation.firstName} ${reservation.lastName}`,
            },
          ],
          happeningId: reservation.happeningId,
          id: reservation.id,
          spaceId: reservation.spaceId,
          start,
          title: `${reservation.firstName} ${reservation.lastName} (${reservation.numberOfPeople})`,
        });
      } else {
        data.push({
          color: reservationColor,
          end: moment(start)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
            .toDate(),
          events: [
            {
              end: moment(start)
                .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
                .toDate(),
              id: reservation.id,
              numberOfPeople: reservation.numberOfPeople,
              start,
              title: `${reservation.firstName} ${reservation.lastName}`,
            },
          ],
          happeningId: reservation.happeningId,
          id: reservation.id,
          spaceId: reservation.spaceId,
          start,
          title: `${reservation.firstName} ${reservation.lastName} (${reservation.numberOfPeople})`,
        });

        data.push({
          color: reservationColor,
          end,
          events: [
            {
              end,
              id: reservation.id,
              numberOfPeople: reservation.numberOfPeople,
              start: moment(end)
                .set({ hour: 0, minute: 1, second: 0, millisecond: 0 })
                .toDate(),
              title: `${reservation.firstName} ${reservation.lastName}`,
            },
          ],
          happeningId: reservation.happeningId,
          id: reservation.id,
          spaceId: reservation.spaceId,
          start: moment(end)
            .set({ hour: 0, minute: 1, second: 0, millisecond: 0 })
            .toDate(),
          title: `${reservation.firstName} ${reservation.lastName} (${reservation.numberOfPeople})`,
        });
      }
    });

    const reduceData: IReservationFormatted[] = [];
    data.forEach((reservation) => {
      const existing = reduceData.filter((v, i) => {
        return (
          v.spaceId === reservation.spaceId &&
          JSON.stringify(v.start) === JSON.stringify(reservation.start) &&
          JSON.stringify(v.end) === JSON.stringify(reservation.end)
        );
      });
      if (existing.length) {
        const existingIndex = reduceData.indexOf(existing[0]);
        reduceData[existingIndex].events = reduceData[
          existingIndex
        ].events.concat(reservation.events);
      } else {
        if (Array.isArray(reservation.events)) {
          reservation.events = reservation.events;
          reduceData.push(reservation);
        }
      }
    });

    reduceData.map((reservation) => {
      if (reservation.events.length > 1) {
        const peopleNumber = reservation.events.reduce(
          (a, b) => a + (b.numberOfPeople || 0),
          0,
        );
        reservation.title = `${getName(
          reservation.happeningId,
          reservation.spaceId,
        )} (${peopleNumber})`;
      }
      return reservation;
    });

    return reduceData;
  },
);

export default getReservationsFormatted;
