import React, { Component } from 'react';

import Manager from '@Compo/BetterManager/Manager';
import { Card, Grid, Loader } from 'tabler-react';
import { IEditProps } from './Edit.types';

const TITLE_TEXT = 'Edytuj grę';

class Edit extends Component<IEditProps> {
  public componentDidMount() {
    this.props.mounted();
  }

  public componentWillUnmount() {
    this.props.unmount();
  }

  public render() {
    const { isLoading, happening, permissionHappeningsWrite } = this.props;

    return (
      <section>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <h3>{TITLE_TEXT}</h3>
          </Grid.Col>
        </Grid.Row>

        {isLoading && <Card body={<Loader />} />}

        {happening && (
          <Manager
            happening={happening}
            handleSave={this.props.save}
            hideSaveButton={!permissionHappeningsWrite}
          />
        )}
      </section>
    );
  }
}

export default Edit;
