import { getType } from 'typesafe-actions';
import { IAction, IProductsReducer } from '../types';
import {
  getAllProducts,
  getProducts,
  removeProduct,
  updateList,
} from './../actions/index';

const initialState: IProductsReducer = {
  allProducts: [],
  items: [],
};

const productsReducer = (
  state: IProductsReducer = initialState,
  action: IAction,
): IProductsReducer => {
  switch (action.type) {
    case getType(getProducts.success):
      return {
        ...state,
        items: action.payload,
      };
    case getType(updateList):
      return {
        ...state,
        items: action.payload,
      };

    case getType(removeProduct):
      const arrayItems = [...state.items];
      const index = arrayItems.indexOf(action.payload);
      if (index > -1) {
        arrayItems.splice(index, 1);
      }
      return {
        ...state,
        items: arrayItems,
      };
    case getType(getAllProducts.success):
      return {
        ...state,
        allProducts: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
