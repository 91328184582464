import React, { FC } from 'react';

import { Icon } from 'tabler-react';
import styles from './Info.module.scss';

const Info: FC = ({ children }) => {
  return (
    <div className={styles.info}>
      <Icon name="info" />
      <div className={styles.text}>{children}</div>
    </div>
  );
};

export default Info;
