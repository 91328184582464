/* tslint:disable:jsx-no-lambda */
import React, { ChangeEvent, Component } from 'react';

import cn from 'classnames';
import { RRule, Weekday } from 'rrule';
import styles from './Days.module.scss';
import { IDay, IDaysProps, IDaysState } from './Days.types';

const initialState: IDay[] = [
  {
    code: RRule.MO,
    name: 'Poniedziałek',
    selected: false,
  },
  {
    code: RRule.TU,
    name: 'Wtorek',
    selected: false,
  },
  {
    code: RRule.WE,
    name: 'Środa',
    selected: false,
  },
  {
    code: RRule.TH,
    name: 'Czwartek',
    selected: false,
  },
  {
    code: RRule.FR,
    name: 'Piątek',
    selected: false,
  },
  {
    code: RRule.SA,
    name: 'Sobota',
    selected: false,
  },
  {
    code: RRule.SU,
    name: 'Niedziela',
    selected: false,
  },
];

class Days extends Component<IDaysProps, IDaysState> {
  constructor(props: IDaysProps) {
    super(props);

    this.state = {
      days: [...initialState],
    };

    /*
     * We want to replace days before mounting component.
     */
    // @ts-ignore
    this.state.days = this.prepareNewDays(props.value);

    this.select = this.select.bind(this);
  }

  public componentDidUpdate(prevProps: IDaysProps) {
    if (this.props.value !== prevProps.value) {
      this.setNewDays(this.props.value);
    }
  }

  public render() {
    return (
      <div className={styles.days}>
        {this.state.days.map((day) => (
          <span
            key={day.code.toString()}
            onClick={() => {
              this.select(day.code);
            }}
            className={cn(styles.day, day.selected && styles.selected)}
          >
            {day.name}
          </span>
        ))}
      </div>
    );
  }

  protected select(code: Weekday) {
    const days = [...this.state.days].map((day) => {
      if (day.code.weekday === code.weekday) {
        return {
          ...day,
          selected: !day.selected,
        };
      }

      return {
        ...day,
      };
    });

    const daysString = this.makeStringFromDays(days);

    this.props.onChange({
      target: {
        name: this.props.name,
        value: daysString,
      },
    } as ChangeEvent<any>);
  }

  protected setNewDays(days: string) {
    this.setState({
      days: this.prepareNewDays(days),
    });
  }

  protected prepareNewDays(days: string) {
    const splittedDays = days.split(',');

    const newDaysForState = this.state.days.map((day) => {
      const stringCode = day.code.toString();

      if (splittedDays.find((code) => code === stringCode)) {
        return {
          ...day,
          selected: true,
        };
      }

      return {
        ...day,
        selected: false,
      };
    });

    return newDaysForState;
  }

  protected makeStringFromDays(days: IDay[]) {
    return days
      .map((day) => {
        if (day.selected) {
          return day.code.toString();
        }

        return null;
      })
      .filter(Boolean)
      .join(',');
  }
}

export default Days;
