import React, { Component } from 'react';

import Manager from '@Compo/BetterManager/Manager';
import { Card, Grid, Loader } from 'tabler-react';
import { IAddProps } from './Add.types';

const TITLE_TEXT = 'Dodaj grę';

class Add extends Component<IAddProps> {
  public render() {
    const { isLoading } = this.props;

    return (
      <section>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <h3>{TITLE_TEXT}</h3>
          </Grid.Col>
        </Grid.Row>

        {isLoading && <Card body={<Loader />} />}

        <Manager handleSave={this.props.save} />
      </section>
    );
  }
}

export default Add;
