import React, { Component } from 'react';
import { Alert, Card, Grid, Loader } from 'tabler-react';

import { ICheckingProps } from '@Compo/Checking/Checking.types';
import Form from './components/Form';

import styles from './Checking.module.scss';

const TITLE_TEXT = 'Sprzedaż';

class Checking extends Component<ICheckingProps> {
  public componentWillUnmount() {
    this.props.transactionReset();
  }

  public render() {
    if (this.props.transaction.payment) {
      return <Form payment={this.props.transaction.payment} />;
    }

    if (this.props.transaction.expired || this.props.transaction.failed) {
      return (
        <Alert type="danger" icon="alert-triangle">
          Nie udało się potwierdzić transakcji. Status:
          <i className={styles.info}>
            {this.props.transaction.expired ? 'expired' : 'failed'}
          </i>
          .
        </Alert>
      );
    }

    return (
      <>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <h3>{TITLE_TEXT}</h3>
          </Grid.Col>
        </Grid.Row>

        <Card body={<Loader />} />
      </>
    );
  }
}

export default Checking;
