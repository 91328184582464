import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IGetProductsPayload,
  IGetProductsPayloadSuccess,
} from '@Model/products/types';

class ProductsApi {
  private static getProductsUrl(productPayload: IGetProductsPayload): string {
    return `${config.api.baseUrl}spaces/${productPayload.spaceId}/products?dateTime=${productPayload.dateTime}`;
  }
  private static getAllProductsUrl(): string {
    return `${config.api.baseUrl}admin/products`;
  }

  private cancelTokenProducts?: CancelTokenSource;

  public getProducts<T extends IGetProductsPayloadSuccess>(
    productPayload: IGetProductsPayload,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getProductsUrl(productPayload), {
          cancelToken: this.cancelTokenProducts.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
  public getAllProducts<T extends IGetProductsPayloadSuccess>(
    partnerId?: string,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getAllProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            partnerId,
          },
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeProducts(response: IGetProductsPayloadSuccess): any {
    return {
      count: response.count,
      items: response.items,
    };
  }

  public cancelProducts() {
    if (this.cancelTokenProducts) {
      this.cancelTokenProducts.cancel();
      this.cancelTokenProducts = undefined;
    }
  }
}

export default new ProductsApi();
