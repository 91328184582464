import React from 'react';

import { Field } from 'formik';
import { Button, Card, Form, Grid } from 'tabler-react';
import styles from './FormLayout.module.scss';
import { IFormLayoutProps } from './FormLayout.types';

export const SAVE_TEXT = 'Zapisz';
export const CANCEL_TEXT = 'Anuluj';

export const START_CASH_TEXT = 'Podaj kwotę początkową';
export const SELECT_PRINTER_TEXT = 'Wybierz drukarkę';
export const DROP_DOWN_INPUT_DEFAULT_VALUE = -1;

const FormLayout = ({
  handleSubmit,
  values,
  touched,
  errors,
  handleChange,
  printers,
  handleBlur,
  closeModal,
}: IFormLayoutProps) => {
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={START_CASH_TEXT}>
                  <Field
                    type="number"
                    className={styles.formInput}
                    invalid={
                      touched && touched.startCash && errors && errors.startCash
                    }
                    value={values.startCash || undefined}
                    name="startCash"
                    onChange={handleChange}
                  />{' '}
                  <Form.InputGroupText className={styles.errorMessage}>
                    {errors.startCash}
                  </Form.InputGroupText>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={SELECT_PRINTER_TEXT}>
                  <div className={styles.customSelect}>
                    <Field
                      component="select"
                      className={styles.formSelect}
                      name="printerId"
                      value={values.printerId || -1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      invalid={values.printerId}
                      feedback={errors.printerId}
                    >
                      <option
                        defaultValue={'true'}
                        value={DROP_DOWN_INPUT_DEFAULT_VALUE}
                      >
                        -
                      </option>
                      {printers &&
                        printers.map((printer) =>
                          !printer.openSession ? (
                            <option
                              value={printer.id || printer.saleUrl}
                              key={printer.id || printer.saleUrl}
                            >
                              {printer.printerId}
                            </option>
                          ) : null,
                        )}
                    </Field>
                    {errors.printerId && touched.printerId && (
                      <Form.InputGroupText className={styles.errorMessage}>
                        {errors.printerId}
                      </Form.InputGroupText>
                    )}
                  </div>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row />
          </div>
        </div>
      </Card>

      <Button.List>
        <Button type="submit" color="primary" icon="save">
          {SAVE_TEXT}
        </Button>
        <Button onClick={closeModal} type="button" color="secondary" icon="x">
          {CANCEL_TEXT}
        </Button>
      </Button.List>
    </form>
  );
};

export default FormLayout;
