import React from 'react';

import cn from 'classnames';
import pl from 'date-fns/locale/pl';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from 'react-datepicker';
import { IDatePickerProps } from './DatePicker';
import styles from './DatePicker.module.scss';

registerLocale('pl', pl);

const Calendar = ({
  isDateSelectionAvailable,
  selectedDate,
  setDate,
  minDate,
}: IDatePickerProps) => {
  return (
    <div
      className={cn(
        styles.datePicker,
        !isDateSelectionAvailable && styles.disabled,
      )}
    >
      <DatePicker
        fixedHeight={true}
        minDate={minDate || moment().toDate()}
        inline={true}
        locale="pl"
        onChange={setDate}
        selected={selectedDate}
      />
    </div>
  );
};

export default Calendar;
