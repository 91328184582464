import { IAddFormValues } from '@/components/reservations/Add/Add.types';
import { IReservationSaveBody } from '@Services/$reservations-api/types';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _CAPTURE_TRANSACTION_DETAILS_FAILURE,
  _CAPTURE_TRANSACTION_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _GET_COMPANY_DATA_FAILURE,
  _GET_COMPANY_DATA_REQUEST,
  _GET_COMPANY_DATA_SUCCESS,
  _GET_RESERVATION_PRINT_DATA_FAILURE,
  _GET_RESERVATION_PRINT_DATA_REQUEST,
  _GET_RESERVATION_PRINT_DATA_SUCCESS,
  _POST_EMPTY_RESERVATION_FAILURE,
  _POST_EMPTY_RESERVATION_REQUEST,
  _POST_EMPTY_RESERVATION_SUCCESS,
  _POST_RESERVATION_FAILURE,
  _POST_RESERVATION_REQUEST,
  _POST_RESERVATION_SUCCESS,
  _SET_TIME_SLOT,
  ADD_MOUNTED,
  CANCEL_AUTO_TRANSACTION,
  CANCEL_TRANSACTION,
  GET_COMPANY_DATA,
  RESET,
  SAVE_BILL,
  SAVE_RESERVATION,
  SELECT_DISCOUNT,
  SELECT_HAPPENING,
  SELECT_PEOPLE_COUNT,
  SELECT_SPACE,
  SELECT_TIME_SLOT,
  SET_COMPANY_DATA,
  SET_DATE,
  SET_DURATION_TIME_AFTER_MIDNIGHT,
  SET_FORM_DATA,
  SET_UP_SELL,
  SUMMARY_MOUNTED,
  TRANSACTION_RESET,
  TRANSACTION_SAVE,
} from './../constants/actions';
import {
  IBook,
  ICompanyDataReducer,
  IEmptyReservationSaveRequestPayload,
  IReservationPrintData,
  IReservationSaveRequestPayload,
  ITimeSlot,
  ITransactionDetailsResponse,
  ITransactionPayment,
} from './../types';

export const getReservationPrintData = createAsyncAction(
  _GET_RESERVATION_PRINT_DATA_REQUEST,
  _GET_RESERVATION_PRINT_DATA_SUCCESS,
  _GET_RESERVATION_PRINT_DATA_FAILURE,
)<undefined, IReservationPrintData, Error>();

export const addMounted = createStandardAction(ADD_MOUNTED)();

export const summaryMounted = createStandardAction(SUMMARY_MOUNTED)();

export const selectHappening = createStandardAction(SELECT_HAPPENING)<
  number | null
>();

export const selectSpace = createStandardAction(SELECT_SPACE)<number | null>();

export const selectDiscount = createStandardAction(SELECT_DISCOUNT)<
  number | null
>();

export const selectPeopleCount = createStandardAction(SELECT_PEOPLE_COUNT)<
  number
>();

export const selectTimeSlot = createStandardAction(SELECT_TIME_SLOT)<
  ITimeSlot
>();

export const reset = createStandardAction(RESET)();

export const setTimeSlot = createStandardAction(
  _SET_TIME_SLOT,
)<ITimeSlot | null>();

export const selectUpSell = createStandardAction(SET_UP_SELL)<boolean>();

export const setDate = createStandardAction(SET_DATE)<Date>();

export const saveReservation = createStandardAction(SAVE_RESERVATION)<
  IReservationSaveBody
>();

export const cancelTransaction = createStandardAction(CANCEL_TRANSACTION)<
  string | null
>();
export const cancelAutoTransaction = createStandardAction(
  CANCEL_AUTO_TRANSACTION,
)<string | null>();

export const postReservation = createAsyncAction(
  _POST_RESERVATION_REQUEST,
  _POST_RESERVATION_SUCCESS,
  _POST_RESERVATION_FAILURE,
)<IReservationSaveRequestPayload, IBook, Error>();

export const postEmptyReservation = createAsyncAction(
  _POST_EMPTY_RESERVATION_REQUEST,
  _POST_EMPTY_RESERVATION_SUCCESS,
  _POST_EMPTY_RESERVATION_FAILURE,
)<IEmptyReservationSaveRequestPayload, IBook, Error>();

export const captureTransactionsDetailsRequest = createAsyncAction(
  _CAPTURE_TRANSACTION_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_DETAILS_FAILURE,
)<string, ITransactionDetailsResponse, Error>();

export const captureTransactionsEmpikDetailsRequest = createAsyncAction(
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
)<string, ITransactionDetailsResponse, Error>();

export const getCompanyData = createAsyncAction(
  _GET_COMPANY_DATA_REQUEST,
  _GET_COMPANY_DATA_SUCCESS,
  _GET_COMPANY_DATA_FAILURE,
)<string, undefined, undefined>();

export const transactionSave = createStandardAction(TRANSACTION_SAVE)<
  ITransactionPayment
>();

export const transactionReset = createStandardAction(TRANSACTION_RESET)();

export const saveBill = createStandardAction(SAVE_BILL)<string>();

export const catchCompanyData = createStandardAction(GET_COMPANY_DATA)<
  string
>();

export const setCompanyData = createStandardAction(SET_COMPANY_DATA)<
  ICompanyDataReducer
>();

export const setFormData = createStandardAction(SET_FORM_DATA)<
  IAddFormValues
>();

export const setDurationTimeAfterMidnight = createStandardAction(
  SET_DURATION_TIME_AFTER_MIDNIGHT,
)();
