import { getType } from 'typesafe-actions';

import { removeDuplicatesArray } from '@Misc/helpers/removeDuplicatesArray';
import {
  addEntryCodeRequest,
  closeModal,
  getAvailableUsers,
  getEntryTokens,
  handleActivePageConsentsList,
  setDate,
  setEmail,
  setEntryIdEdit,
  setFirstName,
  setLastName,
} from './../actions';
import { FORM_IO_RESERVATION_CODE } from './../constants/constants';
import { IAction, IConsentsListReducer } from './../types';

const DEFAULT_PER_PAGE = 50;
const DEFAULT_ACTIVE_PAGE = 0;

const initialState: IConsentsListReducer = {
  activePage: DEFAULT_ACTIVE_PAGE,
  date: '',
  email: '',
  entryTokens: [],
  firstName: '',
  formSmsToken: '',
  lastName: '',
  perPage: DEFAULT_PER_PAGE,
  selectedUser: {
    _id: '',
    access: [],
    created: '',
    data: {
      agreement: false,
      dataurodzenia: '',
      email: '',
      entryId: '',
      imie: '',
      nazwisko: '',
      page1Email: '',
    },
    externalIds: [],
    form: '',
    metadata: {
      browserName: '',
      offset: 0,
      referrer: '',
      timezone: '',
      userAgent: '',
    },
    modified: '',
    owner: null,
    project: '',
    roles: [],
    state: '',
  },
  showModal: false,
  totalCount: 0,
};

const consentsListReducer = (
  state: IConsentsListReducer = initialState,
  action: IAction,
): IConsentsListReducer => {
  switch (action.type) {
    case getType(handleActivePageConsentsList):
      return {
        ...state,
        activePage: action.payload,
      };

    case getType(getAvailableUsers.success):
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };

    case getType(setEntryIdEdit):
      return {
        ...state,
        selectedUser: action.payload,
        showModal: true,
      };

    case getType(closeModal):
      return {
        ...state,
        entryTokens: [],
        showModal: false,
      };

    case getType(getEntryTokens.success):
      return {
        ...state,
        entryTokens: action.payload.length
          ? removeDuplicatesArray(
              action.payload.map((user) => user.data[FORM_IO_RESERVATION_CODE]),
              (entryToken: string) => entryToken,
            )
          : [],
        formSmsToken:
          action.payload &&
          action.payload.length &&
          action.payload[0] &&
          action.payload[0].form
            ? action.payload[0].form
            : state.formSmsToken,
      };
    case getType(addEntryCodeRequest.success):
      return {
        ...state,
        entryTokens: [...state.entryTokens, action.payload],
      };

    case getType(setEmail):
      return {
        ...state,
        activePage: 0,
        email: action.payload,
      };
    case getType(setFirstName):
      return {
        ...state,
        activePage: 0,
        firstName: action.payload,
      };
    case getType(setLastName):
      return {
        ...state,
        activePage: 0,
        lastName: action.payload,
      };
    case getType(setDate):
      return {
        ...state,
        activePage: 0,
        date: action.payload,
      };
    default:
      return state;
  }
};

export default consentsListReducer;
