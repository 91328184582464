import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { addMounted, reset, saveReservation } from '@Model/reservation/actions';
import { getCompanyData, getFormData } from '@Model/reservation/selectors';
import _Store from '@Store';
import Add from './Add.component';
import { IAddFromDispatch, IAddFromState } from './Add.types';

const mapStateToProps = (state: _Store.IState): IAddFromState => {
  const companyData = getCompanyData(state);
  const formData = getFormData(state);

  return {
    companyData,
    formData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IAddFromDispatch => ({
  mounted: () => dispatch(addMounted()),
  reset: () => dispatch(reset()),
  save: (body) => dispatch(saveReservation(body)),
});

export default connect<IAddFromState, IAddFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Add);
