import React, { useEffect, useState } from 'react';

import { Button, Card, Form, Grid, Loader } from 'tabler-react';

import DiscountModal from '@Compo/Discounts/components/DiscountModal/';
import Filters from '@Compo/happenings/List/components/Filters';
import DiscountTable from './components/DiscountTable';
import FiltersByActive from './components/FormLayout/FiltersByActive';
import Pagination from './components/Pagination';
import { IDiscountsProps } from './Discounts.types';

import styles from './Discounts.module.scss';

export const ADD_NEW_DISCOUNT_TEXT = 'Dodaj';
export const DISCOUNT_TEXT = 'Kody rabatowe';
const HIDE_FILTERS_TEXT = 'Ukryj filtry';
const SHOW_FILTERS_TEXT = 'Pokaz filtry';
const SEARCH_TEXT = 'Wpisz by wyszukać...';

const Discounts = ({
  discountsMounted,
  showModal,
  addDiscount,
  isLoading,
  permissionDiscountWrite,
  permissionDiscountView,
  updateSearchText,
  searchText,
}: IDiscountsProps) => {
  useEffect(() => {
    discountsMounted();
  }, []);

  const [showFilters, handleShowFilters] = useState<boolean>(false);

  const catchManageFilters = () => {
    handleShowFilters(!showFilters);
  };
  const catchSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    updateSearchText(value);
  };

  return (
    <>
      {showModal && <DiscountModal />}
      <section>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <h3>{DISCOUNT_TEXT}</h3>
          </Grid.Col>
          {permissionDiscountWrite && (
            <Grid.Col>
              <Button.List align="right">
                <Button
                  onClick={addDiscount}
                  type="button"
                  color="primary"
                  icon="plus"
                >
                  {ADD_NEW_DISCOUNT_TEXT}
                </Button>
                <Button
                  color="lime"
                  onClick={catchManageFilters}
                  icon={showFilters ? 'arrow-up' : 'arrow-down'}
                >
                  {showFilters ? HIDE_FILTERS_TEXT : SHOW_FILTERS_TEXT}
                </Button>
              </Button.List>
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <Form.Input
              name="search"
              placeholder={SEARCH_TEXT}
              value={searchText}
              onChange={catchSearch}
            />
          </Grid.Col>
        </Grid.Row>

        {showFilters && (
          <>
            <Filters />
            <FiltersByActive />
          </>
        )}

        {isLoading && (
          <Card
            body={
              <div className={styles.loader}>
                <Loader />
              </div>
            }
          />
        )}

        {!isLoading && permissionDiscountView && (
          <Grid.Row cards={true} deck={true}>
            <Grid.Col width={12}>
              <Card>
                <DiscountTable />
              </Card>
              <Pagination />
            </Grid.Col>
          </Grid.Row>
        )}
      </section>
    </>
  );
};

export default Discounts;
