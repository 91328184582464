import { createSelector } from 'reselect';

import { ISelectedHappeningSpace } from '@Model/happenings/types';
import { getSelectedSlot } from '@Model/reservation/selectors';
import getSelectedSpace from '@Model/reservation/selectors/getSelectedSpace';
import { ITimeSlot } from '@Model/reservation/types';
import _Store from '@Store';
import {
  IAvailabilitiesReducer,
  IAvailabilitiesResponseSlot,
} from './../types/index';
import getAvailabilities from './getAvailabilities';

const getSelectedConfigurationId = createSelector<
  _Store.IState,
  IAvailabilitiesReducer,
  ITimeSlot | null,
  ISelectedHappeningSpace | null,
  IAvailabilitiesResponseSlot | null
>(
  getAvailabilities,
  getSelectedSlot,
  getSelectedSpace,
  (available, selectedTimeSlot, selectedSpace) => {
    if (
      (available.currentDay || available.currentDay) &&
      selectedTimeSlot &&
      selectedSpace
    ) {
      if (
        { ...(available.currentDay || {}), ...(available.otherDays || {}) }[
          selectedTimeSlot.startTime
        ]
      ) {
        const time = {
          ...(available.currentDay || {}),
          ...(available.otherDays || {}),
        }[selectedTimeSlot.startTime];

        if (time) {
          const selectedTime = time.find(
            (day) => day.spaceId === selectedSpace.id,
          );

          if (selectedTime) {
            return selectedTime;
          }
        }
      }
    }

    return null;
  },
);

export default getSelectedConfigurationId;
