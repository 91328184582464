import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  removeProduct,
  selectProduct,
  setProductCount,
  unSelectProduct,
} from '@Model/products/actions';

import { isBigDesktop, isDesktop } from '@Model/app/selectors';
import _Store from '@Store';
import FormLayout from './Product.component';
import { IProductFromDispatch, IProductFromState } from './Product.types';

const mapStateToProps = (state: _Store.IState): IProductFromState => {
  return {
    isBigDesktop: isBigDesktop(state),
    isDesktop: isDesktop(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IProductFromDispatch => ({
  removeProduct: (product) => dispatch(removeProduct(product)),
  selectProduct: (product) => dispatch(selectProduct(product)),
  setProductCount: (product) => dispatch(setProductCount(product)),
  unSelectProduct: (product) => dispatch(unSelectProduct(product)),
});

export default connect<
  IProductFromState,
  IProductFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FormLayout);
