import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get as getFromAuthorization } from '@Model/authorization/selectors';
import { addDiscount } from '@Model/discount/actions';
import get from '@Model/discount/selectors/get';
import { mounted, updateSearchText } from '@Model/discounts/actions';
import getDiscounts from '@Model/discounts/selectors/get';
import { allPermissions, DISCOUNTS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import Discounts from './Discounts.component';
import { IDiscountsFromDispatch, IDiscountsFromState } from './Discounts.types';

const mapStateToProps = (state: _Store.IState): IDiscountsFromState => {
  const { showModal } = get(state);
  const { searchText } = getDiscounts(state);
  const {
    userInfo: { permissions },
  } = getFromAuthorization(state);

  return {
    isLoading: getLoading(DISCOUNTS)(state),
    permissionDiscountView: permissions.includes(
      allPermissions.access_discounts_view,
    ),
    permissionDiscountWrite: permissions.includes(
      allPermissions.access_discounts_write,
    ),
    searchText,
    showModal,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IDiscountsFromDispatch => {
  return {
    addDiscount: () => {
      dispatch(addDiscount());
    },
    discountsMounted: () => {
      dispatch(mounted());
    },
    updateSearchText: (search: string) => {
      dispatch(updateSearchText(search));
    },
  };
};

export default connect<
  IDiscountsFromState,
  IDiscountsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Discounts);
