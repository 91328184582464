import { connect } from 'react-redux';

import { getProducts } from '@Model/products/selectors';

import _Store from '@Store';
import FormLayout from './Products.component';
import { IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => {
  return {
    products: getProducts(state).items,
  };
};

export default connect<IProductsFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(FormLayout);
