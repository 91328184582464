import { SyntheticEvent } from 'react';

const cancelClick = (event: SyntheticEvent) => {
  event.stopPropagation();

  if (event.nativeEvent) {
    event.nativeEvent.stopImmediatePropagation();
  }
};

export default cancelClick;
