import React from 'react';

import { Route, Switch } from 'react-router';
import routes from './routes';

// Route components
import AdminPage from '@Compo/pages/Admin';
import CalendarPage from '@Compo/pages/Calendar';
import Checking from '@Compo/pages/Checking';
import ConsentsList from '@Compo/pages/ConsentsList';
import DiscountsPage from '@Compo/pages/Discounts';
import HappeningAddPage from '@Compo/pages/HappeningAdd';
import HappeningEditPage from '@Compo/pages/HappeningEdit';
import Happenings from '@Compo/pages/Happenings';
import MainPage from '@Compo/pages/Main';
import ReportsPage from '@Compo/pages/Reports';
import ReservationAdd from '@Compo/pages/ReservationAdd';
import Rules from '@Compo/pages/Rules';
import NewRules from '@Compo/pages/NewRules';
import StartPage from '@Compo/pages/Start';
import StaticsPage from '@Compo/pages/Statics';
import Summary from '@Compo/pages/Summary';
import SummaryCanceled from '@Compo/pages/SummaryCanceled';
import SummaryFail from '@Compo/pages/SummaryFail';
import TransactionsDelayed from '@Compo/pages/TransactionsDelayed';

const Routes = () => (
  <>
    <Switch>
      <Route exact={true} path={routes.index} component={MainPage} />
      <Route exact={true} path={routes.happenings} component={Happenings} />
      <Route
        exact={true}
        path={routes.addHappening}
        component={HappeningAddPage}
      />
      <Route
        exact={true}
        path={routes.editHappening}
        component={HappeningEditPage}
      />
      <Route exact={false} path={routes.calendar} component={CalendarPage} />
      <Route exact={false} path={routes.reports} component={ReportsPage} />
      <Route exact={true} path={routes.start} component={StartPage} />
      <Route
        exact={true}
        path={routes.addReservation}
        component={ReservationAdd}
      />
      <Route exact={true} path={routes.discounts} component={DiscountsPage} />
      <Route exact={true} path={routes.summarySuccess} component={Summary} />
      <Route exact={true} path={routes.summaryFail} component={SummaryFail} />
      <Route
        exact={true}
        path={routes.summaryCanceled}
        component={SummaryCanceled}
      />
      <Route exact={true} path={routes.admin} component={AdminPage} />
      <Route exact={true} path={routes.checking} component={Checking} />
      <Route exact={true} path={routes.rules} component={Rules} />
      <Route exact={true} path={routes.newRules} component={NewRules} />
      <Route exact={true} path={routes.consentsList} component={ConsentsList} />
      <Route exact={true} path={routes.statics} component={StaticsPage} />
      <Route
        exact={true}
        path={routes.transactionsDelayed}
        component={TransactionsDelayed}
      />
    </Switch>
  </>
);

export default Routes;
