import { getTimeSlots } from '@Model/reservation/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { ITimeSlotsFromState } from './TimeSlots';
import TimeSlots from './TimeSlots.component';

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    timeSlots: getTimeSlots(state),
  };
};

export default connect<ITimeSlotsFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(TimeSlots);
