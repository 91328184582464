import {
  getLocation,
  getSearch,
  LOCATION_CHANGE,
  push,
} from 'connected-react-router';
import queryString from 'query-string';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import routes from '@/routes/routes';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import { setToken } from '@Model/app/actions';
import {
  authorizationFail,
  checkAuthorization,
  encodeJwtToken,
  endSession,
  endSessionByPrinterId,
  endUserSession,
  fetchAuthorizationCasherInfo,
  getToken,
  handleUsersPermissions,
  mounted,
  removeAuthorizationHeader,
  runSession,
  setAuthorizationHeader,
  setAuthorizationState,
} from '@Model/authorization/actions';
import getAppToken from '@Model/discount/selectors/getAppToken';
import { refreshPage, setIframeSession } from '@Model/iframe/actions/index';
import {
  catchPrinterPayment,
  endPrinterSession,
  endPrinterSessionById,
  getPaymentSession,
  getPrintersPinging,
  printerPayment,
  stopPingingPrinter,
} from '@Model/printer/actions';
import { addToast } from '@Model/toasts/actions/index';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import {
  IAuthorizationResponse,
  ICasherInfoResponse,
  IPaymentSessionResponse,
  IRunSessionRequest,
} from '@Services/$authorization-api/types';
import _Store from '@Store';
import { getUserInfo } from '../selectors';
import { IPaymentSessionPayload, ISearch } from './../types';

const PLEASE_REFRESH_TEXT = 'Proszę odświeżyć stronę';
const END_SESSION_PLEASE_REFRESH_TEXT =
  'Sesja zakończona, proszę odświeżyć stronę';
const END_SESSION_TEXT = 'Sesja zakończona pomyślnie!';
const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

export const setStartStateWhenAppMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getSearch(state));
      if (search.token && typeof search.token === 'string') {
        const { token } = search;
        return of$(getToken.request(token));
      }

      return of$(removeAuthorizationHeader(), authorizationFail());
    }),
  );
};

export const getTokenWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.request)),
    mergeMap$((action) => {
      return from$(authorizationApi.getSessionToken(action.payload)).pipe(
        map$((data: IAuthorizationResponse) => {
          return getToken.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => authorizationApi.cancelAuthorization()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getToken.failure(error));
        }),
      );
    }),
  );
};

export const setTokenAndRedirectWhenGetTokenSuccess: _Store.IEpic = (
  action$,
  state$,
  { linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search: ISearch = queryString.parse(getSearch(state));
      let redirectUrl = routes.index;
      if (search.transactionId && typeof search.transactionId === 'string') {
        const { transactionId } = search;
        redirectUrl = linksProvider.buildSummaryLink(transactionId);
        if (
          search.error &&
          (search.error === '500' || search.error === '501')
        ) {
          redirectUrl = linksProvider.buildSummaryFailLink(transactionId);
        } else if (
          search.error &&
          (search.error === '400' ||
            search.error === '401' ||
            search.error === '404')
        ) {
          redirectUrl = linksProvider.buildSummaryCanceledLink(transactionId);
        }
      } else if (
        search.reservationId &&
        typeof search.reservationId === 'string'
      ) {
        const { reservationId } = search;
        redirectUrl = linksProvider.buildCalendalPopUpLink(reservationId);
      } else {
        redirectUrl = routes.index;
      }

      const { token } = action.payload;

      return of$(
        setAuthorizationHeader(token),
        encodeJwtToken(token),
        setToken(token),
        push(redirectUrl),
      );
    }),
  );
};

export const encodeJwtTokenAndSetUserStateWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(encodeJwtToken)),
    mergeMap$((action) => {
      const userData = authorizationApi.encodeSessionToken(action.payload);
      if (userData.session && userData.session.session_id) {
        return of$(
          setIframeSession(),
          setAuthorizationState(userData),
          handleUsersPermissions(userData),
          getPrintersPinging.request(),
        );
      }
      return of$(
        setAuthorizationState(userData),
        handleUsersPermissions(userData),
      );
    }),
  );
};

export const handleUsersPermissionsWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleUsersPermissions)),
    mergeMap$((action) => {
      return of$(checkAuthorization.request());
    }),
  );
};

export const fetchAuthorisationCasherInfoWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(fetchAuthorizationCasherInfo.request)),
    mergeMap$((action) => {
      if (!action.payload) {
        return EMPTY$;
      }
      return from$(
        authorizationApi.fetchAuthorizationCasherInfo(action.payload),
      ).pipe(
        map$((data: ICasherInfoResponse) => {
          return fetchAuthorizationCasherInfo.success(data);
        }),
        catchError$((error: Error) => {
          return of$(fetchAuthorizationCasherInfo.failure(error));
        }),
      );
    }),
  );
};

export const setAuthorizationHeaderWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setAuthorizationHeader)),
    withLatestFrom$(state$),
    tap$(([action]) => {
      requestProvider.setAuthorizationHeader(action.payload);
    }),
    mergeMap$(() => EMPTY$),
  );
};

export const checkSessionTokenInWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(checkAuthorization.request)),
    mergeMap$(() => {
      return from$(requestProvider.checkAutorotation()).pipe(
        map$((response: Error) => {
          return checkAuthorization.success(response);
        }),
        catchError$((error: Error) => {
          return of$(checkAuthorization.failure(error));
        }),
      );
    }),
  );
};

export const showRefreshMessageWhenSessionOf: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(checkAuthorization.success)),
    mergeMap$((action) => {
      const errorMessage = catchNewErrorMessage(action.payload);
      if (
        errorMessage &&
        errorMessage.statusCode &&
        errorMessage.statusCode === 401
      ) {
        return of$(
          addToast(PLEASE_REFRESH_TEXT, TYPE_ERROR),
          checkAuthorization.request(),
        );
      } else if (
        errorMessage &&
        errorMessage.message &&
        errorMessage.statusCode &&
        errorMessage.statusCode >= 400 &&
        errorMessage.statusCode !== 409
      ) {
        return of$(
          addToast(errorMessage.message, TYPE_ERROR),
          checkAuthorization.request(),
        );
      }
      return of$(checkAuthorization.request());
    }),
  );
};

export const removeAuthorizationHeaderWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(removeAuthorizationHeader)),
    tap$(() => {
      requestProvider.removeAuthorizationHeader();
    }),
    mergeMap$(() => EMPTY$),
  );
};

export const checkTokenWhenLocationChanged: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(([_, state]) => getLocation(state).pathname !== routes.start),
    mergeMap$(([action, state]) => {
      const appToken = getAppToken(state);

      const devToken = '';
      const devTransactionId = '';

      const getDevToken = (): string => {
        if (process.env.NODE_ENV === 'development') {
          return `?token=${devToken}`;
        }
        return '';
      };

      const getDevTransactionId = (): string => {
        if (process.env.NODE_ENV === 'development' && devTransactionId) {
          return `&transactionId=${devTransactionId}`;
        }
        return '';
      };

      if (!appToken) {
        return of$(push(routes.start + getDevToken() + getDevTransactionId()));
      }
      return EMPTY$;
    }),
  );
};

export const runSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(runSession)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      const { userId, startCash, printerId } = action.payload;

      const request: IRunSessionRequest = {
        printerId,
        startCash,
        userId,
      };

      return from$(authorizationApi.runSession(request)).pipe(
        map$(() => {
          return refreshPage();
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(addToast(message, TYPE_ERROR));
        }),
      );
    }),
  );
};

export const clearSessionByPrinterIdWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endSessionByPrinterId)),
    mergeMap$((action) => {
      return from$(
        authorizationApi.clearSessionByPrinterId(action.payload),
      ).pipe(
        mergeMap$(() => {
          return of$(endPrinterSessionById.request(action.payload));
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(
            endPrinterSessionById.failure(new Error(message)),
            addToast(message, TYPE_ERROR),
          );
        }),
      );
    }),
  );
};

export const clearSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endSession)),
    mergeMap$(() => {
      return from$(authorizationApi.clearSession()).pipe(
        mergeMap$(() => {
          return of$(
            refreshPage(),
            endUserSession(),
            addToast(END_SESSION_PLEASE_REFRESH_TEXT, TYPE_SUCCESS),
            stopPingingPrinter(),
          );
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(addToast(message, TYPE_ERROR));
        }),
      );
    }),
  );
};

export const getSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getPaymentSession.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { session } = getUserInfo(state);

      if (!session) {
        return EMPTY$;
      }

      const { session_external_id } = session;

      return from$(
        authorizationApi.getPaymentSession(session_external_id),
      ).pipe(
        map$((response: IPaymentSessionResponse[]) => {
          if (response && response.length) {
            return endPrinterSession.request(response[0]);
          }

          return printerPayment.failure(new Error(SOMETHING_WENT_WRONG_TEXT));
        }),
        catchError$((error: Error) => {
          const { message } = catchNewErrorMessage(error);

          return of$(
            printerPayment.failure(error),
            addToast(message, TYPE_ERROR),
          );
        }),
      );
    }),
  );
};

export const updatePaymentSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi },
) => {
  return action$.pipe(
    filter$(isActionOf(catchPrinterPayment)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { session } = getUserInfo(state);

      if (!action.payload || !action.payload.cash || !session) {
        return EMPTY$;
      }

      const { session_external_id: sessionUuid } = session;
      const { cash, action: actionType } = action.payload;

      const amount = Math.round(cash * 100);

      const paymentPayload: IPaymentSessionPayload = {
        actionType: actionType === 'income' ? 'income' : 'outcome',
        amount,
        sessionUuid,
      };

      return from$(authorizationApi.updatePaymentSession(paymentPayload)).pipe(
        map$(() => {
          return printerPayment.request(action.payload);
        }),
        catchError$((error: Error) => {
          return of$(printerPayment.failure(error));
        }),
      );
    }),
  );
};
