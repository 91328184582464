// Private actions
export const _GET_REQUEST = 'Zagrywki/reservations/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/reservations/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/reservations/_GET_FAILURE';
export const _SET_FILTERS = 'Zagrywki/reservations/_SET_FILTERS';

export const _GET_DETAILS_REQUEST =
  'Zagrywki/reservations/_GET_DETAILS_REQUEST';
export const _GET_DETAILS_SUCCESS =
  'Zagrywki/reservations/_GET_DETAILS_SUCCESS';
export const _GET_DETAILS_FAILURE =
  'Zagrywki/reservations/_GET_DETAILS_FAILURE';
export const _UPDATE_RESERVATION_REQUEST =
  'Zagrywki/reservations/_UPDATE_RESERVATION_REQUEST';
export const _UPDATE_RESERVATION_SUCCESS =
  'Zagrywki/reservations/_UPDATE_RESERVATION_SUCCESS';
export const _UPDATE_RESERVATION_FAILURE =
  'Zagrywki/reservations/_UPDATE_RESERVATION_FAILURE';
export const _CHECK_RESERVATION_REQUEST =
  'Zagrywki/reservations/_CHECK_RESERVATION_REQUEST';
export const _CHECK_RESERVATION_SUCCESS =
  'Zagrywki/reservations/_CHECK_RESERVATION_SUCCESS';
export const _CHECK_RESERVATION_FAILURE =
  'Zagrywki/reservations/_CHECK_RESERVATION_FAILURE';
export const _CHECK_RESET = 'Zagrywki/reservations/_CHECK_RESET';

export const _GET_RESERVATION_EMPIK_DETAILS_REQUEST =
  'Zagrywki/reservations/_GET_RESERVATION_EMPIK_DETAILS_REQUEST';
export const _GET_RESERVATION_EMPIK_DETAILS_SUCCESS =
  'Zagrywki/reservations/_GET_RESERVATION_EMPIK_DETAILS_SUCCESS';
export const _GET_RESERVATION_EMPIK_DETAILS_FAILURE =
  'Zagrywki/reservations/_GET_RESERVATION_EMPIK_DETAILS_FAILURE';

// Public actions
export const MOUNTED = 'Zagrywki/reservations/MOUNTED';
export const RESEND_TICKET = 'Zagrywki/reservations/RESEND_TICKET';
export const GET_TICKET = 'Zagrywki/reservations/GET_TICKET';
export const SWITCH_FILTER = 'Zagrywki/reservations/SWITCH_FILTER';
export const CHANGE_DATE = 'Zagrywki/reservations/CHANGE_DATE';
export const RELOAD = 'Zagrywki/reservations/RELOAD';
export const SELECT_RESERVATION = 'Zagrywki/reservations/SELECT_RESERVATION';
export const SELECT_RESERVATION_LIST =
  'Zagrywki/reservations/SELECT_RESERVATION_LIST';
export const RESET_SELECTION = 'Zagrywki/reservations/RESET_SELECTION';
export const RESET_SELECTION_LIST =
  'Zagrywki/reservations/RESET_SELECTION_LIST';
export const SAVE_RESERVATION = 'Zagrywki/reservations/SAVE_RESERVATION';
export const CHECK_RESERVATION_DATE =
  'Zagrywki/reservations/CHECK_RESERVATION_DATE';
export const CLEAR_FILTERS = 'Zagrywki/reservations/CLEAR_FILTERS';
export const SELECT_ALL_FILTERS = 'Zagrywki/reservations/SELECT_ALL_FILTERS';
export const CLEAR_FILTERS_REQUEST =
  'Zagrywki/reservations/CLEAR_FILTERS_REQUEST';

export const EMPIK_RESERVATION_DETAILS_RESET =
  'Zagrywki/reservations/EMPIK_RESERVATION_DETAILS_RESET';

// DELAYED TRANSACTIONS
// Private actions
export const _GET_DELAYED_TRANSACTIONS_REQUEST =
  'Zagrywki/reservations/_GET_DELAYED_TRANSACTIONS_REQUEST';
export const _GET_DELAYED_TRANSACTIONS_SUCCESS =
  'Zagrywki/reservations/_GET_DELAYED_TRANSACTIONS_SUCCESS';
export const _GET_DELAYED_TRANSACTIONS_FAILURE =
  'Zagrywki/reservations/_GET_DELAYED_TRANSACTIONS_FAILURE';

export const _CANCEL_DELAYED_TRANSACTIONS_REQUEST =
  'Zagrywki/reservations/_CANCEL_DELAYED_TRANSACTIONS_REQUEST';
export const _CANCEL_DELAYED_TRANSACTIONS_SUCCESS =
  'Zagrywki/reservations/_CANCEL_DELAYED_TRANSACTIONS_SUCCESS';
export const _CANCEL_DELAYED_TRANSACTIONS_FAILURE =
  'Zagrywki/reservations/_CANCEL_DELAYED_TRANSACTIONS_FAILURE';

// DELAYED TRANSACTIONS
// Public actions

export const CATCH_DELAYED_TRANSACTIONS =
  'Zagrywki/reservations/EMPIK_RESERVATION_DETAILS_RESET';

export const CATCH_CANCEL_DELAYED_TRANSACTION =
  'Zagrywki/reservations/CATCH_CANCEL_DELAYED_TRANSACTION';

export const HANDLE_ACTIVE_PAGE = 'Zagrywki/reservations/HANDLE_ACTIVE_PAGE';
