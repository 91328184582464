import compareTimes from '@Misc/helpers/compareTimes';
import { getAvailabilities } from '@Model/happening/selectors';
import { IAvailabilitiesReducer } from '@Model/happening/types';
import _Store from '@Store';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { ITimeSlot } from '../types';
import getSelectedDate from './getSelectedDate';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSlotOverbooking from './getSelectedSlotOverbooking';

const getTimeSlots = createSelector<
  _Store.IState,
  ITimeSlot | null,
  number,
  IAvailabilitiesReducer,
  Date | null,
  ITimeSlot[]
>(
  [
    getSelectedSlot,
    getSelectedSlotOverbooking,
    getAvailabilities,
    getSelectedDate,
  ],
  (selectedSlot, overbooking, availabilities, selectedDate) => {
    const result: ITimeSlot[] = [];
    const isCurrentDay = moment(selectedDate || new Date()).isSame(
      moment(),
      'day',
    );

    const keys = Object.keys(availabilities).sort((timeA, timeB) =>
      compareTimes(timeA, timeB),
    );

    const isSelected = (startTime: string, keyIndex: number): boolean => {
      let selected = false;

      if (selectedSlot) {
        selected = selectedSlot.startTime === startTime;
        if (!selected && overbooking > 0) {
          for (let i: number = 1; i <= overbooking; i += 1) {
            const prevStartTime = keys[keyIndex - i];
            selected = selectedSlot.startTime === prevStartTime;

            if (selected) {
              break;
            }
          }
        }
      }

      return selected;
    };

    let mergedDays = availabilities.currentDay;
    let hours = Object.keys(availabilities.currentDay || {}).sort((a, b) =>
      a.localeCompare(b),
    );

    const dayAsString = moment(selectedDate || Date.now()).format('YYYY-MM-DD');
    if (availabilities.otherDays) {
      hours = [
        ...hours,
        ...Object.keys(availabilities.otherDays).sort((a, b) =>
          a.localeCompare(b),
        ),
      ];

      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    const now = moment
      .utc()
      .add('hours', -4)
      .valueOf();

    hours.forEach((key: string, index: number) => {
      const spaces = mergedDays[key];

      const slotDateTime = moment(`${dayAsString} ${key}`)
        .utc()
        .valueOf();

      const disableByTime =
        now > slotDateTime && !!availabilities.currentDay[key];

      const isDisabled =
        spaces.find((space) => {
          return space.available && !space.closed;
        }) === undefined;

      if (isDisabled || disableByTime) {
        return;
      }

      result.push({
        isDisabled,
        isSelected: isSelected(key, index),
        startTime: key,
      });
    });

    return result;
  },
);

export default getTimeSlots;
