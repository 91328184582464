import { getType } from 'typesafe-actions';

import { getDelayedTransactions, handleActivePage } from '../actions';
import { IAction, IDelayedTransactionsReducer } from '../types';

const initialState: IDelayedTransactionsReducer = {
  items: null,
  page: 0,
  perPage: 10,
  totalCount: 0,
};

const delayedTransactionsReducer = (
  state: IDelayedTransactionsReducer = initialState,
  action: IAction,
): IDelayedTransactionsReducer => {
  switch (action.type) {
    case getType(getDelayedTransactions.success):
      return {
        ...state,
        items: action.payload.transactions,
        totalCount: action.payload.totalCount,
      };
    case getType(handleActivePage):
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};

export default delayedTransactionsReducer;
