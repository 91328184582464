import _ from 'lodash';

import { IFormIoUser } from '@Model/formio/types';

const customizer = (objValue: IFormIoUser, othValue: IFormIoUser) => {
  return _.isEqual(objValue.data, othValue.data);
};

export const uniqueUsers = (arr: IFormIoUser[]) => {
  return _.uniqWith(arr, (arrVal, othVal) =>
    _.isEqualWith(arrVal, othVal, customizer),
  );
};
