import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { addProduct } from '@Model/products/actions';
import { getAllProducts, getProducts } from '@Model/products/selectors';

import _Store from '@Store';
import ProductsList from './ProductsList.component';
import {
  IProductListFromDispatch,
  IProductsListFromState,
} from './ProductsList.types';

const mapStateToProps = (state: _Store.IState): IProductsListFromState => {
  return {
    allProducts: getAllProducts(state),
    products: getProducts(state).items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IProductListFromDispatch => ({
  addProduct: (product) => dispatch(addProduct(product)),
});

export default connect<
  IProductsListFromState,
  IProductListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ProductsList);
