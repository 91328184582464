import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Button, Card, Form, Grid, Loader } from 'tabler-react';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Filters from '@Compo/happenings/List/components/Filters';
import { ITimeSlot } from '@Model/reservation/types';
import cn from 'classnames';
import DatePicker from './../DatePicker';
import TimeSlots from './../TimeSlots';
import Basket from './Basket/Basket';
import { IFormLayoutProps } from './FormLayout';
import InvoiceForm from './InvoiceForm';
import Products from './Products';
import ProductsList from './ProductsList';

import styles from './FormLayout.module.scss';

const DISCOUNT_TEXT = 'Kod rabatowy';
const PRE_PAID_CODE_TEXT = 'Kod karty prepaid';
const DESCRIPTION_TEXT = 'Podaj opis';
const EMAIL_TEXT = 'Podaj adres e-mail';
const FIRST_NAME_TEXT = 'Podaj imię';
const NUMBER_OF_PEOPLE_VALIDATOR_TEXT =
  'Liczba osób nie może być mniejsza niż 0';
const LAST_NAME_TEXT = 'Podaj nazwisko';
const PHONE_TEXT = 'Podaj numer telefonu';
const SAVE_TEXT = 'Zapisz';
const SELECT_DATE_TEXT = 'Wybierz datę';
const SELECT_HAPPENING_TEXT = 'Wybierz wydarzenie';
const SELECT_NUMBER_OF_PEOPLE_TEXT = 'Wybierz liczbę osób';
const SELECT_SLOT_TEXT = 'Wybierz godzinę rozpoczęcia';
const SELECT_SPACE_TEXT = 'Wybierz miejsce';
const SLOT_VALIDATOR_TEXT = 'Wybór slotu czasowego jest wymagany';
const TITLE_TEXT = 'Sprzedaż';
const UPSELL_TEXT = 'Czy chcesz dodać upsell?';
const CONTINUE_BUYING_TEXT = 'Dodaj rezerwacje do koszyka';

const ADD_NEW_PRODUCT_TEXT = 'Produkty';
const ADD_NEW_EMPTY_RESERVATION_TEXT = 'Rezerwacja bez transakcji';
const RESERVATION_TEXT = 'Rezerwacja';
const DATA_TEXT = 'Dane';
const CLEAR_TEXT = 'Wyczyść dane';
const DELAYED_TRANSACTION_TEXT = 'Transakcja opóźniona';

const FORM_SELECT_DEFAULT_VALUE = -1;

const FormLayout = ({
  errors,
  handleChange,
  handleSubmit,
  happenings,
  isLoading,
  controls,
  mounted,
  selectDate,
  selectHappening,
  selectPeopleCount,
  selectSlot,
  setFieldValue,
  spaces,
  touched,
  values,
  selectSpace,
  getCompanyData,
  setFormData,
  isCompanyDataLoading,
  partnerId,
  products,
  minDate,
  selectedDate,
  basketData,
  handleReset,
  setValues,
  resetState,
  selectUpSell,
}: IFormLayoutProps) => {
  const [withReservation, setWithReservation] = useState(false);
  const [withEmptyReservation, setEmptyReservation] = useState(false);
  const [showProducts, handleShowProducts] = useState(false);
  const BagState = useContext(clearContext);

  useEffect(() => {
    mounted();

    if (BagState) {
      BagState.readLocalStorage();

      if (BagState.userData) {
        setValues(BagState.userData);

        restFormValues();
      }
    }
  }, []);

  const restFormValues = () => {
    setFieldValue('happeningId', -1);
    setFieldValue('slot', null);
    setFieldValue('upsell', false);
    setFieldValue('reservationCheckbox', false);
    setFieldValue('numberOfPeople', 1);
    setFieldValue('date', new Date());
  };

  const handleHappeningChange = (event: ChangeEvent<any>) => {
    handleChange(event);
    setFieldValue('reservationCheckbox', withReservation);

    const value: string | null = event.currentTarget.value;
    if (value) {
      selectHappening(Number(value));
    } else {
      selectHappening(null);
    }
  };

  const handleSpaceChange = (event: ChangeEvent<any>) => {
    handleChange(event);

    const value: string | null = event.currentTarget.value;

    if (value) {
      selectSpace(Number(value));
    } else {
      selectSpace(null);
    }
  };

  const handleNumberOfPeopleChange = (event: ChangeEvent<any>) => {
    event.currentTarget.name = 'numberOfPeople';
    handleChange(event);
    selectPeopleCount(Number(event.currentTarget.value));
  };

  const handleUpSellChange = (event: React.FormEvent<HTMLButtonElement>) => {
    event.currentTarget.name = 'upsell';
    setFieldValue('upsell', !values.upsell);
    selectUpSell(!values.upsell);
  };

  const handleDateChange = (date: Date) => {
    setFieldValue('date', date);
    selectDate(date);
  };

  const handleSlotSelection = (slot: ITimeSlot) => {
    if (values.slot && values.slot.startTime === slot.startTime) {
      setFieldValue('slot', null);
    } else {
      setFieldValue('slot', slot);
    }

    selectSlot(slot);
  };

  const handleClickAddReservationButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    setFieldValue('reservationCheckbox', !withReservation);
    setFieldValue('slot', null);
    setWithReservation(!withReservation);
    handleShowProducts(false);
  };

  const handleClickAddProductButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    handleShowProducts(!showProducts);
    setWithReservation(false);
  };

  const handleFormButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setFieldValue('withFromData', !values.withFromData);
  };

  const renderAddReservationButton = () => {
    if (
      (withEmptyReservation && withReservation) ||
      (withEmptyReservation &&
        BagState &&
        BagState.basketItems &&
        BagState.basketItems.length)
    ) {
      return null;
    }

    return (
      <Button
        icon={withReservation ? 'minus' : 'plus'}
        className={styles.customButton}
        color="primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          handleClickAddReservationButton(event)
        }
      >
        {RESERVATION_TEXT}
      </Button>
    );
  };

  const renderPersonalDataButton = () => {
    if (withEmptyReservation) {
      return null;
    }

    return (
      <Button
        icon={values.withFromData ? 'minus' : 'plus'}
        className={styles.customButton}
        color="primary"
        // onClick={handleFormButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          handleFormButton(event)
        }
      >
        {DATA_TEXT}
      </Button>
    );
  };
  const renderProductsButton = () => {
    if (withEmptyReservation) {
      return null;
    }

    return (
      <Button
        onClick={handleClickAddProductButton}
        type="button"
        color="primary"
        icon={showProducts ? 'minus' : 'plus'}
        className={styles.customButton}
      >
        {ADD_NEW_PRODUCT_TEXT}
      </Button>
    );
  };

  const emptyReservationButton = () => {
    const handleEmptyReservation = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();

      setFieldValue('emptyReservation', !withEmptyReservation);
      setEmptyReservation(!withEmptyReservation);
      setFieldValue('showDiscountForm ', false);

      if (BagState && BagState.basketItems && BagState.basketItems.length) {
        setFieldValue('reservationCheckbox', false);
        setWithReservation(false);
      } else {
        setFieldValue('reservationCheckbox', true);
        setWithReservation(true);
      }
    };

    if (showProducts) {
      return null;
    }

    return (
      <Button
        onClick={handleEmptyReservation}
        type="button"
        color="primary"
        icon={withEmptyReservation ? 'minus' : 'plus'}
        className={styles.customButton}
      >
        {ADD_NEW_EMPTY_RESERVATION_TEXT}
      </Button>
    );
  };

  const renderDiscountButton = () => {
    if (withEmptyReservation) {
      return null;
    }

    return (
      <Button
        onClick={() =>
          setFieldValue('showDiscountForm', !values.showDiscountForm)
        }
        type="button"
        color="primary"
        icon={values.showDiscountForm ? 'minus' : 'plus'}
        className={styles.customButton}
      >
        {DISCOUNT_TEXT}
      </Button>
    );
  };

  const renderForm = () => (
    <Card className={styles.userData}>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label={FIRST_NAME_TEXT}>
            <Form.Input
              error={touched.firstName && errors.firstName}
              invalid={touched.firstName && errors.firstName}
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>

        <Grid.Col>
          <Form.Group label={LAST_NAME_TEXT}>
            <Form.Input
              error={touched.lastName && errors.lastName}
              invalid={touched.lastName && errors.lastName}
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Form.Group label={EMAIL_TEXT}>
            <Form.Input
              error={touched.email && errors.email}
              invalid={touched.email && errors.email}
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>

        <Grid.Col>
          <Form.Group label={PHONE_TEXT}>
            <Form.Input
              error={touched.phone && errors.phone}
              invalid={touched.phone && errors.phone}
              name="phone"
              type="tel"
              value={values.phone}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Form.Group label={DESCRIPTION_TEXT}>
            <Form.Textarea
              error={touched.description && errors.description}
              invalid={touched.description && errors.description}
              name="description"
              value={values.description}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <InvoiceForm
        values={values}
        handleChange={handleChange}
        getCompanyData={getCompanyData}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFormData={setFormData}
        isCompanyDataLoading={isCompanyDataLoading}
      />
      <Button onClick={resetForm}>{CLEAR_TEXT}</Button>
    </Card>
  );

  const renderDiscountForm = () => (
    <Card className={styles.userData}>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label={DISCOUNT_TEXT}>
            <Form.Input
              error={touched.discount && errors.discount}
              invalid={touched.discount && errors.discount}
              name="discount"
              type="text"
              value={values.discount || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group label={PRE_PAID_CODE_TEXT}>
            <Form.Input
              error={touched.prepaidCard && errors.prepaidCard}
              invalid={touched.prepaidCard && errors.prepaidCard}
              name="prepaidCard"
              type="text"
              value={values.prepaidCard || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
    </Card>
  );

  const { isHappeningSelected } = controls;
  const showLoader = isLoading && !isCompanyDataLoading;
  const showComponent = !(isLoading && !isCompanyDataLoading);

  const getCanDisplaySaveButton = (): boolean => {
    // IF show set reservation and is not ready cannot save
    if (values.reservationCheckbox && !controls.showUserForm) {
      return false;
    }
    // When set reservation is ready
    else if (
      controls.showUserForm &&
      spaces.length > 0 &&
      values.reservationCheckbox
    ) {
      return true;
    }
    // When do not set reservation also is some items in bag
    else if (!!(BagState && BagState.basketItems.length)) {
      return true;
    }
    // When selling some products
    else if (
      !!(
        products &&
        products.length &&
        products.some((product) => product.selected && product.count)
      )
    ) {
      return true;
    }
    return false;
  };

  const whenActonIsNotSelected =
    !(products && products.length) &&
    !withReservation &&
    !(BagState && BagState.basketItems.length);

  const handleContinueBuying = () => {
    selectHappening(null);
    setFieldValue('reservationCheckbox', false);
    setWithReservation(false);

    if (BagState && basketData && basketData.id) {
      BagState.addToBag(basketData);
    }

    if (BagState) {
      BagState.handleUserData(values);
      handleReset();
      resetState();

      if (!withEmptyReservation) {
        setTimeout(() => {
          if (BagState.userData) {
            BagState.readLocalStorage();

            setValues(BagState.userData);
            restFormValues();
          }
        }, 1000);
      } else {
        setFieldValue('emptyReservation', true);
      }
    }
  };

  const resetForm = () => {
    handleReset();
    setFieldValue('withFromData', true);
    if (BagState) {
      BagState.handleUserData({});
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{TITLE_TEXT}</h3>
        <span className={styles.buttonList}>
          {renderProductsButton()}
          {renderAddReservationButton()}
          {emptyReservationButton()}
          {renderPersonalDataButton()}
          {renderDiscountButton()}
        </span>
      </div>

      {whenActonIsNotSelected && (
        <div className={styles.info}>
          Wybierz produkt lub rezerwacje aby kontynuować zakup
        </div>
      )}

      {showProducts && <ProductsList handleShowProducts={handleShowProducts} />}

      <Products />

      {showLoader && (
        <Card
          body={
            <div className={styles.loader}>
              <Loader />
            </div>
          }
        />
      )}

      {showComponent ? (
        <>
          {withReservation && (
            <Card className={styles.card}>
              <Filters />
              {!!partnerId && (
                <Form.Group label={SELECT_HAPPENING_TEXT}>
                  <Form.Select
                    error={touched.happeningId && errors.happeningId}
                    invalid={touched.happeningId && errors.happeningId}
                    name="happeningId"
                    onChange={handleHappeningChange}
                    value={values.happeningId || ''}
                  >
                    <option value={FORM_SELECT_DEFAULT_VALUE}>-</option>
                    {happenings.map((happening) => (
                      <option key={happening.id} value={happening.id}>
                        {happening.metadata.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {isHappeningSelected && (
                <>
                  {controls.isPaymentForPerson && (
                    <Form.Group
                      label={`${SELECT_NUMBER_OF_PEOPLE_TEXT} (max. ${
                        controls.sliderLength
                      })`}
                    >
                      <div className={styles.ratio}>
                        <div className={styles.input}>
                          <input
                            type="range"
                            className={styles.slider}
                            step="1"
                            max={controls.sliderLength}
                            min={1}
                            value={values.numberOfPeople}
                            name="numberOfPeople"
                            onChange={handleNumberOfPeopleChange}
                          />
                        </div>
                        <div className={styles.number}>
                          {values.numberOfPeople}
                        </div>

                        {touched.numberOfPeople &&
                          values.numberOfPeople < 1 && (
                            <span className="invalid-feedback">
                              {NUMBER_OF_PEOPLE_VALIDATOR_TEXT}
                            </span>
                          )}
                      </div>
                    </Form.Group>
                  )}
                  <Grid.Row>
                    <div className={styles.datepicker}>
                      <Form.Group label={SELECT_DATE_TEXT}>
                        <DatePicker
                          minDate={minDate}
                          selectedDate={selectedDate || values.date}
                          isDateSelectionAvailable={isHappeningSelected}
                          setDate={handleDateChange}
                        />
                      </Form.Group>
                    </div>
                    <Grid.Col>
                      <Form.Group label={SELECT_SLOT_TEXT}>
                        <TimeSlots
                          isInvalid={
                            isHappeningSelected && touched.slot && errors.slot
                          }
                          isSlotSelectionAvailable={isHappeningSelected}
                          setSlot={handleSlotSelection}
                        />
                        {isHappeningSelected && touched.slot && errors.slot && (
                          <span className="invalid-feedback">
                            {SLOT_VALIDATOR_TEXT}
                          </span>
                        )}
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  {values.slot && (
                    <Form.Group
                      label={`${SELECT_SPACE_TEXT} (${
                        spaces.length > 0 ? spaces.length : 'brak'
                      } dostępnych)`}
                    >
                      <Form.Select
                        className={cn(
                          styles.select,
                          !isHappeningSelected && styles.disabled,
                        )}
                        error={
                          isHappeningSelected &&
                          touched.spaceId &&
                          errors.spaceId
                        }
                        invalid={
                          isHappeningSelected &&
                          touched.spaceId &&
                          errors.spaceId
                        }
                        name="spaceId"
                        onChange={handleSpaceChange}
                        value={controls.selectedSpaceId || ''}
                      >
                        <option value={''}>-</option>
                        {spaces.map((space) => (
                          <option key={space.id} value={space.id}>
                            {space.metadata.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}
                  <Grid.Row>
                    <Grid.Col>
                      {controls.isUpsell && (
                        <Form.Group label={UPSELL_TEXT}>
                          <Form.Checkbox
                            checked={values.upsell}
                            isInline={true}
                            label="Tak"
                            name="upsell"
                            onChange={handleUpSellChange}
                          />
                        </Form.Group>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  {/* <Grid.Row> //TODO: keep it
                    <Grid.Col>
                      <Form.Group label={DISCOUNT_TEXT}>
                        <Form.Input
                          error={
                            touched.singleDiscount && errors.singleDiscount
                          }
                          invalid={
                            touched.singleDiscount && errors.singleDiscount
                          }
                          name="singleDiscount"
                          type="text"
                          value={values.singleDiscount || ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row> */}

                  {!withEmptyReservation && (
                    <Grid.Row>
                      <Grid.Col>
                        {controls.selectedSpaceId && (
                          <Button
                            onClick={handleContinueBuying}
                            type="button"
                            color="primary"
                            icon="save"
                          >
                            {CONTINUE_BUYING_TEXT}
                          </Button>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                </>
              )}
            </Card>
          )}

          <Basket />

          {values.withFromData && !withEmptyReservation && renderForm()}
          {values.showDiscountForm &&
            !withEmptyReservation &&
            renderDiscountForm()}

          {!withEmptyReservation && getCanDisplaySaveButton() && (
            <Grid.Row>
              <Grid.Col>
                <Form.Checkbox
                  checked={values.delayedTransaction}
                  isInline={true}
                  label={DELAYED_TRANSACTION_TEXT}
                  name="delayedTransaction"
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
          )}

          {getCanDisplaySaveButton() && (
            <Button.List>
              <Button color="primary" icon="save" type="submit">
                {SAVE_TEXT}
              </Button>
            </Button.List>
          )}
        </>
      ) : null}
    </form>
  );
};

export default FormLayout;
