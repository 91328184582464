import React, { FC } from 'react';

import { RRule } from 'rrule';
import Date from './components/Date';
import styles from './Preview.module.scss';
import { IPreviewProps } from './Preview.types';

const HOW_MANY_REPEATS = 10;
const TIME_START_TEXT = 'Godzina paczątku';
const DATE_TEXT = 'Data';
const DAY_TEXT = 'Dzień';
const ERROR_TEXT = 'Błąd składni reguły';

const Preview: FC<IPreviewProps> = ({ rrule }) => {
  const ruleForExamples = rrule + `;COUNT=${HOW_MANY_REPEATS}`;

  try {
    const forExamples = RRule.fromString(ruleForExamples);

    return (
      <div className={styles.preview}>
        <table>
          <thead>
            <tr>
              <th>{DAY_TEXT}</th>
              <th>{DATE_TEXT}</th>
              <th>{TIME_START_TEXT}</th>
            </tr>
          </thead>
          <tbody>
            {forExamples.all().map((rule: Date, index: number) => {
              return <Date date={rule} key={index} />;
            })}
          </tbody>
        </table>
      </div>
    );
  } catch (e) {
    return <div>{ERROR_TEXT}</div>;
  }
};

export default Preview;
