import React, { useState } from 'react';

import { Field } from 'formik';
import { Button, Form } from 'tabler-react';

import DatePicker from '@Compo/reusable/DatePicker';
import Days from '../../../Days';
import MyInput from '../../../Input';
import MultiDuration from '../../../MultiDuration';
import Preview from '../../../Preview';
import { CapacityField } from './Components/CapacityField';
import { PriceField } from './Components/PriceField';
import { TimeField } from './Components/TimeField';
import { makeName, makeRRule } from './FormLayout.helpes';
import { IFormLayoutProps } from './FormLayout.types';

import styles from './FormLayout.module.scss';

const RULES_NAME_TEXT = 'Nazwa nowej reguły';
const PROPOSED_NAME_TEXT = ' Proponowana nazwa: ';
const TIME_TEXT = 'Czas obowiązywania reguły';
const RULE_TYPE_TEXT = 'Typ reguły';
const CYCLIC_TEXT = 'Cykliczna';
const ONE_TIME_TEXT = 'Jednorazowa';
const CLICK_TO_CHOSE = 'Kliknij by wybrać';
const MODE_TEXT = 'To jest tryb zaawansowany';
const ADVANCE_TEXT = `Jezeli znasz RRULE wpisz wyrażenie w formacie
zgodnym z RFC-5545. Czas trwania podaj zgodnie z ISO-8601.
Jezżli nie znasz tych formatów, skorzystaj z trybu podstawowego.
Tryb podstawowy nie przenosi ustawionych pól do trybu
rozszerzonego (i odwrotnie).`;
const DURATION_TIME_TEXT = 'Czas trwania';
const RULE_TEXT = 'Reguła RRULE';
const RULE_ERROR_TEXT = ' (Błąd składni!)';
const HIDE_TEXT = 'Ukryj';
const SHOW_TEXT = 'Pokaż';
const ADVANCED_MODE_TEXT = 'tryb zaawansowany';
const SAVE_RULE_TEXT = 'Zapisz regułę';
const REMOVE_RULE_TEXT = 'Usuń regułę';
const CANCEL_TEXT = 'Anuluj';
const NEARLIEST_TIME_TEXT = ' najbliższe terminy';
const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć obniżkę?';
const START_DATE_TEXT = 'Data rozpoczęcia';
const END_DATE_TEXT = 'Data zakończenia';
const CLICK_TEXT = 'Kliknij by wybrać';
const PARTNER_TEXT = 'Partner';
const RULE_TYPE = 'Typ reguły:';
const PRICE_RULE_TEXT = 'Reguła cenowa';
const TIME_RULE_TEXT = 'Reguła czasowa';
const CAPACITY_RULE_TEXT = 'Reguła pojemnościowa';

export const FormLayout = (props: IFormLayoutProps) => {
  const [preview, handlePreview] = useState<boolean>(false);
  const {
    handleSubmit,
    values,
    dirty,
    handleActiveConfiguration,
    handleChange,
    setFieldValue,
    handleBlur,
    deleteConfiguration,
    partners,
    touched,
    errors,
  } = props;

  const {
    advanced,
    fromTime,
    duration,
    id,
    title,
    cyclic,
    days,
    until,
    newConfiguration,
    validTo,
    validFrom,
  } = values;

  const rrule = makeRRule(values);
  const rruleName = makeName(values, rrule);

  const catchHandleClose = () => {
    handleActiveConfiguration(null);
  };

  const confirmModal = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (window.confirm(ARE_YOU_SURE_TEXT) && id) {
      deleteConfiguration(id);
    }
  };

  const catchRuleSetPartner = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setFieldValue('partnerId', value);
  };

  const catchRuleType = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    setFieldValue('ruleType', value);
  };

  const RRULE_TEXT = `${RULE_TEXT} ${!rruleName ? RULE_ERROR_TEXT : ''}`;

  const RULE_NAME_TEXT = `${RULES_NAME_TEXT}  ${id ? '- tryb edycji' : ''}`;

  const renderPriceRuleExtraFields = () => {
    if (values && values.ruleType === 'price') {
      return <PriceField {...props} />;
    }
    return null;
  };

  const renderTimeRuleExtraFields = () => {
    if (values && values.ruleType === 'time') {
      return <TimeField {...props} />;
    }
    return null;
  };

  const renderCapacityRuleExtraFields = () => {
    if (values && values.ruleType === 'capacity') {
      return <CapacityField {...props} />;
    }
    return null;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Button
          color="warning"
          size="sm"
          className={styles.close}
          icon="x"
          onClick={catchHandleClose}
        />

        <Form.Group label={RULE_NAME_TEXT}>
          <Field
            placeholder={rruleName || ''}
            name="title"
            type="text"
            value={title || name}
            onChange={handleChange}
            component={MyInput}
          />
          {newConfiguration && newConfiguration !== rruleName && dirty && (
            <div className={styles.useName}>
              {PROPOSED_NAME_TEXT}
              <span
                onClick={() => {
                  setFieldValue('title', rruleName);
                }}
              >
                {rruleName}
              </span>
            </div>
          )}
        </Form.Group>

        <Form.Group label={PARTNER_TEXT}>
          <Form.Select
            value={values.partnerId || ''}
            onChange={catchRuleSetPartner}
            name={'partnerId'}
            invalid={touched.partnerId && errors.partnerId}
          >
            <option value={-1}>-</option>

            {partners &&
              partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name || ''}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <DatePicker
          label={START_DATE_TEXT}
          name="validFrom"
          onBlur={props.handleBlur}
          onChange={props.setFieldValue}
          placeholder={CLICK_TEXT}
          value={validFrom}
          invalid={props.touched.validFrom && props.errors.validFrom}
          feedback={props.errors.validFrom}
          clearable={true}
        />

        <DatePicker
          label={END_DATE_TEXT}
          name="validTo"
          onBlur={props.handleBlur}
          onChange={props.setFieldValue}
          placeholder={CLICK_TEXT}
          value={validTo}
          invalid={props.touched.validTo && props.errors.validTo}
          feedback={props.errors.validTo}
          clearable={true}
        />

        {!advanced && (
          <>
            <Form.Group label={TIME_TEXT}>
              <MultiDuration
                onChange={handleChange}
                names={['fromTime', 'durationToString']}
                values={[fromTime, duration.toString()]}
              />
            </Form.Group>
            <Form.Group label={RULE_TYPE_TEXT}>
              <div className={styles.cyclic}>
                <Form.Radio
                  name="cyclic"
                  label={CYCLIC_TEXT}
                  checked={cyclic}
                  onChange={() => {
                    setFieldValue('cyclic', true);
                  }}
                />
                <Form.Radio
                  name="cyclic"
                  label={ONE_TIME_TEXT}
                  checked={!cyclic}
                  onChange={() => {
                    setFieldValue('cyclic', false);
                  }}
                />
              </div>
            </Form.Group>
            {cyclic && (
              <>
                <Days name="days" onChange={handleChange} value={days} />
                <input type="hidden" defaultValue={days} />
              </>
            )}

            {!cyclic && (
              <DatePicker
                name="until"
                onBlur={handleBlur}
                onChange={setFieldValue}
                placeholder={CLICK_TO_CHOSE}
                value={until}
              />
            )}
          </>
        )}

        {advanced && (
          <>
            <div className={styles.advanced}>
              <h4>{MODE_TEXT}</h4>
              <p>{ADVANCE_TEXT}</p>
            </div>

            <Form.Group label={DURATION_TIME_TEXT}>
              <Field
                name="durationAdvanced"
                placeholder="np.: 8h0m"
                type="text"
                component={MyInput}
              />
            </Form.Group>

            <Form.Group label={RRULE_TEXT}>
              <Field
                placeholder="RRULE:"
                name="ruleAdvanced"
                type="text"
                component={MyInput}
              />
            </Form.Group>
          </>
        )}

        <div className={styles.preview}>
          <div
            className={styles.switch}
            onClick={() => {
              setFieldValue('advanced', !advanced);
            }}
          >
            {advanced ? HIDE_TEXT : SHOW_TEXT} {ADVANCED_MODE_TEXT}
          </div>
        </div>

        <Form.Group label={RULE_TYPE}>
          <Form.Select
            value={values.ruleType || ''}
            onChange={catchRuleType}
            name={'ruleType'}
            invalid={touched.ruleType && errors.ruleType}
          >
            <option value={-1}>-</option>

            <option value={'price'}>{PRICE_RULE_TEXT}</option>
            <option value={'time'}>{TIME_RULE_TEXT}</option>
            <option value={'capacity'}>{CAPACITY_RULE_TEXT}</option>
          </Form.Select>
        </Form.Group>

        {renderPriceRuleExtraFields()}

        {renderTimeRuleExtraFields()}

        {renderCapacityRuleExtraFields()}

        <Button.List>
          <Button icon="check" color="primary" type="submit">
            {SAVE_RULE_TEXT}
          </Button>

          {id && (
            <Button icon="x" color="danger" onClick={confirmModal}>
              {REMOVE_RULE_TEXT}
            </Button>
          )}

          <Button icon="x" color="warning" onClick={catchHandleClose}>
            {CANCEL_TEXT}
          </Button>
        </Button.List>
      </form>

      <div className={styles.preview}>
        <div className={styles.switch} onClick={() => handlePreview(!preview)}>
          {preview ? HIDE_TEXT : SHOW_TEXT} {NEARLIEST_TIME_TEXT}
        </div>

        {preview && <Preview rrule={rrule} />}
      </div>
    </>
  );
};

export default FormLayout;
