// tslint:disable:jsx-no-lambda
import React, { FC, useState } from 'react';

import { Button } from 'tabler-react';
import ImagesLibrary from './../../../ImagesLibrary';
import styles from './Image.module.scss';
import { IImageProps } from './Image.types';

const Image: FC<IImageProps> = ({ url, setUrl,isEditUnAvailable }) => {
  const [shouldShowLibrary, showLibrary] = useState(false);

  return (
    <>
      <div className={styles.image}>
        {url && <img className={styles.img} src={url}/>}
        {!url && <div className={styles.noImage}>Brak obrazu</div>}

        {!isEditUnAvailable &&
        <div className={styles.change}>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              showLibrary(!shouldShowLibrary);
            }}
          >
            Zmień
          </Button>
        </div>
        }
      </div>

      {shouldShowLibrary && (
        <ImagesLibrary
          key={new Date().toISOString()}
          url={url}
          setUrl={(_url) => {
            setUrl(_url);
            showLibrary(false);
          }}
          close={() => {
            showLibrary(false);
          }}
        />
      )}
    </>
  );
};

export default Image;
