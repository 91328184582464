import React, { Component } from 'react';

import { Button, Card, Grid, Loader } from 'tabler-react';
import PrintersModal from './components/PrinterModal';
import PrinterTable from './components/PrinterTable';
import { IPrinterAdminProps } from './PrinterAdmin.types';

const ADMIN_TEXT = 'Administracja drukarek';
const ADD_NEW_Printer_TEXT = 'Dodaj drukarke';

class PrinterAdmin extends Component<IPrinterAdminProps> {
  public componentDidMount = () => {
    this.props.printersMounted();
  };

  public render() {
    const {
      addPrinter,
      showModal,
      isLoading,
      permissionPrintersRead,
      permissionPrintersView,
      permissionPrintersWrite,
    } = this.props;

    return (
      <>
        {showModal && <PrintersModal />}
        {permissionPrintersRead && (
          <section>
            <Grid.Row className="mb-5">
              <Grid.Col>
                <h3>{ADMIN_TEXT}</h3>
              </Grid.Col>

              {permissionPrintersWrite && (
                <Grid.Col>
                  <Button.List align="right">
                    <Button
                      onClick={addPrinter}
                      type="button"
                      color="primary"
                      icon="plus"
                    >
                      {ADD_NEW_Printer_TEXT}
                    </Button>
                  </Button.List>
                </Grid.Col>
              )}
            </Grid.Row>
            {isLoading && <Card body={<Loader />} />}

            {!isLoading && permissionPrintersView && (
              <Grid.Row cards={true} deck={true}>
                <Grid.Col width={12}>
                  <Card>
                    <PrinterTable />
                  </Card>
                </Grid.Col>
              </Grid.Row>
            )}
          </section>
        )}
      </>
    );
  }
}

export default PrinterAdmin;
