import { date, number, object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  code: string()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  endDate: date().nullable(),
  happeningId: number().nullable(),
  partnerId: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .nullable(),
  percentageValue: number().nullable(),
  startDate: date()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  transactionsLimit: number().nullable(),
  value: number().nullable(),
};

export const DiscountSchema = object().shape(configuration);
