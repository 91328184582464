import { getType } from 'typesafe-actions';

import {
  getDiscounts,
  handleActivePage,
  resetState,
  setActiveFilter,
  updateSearchText,
} from '../actions';
import { IAction, IDiscountsReducer } from '../types';

const initialState: IDiscountsReducer = {
  activePage: 0,
  items: [],
  perPage: 30,
  searchText: '',
  totalCount: 0,
};

const happeningsReducer = (
  state: IDiscountsReducer = initialState,
  action: IAction,
): IDiscountsReducer => {
  switch (action.type) {
    case getType(getDiscounts.success):
      return {
        ...state,
        ...action.payload,
      };
    case getType(resetState):
      return initialState;

    case getType(handleActivePage):
      return {
        ...state,
        activePage: action.payload,
      };

    case getType(updateSearchText):
      return {
        ...state,
        searchText: action.payload,
      };

    case getType(setActiveFilter):
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
};

export default happeningsReducer;
