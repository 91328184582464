import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getDiscounts } from '@Model/discounts/actions';
import { get } from '@Model/discounts/selectors';
import { getHappenings } from '@Model/happenings/actions';
import _Store from '@Store';
import { remove, resetState, save, saveDiscount } from '../actions';
import { IDiscountBody } from '../types';

export const requestSaveDiscountOnSaveAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(save)),
    mergeMap$((action) => {
      return of$(resetState(), saveDiscount.request(action.payload));
    }),
  );
};

export const saveDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountApi },
) => {
  return action$.pipe(
    filter$(isActionOf(saveDiscount.request)),
    mergeMap$((action) => {
      const {
        code,
        endDate,
        startDate,
        happeningId,
        transactionsLimit,
        value,
        isPercentage,
        id,
        partnerId,
      } = action.payload;

      const body: IDiscountBody = {
        code,
        dateRange: {
          endDate,
          startDate,
        },
        endDate,
        happeningId:
          happeningId && Number(happeningId) !== -1
            ? Number(happeningId)
            : null,
        id,
        partnerId: Number(partnerId),
        percentageValue: null,
        startDate,
        transactionsLimit: transactionsLimit ? Number(transactionsLimit) : null,
        value: null,
      };

      if (isPercentage) {
        body.percentageValue = Number(value);
      } else {
        body.value = Number(value);
      }

      return from$(discountApi.addSingleDiscount(body)).pipe(
        mergeMap$(() => {
          return of$(
            resetState(),
            saveDiscount.success(),
            getDiscounts.request(),
            getHappenings.request(),
          );
        }),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error), saveDiscount.failure(error));
        }),
      );
    }),
  );
};

export const removeDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountApi },
) => {
  return action$.pipe(
    filter$(isActionOf(remove)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(discountApi.removeDiscount(action.payload || '')).pipe(
        mergeMap$(() => {
          const discounts = get(state);
          const discountCode = action.payload;

          if (discounts && discounts.items) {
            const items = discounts.items.filter((item) => {
              return item.code !== discountCode;
            });

            return [
              getDiscounts.success({
                items,
                totalCount: items.length,
              }),
            ];
          }

          return EMPTY$;
        }),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error));
        }),
      );
    }),
  );
};
