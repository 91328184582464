import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import DiscountForm from '../DiscountForm';

const DiscountModal = () => {
  return (
    <Portal>
      <Modal>
        <DiscountForm />
      </Modal>
    </Portal>
  );
};
export default DiscountModal;
