import { IReservationPrintData } from '@Model/reservation/types';
import {
  _CANCEL_DELAYED_TRANSACTIONS_REQUEST,
  _CANCEL_DELAYED_TRANSACTIONS_SUCCESS,
  _CHECK_RESERVATION_FAILURE,
  _CHECK_RESERVATION_REQUEST,
  _CHECK_RESERVATION_SUCCESS,
  _CHECK_RESET,
  _GET_DELAYED_TRANSACTIONS_FAILURE,
  _GET_DELAYED_TRANSACTIONS_REQUEST,
  _GET_DELAYED_TRANSACTIONS_SUCCESS,
  _GET_DETAILS_FAILURE,
  _GET_DETAILS_REQUEST,
  _GET_DETAILS_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_RESERVATION_EMPIK_DETAILS_FAILURE,
  _GET_RESERVATION_EMPIK_DETAILS_REQUEST,
  _GET_RESERVATION_EMPIK_DETAILS_SUCCESS,
  _GET_SUCCESS,
  _SET_FILTERS,
  _UPDATE_RESERVATION_FAILURE,
  _UPDATE_RESERVATION_REQUEST,
  _UPDATE_RESERVATION_SUCCESS,
  CATCH_CANCEL_DELAYED_TRANSACTION,
  CHANGE_DATE,
  CHECK_RESERVATION_DATE,
  CLEAR_FILTERS,
  CLEAR_FILTERS_REQUEST,
  EMPIK_RESERVATION_DETAILS_RESET,
  GET_TICKET,
  HANDLE_ACTIVE_PAGE,
  MOUNTED,
  RELOAD,
  RESEND_TICKET,
  RESET_SELECTION,
  RESET_SELECTION_LIST,
  SAVE_RESERVATION,
  SELECT_ALL_FILTERS,
  SELECT_RESERVATION,
  SELECT_RESERVATION_LIST,
  SWITCH_FILTER,
} from '@Model/reservations/constants/actions';
import {
  IDelayedTransactionsSuccessPayload,
  IReservationDateCheck,
  IReservationDetailsView,
  IReservationEvent,
  IReservationFilter,
  IReservationFull,
  IReservationsDateRange,
  IReservationsFiltersSwitchPayload,
  IReservationsSuccessPayload,
} from '@Model/reservations/types';
import {
  createAction,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';
import { CATCH_DELAYED_TRANSACTIONS } from './../constants/actions';

export const getReservations = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IReservationsSuccessPayload, Error>();

export const mounted = createStandardAction(MOUNTED)();
export const clearFiltersRequest = createStandardAction(
  CLEAR_FILTERS_REQUEST,
)();
export const clearFilters = createStandardAction(CLEAR_FILTERS)<
  IReservationFilter[]
>();
export const selectAllFilters = createStandardAction(SELECT_ALL_FILTERS)();

export const setFilters = createStandardAction(_SET_FILTERS)<
  IReservationFilter[]
>();

export const switchFilter = createAction(SWITCH_FILTER, (action) => {
  return (happeningId: number, spaceId?: number) => {
    const payload: IReservationsFiltersSwitchPayload = {
      happeningId,
      spaceId,
    };

    return action(payload);
  };
});

export const reload = createStandardAction(RELOAD)<IReservationsDateRange>();
export const changeReservationDate = createStandardAction(CHANGE_DATE)<Date>();

export const selectReservation = createStandardAction(SELECT_RESERVATION)<
  number
>();

export const selectReservationList = createStandardAction(
  SELECT_RESERVATION_LIST,
)<IReservationEvent[]>();

export const getReservationDetails = createAsyncAction(
  _GET_DETAILS_REQUEST,
  _GET_DETAILS_SUCCESS,
  _GET_DETAILS_FAILURE,
)<number, IReservationFull, Error>();

export const resetSelection = createStandardAction(RESET_SELECTION)();

export const resetSelectionList = createStandardAction(RESET_SELECTION_LIST)();

export const resentTicket = createStandardAction(RESEND_TICKET)<string>();

export const getTicket = createStandardAction(GET_TICKET)<string>();

export const saveReservation = createStandardAction(SAVE_RESERVATION)<
  IReservationDetailsView
>();

export const updateReservation = createAsyncAction(
  _UPDATE_RESERVATION_REQUEST,
  _UPDATE_RESERVATION_SUCCESS,
  _UPDATE_RESERVATION_FAILURE,
)<IReservationDetailsView, undefined, Error>();

export const checkReservationDate = createStandardAction(
  CHECK_RESERVATION_DATE,
)<Date>();

export const checkReservation = createAsyncAction(
  _CHECK_RESERVATION_REQUEST,
  _CHECK_RESERVATION_SUCCESS,
  _CHECK_RESERVATION_FAILURE,
)<Date, IReservationDateCheck, Error>();

export const getEmpikReservationDetails = createAsyncAction(
  _GET_RESERVATION_EMPIK_DETAILS_REQUEST,
  _GET_RESERVATION_EMPIK_DETAILS_SUCCESS,
  _GET_RESERVATION_EMPIK_DETAILS_FAILURE,
)<string, IReservationPrintData, Error>();

export const resetCheck = createStandardAction(_CHECK_RESET)();

export const resetEmpikReservationDetails = createStandardAction(
  EMPIK_RESERVATION_DETAILS_RESET,
)();

// DELAYED TRANSACTIONS
export const catchDelayedTransactions = createStandardAction(
  CATCH_DELAYED_TRANSACTIONS,
)();

export const getDelayedTransactions = createAsyncAction(
  _GET_DELAYED_TRANSACTIONS_REQUEST,
  _GET_DELAYED_TRANSACTIONS_SUCCESS,
  _GET_DELAYED_TRANSACTIONS_FAILURE,
)<undefined, IDelayedTransactionsSuccessPayload, Error>();

export const catchCancelDelayedTransaction = createStandardAction(
  CATCH_CANCEL_DELAYED_TRANSACTION,
)<string>();

export const cancelDelayedTransactions = createAsyncAction(
  _CANCEL_DELAYED_TRANSACTIONS_REQUEST,
  _CANCEL_DELAYED_TRANSACTIONS_SUCCESS,
  _CANCEL_DELAYED_TRANSACTIONS_SUCCESS,
)<string, undefined, Error>();

export const handleActivePage = createStandardAction(HANDLE_ACTIVE_PAGE)<
  number
>();
