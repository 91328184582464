import React from 'react';

import cn from 'classnames';

import getColorByIndex from '@Misc/helpers/getColorByIndex';
import { IProductsListProps } from './ProductsList.types';

import styles from './ProductsList.module.scss';

const ProductsList = ({
  allProducts,
  addProduct,
  handleShowProducts,
}: IProductsListProps) => {
  const handleProductChange = (value: number) => {
    const foundedProduct = allProducts.find((product) => product.id === value);
    if (foundedProduct) {
      addProduct(foundedProduct);
      handleShowProducts(true);
    }
  };

  if (allProducts && allProducts.length) {
    return (
      <>
        <div className={styles.wrapper}>
          {allProducts.map((product, index) => (
            <div
              key={product.id}
              onClick={() => handleProductChange(product.id)}
              className={cn(styles.tile)}
              style={{ backgroundColor: getColorByIndex(index) }}
            >
              <p className={styles.productName}>{product.name}</p>
            </div>
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default ProductsList;
