import { getReservationPrintData } from '@Model/reservation/actions';
import { getType } from 'typesafe-actions';
import { IAction, IReservationPrintDataReducer } from '../types';

const initialState: IReservationPrintDataReducer = {
  billId: null,
  bookingIdentifier: null,
  details: {
    firstName: '',
    lastName: '',
  },
  invoice: null,
  paymenType: '',
  revenue: 0,
  serviceFee: 0,
  shipment: null,
  shipmentPrice: 0,
  status: '',
  transactionItems: [],
  transactionUuid: '',
  user: {
    userEmail: '',
    userFirstName: '',
    userLastName: '',
  },
};

const reservationPrintDataReducer = (
  state: IReservationPrintDataReducer = initialState,
  action: IAction,
): IReservationPrintDataReducer => {
  switch (action.type) {
    case getType(getReservationPrintData.success):
      return action.payload;

    default:
      return state;
  }
};

export default reservationPrintDataReducer;
