import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { endSession, runSession } from '@Model/authorization/actions';
import { getUserInfo } from '@Model/authorization/selectors';
import {
  catchEndSession,
  catchSession,
  endPrinterSession,
  endPrinterSessionById,
  endPrinterSessionWithOutCashState,
  endPrinterSessionWithOutCashStateById,
  getPaymentSession,
  getPrinters,
  getPrinterStatus,
  startPingingPrinter,
  startSession,
} from '@Model/printer/actions';
import { getPinging } from '@Model/printer/selectors';
import { allPermissions } from '@Model/state/constants/permissions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IRunSessionRequest } from '@Services/$authorization-api/types';
import _Store from '@Store';
import { getPrinters as getPrintersSelector } from '../selectors';
import getUserPrinter from '../selectors/getUserPrinter';
import { IStartPrinterSessionPayload } from '../types';

const PRINT_SESSION_ERROR_TEXT = 'Błąd ustawienia sesji drukarki';
const END_SESSION_TEXT = 'Sesja zakończona pomyślnie!';

export const catchStartSession: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSession)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(startSession.request(action.payload));
    }),
  );
};

export const startSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(startSession.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { printerId } = action.payload;
      let { startCash } = action.payload;
      const { id, session } = getUserInfo(state);

      const sessionPayload: IRunSessionRequest = {
        printerId: Number(printerId),
        startCash: Number(startCash),
        userId: Number(id),
      };

      const printers = getPrintersSelector(state);
      const printer =
        printers.find((item) => item.id === sessionPayload.printerId) || null;

      if (!printer) {
        return EMPTY$;
      }

      const { saleUrl } = printer;

      const { first_name, last_name } = getUserInfo(state);

      if (startCash) {
        startCash = Math.round(startCash * 100);
      }

      const startSessionPayload: IStartPrinterSessionPayload = [
        {
          cmd: 'login',
          params: `na,${first_name} ${last_name}`,
        },
        { cmd: 'cash', params: `kw,${startCash}\nwp,T` },
        {
          cmd: 'shiftrep',
          params: 'sh,',
        },
      ];

      const { permissions } = getUserInfo(state);
      const pinging = getPinging(state);
      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer,
        )
      ) {
        return of$(runSession(sessionPayload), startSession.success());
      }

      return from$(
        printerApi.startSession(saleUrl, startSessionPayload, session),
      ).pipe(
        mergeMap$(() => {
          if (!pinging) {
            return of$(
              runSession(sessionPayload),
              startSession.success(),
              startPingingPrinter(sessionPayload),
            );
          }
          return of$(runSession(sessionPayload), startSession.success());
        }),

        catchError$((error: Error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            startSession.failure(error),
          );
        }),
      );
    }),
  );
};

export const endSessionWithOutCashState: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionWithOutCashState.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const PENNY_MULTIPLIER = 100;

      const { payload } = action;

      const { saleUrl } = userPrinter;

      const { session } = getUserInfo(state);

      const getEndSessionPayload = () => {
        if (payload && payload.cashSummary) {
          const { startCash, endCash } = payload.cashSummary;
          return [
            { cmd: 'cash', params: `kw,0\nwp,N` },
            { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },

            { cmd: 'formstart', params: 'fn,200\nfh,48' },
            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Raport systemowej sesji',
            },
            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Poczatkowa kwota:',
            },
            {
              cmd: 'formline',
              params: `fn,200\nfl,113\ns1,${startCash / PENNY_MULTIPLIER}zl`,
            },

            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Koncowa kwota:',
            },
            {
              cmd: 'formline',
              params: `fn,200\nfl,113\ns1,${endCash / PENNY_MULTIPLIER}zl`,
            },
            { cmd: 'formend', params: 'fn,200' },

            { cmd: 'logout' },
          ];
        }

        return [
          { cmd: 'cash', params: `kw,0\nwp,N` },
          { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
          { cmd: 'logout' },
        ];
      };

      const endSessionPayload: IStartPrinterSessionPayload = getEndSessionPayload();
      return from$(
        printerApi.startSession(saleUrl, endSessionPayload, session),
      ).pipe(
        mergeMap$(() => {
          return [
            endSession(),
            endPrinterSession.success(),
            endPrinterSessionWithOutCashState.success(),
          ];
        }),
        catchError$((error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSession.failure(error),
            endPrinterSessionWithOutCashState.failure(error),
          );
        }),
      );
    }),
  );
};

export const endSessionByIdWithoutCasStateWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionWithOutCashStateById.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload,
      );

      const { session } = getUserInfo(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      const endSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'cash', params: `kw,0\nwp,N` },
        { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
        { cmd: 'logout' },
      ];

      return from$(
        printerApi.startSession(saleUrl, endSessionPayload, session),
      ).pipe(
        mergeMap$(() => {
          return of$(
            endPrinterSessionById.success(),
            endPrinterSessionWithOutCashStateById.success(),
            addToast(END_SESSION_TEXT, TYPE_SUCCESS),
            getPrinters.request(),
          );
        }),
        catchError$(() => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT)),
            endPrinterSessionWithOutCashStateById.failure(
              new Error(PRINT_SESSION_ERROR_TEXT),
            ),
          );
        }),
      );
    }),
  );
};

export const catchEndSessionWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchEndSession)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(getPaymentSession.request());
    }),
  );
};

export const endSessionByIdWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionById.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload,
      );

      const { session } = getUserInfo(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      const cashStatusPayload = [{ cmd: 'scashstate' }];

      return from$(
        printerApi.getCashStatus(saleUrl, cashStatusPayload, session),
      ).pipe(
        mergeMap$((data) => {
          if (
            data.ok &&
            data.hits.length &&
            data.hits[0] &&
            data.hits[0].results &&
            data.hits[0].results.length &&
            data.hits[0].results[0].cs
          ) {
            const cashBask = data.hits[0].results[0].cs;

            const endSessionPayload: IStartPrinterSessionPayload = [
              { cmd: 'cash', params: `kw,${cashBask}\nwp,N` },
              { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
              { cmd: 'logout' },
            ];

            return from$(
              printerApi.startSession(saleUrl, endSessionPayload, session),
            ).pipe(
              mergeMap$(() => {
                return of$(
                  endPrinterSessionById.success(),
                  addToast(END_SESSION_TEXT, TYPE_SUCCESS),
                  getPrinters.request(),
                );
              }),
            );
          }
          return of$(
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT)),
          );
        }),

        catchError$(() => {
          const firmware = userPrinter.firmware || '1.0.0';

          if (firmware === '1.0.0') {
            return of$(
              endPrinterSessionWithOutCashStateById.request(action.payload),
            );
          }

          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT)),
          );
        }),
      );
    }),
  );
};

export const startPingingPrinterWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(startPingingPrinter)),
    mergeMap$((action) => {
      return of$(getPrinterStatus.request(action.payload));
    }),
  );
};

export const fetchPrinterStatusWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  let status = false;
  return action$.pipe(
    filter$(isActionOf(getPrinterStatus.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { session } = getUserInfo(state);

      if (!action.payload.printerId || !session) {
        return EMPTY$;
      }
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload.printerId,
      );

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      return from$(printerApi.getPrinterStatus(saleUrl)).pipe(
        mergeMap$(() => {
          if (status) {
            status = false;
            printerApi.reportBug(
              new Error(`${saleUrl} - up`),
              JSON.stringify(''),
              saleUrl,
              '',
            );
            return of$(getPrinterStatus.request(action.payload)).pipe(
              delay(15000),
            );
          }
          return of$(getPrinterStatus.request(action.payload)).pipe(
            delay(15000),
          );
        }),

        catchError$(() => {
          if (!status) {
            printerApi.reportBug(
              new Error(`${saleUrl} - down`),
              JSON.stringify(''),
              saleUrl,
              '',
            );
            status = true;
            return of$(getPrinterStatus.request(action.payload)).pipe(
              delay(15000),
            );
          }

          return of$(getPrinterStatus.request(action.payload)).pipe(
            delay(15000),
          );
        }),
      );
    }),
  );
};
