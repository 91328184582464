import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _DELETE_FAILURE,
  _DELETE_REQUEST,
  _DELETE_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _SAVE_FAILURE,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  ADD_NEW_CONFIGURATION,
  CATCH_SAVE,
  CATCH_SAVE_V2,
  DELETE_CONFIGURATION,
  GET_CONFIGURATIONS,
  HANDLE_ACTIVE_CONFIGURATION,
  HANDLE_CONFIGURATIONS_ACTIVE_PAGE,
  HANDLE_RULE_TYPE_PARAM,
  HANDLE_SEARCH_PARAM,
  MOUNTED,
  RESET_STATE,
} from './../constants/actions';
import {
  IConfiguration,
  IConfigurationsSuccessPayload,
  TRuleType,
} from './../types';

export const getConfigurations = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IConfigurationsSuccessPayload, Error>();

export const deleteConfiguration = createAsyncAction(
  _DELETE_REQUEST,
  _DELETE_SUCCESS,
  _DELETE_FAILURE,
)<undefined, undefined, Error>();

export const mounted = createStandardAction(MOUNTED)();
export const getConfiguration = createStandardAction(GET_CONFIGURATIONS)();
export const catchDeleteConfiguration = createStandardAction(
  DELETE_CONFIGURATION,
)<string>();

export const handleConfigurationsActivePage = createStandardAction(
  HANDLE_CONFIGURATIONS_ACTIVE_PAGE,
)<number>();

export const handleSearchParam = createStandardAction(HANDLE_SEARCH_PARAM)<
  string
>();
export const handleRuleTypeParam = createStandardAction(HANDLE_RULE_TYPE_PARAM)<
  TRuleType
>();

export const handleActiveConfiguration = createStandardAction(
  HANDLE_ACTIVE_CONFIGURATION,
)<IConfiguration | null>();

export const addNewConfiguration = createStandardAction(
  ADD_NEW_CONFIGURATION,
)();

export const resetState = createStandardAction(RESET_STATE)();

export const saveConfiguration = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE,
)<IConfiguration, undefined, Error>();

export const catchSaveConfiguration = createStandardAction(CATCH_SAVE)<
  IConfiguration
>();
export const catchSaveConfigurationV2 = createStandardAction(CATCH_SAVE_V2)<{
  configuration: IConfiguration;
  type: string;
}>();
