import React, { ChangeEvent, Component, createRef, KeyboardEvent } from 'react';

import cn from 'classnames';
import { Form } from 'tabler-react';
import * as yup from 'yup';
import styles from './EditableField.module.scss';
import {
  IEditableFieldProps,
  IEditableFieldState,
} from './EditableField.types';

class EditableField extends Component<
  IEditableFieldProps,
  IEditableFieldState
> {
  protected ref = createRef<HTMLInputElement>();

  constructor(props: IEditableFieldProps) {
    super(props);

    this.state = {
      editMode: false,
      errorMessage: null,
      value: props.defaultValue.toString(),
    };

    this.onChange = this.onChange.bind(this);
    this.openEditMode = this.openEditMode.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onEscape = this.onEscape.bind(this);
  }

  public render() {
    if (this.props.textarea) {
      return (
        <Form.Textarea
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          {...this.props.textareaProps}
        />
      );
    }

    return this.renderInput();
  }

  protected renderInput() {
    const classString = cn(styles.wrapper, {
      [styles.medium]: this.props.size === 'medium',
      [styles.big]: this.props.size === 'big',
    });

    if (this.state.editMode) {
      return (
        <div className={classString}>
          <input
            placeholder={this.props.defaultValue.toString()}
            value={this.state.value}
            onChange={this.onChange}
            onKeyPress={this.onEnter}
            onBlur={this.onBlur}
            className={styles.input}
            ref={this.ref}
            onKeyUp={this.onEscape}
          />
          {this.state.errorMessage && (
            <div className={styles.error}>{this.state.errorMessage}</div>
          )}
        </div>
      );
    }

    return (
      <div className={classString}>
        <div className={styles.value} onClick={this.openEditMode}>
          {this.state.value || <span>&nbsp;</span>}
        </div>
      </div>
    );
  }

  protected onChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: event.target.value,
    });
  }

  protected openEditMode() {
    this.setState(
      {
        editMode: true,
      },
      () => {
        setTimeout(() => {
          if (this.ref.current) {
            const elem = this.ref.current;

            elem.focus();
            elem.setSelectionRange(0, elem.value.length);
          }
        }, 100);
      },
    );
  }

  protected onEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      const schema = yup.object().shape({
        field: this.props.validator,
      });

      schema
        .validate({ field: this.state.value })
        .then((value) => {
          this.setState(
            {
              editMode: false,
              errorMessage: null,
            },
            () => {
              this.propagateValue();
            },
          );
        })
        .catch((err) => {
          this.setState({
            errorMessage: err.message,
          });
        });
    }
  }

  protected onEscape(event: KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 27) {
      this.setState({
        editMode: false,
        errorMessage: null,
        value: this.props.defaultValue.toString(),
      });
    }
  }

  protected onBlur() {
    const schema = yup.object().shape({
      field: this.props.validator,
    });

    if (this.props.canBeEmpty) {
      if (this.state.value === '' || this.state.value === '0') {
        this.setState(
          {
            editMode: false,
            errorMessage: null,
            value: '',
          },
          () => {
            this.propagateValue();
          },
        );

        return;
      }
    }

    schema
      .validate({ field: this.state.value })
      .then((value) => {
        this.setState(
          {
            editMode: false,
            errorMessage: null,
          },
          () => {
            this.propagateValue();
          },
        );
      })
      .catch((err) => {
        this.setState({
          editMode: false,
          errorMessage: null,
          value: this.props.defaultValue.toString(),
        });
      });
  }

  protected propagateValue() {
    this.props.onChange(this.props.name, this.state.value);
  }
}

export default EditableField;
