import React from 'react';

import { Grid } from 'tabler-react';

import FilterItem from '../FilterItem';
import { IFiltersProps } from './Filters';

import styles from './Filters.module.scss';

const Filters = ({ availableHappenings, switchFilter }: IFiltersProps) => {
  const onFilterChange = (happeningId: number, spaceId?: number) => {
    switchFilter(happeningId, spaceId);

    if (localStorage) {
      localStorage.setItem('filters', JSON.stringify(availableHappenings));
    }
  };

  return (
    <section>
      <Grid.Row>
        <Grid.Col>
          <div className={styles.filters}>
            {availableHappenings.map((happening) => (
              <FilterItem
                happening={happening}
                key={happening.id}
                switchFilter={onFilterChange}
              />
            ))}
          </div>
        </Grid.Col>
      </Grid.Row>
    </section>
  );
};

export default Filters;
