// Private
export const _GET_REQUEST = 'Zagrywki/products/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/products/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/products/_GET_FAILURE';
// Private
export const _GET_ALL_REQUEST = 'Zagrywki/products/_GET_ALL_REQUEST';
export const _GET_ALL_SUCCESS = 'Zagrywki/products/_GET_ALL_SUCCESS';
export const _GET_ALL_FAILURE = 'Zagrywki/products/_GET_ALL_FAILURE';

export const ADD_PRODUCT = 'Zagrywki/products/ADD_PRODUCT';
export const REMOVE_PRODUCT = 'Zagrywki/products/REMOVE_PRODUCT';
export const SELECT_PRODUCT = 'Zagrywki/products/SELECT_PRODUCT';
export const UN_SELECT_PRODUCT = 'Zagrywki/products/UN_SELECT_PRODUCT';
export const UPDATE_LIST = 'Zagrywki/products/UPDATE_LIST';
export const SET_PRODUCT_COUNT = 'Zagrywki/products/SET_PRODUCT_COUNT';
