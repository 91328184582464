// Private
export const _RESET_STATE = 'Zagrywki/discount/_RESET_STATE';
export const _SAVE_REQUEST = 'Zagrywki/discount/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/discount/_SAVE_SUCCESS';
export const _SAVE_FAILURE = 'Zagrywki/discount/_SAVE_FAILURE';

// Public
export const EDIT = 'Zagrywki/discount/EDIT';
export const ADD = 'Zagrywki/discount/ADD';
export const SAVE = 'Zagrywki/discount/SAVE';
export const REMOVE = 'Zagrywki/discount/REMOVE';
