import React, { useState } from 'react';

import ClickOutsideContainer from '@Compo/reusable/ClickOutsideContainer';
import dateFormat from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { Form } from 'tabler-react';
import { IDatePickerProps } from './DatePicker';
import styles from './DatePicker.module.scss';

registerLocale('pl', pl);

const DatePicker = ({
  clearable,
  disabled,
  feedback,
  invalid,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  className,
}: IDatePickerProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    value || new Date(),
  );

  const handleShowPickerChange = () => {
    if (!disabled) {
      setShowPicker(!showPicker);
    }
  };

  const handleDateTimeChange = (date: Date) => {
    if (!disabled) {
      onChange(name, date);
      setSelectedDate(date);
      setShowPicker(false);
    }
  };

  const handleClickOutside = () => {
    if (!disabled) {
      setShowPicker(false);
    }
  };

  const clear = () => {
    if (!disabled) {
      onChange(name, null);
      setSelectedDate(null);
    }
  };

  const formattedDate = value ? dateFormat(value, 'dd-MM-yyyy') : '';
  return (
    <ClickOutsideContainer
      className={styles.container}
      onClickOutside={handleClickOutside}
    >
      <Form.Group className={className || styles.group} label={label}>
        <div onClick={handleShowPickerChange} role="button">
          <Form.Input
            disabled={disabled}
            feedback={feedback}
            invalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={formattedDate}
            readOnly={true}
          />
        </div>
        {clearable && formattedDate && (
          <button onClick={clear} type="button" className={styles.clear} />
        )}
      </Form.Group>

      {showPicker && (
        <div className={styles.datePicker}>
          <ReactDatePicker
            fixedHeight={true}
            onChange={handleDateTimeChange}
            inline={true}
            locale="pl"
            selected={selectedDate}
          />
        </div>
      )}
    </ClickOutsideContainer>
  );
};

export default DatePicker;
