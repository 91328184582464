import { Selector } from 'reselect';

import { IFormIoUsersReducer } from '@Model/formio/types/index';
import _Store from '@Store';
import { FORM_IO_EMAIL, FORM_IO_NAME } from './../constants/constants';

const getFormIoAvailableUsers: Selector<_Store.IState, IFormIoUsersReducer> = (
  state,
) =>
  state.formIo.availableUsers.filter(
    (user) =>
      !state.formIo.users.find(
        (value) =>
          user._id === value._id ||
          (user.data[FORM_IO_EMAIL] === value.data[FORM_IO_EMAIL] &&
            user.data[FORM_IO_NAME] === value.data[FORM_IO_NAME]),
      ),
  );

export default getFormIoAvailableUsers;
