import { Field } from 'formik';
import React from 'react';
import { Form, Grid } from 'tabler-react';

import MyInput from '../../../Input';
import MySelect from '../../../Select';
import { IFormLayoutProps } from '../FormLayout/FormLayout.types';

const MIN_TEXT = 'Od liczby osób';
const DISCOUNT_TYPE = 'Rodzaj';
const VALUE_TYPE_TEXT = 'Typ wartości';
const VALUE_PRICE_TEXT = 'Wartość (PLN)';
const PERCENT_TEXT = 'Procent';
const VALUE_TEXT = 'Wartość';

const UP_TEXT = 'Podwyższ';
const DOWN_TEXT = 'Obniż';

const RulePerPerson = ({ values: { isPeopleCount } }: IFormLayoutProps) => {
  return (
    <Form.Group>
      {isPeopleCount && (
        <Grid.Row>
          <Grid.Col>
            <Form.Group label={MIN_TEXT}>
              <Field
                name="fromNumberOfPeople"
                type="number"
                component={MyInput}
                disabled={closed}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={DISCOUNT_TYPE}>
              <Field component={MySelect} name="priceGoesUp">
                <option value={'up'}>{UP_TEXT}</option>
                <option value={'down'}>{DOWN_TEXT}</option>
              </Field>
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={VALUE_TYPE_TEXT}>
              <Field component={MySelect} name="perPersonType">
                <option value={'value'}>{VALUE_PRICE_TEXT}</option>
                <option value={'percentageValue'}>{PERCENT_TEXT}</option>
              </Field>
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={VALUE_TEXT}>
              <Field
                name="perPersonValue"
                type="number"
                component={MyInput}
                disabled={closed}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      )}
    </Form.Group>
  );
};

export default RulePerPerson;
