const payment = {
  checking: '/podsumowanie-sprawdz',
  summaryCanceled: '/podsumowanie/:id/transakcja-anulowana',
  summaryFail: '/podsumowanie/:id/blad-platnosci',
  summarySuccess: '/podsumowanie/:id',
};

const routes = {
  ...payment,
  addHappening: '/dodaj-zasob',
  addReservation: '/dodaj-rezerwacje',
  admin: '/administracja',
  calendar: '/kalendarz',
  calendarPopUp: '/kalendarz/:id',
  consentsList: '/lista-zgod',
  discounts: '/kody-rabatowe',
  editHappening: '/edytuj-zasob/:slug',
  happenings: '/zasoby',
  index: '/',
  reports: '/reports',
  rules: '/rules',
  newRules: '/newRules',
  start: '/start',
  statics: '/statystyka',
  transactionsDelayed: '/transakcje-opoznione',
};

export default routes;
