import { combineEpics } from 'redux-observable';

// Import modules
import * as App from '@Model/app/epics';
import * as Authorization from '@Model/authorization/epics';
import * as Configurations from '@Model/configurations/epics';
import * as Discount from '@Model/discount/epics';
import * as Discounts from '@Model/discounts/epics';
import * as FormIo from '@Model/formio/epics';
import * as Happening from '@Model/happening/epics';
import * as Happenings from '@Model/happenings/epics';
import * as Iframe from '@Model/iframe/epics';
import * as PriceTypes from '@Model/priceTypes/epics';
import * as Printer from '@Model/printer/epics';
import * as Products from '@Model/products/epics';
import * as Reports from '@Model/reports/epics';
import * as Reservation from '@Model/reservation/epics';
import * as Reservations from '@Model/reservations/epics';
import * as Smartporter from '@Model/smartporter/epics';
import * as State from '@Model/state/epics';
import * as Statics from '@Model/statics/epics';
import * as User from '@Model/user/epics';

export default combineEpics(
  // Authorization
  Authorization.checkTokenWhenLocationChanged,
  Authorization.removeAuthorizationHeaderWhenRequest,
  Authorization.getTokenWhenRequest,
  Authorization.setAuthorizationHeaderWhenRequest,
  Authorization.setStartStateWhenAppMounted,
  Authorization.setTokenAndRedirectWhenGetTokenSuccess,
  Authorization.encodeJwtTokenAndSetUserStateWhenRequest,
  Authorization.handleUsersPermissionsWhenRequest,
  Authorization.fetchAuthorisationCasherInfoWhenRequested,
  Authorization.checkSessionTokenInWhenRequest,
  Authorization.showRefreshMessageWhenSessionOf,
  Authorization.runSessionWhenRequest,
  Authorization.clearSessionWhenRequest,
  Authorization.getSessionWhenRequest,
  Authorization.updatePaymentSessionWhenRequest,
  Authorization.clearSessionByPrinterIdWhenRequest,

  // App
  App.setDeviceWhenSizesChangedEpic,

  // Configurations
  Configurations.requestConfigurationsWhenMounted,
  Configurations.fetchConfigurationsWhenRequested,
  Configurations.saveNewConfigurationWhenAction,
  Configurations.saveNewConfigurationWhenActionV2,
  Configurations.requestToGetConfigurationsWhenAction,
  Configurations.requestToDeleteConfigurationsWhenAction,

  // Discounts
  Discounts.fetchDiscountsWhenRequested,
  Discounts.requestDiscountsWhenMounted,
  Discounts.getDiscountsWhenSetFilter,

  // Discount
  Discount.requestSaveDiscountOnSaveAction,
  Discount.saveDiscountWhenRequested,
  Discount.removeDiscountWhenRequested,

  // FormIo
  FormIo.catchUsersFromFormIoWhenGetUserSuccess,
  FormIo.getEntryFormWhenGotToken,
  FormIo.getFormAvailableUsers,
  FormIo.getFormAvailableUsersByEmail,
  FormIo.getFormAvailableUsersWhenPageChange,
  FormIo.resetStateWhenMounted,
  FormIo.resetStateWhenSelectReservation,
  FormIo.loginFormIoWhenSelectReservation,
  FormIo.getEnterFormUserTokensWhenRequest,
  FormIo.getEnterFormUserTokens,
  FormIo.addEntryCodeWhenRequest,
  FormIo.saveUserWhenEntryCodeAdded,
  FormIo.saveUsersReservationWhenRequest,
  FormIo.catchSaveUserWhenRequest,
  FormIo.saveUserDataWhenRequest,

  // Happening
  Happening.addHappeningWhenRequested,
  Happening.editHappeningWhenRequested,
  Happening.fetchHappeningWhenRequested,
  Happening.requestHappeningWhenEditFormMounted,
  Happening.requestHappeningWhenRuleHasBeenEdited,
  Happening.resetStateWhenEditFormUnmount,
  Happening.requestForAvailabilitiesWhenHappeningSelected,
  Happening.fetchAvailabilitiesWhenRequested,
  Happening.requestAvailabilitiesWhenDateSelectionChanges,

  // Happenings
  Happenings.fetchHappeningsWhenRequested,
  Happenings.removeHappeningWhenRequested,
  Happenings.requestHappeningsWhenListMounted,

  // Iframe
  Iframe.runResizeMethodWhenSetDevice,
  Iframe.refreshPageWhenRequest,
  Iframe.setIframeSessionWhenRequest,
  Iframe.runRedirectBuyingContinue,

  // Printer
  Printer.requestSavePrinterOnSaveAction,
  Printer.savePrinterWhenRequested,
  Printer.endSessionWithOutCashState,
  Printer.endSessionByIdWithoutCasStateWhenRequest,
  Printer.requestFetchPrintersOnMountedAction,
  Printer.removePrinterWhenRequested,
  Printer.fetchPrinterWhenRequested,
  Printer.printReceiptWhenRequest,
  Printer.startSessionWhenRequest,
  Printer.catchStartSession,
  Printer.catchEndSessionWhenRequest,
  Printer.catchPrintReceiptWhenRequest,
  Printer.endSessionWhenRequest,
  Printer.endSessionByIdWhenRequest,
  Printer.printReportPrinterWhenRequest,
  Printer.clearPrinterWhenRequest,
  Printer.pullDrawerWhenRequest,
  Printer.printerPaymentWhenRequest,
  Printer.startPingingPrinterWhenRequest,
  Printer.fetchPrinterStatusWhenRequest,
  Printer.fetchPrinterPingingWhenRequested,

  // Reservation
  Reservation.requestForHappeningsWhenMounted,
  Reservation.requestForHappeningWhenSelected,
  Reservation.handleSettingSlotWhenRequested,
  Reservation.requestForPostReservationWhenSave,
  Reservation.postReservationWhenRequested,
  Reservation.postEmptyReservationWhenRequested,
  Reservation.caputeTransactionDetailsWhenSummaryMounted,
  Reservation.captureTransactionEmpikDetailsWhenRequest,
  Reservation.cancelTransactionWhenPayload,
  Reservation.cancelAutoTransactionWhenPayload,
  Reservation.saveBillWhenRequest,
  Reservation.getCompanyDataWhenRequest,
  Reservation.catchCompanyDataWhenRequest,
  Reservation.getReceiptPrintDataWhenRequest,
  Reservation.getReceiptWhenRequestWhentPrindDataSuccess,
  Reservation.whenGetHappeningCheckTimeIsBetweenMidnightAndTime,
  Reservation.capturePrintersList,
  Reservation.startPingingWhenPrintersFetch,

  // Reservations
  Reservations.requestHappeningsWhenCalendarMounted,
  Reservations.requestReservationsWhenHappeningsFetched,
  Reservations.fetchReservationsWhenRequested,
  Reservations.setFiltersWhenFilterSwitched,
  Reservations.unselectAllWhenRequest,
  Reservations.selectAllWhenRequest,
  Reservations.requestReservationsWhenReloadRequested,
  Reservations.requestReservationDetailsWhenSelected,
  Reservations.fetchReservationDetailsWhenRequested,
  Reservations.setActiveHappeningWhenDetailsSuccess,
  Reservations.getAvailableWhenChangeDate,
  Reservations.requestCheckWhenDateCheckRequested,
  Reservations.fetchDataCheckWhenRequested,
  Reservations.resetCheckWhenResetSelection,
  Reservations.requestUpdateReservationWhenSaveRequested,
  Reservations.updateReservationWhenRequested,
  Reservations.requestFormEmpikReservationDetailsReuestWhenSelectedReservation,
  Reservations.getEmpikReservationDetailsWhenRequest,
  Reservations.resendTicketWhenRequestWhenRequest,
  Reservations.getTicketWhenRequestWhenRequest,
  Reservations.whenDelayedIsMountedCatch,
  Reservations.getTransactionDetailsWhenRequest,
  Reservations.catchCancelTransactionWhenRequest,
  Reservations.cancelTransactionWhenRequest,
  Reservations.displayMessageWhenRequest,

  // State
  State.setErrorOnDiscountsFailure,
  State.setErrorOnAvailabilitiesFailure,
  State.setErrorOnConfigurationsFailure,
  State.setErrorOnHappeningsFailure,
  State.setErrorOnReservationCheckFailure,
  State.setErrorOnReservationFailure,
  State.setErrorOnReservationsFailure,
  State.setErrorOnSingleDiscountFailure,
  State.setErrorOnSingleHappeningFailure,
  State.setErrorOnFormIoUsersFailure,
  State.setLoaderWhenDiscountsFetchFinished,
  State.setLoaderWhenFormIoUsersFetchFinished,
  State.setLoaderWhenAvailabilitiesFetchFinished,
  State.setLoaderWhenConfigurationsFetchFinished,
  State.setLoaderWhenHappeningsFetchFinished,
  State.setLoaderWhenReservationCheckFetchFinished,
  State.setLoaderWhenReservationFetchFinished,
  State.setLoaderWhenReservationsFetchFinished,
  State.setLoaderWhenSingleDiscountFetchFinished,
  State.setLoaderWhenSingleHappeningFetchFinished,
  State.setUpStateWhenFormIoUsersFetchRequested,
  State.setUpStateWhenDiscountsFetchRequested,
  State.setUpStateWhenAvailabilitiesFetchRequested,
  State.setUpStateWhenConfigurationsFetchRequested,
  State.setUpStateWhenHappeningsFetchRequested,
  State.setUpStateWhenReservationCheckFetchRequested,
  State.setUpStateWhenReservationFetchRequested,
  State.setUpStateWhenReservationsFetchRequested,
  State.setUpStateWhenSingleDiscountFetchRequested,
  State.setUpStateWhenSingleHappeningFetchRequested,
  State.setUpStateStartWhenStartMounted,
  State.setLoaderStartAppSetFinished,
  State.setUpStateWhenPrintingRequested,
  State.setUpStateWhenPrintingFinished,
  State.setLoaderWhenCompanyDataFinished,
  State.setUpStateWhenCompanyDataRequested,
  State.setUpStateWhenGetReportsFetchRequested,
  State.setLoaderWhenGetReportsFetchFinished,

  // Smartporter
  Smartporter.refreshListWhenRequested,
  Smartporter.requestGetApartmentsWhenMounted,
  Smartporter.fetchApartmentsWhenRequest,
  // User
  User.requestSaveUserOnSaveAction,
  User.saveUserWhenRequested,
  User.requestFetchUsersOnMountedAction,
  User.fetchUsersWhenRequested,
  User.removeUserWhenRequested,
  User.requestAdminUsersOnMountedAction,
  User.fetchAdminUsersWhenRequested,

  // Products
  Products.whenReservationMountedGetAllProducts,
  Products.getAllProductsWhenRequest,
  Products.getProductsWhenRequest,
  Products.selectProductWhenRequest,
  Products.unSelectProductWhenRequest,
  Products.addProductWhenRequest,
  Products.setProductCountWhenRequest,

  // Reports
  Reports.captureReportWhenRequest,
  Reports.getReportWhenRequest,
  Reports.setSelectedKeysWhenAction,
  Reports.addNewValueToGroupWhenRequest,

  // Statics
  Statics.requestStaticsUrlWhenMounted,
  Statics.fetchStaticsWhenRequested,

  // PriceTypes
  PriceTypes.catchGetProductsWhenRequest,
  PriceTypes.getProductsWhenRequest,
);
/* @@STORE_COMPONENT@@ */
