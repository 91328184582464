import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { setPartnerID } from '@Model/happenings/actions';
import { get as getHappenings } from '@Model/happenings/selectors';
import _Store from '@Store';
import Partner from './Partner.component';
import { IPartnerFromDispatch, IPartnerFromState } from './Partner.types';

const mapStateToProps = (state: _Store.IState): IPartnerFromState => {
  const {
    userInfo: { partners },
  } = get(state);

  const { partnerId } = getHappenings(state);

  return {
    partnerId,
    partners,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPartnerFromDispatch => {
  return {
    setPartnerID: (partnerId: string) => {
      dispatch(setPartnerID(partnerId));
    },
  };
};

export default connect<
  IPartnerFromState,
  IPartnerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Partner);
