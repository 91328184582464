import { get } from '@Model/authorization/selectors';
import { pullDrawer } from '@Model/printer/actions';
import getUserPrinter from '@Model/printer/selectors/getUserPrinter';
import {
  clearFiltersRequest,
  mounted,
  reload,
  selectAllFilters,
  selectReservation,
  selectReservationList,
  setFilters,
} from '@Model/reservations/actions';
import {
  getDateRange,
  getReservationsFormatted,
  isReservationDetailsListSelected,
  isReservationDetailsSelected,
} from '@Model/reservations/selectors';
import {
  allPermissions,
  HAPPENINGS,
  RESERVATIONS,
} from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { ICalendarFromDispatch, ICalendarFromState } from './Calendar';
import Calendar from './Calendar.component';

const mapStateToProps = (state: _Store.IState): ICalendarFromState => {
  const {
    userInfo: { session, permissions },
  } = get(state);

  const printer = getUserPrinter(state);
  const isSession = !!(session && session.session_id);

  const getGetPullDraw = () => {
    if (printer) {
      const firmware = printer.firmware || '1.0.0';
      return firmware === '1.0.1' && isSession;
    }
    return false;
  };

  const canPullDraw = getGetPullDraw();

  return {
    canPullDraw,
    isLoading: getLoading(RESERVATIONS)(state) || getLoading(HAPPENINGS)(state),
    isReservationListSelected: isReservationDetailsListSelected(state),
    isReservationSelected: isReservationDetailsSelected(state),
    isSession,
    permissionReservationWrite: permissions.includes(
      allPermissions.access_reservations_write,
    ),
    range: getDateRange(state),
    reservations: getReservationsFormatted(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ICalendarFromDispatch => {
  return {
    clearFilters: () => dispatch(clearFiltersRequest()),
    dateChanged: (dateRange) => dispatch(reload(dateRange)),
    mounted: () => dispatch(mounted()),
    pullDrawer: () => dispatch(pullDrawer()),
    selectAllFilters: () => dispatch(selectAllFilters()),
    selectReservation: (reservationId) =>
      dispatch(selectReservation(reservationId)),
    selectReservationList: (reservations) =>
      dispatch(selectReservationList(reservations)),
    setFilters: (filters) => dispatch(setFilters(filters)),
  };
};

export default connect<
  ICalendarFromState,
  ICalendarFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);
