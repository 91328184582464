import { Field } from 'formik';
import React from 'react';
import { Form } from 'tabler-react';

import MyInput from '../../../Input';

const LABEL_TEXT = 'Maksymalna liczba osób';

const MaxNumberOfPeople = () => {
  return (
    <Form.Group label={LABEL_TEXT}>
      <Field name="maxNumberOfPeople" type="number" component={MyInput} />
    </Form.Group>
  );
};

export default MaxNumberOfPeople;
