import React, { FC, useState } from 'react';
import { Form, Grid, Loader } from 'tabler-react';

import { IInvoiceFormTypes } from './IInvoiceFormTypes';

import styles from './InvoiceForm.module.scss';

const INVOICE_TEXT = 'Dane do faktury';
const NIP_TEXT = 'NIP';
const COMPANY_NAME_TEXT = 'Nazwa firmy';
const COMPANY_OFFICE__ADDRESS_STREET_TEXT = 'Ulica';
const COMPANY_OFFICE__ADDRESS_NUMBER_TEXT = 'Numer';
const COMPANY_OFFICE__ADDRESS_ZIP_CODE_TEXT = 'Kod pocztowy';
const COMPANY_OFFICE__CITY_TEXT = 'Miasto';

const InvoiceForm: FC<IInvoiceFormTypes> = ({
  values,
  handleChange,
  errors,
  setFieldValue,
  touched,
  getCompanyData,
  setFormData,
  isCompanyDataLoading,
}) => {
  const [timeOut, setTimeOut] = useState<number>(0);

  const setNipValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setFieldValue('nip', value);

    if (timeOut) {
      clearTimeout(timeOut as any);
    }
    setTimeOut(setTimeout(() => {
      getCompanyData(value);
      setFormData({ ...values, nip: value });
    }, 2000) as any);
  };

  return (
    <div>
      <Form.Group label={INVOICE_TEXT}>
        <Form.Checkbox
          name="invoiceCheckbox"
          checked={values.invoiceCheckbox}
          isInline={true}
          label="Tak"
          onChange={() =>
            setFieldValue('invoiceCheckbox', !values.invoiceCheckbox)
          }
        />
      </Form.Group>

      {values.invoiceCheckbox && (
        <div>
          {isCompanyDataLoading && (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
          <Grid.Row>
            <Grid.Col>
              <Form.Group label={NIP_TEXT}>
                <Form.Input
                  name="nip"
                  value={values.nip}
                  onChange={setNipValue}
                  invalid={touched.nip && errors.nip}
                  error={touched.nip && errors.nip}
                />
              </Form.Group>
            </Grid.Col>

            <Grid.Col>
              <Form.Group label={COMPANY_NAME_TEXT}>
                <Form.Input
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  invalid={touched.companyName && errors.companyName}
                  error={touched.companyName && errors.companyName}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={COMPANY_OFFICE__ADDRESS_STREET_TEXT}>
                  <Form.Input
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    invalid={touched.street && errors.street}
                    error={touched.street && errors.street}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={COMPANY_OFFICE__ADDRESS_NUMBER_TEXT}>
                  <Form.Input
                    name="houseNumber"
                    value={values.houseNumber || ''}
                    onChange={handleChange}
                    invalid={touched.houseNumber && errors.houseNumber}
                    error={touched.houseNumber && errors.houseNumber}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={COMPANY_OFFICE__ADDRESS_ZIP_CODE_TEXT}>
                  <Form.Input
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                    invalid={touched.zipCode && errors.zipCode}
                    error={touched.zipCode && errors.zipCode}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={COMPANY_OFFICE__CITY_TEXT}>
                  <Form.Input
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    invalid={touched.city && errors.city}
                    error={touched.city && errors.city}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </>
        </div>
      )}
    </div>
  );
};

export default InvoiceForm;
