import axios, { CancelTokenSource } from 'axios';

import config from '@Config';

import catchHttpError from '@Misc/helpers/api/catchHttpError';

import getData from '@Misc/helpers/api/getData';

import { IGetPriceTypesSuccessPayload } from '@Model/priceTypes/types';

class RuleTypesApi {
  private static getPriceTypesUrl(): string {
    return `${config.api.baseUrlV2}admin/price-types`;
  }

  private cancelTokenPriceTypes?: CancelTokenSource;

  public getPriceTypes<T extends IGetPriceTypesSuccessPayload>(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenPriceTypes = axios.CancelToken.source();

      axios
        .get(RuleTypesApi.getPriceTypesUrl(), {
          cancelToken: this.cancelTokenPriceTypes.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelProductsTypes() {
    if (this.cancelTokenPriceTypes) {
      this.cancelTokenPriceTypes.cancel();
      this.cancelTokenPriceTypes = undefined;
    }
  }
}

export default new RuleTypesApi();
