import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import PrinterTable from './PrinterTable';
import { IDetailsModalProps } from './ReportModal.types';

import styles from './ReportModal.module.scss';

const TITLE_TEXT = 'Drukuj paport dobowy';

const ReportModal = ({ cancel }: IDetailsModalProps) => {
  return (
    <Portal>
      <Modal>
        <div className={styles.title}>{TITLE_TEXT}</div>
        <div className={styles.cancelButton} onClick={cancel}>
          x
        </div>
        <div className={styles.wrapper}>
          <PrinterTable />
        </div>
      </Modal>
    </Portal>
  );
};

export default ReportModal;
