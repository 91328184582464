import React from 'react';

import { Form, Grid } from 'tabler-react';

import { IFiltersProps } from './Filters.types';

const PARTNER_TEXT = 'Partner';

const Filters = ({ setPartnerID, partners, partnerId }: IFiltersProps) => {
  const catchPartnerId = (partner: React.FormEvent<HTMLSelectElement>) => {
    const { value } = partner.currentTarget;
    setPartnerID(value);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Form.Select
            label={PARTNER_TEXT}
            onChange={catchPartnerId}
            value={partnerId || ''}
            name="partnerId"
          >
            <option value={-1}>-</option>

            {partners &&
              partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name || ''}
                </option>
              ))}
          </Form.Select>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Filters;
