export const _GET_REQUEST = 'Zagrywki/happenings/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/happenings/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/happenings/_GET_FAILURE';

export const _RESET_STATE = 'Zagrywki/happenings/_RESET_STATE';

export const MOUNTED = 'Zagrywki/happenings/MOUNTED';
export const REMOVE = 'Zagrywki/happenings/REMOVE';
export const SET_PARTNER_ID = 'Zagrywki/happenings/SET_PARTNER_ID';
export const SET_ACTIVE_FILTER = 'Zagrywki/happenings/SET_ACTIVE_FILTER';
