import AuthorizationApi from '@Services/$authorization-api';
import ConfigurationsApi from '@Services/$configurations-api';
import DeviceDetector from '@Services/$device-detector';
import DiscountApi from '@Services/$discount-api';
import DiscountsApi from '@Services/$discounts-api';
import FormIoApi from '@Services/$formio-api';
import HappeningsApi from '@Services/$happenings-api';
import IframeProvider from '@Services/$iframe-provider';
import LinksProvider from '@Services/$links-provider';
import PrinterApi from '@Services/$printer-api';
import ProductsApi from '@Services/$products-api';
import ReportsApi from '@Services/$reports-api';
import RequestProvider from '@Services/$request-provider';
import ReservationsApi from '@Services/$reservations-api';
import SmarporterApi from '@Services/$smartporter-api';
import StaticsApi from '@Services/$statics-api';
import UserApi from '@Services/$user-api';
import RuleTypesApi from '@/services/$price-type-api';

const services = {
  authorizationApi: AuthorizationApi,
  configurationsApi: ConfigurationsApi,
  deviceDetector: DeviceDetector,
  discountApi: DiscountApi,
  discountsApi: DiscountsApi,
  formIoApi: FormIoApi,
  happeningsApi: HappeningsApi,
  iframeProvider: IframeProvider,
  linksProvider: LinksProvider,
  printerApi: PrinterApi,
  productsApi: ProductsApi,
  reportsApi: ReportsApi,
  requestProvider: RequestProvider,
  reservationsApi: ReservationsApi,
  smarporterApi: SmarporterApi,
  staticsApi: StaticsApi,
  userApi: UserApi,
  ruleTypesApi: RuleTypesApi,
};

export type IServices = typeof services;

export default services;
