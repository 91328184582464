// Permissions
export const allPermissions: { [key in TPermissions]: TPermissions } = {
  access_analytics_view: 'access_analytics_view',
  access_apartments_view: 'access_apartments_view',
  access_apartments_write: 'access_apartments_write',
  access_cashiers_read: 'access_cashiers_read',
  access_cashiers_view: 'access_cashiers_view',
  access_cashiers_write: 'access_cashiers_write',
  access_cashiersessions_read: 'access_cashiersessions_read',
  access_cashiersessions_view: 'access_cashiersessions_view',
  access_cashiersessions_write: 'access_cashiersessions_write',
  access_cashiersessions_write_without_printer:
    'access_cashiersessions_write_without_printer',
  access_configurations_write: 'access_configurations_write',
  access_discounts_view: 'access_discounts_view',
  access_discounts_write: 'access_discounts_write',
  access_happenings_view: 'access_happenings_view',
  access_happenings_write: 'access_happenings_write',
  access_list_users_form_io_read: 'access_list_users_form_io_read',
  access_permission_delayed_transactions_view:
    'access_permission_delayed_transactions_view',
  access_printers_read: 'access_printers_read',
  access_printers_view: 'access_printers_view',
  access_printers_write: 'access_printers_write',
  access_refunds_write: 'access_refunds_write',
  access_reports_read: 'access_reports_read',
  access_reservations_view: 'access_reservations_view',
  access_reservations_write: 'access_reservations_write',
  access_rules_view: 'access_rules_view',
  access_spaces_read: 'access_spaces_read',
  access_spaces_view: 'access_spaces_view',
  access_spaces_write: 'access_spaces_write',
  access_transactionless_reservation: 'access_transactionless_reservation',
};

export interface IPermissions {
  access_analytics_view: 'access_analytics_view';
  access_apartments_view: 'access_apartments_view';
  access_apartments_write: 'access_apartments_write';
  access_cashiers_read: 'access_cashiers_read';
  access_cashiers_view: 'access_cashiers_view';
  access_cashiers_write: 'access_cashiers_write';
  access_cashiersessions_read: 'access_cashiersessions_read';
  access_cashiersessions_view: 'access_cashiersessions_view';
  access_cashiersessions_write: 'access_cashiersessions_write';
  access_cashiersessions_write_without_printer: 'access_cashiersessions_write_without_printer';
  access_configurations_write: 'access_configurations_write';
  access_discounts_view: 'access_discounts_view';
  access_discounts_write: 'access_discounts_write';
  access_happenings_view: 'access_happenings_view';
  access_happenings_write: 'access_happenings_write';
  access_list_users_form_io_read: 'access_list_users_form_io_read';
  access_permission_delayed_transactions_view: 'access_permission_delayed_transactions_view';
  access_printers_read: 'access_printers_read';
  access_printers_view: 'access_printers_view';
  access_printers_write: 'access_printers_write';
  access_refunds_write: 'access_refunds_write';
  access_reports_read: 'access_reports_read';
  access_reservations_view: 'access_reservations_view';
  access_reservations_write: 'access_reservations_write';
  access_rules_view: 'access_rules_view';
  access_spaces_read: 'access_spaces_read';
  access_spaces_view: 'access_spaces_view';
  access_spaces_write: 'access_spaces_write';
  access_transactionless_reservation: 'access_transactionless_reservation';
}

export type TPermissions =
  | 'access_analytics_view'
  | 'access_apartments_view'
  | 'access_apartments_write'
  | 'access_cashiers_read'
  | 'access_cashiers_view'
  | 'access_cashiers_write'
  | 'access_cashiersessions_read'
  | 'access_cashiersessions_view'
  | 'access_cashiersessions_write'
  | 'access_cashiersessions_write_without_printer'
  | 'access_configurations_write'
  | 'access_discounts_view'
  | 'access_discounts_write'
  | 'access_happenings_view'
  | 'access_happenings_write'
  | 'access_list_users_form_io_read'
  | 'access_permission_delayed_transactions_view'
  | 'access_printers_read'
  | 'access_printers_view'
  | 'access_printers_write'
  | 'access_refunds_write'
  | 'access_reports_read'
  | 'access_reservations_view'
  | 'access_reservations_write'
  | 'access_rules_view'
  | 'access_spaces_read'
  | 'access_spaces_view'
  | 'access_spaces_write'
  | 'access_transactionless_reservation';
