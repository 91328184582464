import { cancelTransaction } from '@Model/reservation/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import SummaryCanceled from './SummaryCanceled.component';
import { ISummaryCanceledFromDispatch } from './SummaryCanceled.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryCanceledFromDispatch => ({
  cancelTransaction: () => dispatch(cancelTransaction(null)),
});

export default connect<{}, ISummaryCanceledFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(SummaryCanceled);
