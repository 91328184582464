import { getType } from 'typesafe-actions';
import {
  fetchAuthorizationCasherInfo,
  handlePaymentModal,
  setAuthorizationState,
} from '../actions';
import { IAction, IAuthorizationReducer } from '../types';
import { closeModal, endUserSession, showModal } from './../actions/index';

const PERMISSION_DEFAULT_VALUE = 0;

const initialState: IAuthorizationReducer = {
  casherInfo: {
    id: 0,
    printerView: {
      id: 0,
      nonfiscalUrl: '',
      posNr: 0,
      printerId: 0,
      reportUrl: '',
      saleUrl: '',
      taxCode23: 0,
      taxCode8: 0,
    },
    userEmail: '',
    userId: 0,
  },
  showModal: false,
  showPaymentModal: false,
  userInfo: {
    cashier_id: 0,
    email: '',
    exp: 0,
    first_name: '',
    iat: 0,
    id: 0,
    is_activated: true,
    is_superuser: false,
    last_name: '',
    permissions: [],
    username: '',
  },
};

const authorizationReducer = (
  state: IAuthorizationReducer = initialState,
  action: IAction,
): IAuthorizationReducer => {
  switch (action.type) {
    case getType(setAuthorizationState):
      return {
        ...state,
        userInfo: action.payload,
      };
    case getType(fetchAuthorizationCasherInfo.success):
      return {
        ...state,
        casherInfo: action.payload,
      };
    case getType(showModal):
      return {
        ...state,
        showModal: true,
      };
    case getType(closeModal):
      return {
        ...state,
        showModal: false,
      };
    case getType(handlePaymentModal):
      return {
        ...state,
        showPaymentModal: action.payload,
      };
    case getType(endUserSession):
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          session: undefined,
        },
      };
    default:
      return state;
  }
};

export default authorizationReducer;
