import React from 'react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import FormLayout from './../FormLayout';

const ConsentsListModal = () => {
  return (
    <Portal>
      <Modal>
        <FormLayout />
      </Modal>
    </Portal>
  );
};
export default ConsentsListModal;
