import React, { FC } from 'react';

import { Button, Table } from 'tabler-react';
import { IDPrinterTableProps } from './PrinterTable.types';

export const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć drukarke?';
export const EMAIL_TEXT = 'Email';
export const END_SESSION_TEXT = 'Zakończ sesję';
export const FIRMWARE_TEXT = 'Wersja opogramawonia';
export const ID_TEXT = 'Id';
export const ID_PRINTER_TEXT = 'Nazwa';
export const SALE_URL_TEXT = 'Url';
export const SESSION_TEXT = 'Sesja';

const PrinterTable: FC<IDPrinterTableProps> = ({
  Printers,
  edit,
  endSession,
  remove,
  permissionPrintersWrite,
}) => {
  return (
    <Table
      responsive={true}
      highlightRowOnHover={true}
      hasOutline={true}
      verticalAlign="center"
      cards={true}
      className="text-nowrap"
    >
      <Table.Header>
        <Table.Row>
          <Table.ColHeader>{ID_TEXT}</Table.ColHeader>
          <Table.ColHeader>{ID_PRINTER_TEXT}</Table.ColHeader>
          <Table.ColHeader alignContent="center">
            {SALE_URL_TEXT}
          </Table.ColHeader>
          <Table.ColHeader alignContent="center">
            {FIRMWARE_TEXT}
          </Table.ColHeader>
          <Table.ColHeader alignContent="center">
            {SESSION_TEXT}
          </Table.ColHeader>
          <Table.ColHeader alignContent="center">
            <i className="icon-settings" />
          </Table.ColHeader>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Printers.map((printer) => {
          const { printerId, saleUrl, id, firmware, openSession } = printer;

          const confirmModal = () => {
            if (window.confirm(ARE_YOU_SURE_TEXT) && id) {
              remove(id);
            }
          };
          const editPrinter = () => {
            edit({ ...printer, edit: true });
          };

          const endPrinterSession = () => {
            if (printer.id !== null) {
              endSession(printer.id);
            }
          };

          return (
            <Table.Row key={id}>
              <Table.Col>{id}</Table.Col>
              <Table.Col>{printerId}</Table.Col>
              <Table.Col alignContent="center">{saleUrl}</Table.Col>
              <Table.Col alignContent="center">{firmware}</Table.Col>
              <Table.Col alignContent="center">
                {openSession && (
                  <Button color="red" size="sm" onClick={endPrinterSession}>
                    {END_SESSION_TEXT}
                  </Button>
                )}
              </Table.Col>
              <Table.Col alignContent="center">
                <Button.List align="right">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={editPrinter}
                    icon="edit-3"
                  />
                  {permissionPrintersWrite && (
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={confirmModal}
                      icon="trash"
                    />
                  )}
                </Button.List>
              </Table.Col>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default PrinterTable;
