import React from 'react';

import { Button } from 'tabler-react';

import { IProductProps } from './Product.types';
import Slider from './Slider';

import styles from './Product.module.scss';

const ADD_SMALL_TEXT = '+';
const REMOVE_SMALL_TEXT = '-';

const FormLayout = ({
  removeProduct,
  unSelectProduct,
  selectProduct,
  setProductCount,
  isBigDesktop,
  isDesktop,
  product: { count, description, id, name, selected, price },
  product,
}: IProductProps) => {
  const cathProductCount = (newCount: number) => {
    setProductCount({ product, count: newCount });
  };

  const renderSlider = () => {
    const showFullText = isBigDesktop || isDesktop;
    const sliderValue = count || 0;
    return (
      <Slider
        minValue={0}
        onChange={cathProductCount}
        showFullText={showFullText}
        maxValue={1000}
        value={sliderValue}
      />
    );
  };

  return (
    <div className={styles.product} key={id}>
      <div className={styles.delete}>
        <Button
          color="secondary"
          size="sm"
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => removeProduct(product)}
          icon="x"
          type="button"
        />
      </div>
      <div className={styles.info}>
        {description && <span className={styles.title}>{description}</span>}
        {name && <span className={styles.infoDescription}>{name}</span>}
      </div>
      <div className={styles.priceItem}>{price} PLN</div>
      <div className={styles.price}>{renderSlider()}</div>
    </div>
  );
};

export default FormLayout;
