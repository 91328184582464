import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  CATCH_GET_RULE_TYPES,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
} from '../constants/actions';
import { IRuleType } from '../types';

export const catchGetRuleType = createStandardAction(CATCH_GET_RULE_TYPES)();

export const getRuleTypes = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IRuleType[], Error>();
