import routes from '@/routes/routes';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import weekEndDate from '@Misc/helpers/weekEndDate';
import weekStartDate from '@Misc/helpers/weekStartDate';
import { saveUserReservation } from '@Model/formio/actions';
import { getAvailabilities } from '@Model/happening/selectors';
import { getHappenings } from '@Model/happenings/actions';
import { IHappenings } from '@Model/happenings/types';
import { IMatch } from '@Model/reservation/types';
import {
  getReservations,
  mounted,
  reload,
  resetSelection,
  saveReservation,
  selectReservation,
  setFilters,
  updateReservation,
} from '@Model/reservations/actions';
import getDateRange from '@Model/reservations/selectors/getDateRange';
import getFilters from '@Model/reservations/selectors/getFilters';
import { IReservationDetailsView } from '@Model/reservations/types';
import {
  IReservationsResponse,
  IReservationUpdateBody,
} from '@Services/$reservations-api/types';
import _Store from '@Store';
import {
  createMatchSelector,
  getLocation,
  LOCATION_CHANGE,
} from 'connected-react-router';
import format from 'date-fns/format';
import moment from 'moment-timezone';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { get } from '@Model/authorization/selectors';
import { allPermissions } from '@Model/state/constants/permissions';

export const requestHappeningsWhenCalendarMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([mounted])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.calendarPopUp);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return of$(getHappenings.request(), selectReservation(Number(id)));
      }

      return of$(getHappenings.request());
    }),
  );
};

export const requestReservationsWhenHappeningsFetched: _Store.IEpic = (
  action$,
  state$,
  { linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(getHappenings.success)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const { pathname } = getLocation(state);
      const {
        userInfo: { permissions },
      } = get(state);

      const permissionHappeningsView = permissions.includes(
        allPermissions.access_happenings_view,
      );

      const permissionReservationView = permissions.includes(
        allPermissions.access_reservations_view,
      );

      const isCalendarPath = linksProvider.compareCalendarPopUpLinkWithCalendarLink(
        pathname,
      );

      return (
        isCalendarPath ||
        !!(!permissionHappeningsView && permissionReservationView)
      );
    }),
    mergeMap$(([action, state]) => {
      const happenings: IHappenings = action.payload;
      let filters = getFilters(state);
      if (!filters.length) {
        filters = happenings.items.map((item) => {
          const { slug, title } = getMetadataForLanguage(item.metadata);
          return {
            color: item.color,
            id: item.id,
            slug,
            spaces: item.spaces.map((space) => {
              const {
                slug: spaceSlug,
                title: spaceTitle,
              } = getMetadataForLanguage(space.metadata);
              return {
                id: space.id,
                selected: true,
                slug: spaceSlug,
                title: spaceTitle,
              };
            }),
            title,
          };
        });
        if (localStorage) {
          localStorage.setItem('filters', JSON.stringify(filters));
        }
      }

      return [setFilters(filters), getReservations.request()];
    }),
  );
};

export const requestReservationsWhenReloadRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(reload)),
    mergeMap$(() => of$(getReservations.request())),
  );
};

export const fetchReservationsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getReservations.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const dateRange = getDateRange(state);
      const reservationsPromises: Array<Promise<IReservationsResponse>> = [];
      const filters = getFilters(state);

      const today = new Date();
      let endRange = format(weekEndDate(today), 'yyyy-MM-dd');
      let startRange = format(weekStartDate(today), 'yyyy-MM-dd');

      if (dateRange !== null) {
        endRange = format(dateRange.endDate, 'yyyy-MM-dd');
        startRange = format(dateRange.startDate, 'yyyy-MM-dd');
      }

      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          const selectedSpaces = filter.spaces.filter(
            (space) => space.selected,
          );

          if (selectedSpaces) {
            selectedSpaces.forEach((space) => {
              reservationsPromises.push(
                reservationsApi.getReservations(
                  filter.slug,
                  space.slug,
                  startRange,
                  endRange,
                ),
              );
            });
          }
        });

        return from$(Promise.all(reservationsPromises)).pipe(
          map$((responses: IReservationsResponse[]) => {
            const reservations = Array.prototype.concat(
              ...responses.map((response) => response.items),
            );

            return getReservations.success(reservations);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => reservationsApi.cancelReservations()),
            ),
          ),
          catchError$((error: Error) => {
            return of$(getReservations.failure(error));
          }),
        );
      }

      return EMPTY;
    }),
  );
};

export const requestUpdateReservationWhenSaveRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(saveReservation)),
    mergeMap$((action) => {
      return of$(
        updateReservation.request(action.payload),
        saveUserReservation.request(),
      );
    }),
  );
};

export const updateReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(updateReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const reservation: IReservationDetailsView = action.payload;
      const availabilities = getAvailabilities(state);

      if (reservation.id && reservation.startDate && reservation.startTime) {
        const timezoneStartDate = moment
          .utc(`${reservation.startDate}T${reservation.startTime}`)
          .format('YYYY-MM-DD HH:mm:ss');
        const formattedDate =
          reservation.startTime &&
          availabilities &&
          availabilities.otherDays &&
          availabilities.otherDays[reservation.startTime]
            ? moment(timezoneStartDate)
                .add('days', 1)
                .toDate()
            : timezoneStartDate;
        const dateTime = moment(formattedDate);

        const body: IReservationUpdateBody = {
          dateTime: dateTime.format('YYYY-MM-DD[T]HH:mm:ss+SS'),
          description: reservation.description || '',
          numberOfPeople: Number(reservation.numberOfPeople) || 0,
        };
        return from$(
          reservationsApi.updateReservationDetails(reservation.id, body),
        ).pipe(
          mergeMap$(() => [
            updateReservation.success(),
            resetSelection(),
            getReservations.request(),
          ]),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => reservationsApi.cancelReservationUpdate()),
            ),
          ),
          catchError$((error: Error) => {
            return of$(updateReservation.failure(error));
          }),
        );
      }

      return EMPTY;
    }),
  );
};
