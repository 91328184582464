import React, { useEffect } from 'react';

import { Avatar, Grid } from 'tabler-react';
import styles from './SummaryFail.module.scss';
import { ISummaryProps } from './SummaryFail.types';

const TEXT_1 = 'Transakcja nie powiodła się';
const TEXT_2 = ' Spróbuj ponownie.';

const SummaryFail = ({ cancelTransaction }: ISummaryProps) => {
  useEffect(() => {
    cancelTransaction();
  }, []);

  return (
    <section>
      <Grid.Col>
        <Grid.Row className={styles.center}>
          <Avatar
            icon="thumbs-down"
            prefix="fa"
            color="blue"
            size="xxl"
            className={styles.bigIcon}
          />
        </Grid.Row>
        <Grid.Row className={styles.center}>
          <h1 className={styles.title}>{TEXT_1}</h1>
        </Grid.Row>
        <Grid.Row className={styles.center}>
          <h1>{TEXT_2}</h1>
        </Grid.Row>
      </Grid.Col>
    </section>
  );
};

export default SummaryFail;
