import { uniqueUsers } from '@Misc/helpers/removeDuplicatesUsers';
import { getType } from 'typesafe-actions';

import {
  addUser,
  getEntryFormsFormIo,
  getUsers,
  removeUser,
  resetState,
} from './../actions';
import { IAction, IFormIoUsersReducer } from './../types';

const initialState: IFormIoUsersReducer = [];

const usersReducer = (
  state: IFormIoUsersReducer = initialState,
  action: IAction,
): IFormIoUsersReducer => {
  switch (action.type) {
    case getType(getEntryFormsFormIo.success):
      return action.payload.data.uczestnicy;
    case getType(getUsers.success):
      return uniqueUsers([...state, ...action.payload.users]);
    case getType(addUser):
      return [...state, action.payload];
    case getType(removeUser):
      return state.filter((user) => user._id !== action.payload);
    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
