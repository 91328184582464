import catchHttpError from '@Misc/helpers/api/catchHttpError';
import axios, { CancelTokenSource } from 'axios';
import { setUser } from 'react-formio';

import getData from '@Misc/helpers/api/getData';
import {
  IData,
  IFormIoSaveUsersResponse,
  IFormIoUser,
  IGetEntryFormSuccessPayload,
  IGetUsersSmsEnterFormSuccessPayload,
  IGetUsersSuccessPayload,
  IGetUsersSuccessResponse,
  ISubmission,
} from '@Model/formio/types';
import { IFormIoLoginResponse, IFormIoUserAdmin } from './types';

class FormIoApi {
  private static getLoinUrl(url: string, login: string, pass: string): string {
    return `${url}/submission?data.email=${login}&data.password=${pass}`;
  }

  private static updateUserEntryIdUrl(url: string): string {
    return `${url}/submission`;
  }

  private static getUsersUrl(url: string, entryId: string): string {
    return `${url}/submission?data.page1Kodrezerwacji=${entryId}`;
  }

  private static getEntryFormUrl(url: string, entryId: string): string {
    return `${url}/submission?data.text=${entryId}`;
  }

  private static getAllEntryTokensUrl(url: string, email: string): string {
    return `${url}/submission?data.page1Email=${email}`;
  }
  private static submitReservation(url: string): string {
    return `${url}/submission`;
  }

  private static getAvailableUsersUrl(
    url: string,
    searchText: string,
    limit: number,
    page: number = 0,
    filterType: string = 'email',
  ): string {
    return `${url}/submission?limit=${limit}&skip=${page *
      limit}&data.${filterType}__regex=${searchText}&sort=-created`;
  }

  private static getAvailableUsersSearch(url: string): string {
    return `${url}/report`;
  }

  private cancelTokenUsers?: CancelTokenSource;

  public loginFormIo(
    formUrl: string,
    email: string,
    password: string,
  ): Promise<IFormIoLoginResponse> {
    return new Promise<IFormIoLoginResponse>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      const loginData = {
        data: {
          email,
          password,
          submit: true,
        },
        state: 'submitted',
      };

      axios
        .post(formUrl, loginData)
        .then((data) => {
          const formIoUser: IFormIoUserAdmin = getData(data);

          setUser(formIoUser);

          if (data.headers && data.headers['x-jwt-token']) {
            const body: IFormIoLoginResponse = {
              formIoUser,
              token: data.headers['x-jwt-token'],
            };
            resolve(body);
          }
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getUsers(
    entryId: string,
    formUrl: string,
    token: string,
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .get(FormIoApi.getUsersUrl(formUrl, entryId), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then((data) => {
          return data;
        })
        .then((data: IGetUsersSuccessResponse[]) => {
          const body: IGetUsersSuccessPayload = {
            totalCount: 0,
            users:
              data
                .map((user) => {
                  return {
                    ...user.data.page2Enterform,
                    modified: user.modified,
                  };
                })
                .filter((user) => user && user.data) || [],
          };

          resolve(body);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getAvailableUsersSearch(
    formUrl: string,
    formId: string,
    token: string,
    email: string,
    limit: number,
    firstName: string,
    lastName: string,
    date: string,
    page: number = 0,
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .post(
          FormIoApi.getAvailableUsersSearch(formUrl),
          this.getSearchData(
            formId,
            email,
            limit,
            firstName,
            lastName,
            date,
            page,
          ),
          {
            cancelToken: this.cancelTokenUsers.token,
            headers: {
              'x-jwt-token': token,
            },
          },
        )
        .then((response) => {
          resolve({
            totalCount: Number(
              response.headers['content-range'].substring(
                response.headers['content-range'].indexOf('/') + 1,
              ),
            ),
            users: response.data,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getAvailableUsers(
    formUrl: string,
    token: string,
    email: string,
    limit: number,
    page: number = 0,
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();
      axios
        .get(FormIoApi.getAvailableUsersUrl(formUrl, email, limit, page), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then((response) => {
          resolve({
            totalCount: Number(
              response.headers['content-range'].substring(
                response.headers['content-range'].indexOf('/') + 1,
              ),
            ),
            users: response.data,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getAllEntryTokens(
    formUrl: string,
    token: string,
    email: string,
  ): Promise<IGetUsersSmsEnterFormSuccessPayload> {
    return new Promise<IGetUsersSmsEnterFormSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenUsers = axios.CancelToken.source();
        axios
          .get(FormIoApi.getAllEntryTokensUrl(formUrl, email), {
            cancelToken: this.cancelTokenUsers.token,
            headers: {
              'x-jwt-token': token,
            },
          })
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(catchHttpError(error)));
      },
    );
  }

  public getEntryForm(
    entryId: string,
    formUrl: string,
    token: string,
  ): Promise<IGetEntryFormSuccessPayload> {
    return new Promise<IGetEntryFormSuccessPayload>((resolve, reject) => {
      axios
        .get(FormIoApi.getEntryFormUrl(formUrl, entryId), {
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then((data) => {
          return data;
        })
        .then((data: IGetEntryFormSuccessPayload) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSearchData(
    formId: string,
    email: string,
    limit: number,
    firstName: string,
    lastName: string,
    date: string,
    page: number = 0,
  ) {
    const getParamsByString = () => {
      const orValue = [];
      if (email && email.length) {
        orValue.push({
          'data.email': {
            $options: 'i',
            $regex: email,
          },
        });
      }

      if (firstName && firstName.length) {
        orValue.push({
          'data.imie': {
            $options: 'i',
            $regex: firstName,
          },
        });
      }

      if (lastName && lastName.length) {
        orValue.push({
          'data.nazwisko': {
            $options: 'i',
            $regex: lastName,
          },
        });
      }

      // TODO: keep it
      // if (date && date.length) {
      //   orValue.push({
      //     'data.dataurodzenia': {
      //       $options: 'i',
      //       $regex: date,
      //     },
      //   });
      // }

      return orValue;
    };

    return [
      {
        $match: {
          $and: getParamsByString(),
          form: {
            $in: [formId],
          },
        },
      },
      { $skip: page * limit },
      { $limit: limit },
      { $sort: { created: -1 } },
    ];
  }
  public updateUserEntryId(
    formUrl: string,
    entryId: string,
    user: IFormIoUser,
    token: string,
  ): Promise<IGetUsersSmsEnterFormSuccessPayload> {
    return new Promise<IGetUsersSmsEnterFormSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenUsers = axios.CancelToken.source();
        const data = {
          data: {
            nextPage: 'page2',
            page1Email: user.data.email,
            page1EnterFormFormUrl: '-',
            page1Imi: user.data.imie,
            page1Kodrezerwacji: entryId,
            page1RedirectAfterSubmit: 'none',
            page1SmsGateWay: '-',
            page1SmsTekst: 'none',
            page2Enterform: {
              _fvid: 0,
              _id: user._id,
              _vid: 0,
              data: {
                agreement: true,
                dataurodzenia: user.data.dataurodzenia,
                email: user.data.email,
                entryId,
                imie: user.data.imie,
                nazwisko: user.data.nazwisko,
                redirectAfterSubmitFormPath: 'none',
              },
              owner: null,
              roles: [],
              state: 'submitted',
            },
            page3parentfirstname: '-',
            page3parentlastname: '-',
            page3phone: 0,
            page4Numbergen: 0,
            wyslijPonownieSmsa: false,
          },
        };

        axios
          .post(FormIoApi.updateUserEntryIdUrl(formUrl), data, {
            cancelToken: this.cancelTokenUsers.token,
          })
          .then(() => resolve())
          .catch((error) => reject(catchHttpError(error)));
      },
    );
  }

  public updateUserData(
    formUrl: string,
    newUserData: IData,
    user: IFormIoUser,
  ): Promise<IGetUsersSmsEnterFormSuccessPayload> {
    return new Promise<IGetUsersSmsEnterFormSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenUsers = axios.CancelToken.source();

        const data = {
          data: {
            agreement: true,
            dataurodzenia: newUserData.dataurodzenia || user.data.dataurodzenia,
            email: newUserData.email || user.data.email,
            entryId: user.data.entryId,
            imie: newUserData.imie || user.data.imie,
            nazwisko: newUserData.nazwisko || user.data.nazwisko,
            redirectAfterSubmitFormPath: 'none',
          },
        };

        axios
          .post(FormIoApi.updateUserEntryIdUrl(formUrl), data, {
            cancelToken: this.cancelTokenUsers.token,
          })
          .then(() => resolve())
          .catch((error) => reject(catchHttpError(error)));
      },
    );
  }

  public saveUsersReservation(
    formUrl: string,
    submission: ISubmission,
    token: string,
  ): Promise<IFormIoSaveUsersResponse> {
    return new Promise<IFormIoSaveUsersResponse>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();
      axios
        .post(FormIoApi.submitReservation(formUrl), submission, {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(() => resolve())
        .catch((error) => reject(catchHttpError(error)));
    });
  }
}

export default new FormIoApi();
