import { getLocation, LOCATION_CHANGE } from 'connected-react-router';

import routes from '@/routes/routes';
import { endSession, handlePaymentModal } from '@Model/authorization/actions';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { getUserInfo } from '@Model/authorization/selectors';
import { saveBill } from '@Model/reservation/actions';
import {
  getReservationDetails,
  getReservationPrintData,
} from '@Model/reservation/selectors';
import { allPermissions } from '@Model/state/constants/permissions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  catchPrintReceipt,
  clearPrinterCash,
  endPrinterSession,
  endPrinterSessionWithOutCashState,
  getPrinters,
  getPrintersPinging,
  mounted,
  printerPayment,
  printReceipt,
  printReport,
  pullDrawer,
  remove,
  resetState,
  save,
  savePrinter,
  startSession,
} from '../actions';
import { getPrinters as getPrintersSelector } from '../selectors';
import getUserPrinter from '../selectors/getUserPrinter';
import {
  IGetPrintersSuccessPayload,
  IPayment,
  IPrinterLine,
  IPrinterReceiptPayload,
  IStartPrinterSessionPayload,
} from '../types';

export * from './printerSession';

const PRINT_ERROR_TEXT = 'Błąd drukowania paragonu';
const PRINT_SESSION_ERROR_TEXT = 'Błąd ustawienia sesji drukarki';
const PRINT_ERROR_DAILY_REPORT_TEXT = 'Błąd drukowania raportu';
const REPORT_HAS_BEEN_DONE_TEXT =
  'Raport został wydrukowany i kwota na drukarce została wyzerowana';
const DRAWER_HAS_BEEN_PULED = 'Szyflada została wysynięta';
const DRAWER_ERROR_TEXT = 'Bład wysuniecia szuflady';
const CASH_TEXT = 'Gotówka';
const CARD_TEXT = 'Karta';
const TRANSFER_TEXT = 'Przelew';

const UPDATE_PRINTER_PAYMENT_SESSION_ERROR_TEXT =
  'Błąd aktualizacji wpłaty/wypłaty na drukarce';
const UPDATE_PRINTER_PAYMENT_SESSION_TEXT = 'Kwota została zaktualizowana';

const NO_ITEMS_TO_PRINT = 'Brak przedmiotów do wydrukowania';

export const requestSavePrinterOnSaveAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(save)),
    mergeMap$((action) => {
      return of$(savePrinter.request(action.payload));
    }),
  );
};

export const savePrinterWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(savePrinter.request)),
    mergeMap$((action) => {
      const { partnerId } = action.payload;

      const printerData = {
        ...action.payload,
        partnerId: partnerId ? Number(partnerId) : null,
      };

      return from$(printerApi.addSinglePrinter(printerData)).pipe(
        mergeMap$(() => {
          return of$(
            savePrinter.success(),
            resetState(),
            getPrinters.request(),
          );
        }),

        catchError$((error: Error) => {
          return of$(savePrinter.failure(error));
        }),
      );
    }),
  );
};

export const removePrinterWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(remove)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(printerApi.removePrinter(action.payload)).pipe(
        mergeMap$(() => {
          return [getPrinters.request()];
        }),
        catchError$((error: Error) => {
          return of$(getPrinters.failure(error));
        }),
      );
    }),
  );
};

export const requestFetchPrintersOnMountedAction: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] !==
        routes.summarySuccess.split('/')[1],
    ),
    mergeMap$(([action, state]) => {
      const { permissions } = getUserInfo(state);
      if (
        permissions &&
        permissions.includes(allPermissions.access_printers_read)
      ) {
        return of$(getPrinters.request());
      }
      return EMPTY$;
    }),
  );
};

export const fetchPrinterWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getPrinters.request)),
    mergeMap$(() => {
      return from$(printerApi.getPrinters()).pipe(
        map$((data: IGetPrintersSuccessPayload) => {
          return getPrinters.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => printerApi.cancelGetPrinters()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getPrinters.failure(error));
        }),
      );
    }),
  );
};

export const fetchPrinterPingingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getPrintersPinging.request)),
    mergeMap$(() => {
      return from$(printerApi.getPrinters()).pipe(
        map$((data: IGetPrintersSuccessPayload) => {
          return getPrintersPinging.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => printerApi.cancelGetPrinters()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getPrintersPinging.failure(error));
        }),
      );
    }),
  );
};

export const catchPrintReceiptWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchPrintReceipt)),
    mergeMap$(() => {
      return of$(printReceipt.request());
    }),
  );
};

export const printReceiptWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(printReceipt.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);

      const { permissions, session } = getUserInfo(state);

      const {
        transactionItems,
        revenue,
        invoice,
        paymenType,
        transactionProducts,
        transactionUuid,
        status,
      } = getReservationPrintData(state);

      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer,
        )
      ) {
        printerApi.reportBug(
          new Error('access_cashiersessions_write_without_printer'),
          '',
          '',
          JSON.stringify(session),
        );
        return of$(printReceipt.success());
      }

      if (status === 'init') {
        return of$(printReceipt.success());
      }

      const { calculatePricePerPerson } = getReservationDetails(
        state,
      ).details.happening;

      const PENNY_MULTIPLIER = 100;
      const priceSummary = Math.round(revenue * PENNY_MULTIPLIER);
      const isPrintingFacture = invoice && invoice.nip;

      const RECEIPT_URL = 'paragon';
      const INVOICE_URL = 'faktura';

      if (
        !userPrinter ||
        (!transactionItems && !transactionProducts) ||
        !priceSummary ||
        priceSummary === 0
      ) {
        printerApi.reportBug(
          new Error('NO_ITEMS_TO_PRINT'),
          '',
          '',
          JSON.stringify(session),
        );
        return of$(
          addToast(NO_ITEMS_TO_PRINT, TYPE_ERROR),
          printReceipt.failure({
            message: NO_ITEMS_TO_PRINT,
            name: 'EMPTY LINES',
          }),
        );
      }

      let { saleUrl } = userPrinter;
      const firmware = userPrinter.firmware || '1.0.0';

      const lines: IPrinterLine[] = transactionItems
        .filter((item) => item.price)
        .map((item) => {
          const { productName, vtp, price, quantity } = item;
          if (calculatePricePerPerson) {
            return {
              il: Number(quantity),
              na: productName,
              pr: Math.round((price / quantity) * PENNY_MULTIPLIER),
              vtp: vtp + ',00',
            };
          }
          return {
            il: 1,
            na: productName,
            pr: Math.round(price * PENNY_MULTIPLIER),
            vtp: vtp + ',00',
          };
        });

      const getProductsLines = (): IPrinterLine[] => {
        if (transactionProducts && transactionProducts.length) {
          return transactionProducts.map((product) => {
            const { productName, vtp, price, quantity } = product;
            return {
              il: Number(quantity),
              na: productName,
              pr: Math.round(price * PENNY_MULTIPLIER),
              vtp: vtp + ',00',
            };
          });
        }
        return [];
      };

      const getInvoiceData = () => {
        if (invoice && isPrintingFacture && firmware === '1.0.0') {
          // Old ver for hangar
          const isCash = paymenType === 'cash';
          const isCard = paymenType === 'card';
          const pt = isCash ? CASH_TEXT : isCard ? CARD_TEXT : TRANSFER_TEXT;

          const { name, numbers, nip: ni, zip, address, city } = invoice;

          if (!numbers || (numbers && !numbers.length)) {
            return {};
          }

          return {
            header: {
              na: [name, address, zip, city],
              nb: numbers[0],
              ni,
              pd: moment().format('YYYY-MM-DD'),
              pt,
            },
          };
        } else if (invoice && isPrintingFacture && firmware === '1.0.1') {
          const isCash = paymenType === 'cash';
          const isCard = paymenType === 'card';
          const pt = isCash ? CASH_TEXT : isCard ? CARD_TEXT : TRANSFER_TEXT;
          const { name, numbers, nip: ni, zip, address, city } = invoice;

          if (!numbers || (numbers && !numbers.length)) {
            return {};
          }

          return {
            header: {
              ad: [address, zip, city],
              na: [name],
              nb: numbers[0],
              ni,

              nm: 'Vat',

              ad_at: 0,
              ad_sc: 0,
              cc: 0,
              co: false,
              fn: 40,
              ln: 40,
              nb_at: 0,
              nb_sc: 0,
            },
          };
        }
        return {};
      };

      const getPaymentData = (): { payments: IPayment[] } | {} => {
        if (invoice && firmware === '1.0.0') {
          return {};
        }

        const isCash = paymenType === 'cash';
        const isCard = paymenType === 'card';
        const isTransfer = paymenType === 'bank_transfer';
        const na = isCash ? CASH_TEXT : isCard ? CARD_TEXT : TRANSFER_TEXT;

        const additionalParam = isPrintingFacture
          ? {
              na,
            }
          : {};

        const wa = priceSummary;
        const re = false;

        if (paymenType) {
          if (isCash) {
            return {
              payments: [{ ty: 0, wa, re, ...additionalParam }],
            };
          } else if (isCard) {
            return {
              payments: [{ ty: 2, wa, re, ...additionalParam }],
            };
          } else if (isTransfer) {
            return {
              payments: [{ ty: 6, wa, re, ...additionalParam }],
            };
          }
        }

        return {
          payments: [{ ty: 0, wa, re, ...additionalParam }],
        };
      };

      const getSummary = () => {
        if (isPrintingFacture) {
          return {
            summary: {
              to: priceSummary,
            },
          };
        }
        return {
          summary: {
            fp: priceSummary,
            to: priceSummary,
          },
        };
      };

      const printRequest: IPrinterReceiptPayload = {
        lines: [...lines, ...getProductsLines()],
        ...getSummary(),
        ...getInvoiceData(),
        ...getPaymentData(),
        extralines: [{ id: 0, na: transactionUuid.slice(0, 6) }],
      };

      if (
        printRequest &&
        printRequest.lines &&
        printRequest.lines.length === 0
      ) {
        printerApi.reportBug(
          new Error('NO_ITEMS_TO_PRINT'),
          '',
          '',
          JSON.stringify(session),
        );

        return of$(
          addToast(NO_ITEMS_TO_PRINT, TYPE_ERROR),
          printReceipt.failure({
            message: NO_ITEMS_TO_PRINT,
            name: 'EMPTY LINES',
          }),
        );
      }

      if (isPrintingFacture) {
        saleUrl = saleUrl + INVOICE_URL;
      } else {
        saleUrl = saleUrl + RECEIPT_URL;
      }

      return from$(
        printerApi.printReceipt(saleUrl, printRequest, session),
      ).pipe(
        mergeMap$(() => {
          return of$(
            saveBill(transactionUuid),
            pullDrawer(),
            printReceipt.success(),
          );
        }),

        catchError$((error) => {
          printerApi.reportBug(
            new Error(error),
            '',
            '',
            JSON.stringify(session),
          );
          if (error) {
            const { message } = JSON.parse(error);

            if (message && typeof message === 'string') {
              return of$(
                addToast(message, TYPE_ERROR),
                printReceipt.failure(error),
              );
            }
          }

          return of$(
            addToast(PRINT_ERROR_TEXT, TYPE_ERROR),
            printReceipt.failure(error),
          );
        }),
      );
    }),
  );
};

export const printerPaymentWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(printerPayment.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { cash, action: paymentAction } = action.payload;
      const { session } = getUserInfo(state);

      if (!session || cash === null) {
        return EMPTY$;
      }

      const { printer_id } = session;

      const printers = getPrintersSelector(state);
      const printer = printers.find((item) => item.id === printer_id) || null;

      if (!printer) {
        return EMPTY$;
      }

      const { saleUrl } = printer;

      const getPaymentParam = (): string => {
        const basicParam = `kw,${Math.round(cash * 100)}\nwp,`;

        if (paymentAction === 'income') {
          return `${basicParam}T`;
        }
        return `${basicParam}N`;
      };

      const startSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'cash', params: getPaymentParam() },
      ];

      return from$(
        printerApi.startSession(saleUrl, startSessionPayload, session),
      ).pipe(
        mergeMap$(() => {
          return of$(
            startSession.success(),
            handlePaymentModal(false),
            addToast(UPDATE_PRINTER_PAYMENT_SESSION_TEXT, TYPE_SUCCESS),
          );
        }),

        catchError$((error: Error) => {
          return of$(
            addToast(UPDATE_PRINTER_PAYMENT_SESSION_ERROR_TEXT, TYPE_ERROR),
            startSession.failure(error),
          );
        }),
      );
    }),
    catchError$((error: Error) => {
      return of$(
        addToast(UPDATE_PRINTER_PAYMENT_SESSION_ERROR_TEXT, TYPE_ERROR),
        startSession.failure(error),
      );
    }),
  );
};

export const endSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSession.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);

      const PENNY_MULTIPLIER = 100;

      const { payload } = action;

      if (!userPrinter || !payload || !payload.cashSummary) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      const cashStatusPayload = [{ cmd: 'scashstate' }];

      const { permissions, session } = getUserInfo(state);
      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer,
        )
      ) {
        return of$(endSession(), endPrinterSession.success());
      }

      const { startCash, endCash } = payload.cashSummary;

      const firmware = userPrinter.firmware || '1.0.0';

      return from$(
        printerApi.getCashStatus(saleUrl, cashStatusPayload, session),
      ).pipe(
        mergeMap$((data) => {
          if (
            data.ok &&
            data.hits.length &&
            data.hits[0] &&
            data.hits[0].results &&
            data.hits[0].results.length &&
            data.hits[0].results[0].cs
          ) {
            const cashBask = data.hits[0].results[0].cs;

            const endSessionPayload: IStartPrinterSessionPayload = [
              { cmd: 'cash', params: `kw,${cashBask}\nwp,N` },
              { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },

              { cmd: 'formstart', params: 'fn,200\nfh,48' },
              {
                cmd: 'formline',
                params: 'fn,200\nfl,474\ns1,Raport systemowej sesji',
              },
              {
                cmd: 'formline',
                params: 'fn,200\nfl,474\ns1,Poczatkowa kwota:',
              },
              {
                cmd: 'formline',
                params: `fn,200\nfl,113\ns1,${startCash / PENNY_MULTIPLIER}zl`,
              },

              {
                cmd: 'formline',
                params: 'fn,200\nfl,474\ns1,Koncowa kwota:',
              },
              {
                cmd: 'formline',
                params: `fn,200\nfl,113\ns1,${endCash / PENNY_MULTIPLIER}zl`,
              },
              { cmd: 'formend', params: 'fn,200' },

              { cmd: 'logout' },
            ];

            return from$(
              printerApi.startSession(saleUrl, endSessionPayload, session),
            ).pipe(
              mergeMap$(() => {
                return [endSession(), endPrinterSession.success()];
              }),
            );
          } else {
            if (firmware === '1.0.0') {
              return of$(endPrinterSessionWithOutCashState.request(payload));
            }
            return EMPTY$;
          }
        }),

        catchError$((error) => {
          if (firmware === '1.0.0') {
            return of$(endPrinterSessionWithOutCashState.request(payload));
          }

          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSession.failure(error),
          );
        }),
      );
    }),
  );
};

export const printReportPrinterWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(printReport)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { saleUrl } = action.payload;
      const { session } = getUserInfo(state);

      return from$(printerApi.printReport(saleUrl, session)).pipe(
        mergeMap$(() => {
          return [clearPrinterCash(action.payload)];
        }),

        catchError$(() => {
          return of$(addToast(PRINT_ERROR_DAILY_REPORT_TEXT, TYPE_ERROR));
        }),
      );
    }),
  );
};

export const clearPrinterWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(clearPrinterCash)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { saleUrl, firmware } = action.payload;
      const { session } = getUserInfo(state);

      const cashStatusPayload = [{ cmd: 'scashstate' }];

      return from$(
        printerApi.getCashStatus(saleUrl, cashStatusPayload, session),
      ).pipe(
        mergeMap$((data) => {
          if (
            data.ok &&
            data.hits.length &&
            data.hits[0] &&
            data.hits[0].results &&
            data.hits[0].results.length &&
            data.hits[0].results[0].cs
          ) {
            const cashBask = data.hits[0].results[0].cs;

            const endSessionPayload: IStartPrinterSessionPayload = [
              { cmd: 'cash', params: `kw,${cashBask}\nwp,N` },
            ];

            return from$(
              printerApi.startSession(saleUrl, endSessionPayload, session),
            ).pipe(
              mergeMap$(() => {
                return [addToast(REPORT_HAS_BEEN_DONE_TEXT, TYPE_ERROR)];
              }),
            );
          }
          return EMPTY$;
        }),

        catchError$(() => {
          if (firmware || '1.0.0' === '1.0.0') {
            return of$(endPrinterSessionWithOutCashState.request(undefined));
          }

          return of$(addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR));
        }),
      );
    }),
  );
};

export const pullDrawerWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi },
) => {
  return action$.pipe(
    filter$(isActionOf(pullDrawer)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);
      const { session } = getUserInfo(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      if (userPrinter.drawer) {
        return from$(printerApi.pullDrawer(saleUrl, session)).pipe(
          mergeMap$((data) => {
            return [addToast(DRAWER_HAS_BEEN_PULED, TYPE_ERROR)];
          }),

          catchError$(() => {
            return of$(addToast(DRAWER_ERROR_TEXT, TYPE_ERROR));
          }),
        );
      }

      return EMPTY$;
    }),
  );
};
