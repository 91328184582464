import React, { Component } from 'react';

import cn from 'classnames';
import styles from './Toast.module.scss';
import { IToastProps, IToastState } from './Toast.types';

const VISIBLE_TIME = 5000;
const ANIMATION_TIME = 1000;
export const HIDDEN = 'HIDDEN';
export const VISIBLE = 'VISIBLE';

class Toast extends Component<IToastProps, IToastState> {
  private interval: any;

  constructor(props: IToastProps) {
    super(props);

    this.state = {
      visibility: HIDDEN,
    };

    this.fade = this.fade.bind(this);
    this.kill = this.kill.bind(this);
    this.doAction = this.doAction.bind(this);
  }

  public componentDidMount() {
    if (this.props.data.action) {
      return;
    }

    this.setState(
      {
        visibility: VISIBLE,
      },
      () => {
        clearTimeout(this.interval);

        this.interval = setTimeout(this.fade, VISIBLE_TIME);
      },
    );
  }

  public componentDidUpdate(prevProps: IToastProps) {
    if (prevProps.data.type !== this.props.data.type) {
      clearTimeout(this.interval);

      this.setState(
        {
          visibility: VISIBLE,
        },
        () => {
          this.componentDidMount();
        },
      );
    }
  }

  public render() {
    const { text, type, action } = this.props.data;

    return (
      <div>
        <div
          className={cn(
            styles.toast,
            this.state.visibility === HIDDEN && styles.hidden,
          )}
        >
          <div className={cn(styles.inner, styles[type.toLowerCase()])}>
            {text}
            {action && (
              <button className={styles.button} onClick={this.doAction}>
                {action.text}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  private fade() {
    this.setState(
      {
        visibility: HIDDEN,
      },
      () => {
        this.interval = setTimeout(this.kill, ANIMATION_TIME);
      },
    );
  }

  private kill() {
    this.props.handleRemove(this.props.data.id);
  }

  private doAction() {
    const { action } = this.props.data;

    if (action) {
      this.props.handleDispatch(action.action);
    }
  }
}

export default Toast;
