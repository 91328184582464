import format from 'date-fns/format';
import { createSelector } from 'reselect';

import config from '@Config';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import { IZagrywkiBasket } from '@Model/basket/types';
import { getHappening } from '@Model/happening/selectors';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';
import {
  IHappeningMetadataItem,
  ISpaceMetadata,
} from '@Model/happenings/types';
import getSelectedDate from './getSelectedDate';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSpace from './getSelectedSpace';
import getSelectedUpSell from './getSelectedUpSell';

const getBasketData = createSelector(
  [
    getSelectedSpace,
    getSelectedDate,
    getSelectedSlot,
    getHappening,
    getSelectedPeopleCount,
    getSelectedConfigurationId,
    getSelectedUpSell,
  ],
  (
    selectedSpace,
    selectedDate,
    selectedSlot,
    selectedHappening,
    selectedPeopleCount,
    selectedConfigurationId,
    selectedUpSell,
  ) => {
    if (!selectedHappening || !selectedSpace) {
      return null;
    }

    const dateTime = selectedDate
      ? `${format(selectedDate, 'yyyy-MM-dd')}T${
          selectedSlot && selectedSlot.startTime ? selectedSlot.startTime : ''
        }+00:00`
      : null;

    const getPrice = (): number => {
      return 0;
    };

    const space = selectedHappening.spaces.find(
      (spaceItem) => spaceItem.id === selectedSpace.id,
    );

    const getNumberOfPeople = (): number => {
      if (selectedHappening && selectedSpace) {
        if (selectedHappening.calculatePricePerPerson && selectedPeopleCount) {
          return selectedPeopleCount;
        } else if (space) {
          return space.maxNumberOfPeople;
        }
      }

      return 0;
    };

    if (selectedHappening && selectedSpace) {
      const spaceMetadata = getMetadataForLanguage<ISpaceMetadata>([
        selectedSpace.metadata,
      ]).title;

      const happeningMetaData = getMetadataForLanguage<IHappeningMetadataItem>(
        selectedHappening.metadata,
      ).title;

      const getConfigurationId = (): number => {
        if (config.cms.showUnfinishedFeatures && selectedConfigurationId) {
          return selectedConfigurationId.rulePriceId;
        } else if (selectedConfigurationId) {
          return selectedConfigurationId.configurationId;
        }
        return -1;
      };

      const getPriceType = (): string => {
        if (
          config.cms.showUnfinishedFeatures &&
          selectedConfigurationId &&
          selectedConfigurationId.prices &&
          selectedConfigurationId.prices.length
        ) {
          const defaultPriceKey = selectedConfigurationId.prices.findIndex(
            (price) => price.type === 'default',
          );

          if (defaultPriceKey !== -1) {
            return selectedConfigurationId.prices[defaultPriceKey].type;
          }

          return selectedConfigurationId.prices[0].type;
        }
        return '';
      };

      const getHappeningProps = (): IZagrywkiBasket => {
        return {
          configurationId: getConfigurationId(),
          dateTime,
          duration: space && space.timeSlot ? space.timeSlot : -1,
          happeningId: selectedHappening.id || -1,
          id: Math.random(),
          isUpSellSelected: selectedUpSell,
          numberOfPeople: getNumberOfPeople(),
          price: getPrice(),
          priceType: getPriceType(),
          spaceId: selectedSpace.id,
          title: `${spaceMetadata} ${happeningMetaData}`,
        };
      };

      return getHappeningProps();
    }
    return null;
  },
);

export default getBasketData;
