import React from 'react';

import { Field } from 'formik';
import { Form, Grid, Button } from 'tabler-react';

import MyInput from '../../../../Input';
import MySelect from '../../../../Select';
import RulePerPerson from '../../RulePerPerson/RulePerPerson';
import { IFormLayoutProps } from '../FormLayout.types';

import styles from '../FormLayout.module.scss';

const SELECT_PRICE_TYPE_TEXT = 'Dodaj nową cene wg typu';
const ADD_UPSALE_TEXT = 'Dodaj upsell';
const RULE_PER_PERSON_TEXT = 'Reguła ważna dla liczby osób';
const DISCOUNT_TYPE = 'Typ obniżki';
const VALUE_PRICE_TEXT = 'wartość (PLN)';
const PERCENT_TEXT = 'procent';
const DISCOUNT_VALUE = 'Wartość obniżki';
const CURRENCY_TEXT = 'PLN';
const PRICES_TEXT = 'Ceny:';
const DEFAULT_PRICE_TEXT = 'Cena domyślna';

export const PriceField = (props: IFormLayoutProps) => {
  const { values, handleChange, setFieldValue, priceTypes } = props;

  const { closed, isUpsell, isPeopleCount, prices } = values;

  const catchPriceType = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value: type } = event.currentTarget;

    const newList = [
      ...values.prices,
      {
        currency: 'pln',
        type,
        value: 0,
      },
    ];
    setFieldValue('prices', newList);
  };

  const catchRemovePrice = (type?: string) => {
    if (type) {
      setFieldValue(
        'prices',
        values.prices.filter((price) => price.type !== type),
      );
    }
  };

  const sortedAddNewPriceAvailable = priceTypes.filter((priceType) => {
    return !values.prices.some((price) => price.type === priceType.type);
  });

  const translateDefaultPriceType = (type: string | undefined) => {
    if (type === 'default') {
      return DEFAULT_PRICE_TEXT;
    }
    return type;
  };

  return (
    <>
      {!!sortedAddNewPriceAvailable.length && (
        <Form.Group label={SELECT_PRICE_TYPE_TEXT}>
          <Form.Select onChange={catchPriceType}>
            <option value={-1}>-</option>
            {sortedAddNewPriceAvailable.map((priceType) => (
              <option key={priceType.type} value={priceType.type}>
                {translateDefaultPriceType(priceType.type)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}

      <Form.Group label={PRICES_TEXT}>
        {prices.map((price, key) => (
          <Grid.Row key={price.type} className={styles.priceRow}>
            <Grid.Col width={6}>
              {translateDefaultPriceType(price.type)}
            </Grid.Col>
            <Grid.Col width={3}>
              <Field
                name={`prices[${key}].value`}
                type="number"
                value={price.value}
                component={MyInput}
                disabled={closed}
              />
            </Grid.Col>
            <Grid.Col width={1}>{CURRENCY_TEXT}</Grid.Col>
            <Grid.Col width={1} className={styles.close}>
              <Button
                color="warning"
                size="sm"
                icon="x"
                onClick={() => catchRemovePrice(price.type)}
              />
            </Grid.Col>
          </Grid.Row>
        ))}
      </Form.Group>

      <Form.Group label={''}>
        <div className={styles.upsell}>
          <div>
            <div>
              <input
                name="isUpsell"
                type="checkbox"
                onChange={handleChange}
                checked={isUpsell}
              />
              {ADD_UPSALE_TEXT}
            </div>

            <div>
              <input
                name="isPeopleCount"
                type="checkbox"
                onChange={handleChange}
                checked={isPeopleCount}
              />
              {RULE_PER_PERSON_TEXT}
            </div>
          </div>
        </div>

        <RulePerPerson {...props} />

        {isUpsell && !closed && (
          <Grid.Row>
            <Grid.Col>
              <Form.Group label={DISCOUNT_TYPE}>
                <Field component={MySelect} name="upsellType">
                  <option value={0}>{VALUE_PRICE_TEXT}</option>
                  <option value={1}>{PERCENT_TEXT}</option>
                </Field>
              </Form.Group>
            </Grid.Col>
            <Grid.Col>
              <Form.Group label={DISCOUNT_VALUE}>
                <Field name="upsellValue" type="number" component={MyInput} />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        )}
      </Form.Group>
    </>
  );
};
