import { combineReducers } from 'redux';

import checkReducer from './check';
import dateRangeReducer from './dateRange';
import delayedTransactionsReducer from './delayedTransactions';
import detailsReducer from './details';
import detailsListReducer from './detailsList';
import empikReservationDetailsReducer from './empikReservationDetails';
import filtersReducer from './filters';
import reservationsReducer from './reservations';

const reducer = combineReducers({
  check: checkReducer,
  dateRange: dateRangeReducer,
  delayedTransactions: delayedTransactionsReducer,
  details: detailsReducer,
  detailsList: detailsListReducer,
  empikReservationDetails: empikReservationDetailsReducer,
  filters: filtersReducer,
  reservations: reservationsReducer,
});

export default reducer;
