import { add } from '@Model/happening/actions';
import { HAPPENING } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Add from './Add.component';
import { IAddFromDispatch, IAddFromState } from './Add.types';

const mapStateToProps = (state: _Store.IState): IAddFromState => {
  return {
    isLoading: getLoading(HAPPENING)(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IAddFromDispatch => {
  return {
    save: (happening) => {
      dispatch(add(happening));
    },
  };
};

export default connect<IAddFromState, IAddFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Add);
