import React from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table } from 'tabler-react';

import Configuration from '@Compo/BetterManager/DefinedConfigurations/components/Configuration';
import { ISortableItemProps } from './Sortable.types';

export const SortableItem = SortableElement(
  ({ value, handleRemove, handleEdit, ...rest }: ISortableItemProps) => (
    <Configuration
      configuration={value}
      handleRemoveConfiguration={handleRemove}
      handleEditConfiguration={handleEdit}
      handleActiveConfiguration={() => null}
      {...rest}
    />
  ),
);

export const SortableBody = SortableContainer(
  ({ children }: { children: any }) => {
    return <Table.Body>{children}</Table.Body>;
  },
);
