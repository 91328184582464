import React, { FC } from 'react';

import cn from 'classnames';
import { Icon } from 'tabler-react';
import AddressUrl from './components/AddressUrl';
import Menu from './components/Menu';
import { IImagesLibraryProps } from './ImagesLibrary.types';

import styles from './../AddingConfiguration/AddingConfiguration.module.scss';
import localStyles from './ImagesLibrary.module.scss';

const ImagesLibrary: FC<IImagesLibraryProps> = ({ close, url, setUrl }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <div className={localStyles.fullWidth}>
          <Icon className={localStyles.close} name="x" onClick={close} />

          <AddressUrl url={url} setUrl={setUrl} />
        </div>
      </div>
    </div>
  );
};

export default ImagesLibrary;
