import React, { ChangeEvent, Component, createRef, RefObject } from 'react';

import { Button, Form } from 'tabler-react';
import styles from './AddressUrl.module.scss';
import { IAddressUrlProps, IAddressUrlState } from './AddressUrl.types';

class AdressUrl extends Component<IAddressUrlProps, IAddressUrlState> {
  private img = new Image();

  private ref: RefObject<HTMLInputElement> = createRef();

  constructor(props: IAddressUrlProps) {
    super(props);

    this.state = {
      url: props.url || null,
    };

    this.change = this.change.bind(this);
    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.save = this.save.bind(this);

    this.img.onload = this.onLoad;
    this.img.onerror = this.onError;
  }

  public componentDidMount() {
    if (this.ref && this.ref.current) {
      this.ref.current.focus();
    }
  }

  public render() {
    const { url } = this.state;

    return (
      <div>
        <Form.Group label="Input Icon">
          <div className="input-icon">
            <span className="input-icon-addon">
              <i className="fe fe-link" />
            </span>
            <input
              className="form-control"
              placeholder="Wklej adres obrazu"
              onChange={this.change}
              defaultValue={this.state.url || ''}
              ref={this.ref}
            />
          </div>
        </Form.Group>

        <div className={styles.preview}>
          <div className={styles.label}>Podgląd</div>

          <div className={styles.image}>
            {url && <img src={url} />}

            {!url && (
              <div className={styles.info}>
                Ładowanie obrazu może chwile potrwać. Jeżeli nie widać podglądu
                wskazany URL moze byc niepoprawny.
                <br />
                <br />
                Tylko poprawnie załadowany obraz zostanie ustawiony.
              </div>
            )}
          </div>
        </div>

        <div className={styles.controls}>
          <Button.List>
            <Button color="primary" onClick={this.save}>
              Wybierz obraz
            </Button>
          </Button.List>
        </div>
      </div>
    );
  }

  private save() {
    this.props.setUrl(this.state.url);
  }

  private change(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.img.src = value;
  }

  private onLoad() {
    this.setState({
      url: this.img.src,
    });
  }

  private onError() {
    this.setState({
      url: null,
    });
  }
}

export default AdressUrl;
