import { combineReducers } from 'redux';
import {
  AUTHORIZATION,
  COMPANY_DATA,
  CONFIGURATIONS,
  DELAYED_TRANSACTIONS,
  DISCOUNT,
  DISCOUNTS,
  FORM_IO,
  HAPPENING,
  HAPPENING_AVAILABILITY,
  HAPPENINGS,
  PRINTER,
  REPORTS,
  RESERVATION,
  RESERVATION_CHECK,
  RESERVATIONS,
  USER,
} from './../constants/constants';

import createSubStateReducer from './createSubStateReducer';

export const reducers = {
  [CONFIGURATIONS]: createSubStateReducer(CONFIGURATIONS),
  [DISCOUNT]: createSubStateReducer(DISCOUNT),
  [DISCOUNTS]: createSubStateReducer(DISCOUNTS),
  [FORM_IO]: createSubStateReducer(FORM_IO),
  [HAPPENING]: createSubStateReducer(HAPPENING),
  [HAPPENING_AVAILABILITY]: createSubStateReducer(HAPPENING_AVAILABILITY),
  [HAPPENINGS]: createSubStateReducer(HAPPENINGS),
  [RESERVATION]: createSubStateReducer(RESERVATION),
  [RESERVATION_CHECK]: createSubStateReducer(RESERVATION_CHECK),
  [RESERVATIONS]: createSubStateReducer(RESERVATIONS),
  [AUTHORIZATION]: createSubStateReducer(AUTHORIZATION),
  [USER]: createSubStateReducer(USER),
  [PRINTER]: createSubStateReducer(PRINTER),
  [REPORTS]: createSubStateReducer(REPORTS),
  [COMPANY_DATA]: createSubStateReducer(COMPANY_DATA),
  [DELAYED_TRANSACTIONS]: createSubStateReducer(DELAYED_TRANSACTIONS),
};

export default combineReducers(reducers);
