import _Store from '@Store';
import { createSelector } from 'reselect';
import { IConsentsList, IFormIoUser } from '../types';
import getFormIoConsentsList from './getFormIoConsentsList';

const getFormIoSelectedUser = createSelector<
  _Store.IState,
  IConsentsList,
  IFormIoUser
>(
  [getFormIoConsentsList],
  (state) => state.selectedUser,
);

export default getFormIoSelectedUser;
