import React, { FC } from 'react';
import { Grid, Loader, Table } from 'tabler-react';

import Pagination from '../Pagination';
import { IReportsProps } from './ReportsTable.types';

import styles from './ReportsTable.module.scss';

export const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć obniżkę?';
export const CODE_TEXT = 'Kod';
export const DURATION_TEXT = 'Czas trwania';
export const EDIT_TEXT = 'Edytuj';
export const FROM_PRESENT_TEXT = 'o procent';
export const FROM_PRICE_TEXT = 'o kwotę';
export const HAPPENING_TEXT = 'Wydarzenie';
export const INFORMATION_TEXT = 'Informacje';
export const LIMIT_TEXT = 'Limit';
export const NO_BOUNDARIES_TEXT = '(bez ograniczeń)';
export const NO_TEXT = 'Nie';
export const REMOVE_TEXT = 'Usuń';
export const SUBTRACT_A_PERCENTAGE_TEXT = 'Obniż cenę';
export const SUBTRACT_AMOUNT_TEXT = 'Odejmnij kwotę';
export const TYPE_TEXT = 'Typ';
export const VALUE_TEXT = 'Wartość';
export const YES_TEXT = 'Tak';
export const SUM_TEXT = 'Całkowita suma';
export const EMPTY_DATA_TEXT = 'Brak danych';

const ReportsTable: FC<IReportsProps> = ({
  reports,
  selectedKeys,
  counts,
  isLoading,
}) => {
  const isEmpty = !(
    reports &&
    reports.length &&
    selectedKeys &&
    selectedKeys.length
  );

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <Grid.Col>
        <div className={styles.loader}>{EMPTY_DATA_TEXT}</div>
      </Grid.Col>
    );
  }

  const checkCount = (key: string): string => {
    key = key.toLocaleLowerCase();
    if (counts[key]) {
      return ` ${SUM_TEXT} ${counts[key]}`;
    }
    return '';
  };

  return (
    <>
      <Table
        responsive={true}
        highlightRowOnHover={true}
        hasOutline={true}
        verticalAlign="center"
        cards={true}
        className="text-nowrap"
      >
        <Table.Header>
          <Table.Row>
            {selectedKeys.map((key) => (
              <Table.ColHeader key={key}>{key}</Table.ColHeader>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {reports.map((report, index) => (
            <Table.Row key={index}>
              {selectedKeys.map((key) => (
                <Table.Col key={index + key}>{report[key] || ''}</Table.Col>
              ))}
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Row>
          {selectedKeys.map((key) => (
            <Table.Col key={key}>{checkCount(key)}</Table.Col>
          ))}
        </Table.Row>
      </Table>
      <Pagination />
    </>
  );
};

export default ReportsTable;
