import React from 'react';

import moment from 'moment-timezone';
import { Button, Table } from 'tabler-react';

import Modal from '@Compo/modals/Modal';
import Portal from '@Compo/modals/Portal';
import { IDetailsModalProps } from './ListModal';

import styles from './ListModal.module.scss';

const CANCEL_TEXT = 'Wyjdź';
const RESERVATION_ID_TEXT = 'ID rezerwacji';
const RESERVATION_TITLE_TEXT = 'Tytuł';
const RESERVATION_DATE_TEXT = 'Zakres trwania';

const ListModal = ({
  cancel,
  reservations,
  selectReservation,
}: IDetailsModalProps) => {
  if (reservations && reservations.length) {
    const timezoneStartDate = moment(reservations[0].start).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    const timezoneEndDate = moment(reservations[0].end).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    return (
      <Portal>
        <Modal>
          <div>{`${RESERVATION_DATE_TEXT} ${timezoneStartDate} - ${timezoneEndDate}`}</div>
          <div className={styles.wrapper}>
            <Table
              responsive={true}
              highlightRowOnHover={true}
              hasOutline={true}
              verticalAlign="center"
              cards={true}
              className="text-nowrap"
              style={{ flex: 1 }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>{RESERVATION_ID_TEXT}</Table.ColHeader>
                  <Table.ColHeader>{RESERVATION_TITLE_TEXT}</Table.ColHeader>
                  <Table.ColHeader alignContent="center">
                    <i className="icon-settings" />
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {reservations &&
                  reservations.map((reservation) => {
                    return (
                      <Table.Row key={reservation.id}>
                        <Table.Col>{reservation.id}</Table.Col>
                        <Table.Col>{reservation.title}</Table.Col>
                        <Table.Col alignContent="center">
                          <Button.List align="center">
                            <Button
                              color="primary"
                              size="sm"
                              // tslint:disable-next-line: jsx-no-lambda
                              onClick={() => selectReservation(reservation.id)}
                              icon="edit-3"
                            />
                          </Button.List>
                        </Table.Col>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <Button.List>
              <Button onClick={cancel} type="button" color="primary" icon="x">
                {CANCEL_TEXT}
              </Button>
            </Button.List>
          </div>
        </Modal>
      </Portal>
    );
  }
  return null;
};

export default ListModal;
