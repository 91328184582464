import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { getUserInfo } from '@Model/authorization/selectors';
import {
  addUser,
  getFormIOUsers,
  setEmail,
  setFirstName,
  setLastName,
} from '@Model/formio/actions';
import {
  getFormIoAdminUser,
  getFormIoAvailableUsers,
  getFormIoConsentsList,
  getFormIoEntryForm,
  getFormIoToken,
  getFormIoUsers,
} from '@Model/formio/selectors';
import { getEmpikReservationEntryCode } from '@Model/reservations/selectors';
import _Store from '@Store';
import FormIo from './FormIo.component';
import { IFormLayoutFromDispatch, IFormLayoutFromState } from './FormIo.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const text = getEmpikReservationEntryCode(state) || '';
  const { parameters } = getUserInfo(state);

  const getFormUrl = (): string => {
    if (parameters && parameters.length) {
      const formUrlObject = parameters.find((param) =>
        param.hasOwnProperty('form_io_transaction_form_url'),
      );
      if (formUrlObject) {
        return formUrlObject.form_io_transaction_form_url;
      }
    }
    return '';
  };

  const users = getFormIoUsers(state);
  const entryForm = getFormIoEntryForm(state);
  const formIoUser = getFormIoAdminUser(state);
  const { email, lastName, firstName } = getFormIoConsentsList(state);

  if (users && users.length) {
    return {
      availableUsers: getFormIoAvailableUsers(state),
      email,
      firstName,
      formIoUser,
      formUrl: getFormUrl(),
      lastName,
      submission: {
        data: {
          text: entryForm.data.text || text,
          uczestnicy: users,
        },
        modified: entryForm.modified,
      },
      token: getFormIoToken(state),
    };
  }

  return {
    availableUsers: getFormIoAvailableUsers(state),
    email,
    firstName,
    formIoUser,
    formUrl: getFormUrl(),
    lastName,
    submission: {
      data: {
        text,
        uczestnicy: [],
      },
      modified: '',
    },
    token: getFormIoToken(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => ({
  addUser: (user) => dispatch(addUser(user)),
  getFormIOUsers: () => dispatch(getFormIOUsers()),
  setEmail: (email) => dispatch(setEmail(email)),
  setFirstName: (firstName) => dispatch(setFirstName(firstName)),
  setLastName: (lastName) => dispatch(setLastName(lastName)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FormIo);
