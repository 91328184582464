import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from './history';

// App reducers
import appReducer from '@Model/app/reducer';
import authorizationReducer from '@Model/authorization/reducer';
import configurationsReducer from '@Model/configurations/reducer';
import discountReducer from '@Model/discount/reducer';
import discountsReducer from '@Model/discounts/reducer';
import formIoReducer from '@Model/formio/reducer';
import happeningReducer from '@Model/happening/reducer';
import happeningsReducer from '@Model/happenings/reducer';
import ruleTypesReducer from '@Model/priceTypes/reducer';
import printerReducer from '@Model/printer/reducer';
import productsReducer from '@Model/products/reducer';
import reportsReducer from '@Model/reports/reducer';
import reservationReducer from '@Model/reservation/reducer';
import reservationsReducer from '@Model/reservations/reducer';
import smartporterReducer from '@Model/smartporter/reducer';
import stateReducer from '@Model/state/reducer';
import staticsReducer from '@Model/statics/reducer';
import toastsReducer from '@Model/toasts/reducer';
import userReducer from '@Model/user/reducer';

const rootReducer = combineReducers({
  // Router
  router: connectRouter(history),

  // App reducers
  app: appReducer,
  authorization: authorizationReducer,
  configurations: configurationsReducer,
  discount: discountReducer,
  discounts: discountsReducer,
  formIo: formIoReducer,
  happening: happeningReducer,
  happenings: happeningsReducer,
  printer: printerReducer,
  products: productsReducer,
  reports: reportsReducer,
  reservation: reservationReducer,
  reservations: reservationsReducer,
  ruleTypes: ruleTypesReducer,
  smartporter: smartporterReducer,
  state: stateReducer,
  statics: staticsReducer,
  toasts: toastsReducer,
  user: userReducer,
});

export default rootReducer;
